import { Container, Button, Form, SpaceBetween, Link } from "@cloudscape-design/components-themed/components";
import React, { useCallback, useState } from "react";
import { useProfilesHandlers } from "@/presentation/providers";
import { useNavigate } from "react-router-dom";
import { PATH_PROFILES, PATHS } from "@/presentation/common-utils/constants";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { makeI18nStrings } from '@/presentation/components/I18nStrings';
import ProfilesDetails from "@/presentation/pages/profiles/profiles-details";
import ProfilesRules from "@/presentation/pages/profiles/profiles-rules";
import { makeNotification } from "@/presentation/pages";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import FormTags from '@/presentation/components/tags/form-tags';
import { useIsMounted } from '@/presentation/hooks';
import { makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
const ProfilesForm = (props) => {
    var _a;
    const [profileInfo, setProfileInfo] = useState(props.profileInfo);
    const { nameValidator, descriptionValidator, profiles, rules } = useProfilesHandlers();
    const navigate = useNavigate();
    const profilesHandler = profiles;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [nameError, setNameError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [tagsError, setTagsError] = useState(false);
    const [overrideNameErrorText, setOverrideNameErrorText] = useState("");
    const isMounted = useIsMounted();
    const onProfileInfoChange = useCallback((stateKey, newStepState) => {
        setProfileInfo(Object.assign(Object.assign({}, profileInfo), { [stateKey]: Object.assign(Object.assign({}, profileInfo[stateKey]), newStepState) }));
    }, [profileInfo]);
    const makeSuccessNotification = (message) => {
        const msg = 'Profile ' + ((props.isCreate || props.isClone) ? 'Created' : 'Updated') + ' Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to " +
            (props.isCreate || props.isClone ? "create" : "update") +
            ". Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const onCancel = () => {
        if (props.isEdit) {
            navigate(PATHS.PROFILES + '/' + profileInfo.details.id);
        }
        else {
            navigate(PATHS.PROFILES);
        }
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        if ((getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorType) === "UniqueNameViolation" && isMounted.current) {
            setNameError(true);
            setOverrideNameErrorText("Duplicate profile name. Please enter a unique profile name.");
        }
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onSubmit = () => {
        const nValid = nameValidator === null || nameValidator === void 0 ? void 0 : nameValidator.validate(profileInfo.details.name);
        const dValid = descriptionValidator === null || descriptionValidator === void 0 ? void 0 : descriptionValidator.validate(profileInfo.details.description);
        if (!(nValid === null || nValid === void 0 ? void 0 : nValid.valid) || !(dValid === null || dValid === void 0 ? void 0 : dValid.valid) || tagsError) {
            // Validation Error
            // Dont allow to create or update
            if (isMounted.current) {
                setNameError(!(nValid === null || nValid === void 0 ? void 0 : nValid.valid));
                setDescError(!(dValid === null || dValid === void 0 ? void 0 : dValid.valid));
            }
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('profile', (props.isCreate || props.isClone) ? 'create' : 'update')));
        }
        else {
            if (isMounted.current) {
                setNameError(false);
                setDescError(false);
            }
            if (props.isEdit) {
                // All good lets update
                profilesHandler
                    .handleUpdate(props.profileDetails, profileInfo)
                    .then((result) => {
                    var _a, _b;
                    if (((_b = (_a = result === null || result === void 0 ? void 0 : result.update_Profiles) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                        pushNotifications(makeSuccessNotification());
                        navigate(PATH_PROFILES.VIEW + "/" + profileInfo.details.id);
                    }
                    else {
                        pushNotifications(makeErrorNotification());
                    }
                }).catch(({ response }) => {
                    handleErrorResponse(response);
                });
            }
            else {
                // All good lets create
                profilesHandler
                    .handleCreate(profileInfo)
                    .then((result) => {
                    var _a, _b, _c;
                    const createdId = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Profiles) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id;
                    if (createdId) {
                        const navigateLink = React.createElement(Link, { fontSize: "inherit", color: "inverted", href: `${PATH_PROFILES.VIEW}/${createdId}` },
                            "#",
                            createdId);
                        const messsge = (React.createElement("span", null,
                            "Profile ",
                            navigateLink,
                            " Created Successfully"));
                        pushNotifications(makeSuccessNotification(messsge));
                        navigate(PATHS.PROFILES);
                    }
                    else {
                        pushNotifications(makeErrorNotification());
                    }
                }).catch(({ response }) => {
                    handleErrorResponse(response);
                });
            }
        }
    };
    const i18nStrings = makeI18nStrings(props, "Profile");
    const onTagsChange = (newtagsInfo) => {
        setTagsError(!(newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.valid));
        onProfileInfoChange("tags", { editorTags: (newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.tags) || [] });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'form-container' },
            React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: onCancel }, i18nStrings.cancelButton),
                    React.createElement(Button, { variant: "primary", onClick: onSubmit }, i18nStrings.submitButton)) },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(ProfilesDetails, { info: profileInfo, isEdit: props.isEdit, onChange: (newStepState) => onProfileInfoChange("details", newStepState), spacing: "l", validation: {
                            nameError,
                            descError,
                            overrideNameErrorText,
                        } }),
                    React.createElement(Container, null,
                        React.createElement(ProfilesRules, { info: profileInfo, setActiveStepIndex: () => { }, onChange: (newStepState) => onProfileInfoChange("rules", newStepState), rules: props.rules })),
                    React.createElement(FormTags, { tags: ((_a = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) || [], onChange: (newStepState) => {
                            onTagsChange(newStepState);
                        } }))))));
};
export default ProfilesForm;
