import React, { useEffect, useState } from "react";
import { Box, Container, FormField, Header, Icon, Link, Select, SpaceBetween, Textarea, ColumnLayout, Button } from "@cloudscape-design/components-themed/components";
import { getFieldOnChange } from "@/presentation/components";
import NameAndDescriptionForm from "@/presentation/components/form/name-description-form";
import { useIdentitiesHandlers } from "@/presentation/providers";
import { UserIdentitiesErrorCode, ValueWithLabel } from "@/presentation/pages/identities/identities-types";
import { IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE, PATHS, IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY, PATH_SETTINGS } from "@/presentation/common-utils/constants";
import { authenticationOptions } from "./constants";
import { getLogoAsUrl, makeNotification } from "@/presentation/pages";
import Skeleton from "react-loading-skeleton";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import MultiSelect from "@/presentation/components/multi-select/multi-select";
import FormAttributeEditor from "@/presentation/components/attribute-editor/form-attribute-editor";
import { useIsMounted } from '@/presentation/hooks';
import { SETTINGS_TABS } from "../settings/settings-types";
const getDefaultAuthentication = (authenticationMethod) => {
    if (authenticationMethod) {
        return authenticationOptions.filter(item => authenticationMethod === item.value)[0];
    }
    else {
        return authenticationOptions[0];
    }
};
const IdentityAuthenticationSettings = (props) => {
    var _a;
    const authenticationMethodOnChange = getFieldOnChange('select-id', 'authenticationMethod', props.onChange);
    const { info, validation, recheckErrors } = props;
    const [authenticationMethod, setAuthenticationMethod] = useState(info.details.authenticationMethod);
    const [selectedAuthenticationMethod, setSelectedAuthenticationMethod] = useState(undefined);
    const onCIDRChange = getFieldOnChange('input', 'cidrRange', props.onChange);
    const { identities, cidrRangesValidator, tenants, userIdentityKeyValueValidator } = useIdentitiesHandlers();
    const [cidrRange, setCidrRange] = useState(info.details.cidrRange);
    const [cidrError, setCidrError] = useState(validation.cidrError);
    const [cloudTenantsIDError, setCloudTenantsIDError] = useState(validation.cloudTenantsIDError);
    const [cloudTenantsError, setCloudTenantsError] = useState(validation.cloudTenantsError);
    const [cloudTenantsErrorMessage, setCloudTenantsErrorMessage] = useState(null);
    const [cloudTenantOptions, setCloudTenantOptions] = useState([]);
    const [selectedCloudTenantOption, setSelectedCloudTenantOption] = useState(undefined);
    const [cloudTenantsLoading, setCloudTenantsLoading] = useState(true);
    const [cloudTenantStructureOptions, setCloudTenantStructureOptions] = useState([]);
    const [selectedCloudTenantStructureOptions, setSelectedCloudTenantStructureOptions] = useState((_a = props.info.details) === null || _a === void 0 ? void 0 : _a.cloudTenants);
    const [cloudTenantsStructureStatus, setCloudTenantsStructureStatus] = useState('pending');
    const [invalidTenantIds, setInvalidTenantIds] = useState([]);
    const { pushNotifications, dismissNotification } = useNotifications();
    const cloudTenantsOnChange = getFieldOnChange('multi-select', 'cloudTenants', props.onChange);
    const identitiesHandler = identities;
    const cloudTenantsHandler = tenants;
    const isMounted = useIsMounted();
    const validatedOnCidrBlur = (val) => {
        const madeCidrRanges = identitiesHandler.removeSpacesFromRanges(val);
        const validationResult = cidrRangesValidator === null || cidrRangesValidator === void 0 ? void 0 : cidrRangesValidator.validate(madeCidrRanges);
        setCidrError(!validationResult.valid);
    };
    const validatedOnCidrchange = (event) => {
        onCIDRChange(event);
        setCidrRange(event.detail.value);
        validatedOnCidrBlur(event.detail.value);
    };
    useEffect(() => {
        setCidrError(validation.cidrError);
    }, [validation.cidrError]);
    useEffect(() => {
        setSelectedAuthenticationMethod(getDefaultAuthentication(authenticationMethod));
        if (authenticationMethod === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT) {
            fetchCloudTenantOptions();
        }
    }, [authenticationMethod]);
    useEffect(() => {
        setCloudTenantsIDError(validation.cloudTenantsIDError);
    }, [validation.cloudTenantsIDError]);
    useEffect(() => {
        setCloudTenantsError(validation.cloudTenantsError);
    }, [validation.cloudTenantsError]);
    const fetchCloudTenantOptions = () => {
        var _a;
        if (isMounted.current) {
            setCloudTenantsLoading(true);
        }
        (_a = cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.list()) === null || _a === void 0 ? void 0 : _a.then(listTenants => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (((_a = listTenants === null || listTenants === void 0 ? void 0 : listTenants.CloudTenants) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const options = (_c = (_b = listTenants === null || listTenants === void 0 ? void 0 : listTenants.CloudTenants) === null || _b === void 0 ? void 0 : _b.map((tenant) => {
                    var _a, _b;
                    return {
                        label: (_a = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _a !== void 0 ? _a : '',
                        value: String(tenant === null || tenant === void 0 ? void 0 : tenant.id),
                        iconUrl: getLogoAsUrl((_b = tenant === null || tenant === void 0 ? void 0 : tenant.Provider) === null || _b === void 0 ? void 0 : _b.name)
                    };
                })) !== null && _c !== void 0 ? _c : [];
                if (isMounted.current) {
                    setCloudTenantOptions(options);
                    const incomingSelectedOption = (_f = (_e = (_d = listTenants === null || listTenants === void 0 ? void 0 : listTenants.CloudTenants) === null || _d === void 0 ? void 0 : _d.filter((tenant) => (tenant === null || tenant === void 0 ? void 0 : tenant.id) === props.cloudTenantID)) === null || _e === void 0 ? void 0 : _e.map(tenant => {
                        var _a, _b;
                        return {
                            label: (_a = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _a !== void 0 ? _a : '',
                            value: String(tenant === null || tenant === void 0 ? void 0 : tenant.id),
                            iconUrl: getLogoAsUrl((_b = tenant === null || tenant === void 0 ? void 0 : tenant.Provider) === null || _b === void 0 ? void 0 : _b.name)
                        };
                    })) !== null && _f !== void 0 ? _f : [];
                    if ((incomingSelectedOption === null || incomingSelectedOption === void 0 ? void 0 : incomingSelectedOption.length) > 0 && props.cloudTenantID) {
                        setSelectedCloudTenantOption(incomingSelectedOption[0]);
                        fetchCloudTenantStructure(props.cloudTenantID);
                    }
                    // cloud details have been deleted in db, so set error
                    else if (props.cloudTenantID && (incomingSelectedOption === null || incomingSelectedOption === void 0 ? void 0 : incomingSelectedOption.length) === 0) {
                        setSelectedCloudTenantOption(undefined);
                        const notFoundTenants = (_j = (_h = (_g = props.info.details) === null || _g === void 0 ? void 0 : _g.cloudTenants) === null || _h === void 0 ? void 0 : _h.map(tenant => {
                            return {
                                value: tenant === null || tenant === void 0 ? void 0 : tenant.value,
                                isTenant: true,
                                path: '',
                                label: '',
                                labelSearchable: '[Not Found]',
                                tenantName: '',
                                isValid: false,
                                disabled: true,
                                disabledReason: "You don't have permission to select this option."
                            };
                        })) !== null && _j !== void 0 ? _j : [];
                        setCloudTenantStructureOptions(notFoundTenants);
                    }
                }
            }
        }).catch(console.error).finally(() => {
            if (isMounted.current) {
                setCloudTenantsLoading(false);
            }
        });
    };
    const openCloudTenantStructuresInNewTab = () => {
        const link = `${PATHS.SETTINGS}/${SETTINGS_TABS.CLOUD_TENANTS}/${selectedCloudTenantOption === null || selectedCloudTenantOption === void 0 ? void 0 : selectedCloudTenantOption.value}`;
        window.open(link, '_blank');
    };
    const fetchCloudTenantStructure = (cloudTenantID, updatedCloudTenantsOptions) => {
        var _a;
        if (isMounted.current) {
            setCloudTenantsLoading(true);
        }
        (_a = cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.get({
            id: cloudTenantID
        })) === null || _a === void 0 ? void 0 : _a.then(cloudTenant => {
            var _a, _b, _c, _d;
            const cloudTenantStructure = (_a = cloudTenant === null || cloudTenant === void 0 ? void 0 : cloudTenant.CloudTenants_by_pk) === null || _a === void 0 ? void 0 : _a.tenant_structure;
            if (cloudTenantStructure) {
                const currentSelectedCloudTenants = updatedCloudTenantsOptions !== null && updatedCloudTenantsOptions !== void 0 ? updatedCloudTenantsOptions : (_b = props.info.details) === null || _b === void 0 ? void 0 : _b.cloudTenants;
                const invalidTenants = currentSelectedCloudTenants === null || currentSelectedCloudTenants === void 0 ? void 0 : currentSelectedCloudTenants.filter(optionValue => !cloudTenantStructure[optionValue.value]).map(option => {
                    return {
                        value: option === null || option === void 0 ? void 0 : option.value,
                        isTenant: true,
                        path: '',
                        label: option === null || option === void 0 ? void 0 : option.value,
                        labelSearchable: '[Not Found]',
                        tenantName: '',
                        description: '[Not Found]',
                        isValid: false,
                        iconSvg: (React.createElement("span", { className: "invalid-option" })),
                    };
                });
                const options = cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.makeCloudTenantStructureSelectionOptions(cloudTenantStructure);
                let consolidatedOptions = [];
                if (invalidTenants.length > 0) {
                    const invalidTenantIdValues = (_c = invalidTenants === null || invalidTenants === void 0 ? void 0 : invalidTenants.map(tenant => tenant === null || tenant === void 0 ? void 0 : tenant.value)) !== null && _c !== void 0 ? _c : [];
                    setInvalidTenantIds(invalidTenantIdValues);
                    setCloudTenantsError(true);
                    setCloudTenantsErrorMessage("Please correct invalid tenant IDs");
                    const updatedInvalidTenants = invalidTenants.map((obj, index) => (Object.assign(Object.assign({}, obj), { disabled: true, disabledReason: "You don't have permission to select this option." })));
                    consolidatedOptions = [...options, ...updatedInvalidTenants];
                }
                else {
                    consolidatedOptions = options;
                }
                if (isMounted.current) {
                    const consolidatedSelectedOptions = [...options, ...invalidTenants];
                    setCloudTenantStructureOptions(consolidatedOptions);
                    setCloudTenantsStructureStatus('loading');
                    const tenants = (_d = currentSelectedCloudTenants === null || currentSelectedCloudTenants === void 0 ? void 0 : currentSelectedCloudTenants.map(tenant => tenant === null || tenant === void 0 ? void 0 : tenant.value)) !== null && _d !== void 0 ? _d : [];
                    const incomingSelectedOptions = consolidatedSelectedOptions.filter(option => tenants.includes(option === null || option === void 0 ? void 0 : option.value));
                    if ((incomingSelectedOptions === null || incomingSelectedOptions === void 0 ? void 0 : incomingSelectedOptions.length) > 0) {
                        setSelectedCloudTenantStructureOptions(incomingSelectedOptions);
                        const eventWithIncomingOptions = {
                            selectedOptions: incomingSelectedOptions
                        };
                        cloudTenantsOnChange(eventWithIncomingOptions);
                    }
                }
            }
            else {
                pushNotifications(makeNotification("error", 'Tenant Structure not found. Contact Admin', dismissNotification));
            }
        }).catch(console.error).finally(() => {
            if (isMounted.current) {
                setCloudTenantsLoading(false);
            }
        });
    };
    const handleOnLoadCloudTenantStructureOptions = (filteringText, pageNumber) => {
        return cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.fetchMultiSelectData(cloudTenantStructureOptions, filteringText, pageNumber);
    };
    const handleAuthenticationMethodChange = (event) => {
        authenticationMethodOnChange(event.detail);
        setAuthenticationMethod(event.detail.selectedOption.value);
        if (event.detail.selectedOption.value === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT) {
            fetchCloudTenantOptions();
        }
    };
    const handleCloudTenantOptionsChange = (event) => {
        const tenantID = parseInt(event.detail.selectedOption.value, 10);
        props.cloudTenantIDOnChange(tenantID);
        setCloudTenantsIDError(!tenantID);
        setCloudTenantsError(false);
        setCloudTenantsErrorMessage(null);
        setInvalidTenantIds([]);
        setSelectedCloudTenantOption(event.detail.selectedOption);
        setSelectedCloudTenantStructureOptions([]);
        const syntheticEmptyEvent = {
            selectedOptions: []
        };
        cloudTenantsOnChange(syntheticEmptyEvent);
        fetchCloudTenantStructure(tenantID, []);
    };
    const handleCloudTenantStructureOptionsChange = (event) => {
        var _a, _b, _c, _d;
        setSelectedCloudTenantStructureOptions(event.detail.selectedOptions);
        const tenants = (_b = (_a = event.detail.selectedOptions) === null || _a === void 0 ? void 0 : _a.map(tenant => tenant === null || tenant === void 0 ? void 0 : tenant.value)) !== null && _b !== void 0 ? _b : [];
        const filterInvalidTenantIds = (_c = invalidTenantIds.filter(option => !(tenants === null || tenants === void 0 ? void 0 : tenants.includes(option)))) !== null && _c !== void 0 ? _c : [];
        if (filterInvalidTenantIds.length > 0) {
            const temCloudTenantStructureOptions = [...cloudTenantStructureOptions];
            const updatedCloudTenantStructureOptions = temCloudTenantStructureOptions.filter(item => !(item === null || item === void 0 ? void 0 : item.disabled) && !filterInvalidTenantIds.includes(item.value));
            const removeInvalidItems = (invalidTenantIds === null || invalidTenantIds === void 0 ? void 0 : invalidTenantIds.filter(item => !filterInvalidTenantIds.includes(item))) || [];
            setInvalidTenantIds(removeInvalidItems);
            setCloudTenantStructureOptions(updatedCloudTenantStructureOptions);
        }
        cloudTenantsOnChange(event.detail);
        setCloudTenantsError(((_d = event.detail.selectedOptions) === null || _d === void 0 ? void 0 : _d.length) < 1);
    };
    const handleUserIdentities = (userIdentityInfo) => {
        props.onChange({ userIdentities: userIdentityInfo || [] });
    };
    const makeCloudTenantOptions = () => {
        if (authenticationMethod !== IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT) {
            return React.createElement(React.Fragment, null);
        }
        if (authenticationMethod === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT && cloudTenantsLoading) {
            return React.createElement(Skeleton, { count: 5 });
        }
        const getCloudTenantStructureLabel = () => {
            return React.createElement(React.Fragment, null,
                "Cloud Tenant Structure ",
                '( ',
                React.createElement(Link, { fontSize: "inherit", target: '_blank', href: `${PATH_SETTINGS.CLOUD_TENANTS_CREATE}` },
                    'Create a cloud tenant structure ',
                    React.createElement(Icon, { size: 'normal', name: 'external' })),
                ' )');
        };
        return React.createElement(SpaceBetween, { size: 'l' },
            React.createElement(FormField, { label: getCloudTenantStructureLabel(), description: "Structure representing the hierarchy of the organization's cloud tenants (defined in Settings)", errorText: cloudTenantsIDError ? 'Please select a cloud tenant structure' : '' },
                React.createElement("div", { className: "select-dropdown flex flex-wrap justify-between" },
                    React.createElement("div", { className: "w-4/6 grow mr-4 py-1" },
                        React.createElement(Select, { selectedOption: selectedCloudTenantOption, onChange: handleCloudTenantOptionsChange, options: cloudTenantOptions, placeholder: "Select Cloud Tenant Structure ", selectedAriaLabel: "Selected", triggerVariant: "option" })),
                    React.createElement("div", { className: "py-1" },
                        React.createElement(Button, { variant: "normal", disabled: !selectedCloudTenantOption, onClick: () => openCloudTenantStructuresInNewTab() },
                            "Open ",
                            React.createElement(Icon, { size: 'normal', name: 'external' }))))),
            React.createElement(FormField, { label: "Tenant IDs", description: "Tenants that this identity applies to e.g. accounts, projects, subscriptions or organisational groupings", errorText: cloudTenantsError ? `${cloudTenantsErrorMessage !== null && cloudTenantsErrorMessage !== void 0 ? cloudTenantsErrorMessage : 'Please select one or more tenant IDs'}` : '' },
                React.createElement("div", { className: 'select-dropdown multi' },
                    React.createElement(MultiSelect, { selectedOptions: selectedCloudTenantStructureOptions, onChange: handleCloudTenantStructureOptionsChange, options: cloudTenantStructureOptions, tokenLimit: 5, async: true, placeholderText: 'Select Tenant IDs', handleOnLoadItems: handleOnLoadCloudTenantStructureOptions, filteringPlaceholder: 'Find Tenant IDs', loadingText: 'Loading Tenant IDs', errorText: "Error fetching results.", recoveryText: 'Retry', emptyText: 'No Tenant IDs found', status: cloudTenantsStructureStatus, invalid: invalidTenantIds.length > 0 }))));
    };
    return React.createElement(Box, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Identity Settings") },
            React.createElement(SpaceBetween, { size: props.spacing },
                React.createElement(FormField, { label: "Identifier", description: "Method of identification when a request is sent to Kivera" },
                    React.createElement(Select, { selectedOption: selectedAuthenticationMethod, onChange: handleAuthenticationMethodChange, options: authenticationOptions, selectedAriaLabel: "Selected", triggerVariant: "option" })),
                authenticationMethod === IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE ?
                    React.createElement(FormField, { label: "CIDR Range(s)", description: "CIDR range(s) that this identity applies to", constraintText: "Comma separated list", errorText: cidrError ? 'Please enter one or more valid CIDR ranges' : '' },
                        React.createElement(Textarea, { "data-testid": "identity-details-form-identifier-cidr-range", value: cidrRange, onChange: (event) => {
                                validatedOnCidrchange(event);
                            }, placeholder: "e.g. 10.0.0.1/24, 10.0.2.5/16", onBlur: () => {
                                validatedOnCidrBlur(cidrRange);
                            } }))
                    : null,
                authenticationMethod === IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY ?
                    React.createElement(FormField, { label: React.createElement(Header, { variant: "h3" }, "User Identity Attributes"), description: "User identity field values that must match in order for this identity to apply" },
                        React.createElement("div", { style: { maxWidth: '75%', marginTop: "10px" } },
                            React.createElement(ColumnLayout, { columns: 2 },
                                React.createElement(ValueWithLabel, { label: "Attribute" }, "JSONPath format"),
                                React.createElement(ValueWithLabel, { label: "Value" }, "Glob pattern"))),
                        React.createElement(FormAttributeEditor, { items: info.details.userIdentities || [], onChange: (userIdentityDetails) => {
                                handleUserIdentities(userIdentityDetails);
                            }, addButtonText: "Add Attribute", removeButtonText: "Remove", itemLimit: 20, attributeKeyPlaceholder: "Enter Attribute", attributeValuePlaceholder: "Enter Value", recheckErrors: recheckErrors || false, itemLimitEntitySuffixSingle: "attribute", itemLimitEntitySuffixPlural: "attributes", attributeKeyValueValidator: userIdentityKeyValueValidator, attributeErrorCodeMappings: UserIdentitiesErrorCode }))
                    : null,
                makeCloudTenantOptions())));
};
const IdentitiesDetails = (props) => {
    const { nameValidator, descriptionValidator, tenants } = useIdentitiesHandlers();
    const args = Object.assign({ title: "Identity Details", resourceText: "identity", nameValidator,
        descriptionValidator,
        tenants }, props);
    return (React.createElement(SpaceBetween, { size: props.spacing },
        React.createElement(NameAndDescriptionForm, Object.assign({}, args)),
        React.createElement(IdentityAuthenticationSettings, Object.assign({}, props))));
};
export default IdentitiesDetails;
