export const INITIAL_TABLE_PAGE_INDEX = 0;
export const INITIAL_TABLE_PAGE_SIZE = 10;
/**
 * Modules - Table names and Date Options
 */
export const MONITORING_DATE_OPTIONS = 'monitoring_dates';
export const MONITORING_DATETIME_OPTIONS = 'monitoring_datetimes';
export const MONITORING_DATERANGE_OPTIONS = 'monitoring_daterange';
export const MONITORING_TABLE_OPTIONS = 'monitoring_table';
export const MONITORING_TABLE_SEARCH_QUERY = 'monitoring_table_search_query';
export const AUDIT_LOGS_DATE_OPTIONS = 'audit_logs_dates';
export const AUDIT_LOGS_DATETIME_OPTIONS = 'audit_logs_datetimes';
export const AUDIT_LOGS_DATERANGE_OPTIONS = 'audit_logs_daterange';
export const AUDIT_LOGS_TABLE_OPTIONS = 'audit_logs_table';
export const AUDIT_LOGS_TABLE_SEARCH_QUERY = 'audit_logs_table_search_query';
export const RULE_SIMULATOR_DATE_OPTIONS = 'rule_simulator_dates';
export const RULE_SIMULATOR_DATETIME_OPTIONS = 'rule_simulator_datetimes';
export const RULE_SIMULATOR_DATERANGE_OPTIONS = 'rule_simulator_daterange';
export const RULE_SIMULATOR_TABLE_OPTIONS = 'rule_simulator_table';
export const RULE_SIMULATOR_TABLE_SEARCH_QUERY = 'rule_simulator_table_search_query';
export const RULE_LOG_REDIRECTION = 'rule_log_redirection';
export const DOCU_KIVERA_REDIRECTION = 'docu_page_redirection_elems';
export const SUPPORT_KIVERA_REDIRECTION = 'support_page_redirection_elems';
export const DASHBOARD_OPTIONS = 'dashboard';
export const DASHBOARD_METABASE_LINK = 'dashboard_metabase_link_org_id_';
export const DASHBOARD_METABASE_BILLING_LINK = 'dashboard_metabase_billing_link_org_id_';
export const DASHBOARD_METABASE_REPORTING_LINK = 'dashboard_metabase_reporting_link_org_id_';
export const APPLICATIONS_TABLE_OPTIONS = 'applications_table';
export const APPLICATIONS_TABLE_SEARCH_QUERY = 'applications_table_search_query';
export const APPLICATIONS_DETAIL_PROFILES_TABLE_OPTIONS = 'applications_detail_profiles_table';
export const APPLICATIONS_TABLE_TAB_QUERY = 'applications_tab';
export const IDENTITIES_TABLE_OPTIONS = 'identities_table';
export const IDENTITIES_TABLE_SEARCH_QUERY = 'identities_table_search_query';
export const IDENTITIES_DETAIL_PROFILES_TABLE_OPTIONS = 'identities_detail_profiles_table';
export const IDENTITIES_TABLE_TAB_QUERY = 'identities_tab';
export const TRAILBLAZERS_TABLE_OPTIONS = 'trailblazers_table';
export const TRAILBLAZERS_TABLE_SEARCH_QUERY = 'trailblazers_table_search_query';
export const TRAILBLAZERS_DETAIL_IDENTITIES_TABLE_OPTIONS = 'trailblazers_detail_identities_table';
export const TRAILBLAZERS_TABLE_TAB_QUERY = 'trailblazers_tab';
export const GLOBAL_SERVICES_TABLE_OPTIONS = 'global_services_table';
export const GLOBAL_SERVICES_TABLE_SEARCH_QUERY = 'global_services_table_search_query';
export const POLICIES_TABLE_OPTIONS = 'policies_table';
export const POLICIES_TABLE_SEARCH_QUERY = 'policies_table_search_query';
export const PROFILES_TABLE_OPTIONS = 'profiles_table';
export const PROFILES_TABLE_SEARCH_QUERY = 'profiles_table_search_query';
export const PROFILES_DETAIL_RULES_TABLE_OPTIONS = 'profiles_detail_rules_table';
export const RULES_TABLE_OPTIONS = 'rules_table';
export const RULES_TABLE_SEARCH_QUERY = 'rules_table_search_query';
export const RULES_TABLE_TAB_QUERY = 'rules_tab';
export const RULES_DETAIL_POLICIES_TABLE_OPTIONS = 'rules_detail_policies_table';
export const RULES_DEPENDENCIES_RULES_TABLE_OPTIONS = 'rules_dependencies_rules_table';
export const RULE_PARAMETER_TABLE_OPTIONS = 'rule_parameter_table';
export const RULE_PARAMETER_TABLE_SEARCH_QUERY = 'rule_parameter_table_search_query';
export const RULE_PARAMETER_SEARCH_QUERY = 'rule_parameters_search_query';
export const RULE_PARAMETERS_AFFECTED_POLICIES_SEARCH_QUERY = 'rule_parameters_affected_policies_search_query';
export const PROXIES_DETAIL_APPLICATIONS_TABLE_OPTIONS = 'proxies_detail_applications_table';
export const PROXY_CARDS_SEARCH_QUERY = 'proxy_cards_search_query';
export const PROXIES_TABLE_OPTIONS = 'proxies_table';
export const PROXIES_TABLE_SEARCH_QUERY = 'proxies_table_search_query';
export const SETTINGS_USERS_TABLE_OPTIONS = 'settings_users_table';
export const MYPROFILE_ORGANIZATIONS_TABLE_OPTIONS = 'myprofile_organizations_table';
export const RULE_PARAMETERS_AFFECTED_POLICIES_TABLE_OPTIONS = 'rule_parameters_affected_policies_table';
export const APPLICATIONS_ASSOCIATED_PROXIES_TABLE_OPTIONS = 'applications_associated_proxies_table';
export const APPLICATIONS_ASSOCIATED_PROXIES_SEARCH_QUERY = 'applications_associated_proxies_search_query';
export const IDENTITIES_ASSOCIATED_PROXIES_TABLE_OPTIONS = 'applications_associated_proxies_table';
export const IDENTITIES_ASSOCIATED_PROXIES_SEARCH_QUERY = 'applications_associated_proxies_search_query';
export const IDENTITIES_ASSOCIATED_USERGROUP_TABLE_OPTIONS = 'identities_associated_usergroup_table';
export const IDENTITIES_ASSOCIATED_USERGROUP_SEARCH_QUERY = 'identities_associated_usergroup_search_query';
export const PROFILES_ASSOCIATED_IDENTITIES_TABLE_OPTIONS = 'profiles_associated_identities_table';
export const PROFILES_ASSOCIATED_IDENTITIES_SEARCH_QUERY = 'profiles_associated_identities_search_query';
export const MYPROFILE_USERAPITOKEN_TABLE_OPTIONS = 'myprofile_user_api_token_table';
export const REDIRECT_TOAST = 'redirect_toast';
export const DEFAULT_TOAST_TIMEOUT = 3000; // 3 seconds
export const SETTINGS_TABLE_SEARCH_QUERY = 'settings_table_search_query';
export const SETTINGS_TABLE_TAB_QUERY = 'settings_tab';
export const SETTINGS_NOTIFICATIONS_DESTINATIONS_TABLE_OPTIONS = 'settings_notifications_destinations_table';
export const SETTINGS_NOTIFICATIONS_DESTINATIONS_TABLE_SEARCH_QUERY = 'settings_notifications_destinations_table_search_query';
export const SETTINGS_NOTIFICATIONS_MONITORS_TABLE_OPTIONS = 'settings_notifications_monitors_table';
export const SETTINGS_NOTIFICATIONS_MONITORS_TABLE_SEARCH_QUERY = 'settings_notifications_monitors_table_search_query';
