var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler, } from '@/presentation/handlers';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { v4 as uuidv4 } from 'uuid';
import { IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH, IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE, IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY, IDENTITY_TYPE_AUTHENTICATION_TYPE_SUPER_SELECT_DEFAULT_OPTION, } from '@/presentation/common-utils/constants';
import { constructTagString } from '@/presentation/pages';
export var IDENTITY_TYPE;
(function (IDENTITY_TYPE) {
    IDENTITY_TYPE["APPLICATION"] = "APPLICATION";
    IDENTITY_TYPE["TENANT"] = "TENANT";
    IDENTITY_TYPE["USERGROUP"] = "USERGROUP";
})(IDENTITY_TYPE || (IDENTITY_TYPE = {}));
export const IdentitiesInitialValues = {
    id: 0,
    name: '',
    apiKey: '',
    description: '',
    profiles: [],
    cidrRanges: '',
    tags: [],
    identityAuthType: IDENTITY_TYPE_AUTHENTICATION_TYPE_SUPER_SELECT_DEFAULT_OPTION
};
export var IdentityConfigReadableText;
(function (IdentityConfigReadableText) {
    IdentityConfigReadableText["BASIC_AUTH"] = "Basic Authentication";
    IdentityConfigReadableText["CIDR_RANGE"] = "CIDR Ranges";
    IdentityConfigReadableText["CLOUD_TENANT"] = "Cloud Tenants";
    IdentityConfigReadableText["USER_IDENTITY"] = "User Identity";
})(IdentityConfigReadableText || (IdentityConfigReadableText = {}));
export class IdentitiesHandler extends PagesBaseHandler {
    constructor(remoteHandlers, remotes) {
        super();
        this.getCIDRRangeDetails = (authConfig) => {
            const cidrRanges = authConfig === null || authConfig === void 0 ? void 0 : authConfig.CIDR_RANGE;
            const result = (cidrRanges === null || cidrRanges === void 0 ? void 0 : cidrRanges.join(', ')) || '';
            return result;
        };
        this.getCloudTenantsDetails = (item) => {
            var _a, _b, _c, _d, _e, _f;
            const authConfig = (_a = item === null || item === void 0 ? void 0 : item.config) === null || _a === void 0 ? void 0 : _a.authentication;
            const itemCloudTenant = (item === null || item === void 0 ? void 0 : item.cloud_tenant) || [];
            const cloudTenants = (_b = authConfig === null || authConfig === void 0 ? void 0 : authConfig.CLOUD_TENANT) === null || _b === void 0 ? void 0 : _b.selected;
            const result = (cloudTenants === null || cloudTenants === void 0 ? void 0 : cloudTenants.join(', ')) || '';
            if (itemCloudTenant.length > 0 && ((_c = authConfig === null || authConfig === void 0 ? void 0 : authConfig.CLOUD_TENANT) === null || _c === void 0 ? void 0 : _c.id) && ((_d = itemCloudTenant[0]) === null || _d === void 0 ? void 0 : _d.id) === ((_e = authConfig === null || authConfig === void 0 ? void 0 : authConfig.CLOUD_TENANT) === null || _e === void 0 ? void 0 : _e.id)) {
                const itemCloudTenantName = (_f = itemCloudTenant[0]) === null || _f === void 0 ? void 0 : _f.name;
                return `${itemCloudTenantName} (${result})`;
            }
            return result;
        };
        this.getUserIdentityDetails = (authConfig) => {
            var _a;
            const mappedData = (_a = authConfig === null || authConfig === void 0 ? void 0 : authConfig.USER_IDENTITY) === null || _a === void 0 ? void 0 : _a.mapping;
            const keyValueStrings = mappedData ? Object.entries(mappedData).map(([key, value]) => `${key}: ${value}`).join(', ') : '';
            return keyValueStrings;
        };
        this.getIdentifierDetails = (item) => {
            var _a, _b;
            const identityName = (item === null || item === void 0 ? void 0 : item.identity_type) || '';
            switch (identityName) {
                case 'BASIC_AUTH':
                    return `Name: ${item.name}, Password: ******`;
                case 'CIDR_RANGE':
                    return this.getCIDRRangeDetails((_a = item === null || item === void 0 ? void 0 : item.config) === null || _a === void 0 ? void 0 : _a.authentication);
                case 'CLOUD_TENANT':
                    return this.getCloudTenantsDetails(item);
                case 'USER_IDENTITY':
                    return this.getUserIdentityDetails((_b = item === null || item === void 0 ? void 0 : item.config) === null || _b === void 0 ? void 0 : _b.authentication);
                default:
                    return '';
            }
        };
        this.makeDefaultIdentitySelectionOption = (proxyIdentities, defaultIdentity) => {
            var _a, _b;
            const selectedIdentity = proxyIdentities.filter(identity => String(identity === null || identity === void 0 ? void 0 : identity.id) === defaultIdentity);
            if ((selectedIdentity === null || selectedIdentity === void 0 ? void 0 : selectedIdentity.length) === 1) {
                return {
                    label: (_a = selectedIdentity === null || selectedIdentity === void 0 ? void 0 : selectedIdentity[0]) === null || _a === void 0 ? void 0 : _a.name,
                    value: String((_b = selectedIdentity === null || selectedIdentity === void 0 ? void 0 : selectedIdentity[0]) === null || _b === void 0 ? void 0 : _b.id)
                };
            }
            return {};
        };
        this.formattedListEntities = (elements) => {
            const formatted = elements === null || elements === void 0 ? void 0 : elements.map((element, i) => {
                return {
                    key: element,
                    description: element
                };
            });
            return formatted !== null && formatted !== void 0 ? formatted : [];
        };
        this.formattedListUserGroups = (elements) => {
            const formatted = elements === null || elements === void 0 ? void 0 : elements.map((element, i) => {
                return {
                    key: element,
                    description: element
                };
            });
            return formatted !== null && formatted !== void 0 ? formatted : [];
        };
        this.setListIdentities = (listIdentities) => {
            this.listIdentities = listIdentities;
            return this;
        };
        this.getFormattedOptions = (listIdentities) => {
            var _a;
            return (_a = listIdentities === null || listIdentities === void 0 ? void 0 : listIdentities.Identities) === null || _a === void 0 ? void 0 : _a.map((identity) => {
                return { value: identity.id.toString(), label: identity.name };
            });
        };
        this.makeIdentityConfig = (stepsInfo, cloudTenantID) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            let cidrRanges = [];
            if ((_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _a === void 0 ? void 0 : _a.cidrRange) {
                cidrRanges = this.makeCIDRRanges(stepsInfo.details.cidrRange);
            }
            let config = {};
            if (((_b = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _b === void 0 ? void 0 : _b.authenticationMethod) === IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH) {
                config = {
                    authentication: {
                        BASIC_AUTH: (_c = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _c === void 0 ? void 0 : _c.apiKey
                    }
                };
            }
            else if (((_d = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _d === void 0 ? void 0 : _d.authenticationMethod) === IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE) {
                config = {
                    authentication: {
                        CIDR_RANGE: cidrRanges
                    }
                };
            }
            else if (((_e = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _e === void 0 ? void 0 : _e.authenticationMethod) === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT) {
                const selectedOptions = (_j = (_h = (_g = (_f = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _f === void 0 ? void 0 : _f.cloudTenants) === null || _g === void 0 ? void 0 : _g.filter(tenant => !!(tenant === null || tenant === void 0 ? void 0 : tenant.value))) === null || _h === void 0 ? void 0 : _h.map(tenant => tenant === null || tenant === void 0 ? void 0 : tenant.value)) !== null && _j !== void 0 ? _j : [];
                config = {
                    authentication: {
                        CLOUD_TENANT: {
                            id: cloudTenantID,
                            selected: selectedOptions
                        }
                    }
                };
            }
            else if (((_k = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _k === void 0 ? void 0 : _k.authenticationMethod) === IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY) {
                const userIdentityOptions = {};
                (_m = (_l = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _l === void 0 ? void 0 : _l.userIdentities) === null || _m === void 0 ? void 0 : _m.filter(item => item.key !== "" || item.value !== "").forEach(item => {
                    userIdentityOptions[item.key] = [item.value];
                });
                config = {
                    authentication: {
                        USER_IDENTITY: {
                            mapping: Object.assign({}, userIdentityOptions)
                        }
                    }
                };
            }
            return config;
        };
        this.getCreateParams = (orgID, stepsInfo, cloudTenantID) => {
            var _a, _b;
            return {
                name: stepsInfo.details.name,
                description: stepsInfo.details.description,
                config: this.makeIdentityConfig(stepsInfo, cloudTenantID),
                organization_id: orgID,
                identity_type: stepsInfo.details.authenticationMethod,
                tags: ((_b = (_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) === null || _b === void 0 ? void 0 : _b.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!tag.key).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                })) || [],
                profiles: stepsInfo.profiles.attachedProfiles.map(profile => {
                    return {
                        profile_id: profile.id
                    };
                })
            };
        };
        this.getUpdateIdentityProfilesParams = (identity, stepsInfo) => {
            var _a, _b, _c, _d;
            const initialProfiles = (_d = (_c = (_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.IdentityProfiles) === null || _c === void 0 ? void 0 : _c.map(profile => profile.profile_id)) === null || _d === void 0 ? void 0 : _d.filter(id => !!id);
            const toBeUpdatedProfiles = stepsInfo.profiles.attachedProfiles.map(profile => profile.id);
            // the below makes a difference of what is present in initialProfiles, but not in toBeUpdatedProfiles
            const toBeDeletedProfiles = initialProfiles.filter(id => !toBeUpdatedProfiles.includes(id));
            const profileObjects = toBeUpdatedProfiles.map((profileID) => {
                var _a, _b, _c;
                return {
                    profile_id: profileID,
                    identity_id: (_c = (_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
                    deleted: false
                };
            });
            toBeDeletedProfiles.map(profileID => {
                var _a, _b, _c;
                profileObjects.push({
                    profile_id: profileID,
                    identity_id: (_c = (_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
                    deleted: true
                });
            });
            return profileObjects;
        };
        this.getIdentityUpdateParams = (identity, stepsInfo, cloudTenantID) => {
            var _a, _b;
            return {
                id: stepsInfo.details.id,
                description: stepsInfo.details.description,
                identity_type: stepsInfo.details.authenticationMethod,
                tags: ((_b = (_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) === null || _b === void 0 ? void 0 : _b.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!(tag === null || tag === void 0 ? void 0 : tag.key)).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                })) || [],
                config: this.makeIdentityConfig(stepsInfo, cloudTenantID),
            };
        };
        this.removeSpacesFromRanges = (rangeText) => {
            let cidrRanges = [];
            if (rangeText) {
                cidrRanges = rangeText.split(',').map(range => range.trim());
            }
            return cidrRanges.join(',');
        };
        this.makeCIDRRanges = (rangeText) => {
            let cidrRanges = [];
            if (rangeText) {
                cidrRanges = rangeText.split(',').map(range => range.trim());
            }
            return cidrRanges;
        };
        this.getAPIKeyFromConfig = (config) => {
            var _a, _b;
            return (_b = (_a = config === null || config === void 0 ? void 0 : config.authentication) === null || _a === void 0 ? void 0 : _a.BASIC_AUTH) !== null && _b !== void 0 ? _b : '';
        };
        this.getCIDRRangeFromConfig = (config) => {
            var _a, _b;
            return (_b = (_a = config === null || config === void 0 ? void 0 : config.authentication) === null || _a === void 0 ? void 0 : _a.CIDR_RANGE) !== null && _b !== void 0 ? _b : [];
        };
        this.getCloudTenantDetailsFromConfig = (config) => {
            var _a;
            return (_a = config === null || config === void 0 ? void 0 : config.authentication) === null || _a === void 0 ? void 0 : _a.CLOUD_TENANT;
        };
        this.getUserIdentitiesDetailsFromConfig = (config) => {
            var _a;
            return (_a = config === null || config === void 0 ? void 0 : config.authentication) === null || _a === void 0 ? void 0 : _a.USER_IDENTITY;
        };
        this.createIdentity = (stepsInfo, cloudTenantID) => __awaiter(this, void 0, void 0, function* () {
            const token = yield this.remotes.identities.getToken();
            const orgID = getOrgIDFromToken(token);
            const identityParams = this.getCreateParams(orgID, stepsInfo, cloudTenantID);
            return this.remotes.identities.create(identityParams);
        });
        this.updateIdentity = (identity, stepsInfo, cloudTenantID) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const identityParams = this.getIdentityUpdateParams(identity, stepsInfo, cloudTenantID);
            const identityProfilesInsertInputs = this.getUpdateIdentityProfilesParams(identity, stepsInfo);
            const updateProfileParams = {
                objects: identityProfilesInsertInputs
            };
            const result = yield this.remotes.identities.update(identityParams);
            if (result.update_Identities.returning.length > 0) {
                const profilesAttached = yield this.remotes.identities.attachProfiles(updateProfileParams);
                if (((_b = (_a = profilesAttached === null || profilesAttached === void 0 ? void 0 : profilesAttached.insert_IdentityProfiles) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) === identityProfilesInsertInputs.length) {
                    return result;
                }
            }
            return undefined;
        });
        this.remotes = remotes;
        this.remoteHandlers = remoteHandlers;
    }
    getItemID() {
        return 'id';
    }
    getEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.identities.get({
                id: id
            });
        });
    }
    deleteEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.identities.delete({
                id: id
            });
        });
    }
    deleteBulk(ids) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.remotes.identities.deleteBulk({
                ids: ids
            });
            return {
                success: ((_b = (_a = result === null || result === void 0 ? void 0 : result.update_Identities) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) === ids.length,
                response: result
            };
        });
    }
    getNonDeletedItems(items, entityID) {
        return items.filter((item) => item.id !== entityID);
    }
    getDataCounts(identitiesData) {
        var _a;
        let rulesCount = 0;
        let profilesList = [];
        if (identitiesData.length > 0) {
            profilesList = (_a = identitiesData.map(identityProfiles => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                const tempRuleCount = (_d = (_c = (_b = (_a = identityProfiles === null || identityProfiles === void 0 ? void 0 : identityProfiles.Profile) === null || _a === void 0 ? void 0 : _a.ProfileRules_aggregate) === null || _b === void 0 ? void 0 : _b.aggregate) === null || _c === void 0 ? void 0 : _c.count) !== null && _d !== void 0 ? _d : 0;
                rulesCount += tempRuleCount;
                return {
                    id: identityProfiles === null || identityProfiles === void 0 ? void 0 : identityProfiles.profile_id,
                    name: (_e = identityProfiles === null || identityProfiles === void 0 ? void 0 : identityProfiles.Profile) === null || _e === void 0 ? void 0 : _e.name,
                    description: (_f = identityProfiles === null || identityProfiles === void 0 ? void 0 : identityProfiles.Profile) === null || _f === void 0 ? void 0 : _f.description,
                    numberOfRules: (_k = (_j = (_h = (_g = identityProfiles === null || identityProfiles === void 0 ? void 0 : identityProfiles.Profile) === null || _g === void 0 ? void 0 : _g.ProfileRules_aggregate) === null || _h === void 0 ? void 0 : _h.aggregate) === null || _j === void 0 ? void 0 : _j.count) !== null && _k !== void 0 ? _k : 0
                };
            })) === null || _a === void 0 ? void 0 : _a.filter(profile => !!(profile === null || profile === void 0 ? void 0 : profile.id));
        }
        return { rulesCount, profilesList };
    }
    getFormattedEntity(identity) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61;
        const profilesAggregateCount = ((_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Profiles_aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count) || 0;
        const profileCount = (_g = (_f = (_e = (_d = (_c = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.IdentityProfiles_aggregate) === null || _e === void 0 ? void 0 : _e.aggregate) === null || _f === void 0 ? void 0 : _f.count) !== null && _g !== void 0 ? _g : 0;
        const { rulesCount, profilesList } = this.getDataCounts((_j = (_h = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.IdentityProfiles);
        return {
            id: (_l = (_k = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.id,
            searchableID: String((_o = (_m = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o.id),
            name: (_r = (_q = (_p = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.name) !== null && _r !== void 0 ? _r : '',
            type: (_u = (_t = (_s = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _s === void 0 ? void 0 : _s[0]) === null || _t === void 0 ? void 0 : _t.identity_type) !== null && _u !== void 0 ? _u : '',
            description: (_x = (_w = (_v = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.description) !== null && _x !== void 0 ? _x : '',
            identifier: (_0 = (_z = (_y = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _y === void 0 ? void 0 : _y[0]) === null || _z === void 0 ? void 0 : _z.identity_type) !== null && _0 !== void 0 ? _0 : '',
            attachedRulesCount: rulesCount,
            attachedProfilesCount: profileCount,
            counterAccepts: (_6 = (_5 = (_4 = (_3 = (_2 = (_1 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _1 === void 0 ? void 0 : _1[0]) === null || _2 === void 0 ? void 0 : _2.Counters_aggregate) === null || _3 === void 0 ? void 0 : _3.aggregate) === null || _4 === void 0 ? void 0 : _4.sum) === null || _5 === void 0 ? void 0 : _5.counter_accepts) !== null && _6 !== void 0 ? _6 : 0,
            counterDenials: (_12 = (_11 = (_10 = (_9 = (_8 = (_7 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _7 === void 0 ? void 0 : _7[0]) === null || _8 === void 0 ? void 0 : _8.Counters_aggregate) === null || _9 === void 0 ? void 0 : _9.aggregate) === null || _10 === void 0 ? void 0 : _10.sum) === null || _11 === void 0 ? void 0 : _11.counter_denials) !== null && _12 !== void 0 ? _12 : 0,
            counterLogs: (_18 = (_17 = (_16 = (_15 = (_14 = (_13 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _13 === void 0 ? void 0 : _13[0]) === null || _14 === void 0 ? void 0 : _14.Counters_aggregate) === null || _15 === void 0 ? void 0 : _15.aggregate) === null || _16 === void 0 ? void 0 : _16.sum) === null || _17 === void 0 ? void 0 : _17.counter_notifies) !== null && _18 !== void 0 ? _18 : 0,
            counterTotal: (_24 = (_23 = (_22 = (_21 = (_20 = (_19 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _19 === void 0 ? void 0 : _19[0]) === null || _20 === void 0 ? void 0 : _20.Counters_aggregate) === null || _21 === void 0 ? void 0 : _21.aggregate) === null || _22 === void 0 ? void 0 : _22.sum) === null || _23 === void 0 ? void 0 : _23.counter_total_request) !== null && _24 !== void 0 ? _24 : 0,
            apiKey: this.getAPIKeyFromConfig((_26 = (_25 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _25 === void 0 ? void 0 : _25[0]) === null || _26 === void 0 ? void 0 : _26.config),
            cidrRange: this.getCIDRRangeFromConfig((_28 = (_27 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _27 === void 0 ? void 0 : _27[0]) === null || _28 === void 0 ? void 0 : _28.config),
            identityProfiles: profilesList,
            tags: (_31 = (_30 = (_29 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _29 === void 0 ? void 0 : _29[0]) === null || _30 === void 0 ? void 0 : _30.tags) !== null && _31 !== void 0 ? _31 : [],
            profilesAggregateCount,
            created_at: (_34 = (_33 = (_32 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _32 === void 0 ? void 0 : _32[0]) === null || _33 === void 0 ? void 0 : _33.created_at) !== null && _34 !== void 0 ? _34 : '',
            created_by: (_38 = (_37 = (_36 = (_35 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _35 === void 0 ? void 0 : _35[0]) === null || _36 === void 0 ? void 0 : _36.CreatedByUser) === null || _37 === void 0 ? void 0 : _37.id) !== null && _38 !== void 0 ? _38 : '',
            updated_at: (_41 = (_40 = (_39 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _39 === void 0 ? void 0 : _39[0]) === null || _40 === void 0 ? void 0 : _40.updated_at) !== null && _41 !== void 0 ? _41 : '',
            updated_by: (_45 = (_44 = (_43 = (_42 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _42 === void 0 ? void 0 : _42[0]) === null || _43 === void 0 ? void 0 : _43.UpdatedByUser) === null || _44 === void 0 ? void 0 : _44.id) !== null && _45 !== void 0 ? _45 : '',
            createdByFullName: ((_47 = (_46 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _46 === void 0 ? void 0 : _46[0]) === null || _47 === void 0 ? void 0 : _47.CreatedByUser) ? `${((_50 = (_49 = (_48 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _48 === void 0 ? void 0 : _48[0]) === null || _49 === void 0 ? void 0 : _49.CreatedByUser) === null || _50 === void 0 ? void 0 : _50.given_name) || ''} ${((_53 = (_52 = (_51 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _51 === void 0 ? void 0 : _51[0]) === null || _52 === void 0 ? void 0 : _52.CreatedByUser) === null || _53 === void 0 ? void 0 : _53.family_name) || ''}` : '',
            updatedByFullName: ((_55 = (_54 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _54 === void 0 ? void 0 : _54[0]) === null || _55 === void 0 ? void 0 : _55.UpdatedByUser) ? `${((_58 = (_57 = (_56 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _56 === void 0 ? void 0 : _56[0]) === null || _57 === void 0 ? void 0 : _57.UpdatedByUser) === null || _58 === void 0 ? void 0 : _58.given_name) || ''} ${((_61 = (_60 = (_59 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _59 === void 0 ? void 0 : _59[0]) === null || _60 === void 0 ? void 0 : _60.UpdatedByUser) === null || _61 === void 0 ? void 0 : _61.family_name) || ''}` : '',
        };
    }
    getFormattedEntities(identities) {
        return identities.Identities.map((identity) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            const profileCount = (_c = (_b = (_a = identity === null || identity === void 0 ? void 0 : identity.IdentityProfiles_aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count) !== null && _c !== void 0 ? _c : 0;
            const { rulesCount } = this.getDataCounts(identity === null || identity === void 0 ? void 0 : identity.IdentityProfiles);
            return {
                id: identity === null || identity === void 0 ? void 0 : identity.id,
                searchableID: String(identity === null || identity === void 0 ? void 0 : identity.id),
                key: String(identity === null || identity === void 0 ? void 0 : identity.id),
                name: (_d = identity === null || identity === void 0 ? void 0 : identity.name) !== null && _d !== void 0 ? _d : '',
                identifier: (_e = identity === null || identity === void 0 ? void 0 : identity.identity_type) !== null && _e !== void 0 ? _e : '',
                type: (_f = identity === null || identity === void 0 ? void 0 : identity.identity_type) !== null && _f !== void 0 ? _f : '',
                description: (_g = identity === null || identity === void 0 ? void 0 : identity.description) !== null && _g !== void 0 ? _g : '',
                attachedRulesCount: rulesCount,
                attachedProfilesCount: profileCount,
                identifierReadableName: (_h = IdentityConfigReadableText === null || IdentityConfigReadableText === void 0 ? void 0 : IdentityConfigReadableText[identity === null || identity === void 0 ? void 0 : identity.identity_type]) !== null && _h !== void 0 ? _h : '',
                tagString: constructTagString((identity === null || identity === void 0 ? void 0 : identity.tags) || []),
                configDetails: this.getIdentifierDetails(identity),
                config: identity === null || identity === void 0 ? void 0 : identity.config,
                created_at: (_j = identity === null || identity === void 0 ? void 0 : identity.created_at) !== null && _j !== void 0 ? _j : '',
                created_by: (_l = (_k = identity === null || identity === void 0 ? void 0 : identity.CreatedByUser) === null || _k === void 0 ? void 0 : _k.id) !== null && _l !== void 0 ? _l : '',
                updated_at: (_m = identity === null || identity === void 0 ? void 0 : identity.updated_at) !== null && _m !== void 0 ? _m : '',
                updated_by: (_p = (_o = identity === null || identity === void 0 ? void 0 : identity.UpdatedByUser) === null || _o === void 0 ? void 0 : _o.id) !== null && _p !== void 0 ? _p : '',
                createdByFullName: (identity === null || identity === void 0 ? void 0 : identity.CreatedByUser) ? `${((_q = identity === null || identity === void 0 ? void 0 : identity.CreatedByUser) === null || _q === void 0 ? void 0 : _q.given_name) || ''} ${((_r = identity === null || identity === void 0 ? void 0 : identity.CreatedByUser) === null || _r === void 0 ? void 0 : _r.family_name) || ''}` : '',
                updatedByFullName: (identity === null || identity === void 0 ? void 0 : identity.UpdatedByUser) ? `${((_s = identity === null || identity === void 0 ? void 0 : identity.UpdatedByUser) === null || _s === void 0 ? void 0 : _s.given_name) || ''} ${((_t = identity === null || identity === void 0 ? void 0 : identity.UpdatedByUser) === null || _t === void 0 ? void 0 : _t.family_name) || ''}` : '',
            };
        });
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.identities.list();
        });
    }
    getUnique(identities) {
        const existing = [];
        const uniqueIdentities = {
            Identities: []
        };
        identities.Identities.map(identity => {
            if (!existing.includes(identity.id)) {
                uniqueIdentities.Identities.push(identity);
                existing.push(identity.id);
            }
        });
        return uniqueIdentities;
    }
    getFormattedSelectableOptions(identities, selectedIdentities) {
        const selectables = [];
        this.getUnique(identities).Identities.map((identity) => {
            selectables.push({
                key: identity.id.toString(),
                title: identity.name,
                label: uuidv4(),
                subtitle: identity.description,
                searchableLabel: `${identity.name} ${identity.description}`,
                checked: selectedIdentities.includes(identity.id) ? 'on' : undefined
            });
        });
        return this.sortSelectableOptionsBySelectedFirst(selectables);
    }
    getSelectableInMemoryFormattedEntities(listIdentities, selectedIdentities, defaultIdentity) {
        const selectables = listIdentities.Identities
            .map((identity) => {
            return {
                id: identity.id,
                isSelected: (selectedIdentities === null || selectedIdentities === void 0 ? void 0 : selectedIdentities.includes(identity.id)) || defaultIdentity === String(identity.id),
                searchableID: String(identity.id),
                name: identity.name,
                description: identity.description,
                isDisabled: defaultIdentity === String(identity.id),
                identifier: identity === null || identity === void 0 ? void 0 : identity.identity_type,
                configDetails: this.getIdentifierDetails(identity),
            };
        });
        selectables.sort((optionA, optionB) => {
            if (optionA.isSelected && optionB.isSelected) {
                return 0;
            }
            else if (optionA.isSelected && !optionB.isSelected) {
                return -1;
            }
            else {
                return 1;
            }
        });
        return selectables;
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield input;
        });
    }
    getFormattedSuperSelectOptions(identities) {
        const superSelects = [];
        this.getUnique(identities).Identities.map((identity) => {
            superSelects.push({
                value: identity.id.toString(),
                inputDisplay: identity.name
            });
        });
        superSelects.unshift({
            value: '0',
            inputDisplay: 'Select Default Identity'
        });
        return superSelects;
    }
    extractFilters(any) {
        return [];
    }
}
