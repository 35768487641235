import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../providers/app-context-provider";
const RouteWrapper = (props) => {
    const { isSwitchOrg, setIsSwitchOrg } = useAppContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (isSwitchOrg) {
            setIsSwitchOrg(false);
            if (props === null || props === void 0 ? void 0 : props.path) {
                navigate(props.path);
            }
        }
    }, []);
    return React.createElement(React.Fragment, null, props.children);
};
export default RouteWrapper;
