export const dawn = {
    "base": "vs",
    "inherit": true,
    "rules": [
        {
            "foreground": "#5a525f",
            "fontStyle": "italic",
            "token": "comment"
        },
        {
            "foreground": "#811f24",
            "fontStyle": "bold",
            "token": "constant"
        },
        {
            "foreground": "#bf4f24",
            "token": "entity"
        },
        {
            "foreground": "#794938",
            "token": "keyword"
        },
        {
            "foreground": "#a71d5d",
            "fontStyle": "italic",
            "token": "storage"
        },
        {
            "foreground": "#0b6125",
            "token": "string | punctuation.definition.string"
        },
        {
            "foreground": "#691c97",
            "token": "support"
        },
        {
            "foreground": "#234a97",
            "token": "variable"
        },
        {
            "foreground": "#794938",
            "token": "punctuation.separator"
        },
        {
            "foreground": "#b52a1d",
            "fontStyle": "bold italic underline",
            "token": "invalid.deprecated"
        },
        {
            "foreground": "#f8f8f8",
            "background": "#b52a1d",
            "fontStyle": "italic underline",
            "token": "invalid.illegal"
        },
        {
            "foreground": "#080808",
            "background": "#6f8bba26",
            "token": "string source"
        },
        {
            "foreground": "#696969",
            "fontStyle": "bold",
            "token": "string constant"
        },
        {
            "foreground": "#234a97",
            "token": "string variable"
        },
        {
            "foreground": "#cf5628",
            "token": "string.regexp"
        },
        {
            "foreground": "#cf5628",
            "fontStyle": "bold italic",
            "token": "string.regexp.character-class"
        },
        {
            "foreground": "#cf5628",
            "fontStyle": "bold italic",
            "token": "string.regexp constant.character.escaped"
        },
        {
            "foreground": "#cf5628",
            "fontStyle": "bold italic",
            "token": "string.regexp source.ruby.embedded"
        },
        {
            "foreground": "#cf5628",
            "fontStyle": "bold italic",
            "token": "string.regexp string.regexp.arbitrary-repitition"
        },
        {
            "foreground": "#811f24",
            "fontStyle": "bold",
            "token": "string.regexp constant.character.escape"
        },
        {
            "background": "#6f8bba26",
            "token": "text source"
        },
        {
            "foreground": "#693a17",
            "token": "support.function"
        },
        {
            "foreground": "#b4371f",
            "token": "support.constant"
        },
        {
            "foreground": "#234a97",
            "token": "support.variable"
        },
        {
            "foreground": "#693a17",
            "token": "markup.list"
        },
        {
            "foreground": "#19356d",
            "fontStyle": "bold",
            "token": "markup.heading | markup.heading entity.name"
        },
        {
            "foreground": "#0b6125",
            "background": "#bbbb30",
            "fontStyle": "italic",
            "token": "markup.quote"
        },
        {
            "foreground": "#080808",
            "fontStyle": "italic",
            "token": "markup.italic"
        },
        {
            "foreground": "#080808",
            "fontStyle": "bold",
            "token": "markup.bold"
        },
        {
            "foreground": "#080808",
            "fontStyle": "underline",
            "token": "markup.underline"
        },
        {
            "foreground": "#234a97",
            "fontStyle": "italic underline",
            "token": "markup.link"
        },
        {
            "foreground": "#234a97",
            "background": "#bbbb30",
            "token": "markup.raw"
        },
        {
            "foreground": "#b52a1d",
            "token": "markup.deleted"
        },
        {
            "foreground": "#19356d",
            "background": "#dcdcdc",
            "fontStyle": "bold",
            "token": "meta.separator"
        }
    ],
    "colors": {
        "editor.foreground": "#080808",
        "editor.background": "#F9F9F9",
        "editor.selectionBackground": "#275FFF4D",
        "editor.lineHighlightBackground": "#2463B41F",
        "editorCursor.foreground": "#000000",
        "editorWhitespace.foreground": "#4B4B7E80"
    }
};
