export const DefaultI18nStrings = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: "DEFAULT",
    previousButton: "DEFAULT",
    navigationAriaLabel: "DEFAULT",
    nextButton: "DEFAULT",
    submitButton: "DEFAULT",
    optional: "DEFAULT",
    skipToButtonLabel: (step) => `Skip to ${step.title}`,
};
export const CreateCloneI18nStrings = (resourceName) => {
    return {
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
        cancelButton: "Cancel",
        previousButton: "Previous",
        navigationAriaLabel: "Steps",
        nextButton: "Next",
        submitButton: `Create ${resourceName}`,
        optional: "optional",
        skipToButtonLabel: (step) => `Skip to ${step.title}`,
    };
};
export const EditI18nStrings = (resourceName) => {
    return {
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
        cancelButton: "Cancel",
        previousButton: "Previous",
        navigationAriaLabel: "Steps",
        nextButton: "Next",
        submitButton: `Update ${resourceName}`,
        optional: "optional",
        skipToButtonLabel: (step) => `Skip to ${step.title}`
    };
};
export const TagEditorI18nStrings = {
    keyPlaceholder: "Enter key",
    valuePlaceholder: "Enter value",
    addButton: "Add Tag",
    removeButton: "Remove",
    undoButton: "Undo",
    undoPrompt: "This tag will be removed when changes are saved",
    loading: "Loading tags that are associated with this resource",
    keyHeader: "Key",
    valueHeader: "Value",
    optional: "optional",
    keySuggestion: "Custom tag key",
    valueSuggestion: "Custom tag value",
    emptyTags: "No tags associated with the resource.",
    tooManyKeysSuggestion: "You have more keys than can be displayed",
    tooManyValuesSuggestion: "You have more values than can be displayed",
    keysSuggestionLoading: "Loading key values",
    keysSuggestionError: "Tag keys could not be retrieved",
    valuesSuggestionLoading: "Loading tag values",
    valuesSuggestionError: "Tag values could not be retrieved",
    emptyKeyError: "Please enter a tag key",
    maxKeyCharLengthError: "The maximum number of characters you can use in a tag key is 128.",
    maxValueCharLengthError: "The maximum number of characters you can use in a tag value is 256.",
    duplicateKeyError: "You must specify a unique tag key.",
    invalidKeyError: "Please enter a valid tag key. Key can only contain alphanumeric characters, spaces and any of the following: _.:/=+@-",
    invalidValueError: "Please enter a valid tag value. Value is optional but if present can only contain alphanumeric characters, spaces and any of the following: _.:/=+@-",
    awsPrefixError: "Cannot start with aws:",
    tagLimit: (availableTags) => `You can add up to ${availableTags} more tag${availableTags > 1 ? "s" : ""}`,
    tagLimitReached: (tagLimit) => `You have reached the limit of ${tagLimit} tags`,
    tagLimitExceeded: (tagLimit) => `You have exceeded the limit of ${tagLimit} tags`,
    enteredKeyLabel: (tag) => `Use "${tag}"`,
    enteredValueLabel: (tag) => `Use "${tag}"`,
};
export const makeI18nStrings = (props, resource) => {
    if (props.isCreate || props.isClone)
        return CreateCloneI18nStrings(resource);
    if (props.isEdit)
        return EditI18nStrings(resource);
    return DefaultI18nStrings;
};
