import { Alert, Box, Button, ColumnLayout, ExpandableSection, Modal, SpaceBetween } from '@cloudscape-design/components-themed/components';
import React from 'react';
import { getRandomUUID } from '@/lib/misc/utils';
const InspectionModal = (props) => {
    var _a, _b;
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit();
    };
    return React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: props.header, closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
            React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                React.createElement(Button, { variant: "primary", onClick: handleSubmit }, props.submitButton))) },
        React.createElement(SpaceBetween, { size: "m" },
            React.createElement(Alert, { type: 'warning' }, props.alertInfo),
            React.createElement(ColumnLayout, { columns: 1 }, props.items.length <= 5
                ? React.createElement(React.Fragment, null, React.createElement("ul", null, (_a = props.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                    return React.createElement("li", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.service);
                })))
                : React.createElement(ExpandableSection, { headerText: "Services" }, React.createElement("ul", null, (_b = props.items) === null || _b === void 0 ? void 0 : _b.map(item => {
                    return React.createElement("li", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.service);
                }))))));
};
export default InspectionModal;
