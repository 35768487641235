export class CookieStorageAdapter {
    get(key) {
        const nameEQ = key + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
    }
    set(key, value, expiryInHours = 24, sameSite = 'Strict') {
        const d = new Date();
        d.setTime(d.getTime() + expiryInHours * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        // Adding HttpOnly restricts access to cookies using Javascript's document.cookie method
        // This can be tested by adding HttpOnly below and clear the docs link cookies, we will see that on every page load
        // the mgmt API will be called to fetch the docs token, because our Javascript could not write to cookies.
        // https://web.dev/articles/first-party-cookie-recipes#the_recipe  --> click on Details Tab
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#restrict_access_to_cookies
        document.cookie = key + "=" + JSON.stringify(value) + ";" + expires + ";path=/; SameSite=" + sameSite + "; Secure";
    }
}
