import { Alert, Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween, } from "@cloudscape-design/components-themed/components";
import React, { useEffect, useState } from "react";
const OrganizationModal = (props) => {
    var _a, _b;
    const [leaveInputText, setLeaveOrgInputText] = useState("");
    const inputMatchesConsentText = leaveInputText === props.consentText;
    useEffect(() => {
        setLeaveOrgInputText("");
    }, [props.visible]);
    const handleLeaveOrgSubmit = (event) => {
        event.preventDefault();
        if (props.consentText) {
            props.onLeaveOrg();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: props.headerText, closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
                React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                    React.createElement(Button, { variant: "primary", disabled: !inputMatchesConsentText, onClick: props.onLeaveOrg }, "Submit"))) },
            React.createElement(SpaceBetween, { size: "m" },
                React.createElement(Box, { variant: "span" },
                    "Leave Organization",
                    React.createElement(Box, { variant: "span", fontWeight: "bold" }, ` ${((_b = (_a = props.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.companyName) || ''}`),
                    "? This action cannot be undone."),
                React.createElement(Alert, { type: "warning" }, "Proceeding with this action will remove your user from this organization. If you require access in the future, an administrator will need to re-invite you to the organization."),
                React.createElement(Box, null, "To avoid accidental removal we ask you to provide additional written consent."),
                React.createElement(ColumnLayout, { columns: 2 },
                    React.createElement("form", { onSubmit: handleLeaveOrgSubmit },
                        React.createElement(FormField, { label: `Type "${props.consentText}" to agree.` },
                            React.createElement(Input, { placeholder: props.consentText, onChange: (event) => setLeaveOrgInputText(event.detail.value), value: leaveInputText, ariaRequired: true, ariaLabelledby: "leave-confirmation-label" }))))))));
};
export default OrganizationModal;
