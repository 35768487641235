import { CookieStorageAdapter, SessionStorageAdapter } from '@/infra/cache';
export const GetDocsToken = (storage) => {
    const csAdapter = new CookieStorageAdapter();
    return csAdapter.get(storage);
};
export const SetDocsToken = (storage, docuToken) => {
    const csAdapter = new CookieStorageAdapter();
    csAdapter.set(storage, docuToken, 24, 'Strict');
};
export const GetDashboardURL = (storage) => {
    const csAdapter = new CookieStorageAdapter();
    return csAdapter.get(storage);
};
export const SetDashboardURL = (storage, dashboardURL) => {
    const csAdapter = new CookieStorageAdapter();
    csAdapter.set(storage, dashboardURL, 23, 'Strict');
};
export const GetReportingURL = (storage) => {
    const csAdapter = new CookieStorageAdapter();
    return csAdapter.get(storage);
};
export const SetReportingURL = (storage, reportingURL) => {
    const csAdapter = new CookieStorageAdapter();
    csAdapter.set(storage, reportingURL, 23);
};
export const SetPropertyFilters = (storage, filters) => {
    const ssAdapter = new SessionStorageAdapter();
    ssAdapter.set(storage, filters);
};
export const GetPropertyFilters = (storage, sendDefaultIfEmpty = true) => {
    const ssAdapter = new SessionStorageAdapter();
    const defaultFilter = { tokens: [], operation: 'and' };
    const retrievedFilters = ssAdapter.get(storage);
    if (retrievedFilters) {
        return retrievedFilters;
    }
    return sendDefaultIfEmpty ? defaultFilter : undefined;
};
export const SetTableSortingColumn = (storage, sortInfo) => {
    if (storage) {
        const ssAdapter = new SessionStorageAdapter();
        ssAdapter.set(storage, sortInfo);
    }
};
export const GetTableSortingColumn = (storage) => {
    const ssAdapter = new SessionStorageAdapter();
    return ssAdapter.get(storage);
};
export const SetEditedPolicyStatus = (policyString, value) => {
    if (policyString) {
        const ssAdapter = new SessionStorageAdapter();
        ssAdapter.set(policyString, value);
    }
};
export const GetEditedPolicyStatus = (policyString) => {
    const ssAdapter = new SessionStorageAdapter();
    return ssAdapter.get(policyString);
};
