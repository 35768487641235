import { RULE_TYPE_ARRAY_COMBO_NOT_VALID, RULE_TYPE_MAX_LENGTH, RULE_TYPE_MIN_LENGTH, RULE_TYPE_NOT_EMPTY_OPTION_VALUE, RULE_TYPE_UNIQUE_ARRAY, RULE_TYPE_VALID_EMAIL, RULE_TYPE_VALID_EMAIL_ALLOWED_DOMAIN, RULE_TYPE_WILDCARD_OVERWRITES } from '@/presentation/handlers';
import { areCombosValid, getWildcardCountFromArray, isUniqueArray, isValidEmail } from '@/presentation/common-utils/common-utils';
import { WILDCARD } from '@/presentation/common-utils/constants';
export class OptionsArrayValidator {
    constructor(rules) {
        this.rules = [];
        this.addRule = (rule) => {
            const filtered = this.rules.filter(r => r.type !== rule.type);
            return [...filtered, rule];
        };
        this.makeError = (errorMessage) => {
            return { valid: false, message: errorMessage };
        };
        this.makeValid = (value) => {
            return { valid: true, value: value };
        };
        this.minLength = (min) => {
            this.rules = this.addRule({ type: RULE_TYPE_MIN_LENGTH, min: min });
            return this;
        };
        this.maxLength = (max) => {
            this.rules = this.addRule({ type: RULE_TYPE_MAX_LENGTH, max: max });
            return this;
        };
        this.notEmpty = () => {
            this.rules = this.addRule({ type: RULE_TYPE_MIN_LENGTH, min: 1 });
            return this;
        };
        this.empty = () => {
            this.rules = this.addRule({ type: RULE_TYPE_MAX_LENGTH, max: 0 });
            return this;
        };
        this.wildcardOverwrites = () => {
            this.rules = this.addRule({ type: RULE_TYPE_WILDCARD_OVERWRITES });
            return this;
        };
        this.isValidEmail = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_EMAIL });
            return this;
        };
        this.hasUniqueOptions = () => {
            this.rules = this.addRule({ type: RULE_TYPE_UNIQUE_ARRAY });
            return this;
        };
        this.isValidEmailAllowedDomain = (allowedDomains) => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_EMAIL_ALLOWED_DOMAIN, allowedDomains: allowedDomains });
            return this;
        };
        this.comboNotValid = (invalidCombos, error) => {
            this.rules = this.addRule({ type: RULE_TYPE_ARRAY_COMBO_NOT_VALID, invalidCombos: invalidCombos, error: error });
            return this;
        };
        this.notEmptyOptionValue = () => {
            this.rules = this.addRule({ type: RULE_TYPE_NOT_EMPTY_OPTION_VALUE });
            return this;
        };
        this.checkRule = (rule, values) => {
            var _a;
            switch (rule.type) {
                case RULE_TYPE_MIN_LENGTH:
                    return values.length < rule.min
                        ? this.makeError(`Value cannot be empty`)
                        : this.makeValid(values);
                case RULE_TYPE_MAX_LENGTH:
                    return values.length > rule.max
                        ? this.makeError(`Array length must be less than or equal to ${rule.max} but was ${values.length}.`)
                        : this.makeValid(values);
                case RULE_TYPE_VALID_EMAIL:
                    return values.filter(option => isValidEmail(option.label)).length === values.length // all email ids should be valid
                        ? this.makeValid(values)
                        : this.makeError(`Please enter a list of valid email addresses`);
                case RULE_TYPE_VALID_EMAIL_ALLOWED_DOMAIN:
                    // if wildcard is present allow everything
                    if (rule.allowedDomains.includes(WILDCARD)) {
                        return this.makeValid(values);
                    }
                    for (const option of values) {
                        const splitted = option.label.split('@');
                        if (splitted.length !== 2) { // for whatever reason the regex fails, return false
                            return this.makeError(`Invalid email address`);
                        }
                        // all emails should belong to the allowed domains
                        if (!rule.allowedDomains.includes(splitted[1])) {
                            return this.makeError('Please enter email addresses belonging only to allowed domains');
                        }
                    }
                    return this.makeValid(values);
                case RULE_TYPE_WILDCARD_OVERWRITES:
                    const wildcardCount = getWildcardCountFromArray(values.map(option => option.label));
                    // only one wildcard present or no wildcard present, so its all good
                    if (wildcardCount <= 1 && values.length === 1) {
                        return this.makeValid(values);
                    }
                    else if (wildcardCount === 1 && values.length > 1) {
                        // one wildcard and some other values,
                        // throw error that wildcard is going to overwrite other options
                        return this.makeError(`Wildcard option will overwrite others.`);
                    }
                    else if (wildcardCount > 1) {
                        // more than one wildcard
                        return this.makeError(`Only one wildcard allowed.`);
                    }
                    return this.makeValid(values);
                case RULE_TYPE_UNIQUE_ARRAY:
                    return isUniqueArray(values.map(option => option.label))
                        ? this.makeValid(values)
                        : this.makeError(`Options not Unique`);
                case RULE_TYPE_ARRAY_COMBO_NOT_VALID:
                    return areCombosValid(rule.invalidCombos, values.map(option => option.value))
                        ? this.makeValid(values)
                        : this.makeError(rule.error === '' ? 'Combinations of Selections is Invalid' : rule.error);
                case RULE_TYPE_NOT_EMPTY_OPTION_VALUE:
                    return ((_a = values.filter(option => !!option.value)) === null || _a === void 0 ? void 0 : _a.length) > 0
                        ? this.makeValid(values)
                        : this.makeError(`Invalid Options`);
            }
        };
        this.validate = (value) => {
            if (value === null) {
                return this.makeError('OptionsArrayValidator expected an array but received null.');
            }
            else if (value === undefined) {
                return this.makeError('OptionsArrayValidator expected an array but received undefined.');
            }
            else if (!Array.isArray(value)) {
                return this.makeError(`OptionsArrayValidator expected an array but received ${typeof value}.`);
            }
            for (const rule of this.rules) {
                const result = this.checkRule(rule, value);
                if (!result.valid) {
                    return result;
                }
            }
            return {
                valid: true,
                value: value
            };
        };
        if (rules && rules.length > 0) {
            this.rules = rules;
        }
    }
}
