import React, { useEffect } from "react";
import { Box, Container, Header, SpaceBetween, TagEditor, } from "@cloudscape-design/components-themed/components";
import { TagEditorI18nStrings } from "@/presentation/components/I18nStrings";
const FormTags = (props) => {
    const ref = React.useRef(null);
    useEffect(() => {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
            if (hashValue === "tags") {
                document.getElementById("tags").scrollIntoView();
            }
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const emptyTagsArray = [
        {
            key: "",
            value: "",
            existing: false,
        },
    ];
    const tagsArray = (props === null || props === void 0 ? void 0 : props.tags) || emptyTagsArray;
    return (React.createElement("div", { ref: ref, id: "tags" },
        React.createElement(Box, { margin: { bottom: "l" } },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Manage Tags") },
                    React.createElement(TagEditor, { i18nStrings: TagEditorI18nStrings, tags: tagsArray, onChange: ({ detail }) => {
                            props.onChange(detail);
                        }, tagLimit: 10 }))))));
};
export default FormTags;
