import { getLogoAsUrl, getRiskRatingNumerical, makeRiskRatingText, RISK_RATING_PASCAL_CASE } from '@/presentation/pages/common';
import React from 'react';
import { Badge } from "@cloudscape-design/components-themed/components";
import { getRandomUUID } from "@/lib/misc/utils";
import gravatarUrl from 'gravatar-url';
import { getLocaleTimestamp } from '../common-utils/common-utils';
export const idFilteringProperty = {
    key: 'id',
    operators: ['=', '!=', ':', '!:', '<', '<=', '>', '>='],
    propertyLabel: 'ID',
    groupValuesLabel: 'ID values'
};
export const nameFilteringProperty = {
    key: 'name',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Name',
    groupValuesLabel: 'Name values'
};
export const descriptionFilteringProperty = {
    key: 'description',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Description',
    groupValuesLabel: 'Description values'
};
export const providerFilteringProperty = {
    key: 'provider',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Provider',
    groupValuesLabel: 'Provider values'
};
export const serviceFilteringProperty = {
    key: 'service',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Service',
    groupValuesLabel: 'Service values'
};
export const actionFilteringProperty = {
    key: 'actions',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Actions',
    groupValuesLabel: 'Action values'
};
export const complianceFrameworksFilteringProperty = {
    key: 'complianceFrameworks',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Compliance Frameworks',
    groupValuesLabel: 'Compliance Frameworks values'
};
export const riskRatingFilteringProperty = {
    key: 'riskRatingPascalCase',
    operators: ['=', '!='],
    propertyLabel: 'Risk Rating',
    groupValuesLabel: 'Risk Rating values'
};
export const companyNameFilteringProperty = {
    key: 'companyName',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Organization',
    groupValuesLabel: 'Organization values'
};
export const numberOfRulesFilteringProperty = {
    key: 'numberOfRules',
    operators: ['=', '!=', '<', '<=', '>', '>='],
    propertyLabel: 'Attached Rules (#)',
    groupValuesLabel: 'Attached Rules (#) values'
};
export const xkidFilteringProperty = {
    key: 'xkid',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Xkid',
    groupValuesLabel: 'Xkid values'
};
export const userDetailsCreatedFilteringProperty = {
    key: 'createdByFullName',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Created By',
    groupValuesLabel: 'Created By values'
};
export const userDetailsUpdatedFilteringProperty = {
    key: 'updatedByFullName',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Updated By',
    groupValuesLabel: 'Updated By values'
};
export const tagsFilteringProperty = {
    key: 'tagString',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Tags',
    groupValuesLabel: 'Attached Tag values'
};
export const logBodyFilteringProperty = {
    key: 'logBody',
    operators: ['=', '!='],
    propertyLabel: 'Log Body',
    groupValuesLabel: 'Log Body values'
};
// Column Definitions
export const providerColumnDefinition = {
    id: 'provider',
    sortingField: 'provider',
    header: 'Provider',
    cell: (item) => (item === null || item === void 0 ? void 0 : item.provider) ? React.createElement("img", { src: getLogoAsUrl(item.provider), width: "23px", height: "23px" }) : '-',
    width: 120
};
export const serviceColumnDefinition = {
    id: 'service',
    sortingField: 'service',
    header: 'Service',
    cell: (item) => String((item === null || item === void 0 ? void 0 : item.service) || '-').toUpperCase()
};
export const actionsColumnDefinition = {
    id: 'actions',
    sortingField: 'actionsConcat',
    header: 'Actions',
    cell: (item) => (item === null || item === void 0 ? void 0 : item.actionsConcat) || "-"
};
export const complianceFrameworksColumnDefinition = {
    id: 'complianceFrameworks',
    header: 'Compliance Frameworks',
    cell: (item) => ((item === null || item === void 0 ? void 0 : item.complianceFrameworks) && (item === null || item === void 0 ? void 0 : item.complianceFrameworks) !== '[null]') ? item === null || item === void 0 ? void 0 : item.complianceFrameworks : '-',
    sortingComparator: (a, b) => {
        // when implementing sortingComparator we should only implement ascending sort
        // the framework will reverse it when user clicks descending sort
        const cleanedA = String(a === null || a === void 0 ? void 0 : a.complianceFrameworks).replaceAll(/\s*|\t|\r|\n/gm, '').toLowerCase();
        const cleanedB = String(b === null || b === void 0 ? void 0 : b.complianceFrameworks).replaceAll(/\s*|\t|\r|\n/gm, '').toLowerCase();
        if (cleanedA < cleanedB) {
            // give priority to null/empty when ascending sort
            if ((cleanedA === null || cleanedA === void 0 ? void 0 : cleanedA.length) === 0) {
                return 1;
            }
            return -1;
        }
        else if (cleanedA > cleanedB) {
            // give priority to null/empty when ascending sort
            if ((cleanedB === null || cleanedB === void 0 ? void 0 : cleanedB.length) === 0) {
                return -1;
            }
            return 1;
        }
        return 0;
    }
};
export const riskRatingColumnDefinition = {
    id: 'riskRating',
    header: 'Risk Rating',
    sortingComparator: (a, b) => {
        const numA = getRiskRatingNumerical(a === null || a === void 0 ? void 0 : a.riskRating);
        const numB = getRiskRatingNumerical(b === null || b === void 0 ? void 0 : b.riskRating);
        if (numA < numB) {
            return -1;
        }
        else if (numA > numB) {
            return 1;
        }
        return 0;
    },
    cell: (item) => {
        return ((item === null || item === void 0 ? void 0 : item.riskRating) && (item === null || item === void 0 ? void 0 : item.riskRating) !== '[null]') ? (React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, makeRiskRatingText(item === null || item === void 0 ? void 0 : item.riskRating))) : '-';
    }
};
export const descriptionColumnDefinition = {
    id: 'description',
    sortingField: 'description',
    header: 'Description',
    cell: (item) => (item === null || item === void 0 ? void 0 : item.description) || '-'
};
export const createdAtColumnDefinition = {
    id: 'created_at',
    sortingField: 'created_at',
    header: 'Created',
    cell: (item) => {
        return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.created_at);
    },
};
export const updatedAtColumnDefinition = {
    id: 'updated_at',
    sortingField: 'updated_at',
    header: 'Updated',
    cell: (item) => {
        return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.updated_at);
    },
};
export const userDetailsCreatedColumnDefinition = [
    {
        id: 'created_by',
        sortingField: 'createdByFullName',
        header: 'Created By',
        cell: (item) => {
            var _a;
            return ((item === null || item === void 0 ? void 0 : item.created_by) ?
                (React.createElement("div", { className: "flex items-center gap-2" },
                    (item === null || item === void 0 ? void 0 : item.created_by) && React.createElement("img", { className: "rounded-full", src: gravatarUrl((_a = item === null || item === void 0 ? void 0 : item.created_by) !== null && _a !== void 0 ? _a : '', { size: 28, default: 'retro' }), alt: '', title: (item === null || item === void 0 ? void 0 : item.createdByFullName) || '' }),
                    React.createElement("span", null, item === null || item === void 0 ? void 0 : item.createdByFullName))) : '-');
        },
        minWidth: 150
    },
    createdAtColumnDefinition
];
export const userDetailsUpdatedColumnDefinition = [
    {
        id: 'updated_by',
        sortingField: 'updatedByFullName',
        header: 'Updated By',
        cell: (item) => {
            var _a;
            return ((item === null || item === void 0 ? void 0 : item.updated_by) ?
                (React.createElement("div", { className: "flex items-center gap-2" },
                    (item === null || item === void 0 ? void 0 : item.updated_by) && React.createElement("img", { className: "rounded-full", src: gravatarUrl((_a = item === null || item === void 0 ? void 0 : item.updated_by) !== null && _a !== void 0 ? _a : '', { size: 28, default: 'retro' }), alt: '', title: (item === null || item === void 0 ? void 0 : item.updatedByFullName) || '' }),
                    React.createElement("span", null, item === null || item === void 0 ? void 0 : item.updatedByFullName))) : '-');
        },
        minWidth: 150
    },
    updatedAtColumnDefinition
];
export const logBodyFilteringOptions = [
    {
        propertyKey: 'logBody',
        value: 'true'
    },
    {
        propertyKey: 'logBody',
        value: 'false'
    }
];
export const riskRatingFilteringOptions = [
    {
        propertyKey: 'riskRatingPascalCase',
        value: '[null]'
    },
    {
        propertyKey: 'riskRatingPascalCase',
        value: RISK_RATING_PASCAL_CASE.CRITICAL
    },
    {
        propertyKey: 'riskRatingPascalCase',
        value: RISK_RATING_PASCAL_CASE.HIGH
    },
    {
        propertyKey: 'riskRatingPascalCase',
        value: RISK_RATING_PASCAL_CASE.MEDIUM
    },
    {
        propertyKey: 'riskRatingPascalCase',
        value: RISK_RATING_PASCAL_CASE.LOW
    },
    {
        propertyKey: 'riskRatingPascalCase',
        value: RISK_RATING_PASCAL_CASE.VERY_LOW
    },
];
export const initialComplianceFrameworksFilteringOptions = [
    {
        propertyKey: 'complianceFrameworks',
        value: '[null]'
    },
];
export const getCreatedUpdatedFilteringOptions = (userDetails, propertyKey) => {
    var _a;
    return (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.map((userName) => {
        return {
            propertyKey: propertyKey,
            value: userName
        };
    })) !== null && _a !== void 0 ? _a : [];
};
export const manageCreatedUpdatedList = (formattedItems) => {
    let createdByUsers = [];
    let updatedByUsers = [];
    formattedItems.forEach(userInfo => {
        if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo.createdByFullName) !== "") {
            createdByUsers.push(userInfo === null || userInfo === void 0 ? void 0 : userInfo.createdByFullName);
        }
        if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo.updatedByFullName) !== "") {
            updatedByUsers.push(userInfo === null || userInfo === void 0 ? void 0 : userInfo.updatedByFullName);
        }
    });
    const uniqueCreatedByUsers = [...new Set(createdByUsers)];
    const uniqueUpdatedByUsers = [...new Set(updatedByUsers)];
    const filteredCreatedItems = getCreatedUpdatedFilteringOptions(uniqueCreatedByUsers, "createdByFullName");
    const filteredUpdatedItems = getCreatedUpdatedFilteringOptions(uniqueUpdatedByUsers, "updatedByFullName");
    return [...filteredCreatedItems, ...filteredUpdatedItems];
};
