import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH, IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE, IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY, PATHS, PATHS_SINGULAR } from '@/presentation/common-utils/constants';
import RegularLink from '@/presentation/components/links/regular-link';
import ExternalLink from '@/presentation/components/links/external-link';
import { descriptionFilteringProperty, idFilteringProperty, nameFilteringProperty } from '@/presentation/pages/common-config';
import { Badge } from '@cloudscape-design/components-themed/components';
import { getIdentifierLabel } from "@/presentation/pages/identities/constants";
const indentifierLabelObject = getIdentifierLabel();
export const ProxiesIdentitiesColumnDefinitions = (incomingPath, withPreviousPath = false) => {
    return addColumnSortLabels([
        {
            id: 'id',
            header: 'ID',
            cell: (item) => {
                let link = `${PATHS.IDENTITIES}/${item.id}`;
                if (withPreviousPath) {
                    // /proxies/:id/identities/:identitiesID
                    link = `${incomingPath + PATHS_SINGULAR.IDENTITY}/${item.id}`;
                    return React.createElement(RegularLink, { to: link, label: item.id });
                }
                return React.createElement(ExternalLink, { to: link, label: item.id });
            },
            sortingField: 'id',
            width: 120
        },
        {
            id: 'name',
            sortingField: 'name',
            header: 'Name',
            cell: (item) => {
                if (item === null || item === void 0 ? void 0 : item.isDefaultIdentity) {
                    return (React.createElement("div", { className: "flex" },
                        React.createElement("div", { className: "flex-none mr-2" }, (item === null || item === void 0 ? void 0 : item.name) || '-'),
                        React.createElement("div", { className: "flex-none" },
                            React.createElement(Badge, { color: "grey" }, "DEFAULT IDENTITY"))));
                }
                return (item === null || item === void 0 ? void 0 : item.name) || '-';
            },
        },
        {
            id: 'description',
            sortingField: 'description',
            header: 'Description',
            cell: (item) => (item === null || item === void 0 ? void 0 : item.description) || '-',
        },
        {
            id: 'identifier',
            header: 'Identifier',
            cell: item => {
                return ((item === null || item === void 0 ? void 0 : item.identifier) ? (React.createElement(Badge, { color: 'grey' }, indentifierLabelObject[item.identifier] || item.identifier)) : '-');
            },
            sortingField: 'identifier',
        },
        {
            id: 'configDetails',
            header: 'Details',
            cell: (item) => {
                return (item === null || item === void 0 ? void 0 : item.configDetails) || '';
            },
            sortingField: 'configDetails'
        }
    ]);
};
export const ProxiesIdentitiesDetailsFilteringProperties = [
    idFilteringProperty,
    nameFilteringProperty,
    descriptionFilteringProperty,
    {
        key: 'identifierReadableName',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Identifier',
        groupValuesLabel: 'Identifier values'
    },
    {
        key: 'configDetails',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Details',
        groupValuesLabel: 'Details values'
    }
];
export const IdentitiesListTablePropertyFilterFilteringOptions = [
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY]
    }
];
export const ProxiesIdentitiesDetailsTablePreferences = {
    pageSize: 10,
    visibleContent: ['checkbox', 'id', 'name', 'description', 'identifier'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const ProxiesIdentitiesDetailsTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'name', label: 'Name', editable: false },
            { id: 'description', label: 'Description', editable: false },
            { id: 'identifier', label: 'Identifier', editable: true },
            { id: 'configDetails', label: 'Details', editable: true }
        ]
    }
];
export const ProxiesIdentitiesDetailsTablePageSizeOptions = [
    { value: 10, label: '10 Identities' },
    { value: 30, label: '30 Identities' },
    { value: 50, label: '50 Identities' }
];
