var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RequestSessionStorageAdapter } from 'tsclient';
export class AuthorizeHttpClientDecorator {
    constructor(client, getTokenSilently) {
        this.getToken = () => __awaiter(this, void 0, void 0, function* () {
            return yield this.getTokenSilently();
        });
        this.httpClient = client;
        this.getTokenSilently = getTokenSilently;
    }
    request(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.getTokenSilently();
            Object.assign(data, {
                headers: Object.assign(data.headers || {}, {
                    Authorization: 'Bearer ' + token
                })
            });
            // Add 15 seconds max timeout for mgmt APIs
            // due to time constraints we are adding 15 seconds
            // after testing stats in dev and DataDog we will reduce this based on results
            if (data.url.startsWith('https://mgmt')) {
                data.timeout = 15000; // 15 seconds
            }
            const response = yield this.httpClient.request(data);
            // For mgmt APIs, mark it as write calls and let the subsequent requests go to primary
            if (data.url.startsWith('https://mgmt')) {
                const rfpAdapter = new RequestSessionStorageAdapter();
                rfpAdapter.startRequestingFromPrimary();
            }
            return response;
        });
    }
    unauthenticatedRequest(data) {
        return __awaiter(this, void 0, void 0, function* () {
            Object.assign(data, {
                headers: Object.assign(data.headers || {})
            });
            return yield this.httpClient.request(data);
        });
    }
}
