import { AppLayout, BreadcrumbGroup } from '@cloudscape-design/components-themed/components';
import { appLayoutLabels } from '@/presentation/components/commons/labels';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@/lib/auth0';
import { useIsMounted } from '../hooks';
import { ReactComponent as OrganizationIcon } from '@/presentation/assets/img/icons/organization_icon.svg';
export function KiverascapeLayout(props) {
    const [orgName, setOrgName] = useState('');
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const { user } = useAuth0();
    const onBreadCrumbClick = (e) => {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        const event = e === null || e === void 0 ? void 0 : e.detail;
        navigate((event === null || event === void 0 ? void 0 : event.href) || '/');
    };
    let layoutProps = Object.assign({ navigationHide: true, maxContentWidth: Number.MAX_VALUE, toolsHide: true, headerSelector: "#header", ariaLabels: appLayoutLabels }, props);
    useEffect(() => {
        var _a, _b;
        if (isMounted.current) {
            const fullUserDetails = user;
            if (fullUserDetails) {
                const currentMemberShips = (fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.memberships) || [];
                const org = currentMemberShips.filter((membership) => (membership === null || membership === void 0 ? void 0 : membership.organization.id) === (fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.org_id));
                if (org.length === 1) {
                    setOrgName(((_b = (_a = org[0]) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.company_name) || '');
                }
            }
        }
    }, [user]);
    if (props === null || props === void 0 ? void 0 : props.breadcrumbMenu) {
        const breadCrumbsInfo = (React.createElement("div", { className: "flex justify-between" },
            React.createElement(BreadcrumbGroup, { items: props === null || props === void 0 ? void 0 : props.breadcrumbMenu, onFollow: onBreadCrumbClick, expandAriaLabel: "Show path", ariaLabel: "Breadcrumbs" }),
            React.createElement("div", { className: "org-name-banner" },
                React.createElement("span", { className: "org-name-icon" },
                    React.createElement(OrganizationIcon, null)),
                React.createElement("span", { className: "organization-name" }, orgName))));
        layoutProps = Object.assign(Object.assign({}, layoutProps), { breadcrumbs: React.createElement(React.Fragment, null, breadCrumbsInfo) });
    }
    return React.createElement(AppLayout, Object.assign({}, layoutProps));
}
