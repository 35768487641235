import React from 'react';
import { StatusIndicator, SpaceBetween, Button, Icon } from '@cloudscape-design/components-themed/components';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { PATH_RULES, PATHS } from '@/presentation/common-utils/constants';
import RegularLink from '@/presentation/components/links/regular-link';
import ButtonLink from '@/presentation/components/links/button-link';
import { getRandomUUID, sortingComparatorByKey } from '@/lib/misc/utils';
import PlaySVGIconUrl from '@/presentation/assets/img/icons/play-icon.svg';
import { actionFilteringProperty, actionsColumnDefinition, complianceFrameworksColumnDefinition, complianceFrameworksFilteringProperty, descriptionColumnDefinition, descriptionFilteringProperty, idFilteringProperty, providerColumnDefinition, serviceColumnDefinition, serviceFilteringProperty, providerFilteringProperty, riskRatingColumnDefinition, riskRatingFilteringProperty, userDetailsCreatedColumnDefinition, userDetailsUpdatedColumnDefinition, userDetailsUpdatedFilteringProperty, userDetailsCreatedFilteringProperty, tagsFilteringProperty, logBodyFilteringOptions, riskRatingFilteringOptions, initialComplianceFrameworksFilteringOptions, createdAtColumnDefinition, updatedAtColumnDefinition, logBodyFilteringProperty } from '@/presentation/pages/common-config';
import { Tooltip } from '@/presentation/components/tooltip';
import LogoLetterKUrl from '@/presentation/assets/img/logo/logo-letter-k-rule-library.svg';
export const RulesListColumnDefinitions = () => {
    return addColumnSortLabels([
        {
            id: 'id',
            sortingField: 'id',
            header: 'ID',
            cell: (item) => {
                const link = `${PATH_RULES.VIEW}/${item.id}`;
                if (item === null || item === void 0 ? void 0 : item.importedFrom) {
                    return (React.createElement("div", { className: "flex", key: getRandomUUID() },
                        React.createElement("div", { className: "grow-0" },
                            React.createElement(RegularLink, { to: link, label: item.id })),
                        React.createElement("div", { className: "grow-0" },
                            React.createElement("img", { src: LogoLetterKUrl, width: "15px", height: "15px", className: 'imported-from-status imported-tooltip-' + (item === null || item === void 0 ? void 0 : item.importedFrom) }),
                            React.createElement(Tooltip, { anchorSelect: '.imported-tooltip-' + (item === null || item === void 0 ? void 0 : item.importedFrom), place: 'bottom', style: { zIndex: '2000' } },
                                "Imported from KRL:", item === null || item === void 0 ? void 0 :
                                item.importedFrom))));
                }
                return React.createElement(RegularLink, { to: link, label: item.id });
            },
            width: 120
        },
        providerColumnDefinition,
        serviceColumnDefinition,
        actionsColumnDefinition,
        descriptionColumnDefinition,
        complianceFrameworksColumnDefinition,
        riskRatingColumnDefinition,
        {
            id: 'logBody',
            sortingField: 'logBody',
            header: 'Log Body',
            cell: (item) => {
                switch (item.logBody) {
                    case 'true':
                        return React.createElement(StatusIndicator, { type: 'success' });
                    case 'false':
                        return React.createElement(StatusIndicator, { type: 'error' });
                    default:
                        return '-';
                }
            }
        },
        {
            id: 'numberOfProfiles',
            sortingField: 'numberOfProfiles',
            header: 'Attached to Profiles (#)',
            cell: (item) => (item === null || item === void 0 ? void 0 : item.numberOfProfiles) || '-',
            sortingComparator: (a, b) => sortingComparatorByKey(a, b, 'numberOfProfiles')
        },
        ...userDetailsCreatedColumnDefinition,
        ...userDetailsUpdatedColumnDefinition,
        {
            id: 'table-actions',
            header: '',
            cell: (item) => {
                const cloneLink = `${PATHS.RULES}/${item.id}/clone`;
                const editLink = `${PATHS.RULES}/${item.id}/edit#open-policy-editor`;
                const simulateLink = `${PATH_RULES.VIEW}/${item.id}/${PATH_RULES.SIMULATOR}`;
                return (React.createElement(React.Fragment, null,
                    React.createElement(SpaceBetween, { size: 's', direction: 'horizontal' },
                        React.createElement(ButtonLink, { to: simulateLink, key: `run-simulator-button-${item.id}`, variant: "inline-icon", iconUrl: PlaySVGIconUrl, ariaLabel: 'run-simulator-button', tooltipClass: `rules-list-simulate-button-${item.id}`, tooltipMessage: 'Simulate' }),
                        React.createElement(ButtonLink, { to: cloneLink, key: `copy-button-${item.id}`, variant: "inline-icon", iconName: "copy", ariaLabel: 'copy-button', tooltipClass: `rules-list-clone-button-${item.id}`, tooltipMessage: 'Clone' }),
                        React.createElement("div", { className: "nav-link-custom-button-class" },
                            React.createElement(ButtonLink, { to: editLink, key: `script-button-${item.id}`, variant: "inline-icon", iconSvg: React.createElement(Icon, { name: 'script', size: "medium", variant: "link" }), ariaLabel: 'script-button', tooltipClass: `rules-list-script-button-${item.id}`, tooltipMessage: 'Edit Policy' })))));
            },
            minWidth: 140
        }
    ]);
};
export const RulesListTablePreferences = {
    pageSize: 30,
    visibleContent: [
        'id',
        'provider',
        'service',
        'actions',
        'description',
        'updated_by',
        'updated_at',
        'table-actions'
    ],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const RulesLibraryListColumnDefinitions = (handleCopy) => {
    return addColumnSortLabels([
        {
            id: 'id',
            sortingField: 'id',
            header: 'ID',
            cell: (item) => item === null || item === void 0 ? void 0 : item.id,
            width: 120
        },
        providerColumnDefinition,
        serviceColumnDefinition,
        actionsColumnDefinition,
        descriptionColumnDefinition,
        complianceFrameworksColumnDefinition,
        riskRatingColumnDefinition,
        {
            id: 'logBody',
            sortingField: 'logBody',
            header: 'Log Body',
            cell: (item) => {
                switch (item.logBody) {
                    case 'true':
                        return React.createElement(StatusIndicator, { type: 'success' });
                    case 'false':
                        return React.createElement(StatusIndicator, { type: 'error' });
                    default:
                        return '-';
                }
            }
        },
        createdAtColumnDefinition,
        updatedAtColumnDefinition,
        {
            id: 'table-actions',
            header: '',
            cell: (item) => {
                return (React.createElement(Button, { key: "inspect-button", variant: "inline-icon", iconName: "search", ariaLabel: 'inspect-button', onClick: () => handleCopy(item) }));
            },
            maxWidth: 120
        }
    ]);
};
export const RulesLibraryListTablePreferences = {
    pageSize: 30,
    visibleContent: [
        'id',
        'provider',
        'service',
        'actions',
        'description',
        'updated_at',
        'table-actions'
    ],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const RulesLibraryListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'provider', label: 'Provider', editable: false },
            { id: 'service', label: 'Service', editable: false },
            { id: 'actions', label: 'Actions', editable: true },
            { id: 'description', label: 'Description', editable: false },
            { id: 'complianceFrameworks', label: 'Compliance Frameworks', editable: true },
            { id: 'riskRating', label: 'Risk Rating', editable: true },
            { id: 'logBody', label: 'Log Body', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const RulesListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'provider', label: 'Provider', editable: false },
            { id: 'service', label: 'Service', editable: false },
            { id: 'actions', label: 'Actions', editable: true },
            { id: 'description', label: 'Description', editable: false },
            { id: 'complianceFrameworks', label: 'Compliance Frameworks', editable: true },
            { id: 'riskRating', label: 'Risk Rating', editable: true },
            { id: 'logBody', label: 'Log Body', editable: true },
            { id: 'numberOfProfiles', label: 'Attached to Profiles (#)', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const RulesListTablePageSizeOptions = [
    { value: 10, label: '10 Rules' },
    { value: 30, label: '30 Rules' },
    { value: 50, label: '50 Rules' }
];
export const RulesLibraryListPropertyFilterFilteringProperties = [
    idFilteringProperty,
    providerFilteringProperty,
    serviceFilteringProperty,
    actionFilteringProperty,
    descriptionFilteringProperty,
    complianceFrameworksFilteringProperty,
    riskRatingFilteringProperty,
    logBodyFilteringProperty,
];
export const RulesListPropertyFilterFilteringProperties = [
    ...RulesLibraryListPropertyFilterFilteringProperties,
    {
        key: 'numberOfProfiles',
        operators: ['=', '!=', '<', '<=', '>', '>='],
        propertyLabel: 'Attached To Profiles (#)',
        groupValuesLabel: 'Attached To Profiles (#) values'
    },
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty,
    tagsFilteringProperty
];
export const RulesListTableFilteringOptions = [
    ...logBodyFilteringOptions,
    ...riskRatingFilteringOptions,
    ...initialComplianceFrameworksFilteringOptions
];
export const RulesLibraryListTableFilteringOptions = [...logBodyFilteringOptions, ...riskRatingFilteringOptions, ...initialComplianceFrameworksFilteringOptions];
export const getFormattedOptions = (type, optionsData) => {
    return optionsData.filter(item => item.trim() !== "").map((method) => {
        return { operator: "=", propertyKey: `${type}`, value: method };
    });
};
export const getFormattedActions = (type, optionsData) => {
    return optionsData.filter(item => item.trim() !== "").map((method) => {
        return { operator: "=", propertyKey: `${type}`, value: method, label: method };
    });
};
export const configData = {
    action: getFormattedActions("action", ["*"]),
    endpoint: getFormattedOptions("endpoint", ["*"]),
    method: getFormattedOptions("method", ["*"]),
    path: getFormattedOptions("path", ["*"]),
};
