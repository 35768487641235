import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getRandomUUID } from '@/lib/misc/utils';
import { flip, offset, safePolygon, useFloating, useHover, useInteractions } from '@floating-ui/react';
import RightArrowSVGIconUrl from '@/presentation/assets/img/icons/right-arrow.svg';
const SwitchOrganizationMenu = ({ memberships, activeOrgID, switchOrg, position }) => {
    const [isOpen, setIsOpen] = useState(false);
    const floatPlacement = position === 'sidebar' ? 'right-start' : 'left-start';
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: floatPlacement,
        middleware: [
            offset(4),
            flip()
        ],
    });
    const hover = useHover(context, {
        handleClose: safePolygon(),
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
    const location = useLocation();
    const getOrgs = () => {
        if (memberships.length < 2) {
            return null;
        }
        const currentBaseUrl = `${location.pathname}${location.hash}`;
        return memberships.map((item) => {
            var _a, _b;
            if (activeOrgID !== ((_a = item === null || item === void 0 ? void 0 : item.organization) === null || _a === void 0 ? void 0 : _a.id)) {
                return (React.createElement(NavLink, { key: getRandomUUID(), to: currentBaseUrl, role: "popover-submenu-item", className: "block border-l-4 px-4 py-1.5 text-base text-sm text-gray-700 border-[#efefef] hover:border-[#cf4dff] hover:bg-gray-50", onClick: () => {
                        var _a;
                        switchOrg((_a = item === null || item === void 0 ? void 0 : item.organization) === null || _a === void 0 ? void 0 : _a.id);
                    } }, (_b = item === null || item === void 0 ? void 0 : item.organization) === null || _b === void 0 ? void 0 : _b.company_name));
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", Object.assign({ className: "group block border-l-4 px-4 py-1.5 text-base text-sm text-gray-700 hover:border-[#cf4dff] hover:bg-gray-50 cursor-pointer " + (isOpen ? "border-[#cf4dff] bg-gray-50" : ""), ref: refs.setReference }, getReferenceProps()),
            React.createElement("div", { className: "flex items-baseline" },
                React.createElement("span", { className: "mr-2" }, "Switch Organization"),
                React.createElement("img", { src: RightArrowSVGIconUrl, alt: 'right arrow icon' }))),
        isOpen && (React.createElement("div", Object.assign({ className: "floating", ref: refs.setFloating, style: floatingStyles }, getFloatingProps()),
            React.createElement("div", { className: "min-w-56 w-auto rounded-md bg-[#efefef] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, getOrgs()))));
};
export default SwitchOrganizationMenu;
