import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { OnboardingHandlersProvider } from "@/presentation/providers/onboarding-handlers-provider";
import OnBoarding from "@/presentation/pages/on-boarding/on-boarding";
import countryList from 'country-list';
import moment from 'moment-timezone';
import { useAuth0 } from "@/lib/auth0";
const MakeOnboardingPage = () => {
    var _a;
    const { user } = useAuth0();
    const userDetails = user;
    let selectedOption = null;
    const makeCountryList = () => {
        const list = countryList.getData();
        // sort country firstName instead of country code
        list.sort((a, b) => {
            return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0);
        });
        return list.map(item => ({
            label: item.name,
            value: item.code
        }));
    };
    const countryListOptions = makeCountryList();
    const tz = moment.tz.guess();
    const guessedOption = (_a = countryListOptions.filter(item => { var _a; return (item === null || item === void 0 ? void 0 : item.label) === ((_a = tz === null || tz === void 0 ? void 0 : tz.split('/')) === null || _a === void 0 ? void 0 : _a[0]); })) === null || _a === void 0 ? void 0 : _a[0];
    if ((userDetails === null || userDetails === void 0 ? void 0 : userDetails.country_iso_code) !== '') {
        const filteredOption = countryListOptions.filter(country => {
            const option = country;
            return (option === null || option === void 0 ? void 0 : option.value) === (userDetails === null || userDetails === void 0 ? void 0 : userDetails.country_iso_code);
        });
        //  if user country iso code is in our list
        // lets add it selected option else the default option will take over
        if ((filteredOption === null || filteredOption === void 0 ? void 0 : filteredOption.length) > 0) {
            selectedOption = filteredOption[0];
        }
    }
    else if (guessedOption) {
        selectedOption = guessedOption;
    }
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(OnboardingHandlersProvider, null,
            React.createElement(OnBoarding, { countryList: countryListOptions, selectedCountry: selectedOption }))));
};
export default MakeOnboardingPage;
