import { RULE_TYPE_MAX_LENGTH, RULE_TYPE_MIN_LENGTH, RULE_TYPE_VALID_ATTRIBUTE_KEYS, RULE_TYPE_VALID_ATTRIBUTE_SELECT_KEY_VALUE_PAIRS, RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_DOMAIN_NAMES_VALUE_SELECT, RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_VALUE_PAIRS } from '@/presentation/handlers';
import { isAttributeKeyValid, isAttributeKeyValidDomainName, isValidJsonPath } from '@/presentation/common-utils/common-utils';
import { AttributeErrorCode } from '@/presentation/pages';
import { isEmpty } from 'lodash';
export class AttributeKeyValueValidator {
    constructor(rules) {
        this.rules = [];
        this.ignoreEmptyKeyValuePairs = false;
        this.addRule = (rule) => {
            const filtered = this.rules.filter(r => r.type !== rule.type);
            return [...filtered, rule];
        };
        this.ignoreEmptyPairs = () => {
            this.ignoreEmptyKeyValuePairs = true;
            return this;
        };
        this.makeError = (errorMessage, errorItems) => {
            return { valid: false, message: errorMessage, errorItems: errorItems };
        };
        this.makeValid = (value) => {
            return { valid: true, value: value };
        };
        this.notEmpty = () => {
            this.rules = this.addRule({ type: RULE_TYPE_MIN_LENGTH, min: 1 });
            return this;
        };
        this.haveValidAttributeSelectKeyValuePairs = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ATTRIBUTE_SELECT_KEY_VALUE_PAIRS });
            return this;
        };
        this.haveValidAttributeTextKeyValuePairs = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_VALUE_PAIRS });
            return this;
        };
        this.haveValidAttributeTextKeyDomainNamesValueSelectPairs = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_DOMAIN_NAMES_VALUE_SELECT });
            return this;
        };
        this.haveValidAttributeKeys = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ATTRIBUTE_KEYS });
            return this;
        };
        this.validateForAttributeSelectKeyValuePairs = (attributes) => {
            const errorItems = [];
            let vAttrKeys = attributes;
            if (this.ignoreEmptyKeyValuePairs) {
                // check only if either key or value is empty
                vAttrKeys = attributes.filter(item => !isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) || !isEmpty(String(item === null || item === void 0 ? void 0 : item.value).trim()));
            }
            const vAttrKeyValuePairs = [];
            vAttrKeys.forEach(item => {
                let ERROR = false;
                const kvPair = (item === null || item === void 0 ? void 0 : item.key) + '_' + (item === null || item === void 0 ? void 0 : item.value);
                const duplicateFound = vAttrKeyValuePairs.includes(kvPair);
                if (!duplicateFound) {
                    vAttrKeyValuePairs.push(kvPair);
                }
                const tmpKV = item;
                if (duplicateFound) {
                    ERROR = true;
                    tmpKV.errorInValue = true;
                    tmpKV.errorCode = AttributeErrorCode.DUPLICATE_VALUE;
                    tmpKV.errorCodeKey = AttributeErrorCode.DUPLICATE_VALUE;
                }
                // we check for this secondly as this has more precedence
                // in case of duplicate value and invalid key - show invalid key as error
                // as it is mandatory to fix the key first
                if (!isAttributeKeyValid(item === null || item === void 0 ? void 0 : item.key)) {
                    ERROR = true;
                    tmpKV.errorInKey = true;
                    tmpKV.errorCode = AttributeErrorCode.INVALID_KEY;
                    tmpKV.errorCodeKey = AttributeErrorCode.INVALID_KEY;
                }
                if (ERROR) {
                    errorItems.push(tmpKV);
                }
            });
            return errorItems;
        };
        this.validateDuplicateKeys = (dataArray) => {
            const vAttrKeyValuePairs = [];
            let duplicateFound = false;
            const errorData = [];
            dataArray === null || dataArray === void 0 ? void 0 : dataArray.forEach(item => {
                if (!isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim())) {
                    duplicateFound = vAttrKeyValuePairs.includes(item.key);
                    if (!duplicateFound) {
                        vAttrKeyValuePairs.push(item.key);
                    }
                    let tmpKV = item;
                    if (duplicateFound) {
                        tmpKV.errorInKey = true;
                        tmpKV.errorCodeKey = AttributeErrorCode.DUPLICATE_KEY;
                        errorData.push(tmpKV);
                    }
                }
            });
            return errorData;
        };
        this.validateForAttributeTextKeyValuePairs = (attributes) => {
            const errorItems = [];
            let vAttrKeys = attributes;
            if (this.ignoreEmptyKeyValuePairs) {
                // check only if either key or value is empty
                vAttrKeys = attributes.filter(item => !isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) || !isEmpty(String(item === null || item === void 0 ? void 0 : item.value).trim()));
            }
            const vAttrKeyValuePairs = [];
            vAttrKeys.forEach(item => {
                let ERROR = false;
                let duplicateFound = false;
                if (!isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim())) {
                    duplicateFound = vAttrKeyValuePairs.includes(item.key);
                    if (!duplicateFound) {
                        vAttrKeyValuePairs.push(item.key);
                    }
                }
                let tmpKV = item;
                //  duplicate key has the highest precedent
                if (duplicateFound) {
                    ERROR = true;
                    tmpKV.errorInKey = true;
                    tmpKV.errorCodeKey = AttributeErrorCode.DUPLICATE_KEY;
                }
                else if (!(item === null || item === void 0 ? void 0 : item.key) || !isValidJsonPath(item === null || item === void 0 ? void 0 : item.key)) {
                    ERROR = true;
                    tmpKV.errorInKey = true;
                    tmpKV.errorCodeKey = AttributeErrorCode.INVALID_KEY;
                }
                else {
                    tmpKV.errorInKey = false;
                    tmpKV.errorCodeKey = undefined;
                }
                // valid value not empty
                if (!(item === null || item === void 0 ? void 0 : item.value)) {
                    ERROR = true;
                    tmpKV.errorInValue = true;
                    tmpKV.errorCodeValue = AttributeErrorCode.INVALID_VALUE;
                }
                else {
                    tmpKV.errorInValue = false;
                    tmpKV.errorCodeValue = undefined;
                }
                if (ERROR) {
                    errorItems.push(tmpKV);
                }
            });
            return errorItems;
        };
        this.validateForAttributeTextKeyDomainNamesValueSelect = (attributes, additionalValues, interactiveFields) => {
            const errorItems = [];
            let vAttrKeys = attributes;
            if (this.ignoreEmptyKeyValuePairs) {
                // check only if either key or value is empty
                vAttrKeys = attributes.filter(item => !isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) || !isEmpty(String(item === null || item === void 0 ? void 0 : item.value).trim()));
            }
            const defaultSelectedActionValue = additionalValues;
            const vAttrKeyValuePairs = [];
            vAttrKeys.forEach(item => {
                const { key: itemKey = '', value: itemValue = '' } = item;
                let ERROR = false;
                const duplicateFound = !isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) && vAttrKeyValuePairs.includes(item.key);
                const keyDataId = `key-${item.id}`;
                const valueDataId = `value-${item.id}`;
                const isItemKeyExist = itemKey !== '';
                const isItemValueExist = itemValue !== '';
                let tmpKV = item;
                tmpKV.errorInKey = false;
                tmpKV.errorCodeKey = undefined;
                tmpKV.errorInValue = false;
                tmpKV.errorCodeValue = undefined;
                //  duplicate key has the highest precedent
                if (duplicateFound) {
                    vAttrKeyValuePairs.push(item.key);
                    ERROR = true;
                    tmpKV.errorInKey = true;
                    tmpKV.errorCodeKey = AttributeErrorCode.DUPLICATE_KEY;
                }
                else if ((isItemValueExist && !isItemKeyExist && (interactiveFields === null || interactiveFields === void 0 ? void 0 : interactiveFields[keyDataId])) || ((item === null || item === void 0 ? void 0 : item.key) && !isAttributeKeyValidDomainName(item === null || item === void 0 ? void 0 : item.key)) || (!interactiveFields && !isItemKeyExist)) {
                    ERROR = true;
                    tmpKV.errorInKey = true;
                    tmpKV.errorCodeKey = AttributeErrorCode.INVALID_KEY;
                }
                if ((isItemKeyExist && !isItemValueExist && (interactiveFields === null || interactiveFields === void 0 ? void 0 : interactiveFields[valueDataId])) || (!interactiveFields && !(item === null || item === void 0 ? void 0 : item.value))) {
                    ERROR = true;
                    tmpKV.errorInValue = true;
                    tmpKV.errorCodeValue = AttributeErrorCode.INVALID_VALUE;
                }
                else if ((isItemValueExist && (defaultSelectedActionValue === null || defaultSelectedActionValue === void 0 ? void 0 : defaultSelectedActionValue.value) === (item === null || item === void 0 ? void 0 : item.value))) {
                    ERROR = true;
                    tmpKV.errorInValue = true;
                    tmpKV.errorCodeValue = AttributeErrorCode.INVALID_VALUE_ADDITIONAL;
                }
                if (ERROR) {
                    errorItems.push(tmpKV);
                }
            });
            return errorItems;
        };
        this.checkRule = (rule, attributes, additionalValues, interactiveFields) => {
            let errorItems = [];
            switch (rule.type) {
                case RULE_TYPE_MIN_LENGTH:
                    return attributes.length < rule.min
                        ? this.makeError(`Value cannot be empty`, [])
                        : this.makeValid(attributes);
                case RULE_TYPE_MAX_LENGTH:
                    return attributes.length > rule.max
                        ? this.makeError(`Array length must be less than or equal to ${rule.max} but was ${attributes.length}.`, [])
                        : this.makeValid(attributes);
                case RULE_TYPE_VALID_ATTRIBUTE_SELECT_KEY_VALUE_PAIRS:
                    errorItems = this.validateForAttributeSelectKeyValuePairs(attributes);
                    return (errorItems === null || errorItems === void 0 ? void 0 : errorItems.length) === 0
                        ? this.makeValid(attributes)
                        : this.makeError(`String must be alpha numeric or hyphen or dot.`, errorItems);
                case RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_VALUE_PAIRS:
                    errorItems = this.validateForAttributeTextKeyValuePairs(attributes);
                    return (errorItems === null || errorItems === void 0 ? void 0 : errorItems.length) === 0
                        ? this.makeValid(attributes)
                        : this.makeError(`String must be alpha numeric or hyphen or dot.`, errorItems);
                case RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_DOMAIN_NAMES_VALUE_SELECT:
                    errorItems = this.validateForAttributeTextKeyDomainNamesValueSelect(attributes, additionalValues, interactiveFields);
                    return (errorItems === null || errorItems === void 0 ? void 0 : errorItems.length) === 0
                        ? this.makeValid(attributes)
                        : this.makeError(`Domain Name must be alpha numeric or hyphen or dot.`, errorItems);
                case RULE_TYPE_VALID_ATTRIBUTE_KEYS:
                    errorItems = this.validateDuplicateKeys(attributes);
                    return (errorItems === null || errorItems === void 0 ? void 0 : errorItems.length) === 0
                        ? this.makeValid(attributes)
                        : this.makeError(`Duplicate keys entered.`, errorItems);
            }
        };
        this.validate = (value, additionalValues, interactiveFields) => {
            if (value === null) {
                return this.makeError('AttributeKeyValueValidator expected an array but received null.', []);
            }
            else if (value === undefined) {
                return this.makeError('AttributeKeyValueValidator expected an array but received undefined.', []);
            }
            else if (!Array.isArray(value)) {
                return this.makeError(`AttributeKeyValueValidator expected an array but received ${typeof value}.`, []);
            }
            for (const rule of this.rules) {
                const result = this.checkRule(rule, value, additionalValues, interactiveFields);
                if (!result.valid) {
                    return result;
                }
            }
            return {
                valid: true,
                value: value
            };
        };
        if (rules && rules.length > 0) {
            this.rules = rules;
        }
    }
}
