import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, ColumnLayout, Container, Table, } from "@cloudscape-design/components-themed/components";
import { TAGS_COLUMN_DEFINITIONS, sortMultiColumns } from "@/presentation/pages";
import { TableHeader } from "@/presentation/components/commons/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
const ShowTags = (props) => {
    var _a;
    const navigate = useNavigate();
    const tags = (_a = props.tags) !== null && _a !== void 0 ? _a : [];
    const buttonText = tags.length > 0 ? "Edit Tags" : "Add Tag";
    const sortedTags = sortMultiColumns(tags, ["key", "value"]);
    const { items, collectionProps } = useCollection(sortedTags, {
        sorting: { defaultState: { sortingColumn: TAGS_COLUMN_DEFINITIONS[0], isDescending: false } },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                React.createElement(Box, null,
                    React.createElement(Table, Object.assign({}, collectionProps, { header: React.createElement(TableHeader, { title: "Tags", totalItems: items || [], description: `Tags are labels assigned to a ${props.resource}. They can be used to filter or search for rules and for monitoring/reporting.`, actionButtons: (props === null || props === void 0 ? void 0 : props.noButton) ? null : React.createElement(Button, { onClick: () => navigate(props.basePath + "/" + (props === null || props === void 0 ? void 0 : props.id) + "/edit" + "#tags") }, buttonText) }), empty: React.createElement(Box, { textAlign: "center", color: "inherit" },
                            React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, `No tags are assigned to this ${props.resource}`)), variant: "embedded", columnDefinitions: TAGS_COLUMN_DEFINITIONS, items: items })))))));
};
export default ShowTags;
