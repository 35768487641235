import { KiveraClient, KiveraSubscriptionClient } from 'tsclient';
export const KiveraGQLClient = (token) => {
    return KiveraClient(`${process.env.AUTH_AUDIENCE}`, token);
};
export const KiveraGQLSocketClient = (token) => {
    return KiveraSubscriptionClient(`${process.env.WEBSOCKET_ENDPOINT}`, token);
};
export const KiveraAdminGQLClient = (token) => {
    return KiveraClient(`${process.env.AUTH_AUDIENCE}`, token);
};
