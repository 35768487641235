import React from "react";
import { Link } from "@cloudscape-design/components-themed/components";
import { ReactComponent as ContactCustomerSupportImg } from '@/presentation/assets/img/pages/support-team.svg';
const SettingsSSO = () => {
    return React.createElement("div", { className: "max-w-full text-center" },
        React.createElement("div", { className: "text-center mx-auto" },
            React.createElement("p", { className: "" },
                React.createElement(ContactCustomerSupportImg, { className: " mx-auto w-64 h-auto mt-10" }),
                React.createElement("span", { className: "px-1" }, "Please"),
                React.createElement(Link, { fontSize: "inherit", external: true, externalIconAriaLabel: "Contact Kivera support", href: "mailto:support@kivera.io" }, "contact Kivera Support"),
                " to configure SSO")));
};
export default SettingsSSO;
