import React, { useEffect, useState } from 'react';
import { FormField, Select } from '@cloudscape-design/components-themed/components';
import { AttributeErrorCode } from '@/presentation/pages';
const ACLValueSelectComponent = (props) => {
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [options] = useState((props === null || props === void 0 ? void 0 : props.options) || []);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const errorItem = (_b = (_a = props.errorItems) === null || _a === void 0 ? void 0 : _a.filter(it => { var _a; return (it === null || it === void 0 ? void 0 : it.id) === ((_a = props.item) === null || _a === void 0 ? void 0 : _a.id); })) !== null && _b !== void 0 ? _b : [];
        if (((_c = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _c === void 0 ? void 0 : _c.errorCodeValue) === AttributeErrorCode.INVALID_VALUE || ((_d = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _d === void 0 ? void 0 : _d.errorCodeValue) === AttributeErrorCode.INVALID_VALUE_ADDITIONAL
            || ((_e = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _e === void 0 ? void 0 : _e.errorCodeValue) === AttributeErrorCode.DUPLICATE_VALUE) {
            const errText = (_h = (_f = props.attributeErrorCodeMappings) === null || _f === void 0 ? void 0 : _f[(_g = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _g === void 0 ? void 0 : _g.errorCodeValue]) !== null && _h !== void 0 ? _h : '';
            setErrorText(errText);
            setIsInvalid((errorItem === null || errorItem === void 0 ? void 0 : errorItem.length) === 1);
        }
        else {
            // Clear the previous errors
            setErrorText('');
            setIsInvalid(false);
        }
    }, [props.errorItems]);
    useEffect(() => {
        var _a, _b;
        // this item has changed
        if (((_b = (_a = props === null || props === void 0 ? void 0 : props.items) === null || _a === void 0 ? void 0 : _a.filter(it => { var _a; return (it === null || it === void 0 ? void 0 : it.id) === ((_a = props.item) === null || _a === void 0 ? void 0 : _a.id); })) === null || _b === void 0 ? void 0 : _b.length) === 1) {
            const option = {
                value: props.item['value'],
                label: props.item['value']
            };
            setSelectedOption(option);
        }
    }, [props]);
    return React.createElement(FormField, { errorText: isInvalid ? errorText : '' },
        React.createElement(Select, { key: props.item.id + '-' + 'value', selectedOption: selectedOption, onChange: (event) => {
                var _a;
                setSelectedOption((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.selectedOption);
                props.onChange(event, props.item);
            }, options: options, invalid: isInvalid, filteringType: "auto", onBlur: props.onBlur }));
};
export default ACLValueSelectComponent;
