export class ListNode {
    constructor(data) {
        this.data = data;
        this.next = null;
    }
}
export class LinkedList {
    constructor(head = null) {
        this.head = head;
    }
    clear() {
        this.head = null;
    }
    getNode(index) {
        let node = this.head;
        if (index === 0) {
            return this.head;
        }
        while (index--) {
            if (node.next !== null)
                node = node.next;
            else
                throw Error("Index Out of Bound");
        }
        return node;
    }
    isEmpty() {
        return !this.head;
    }
    appendNode(newNode) {
        let node = this.head;
        if (!node) {
            //empty list
            this.head = newNode;
            return;
        }
        while (node.next) {
            node = node.next;
        }
        node.next = newNode;
    }
    printList() {
        let node = this.head;
        console.log("HEAD->");
        while (node) {
            console.log(node.data + "->");
            node = node.next;
        }
        console.log("NULL");
    }
}
