var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HandleHttpResponse, HttpStatusCode } from '@/data/protocols/http';
import { KiveraGQLClient } from '@/infra/http';
import { RemoteBaseClass } from '@/data/usecases';
export class RemoteMyProfiles extends RemoteBaseClass {
    constructor(userAPITokenUrl, userResetPasswordUrl, client, getTokenSilently) {
        super(getTokenSilently);
        this.userAPITokenUrl = userAPITokenUrl;
        this.userResetPasswordUrl = userResetPasswordUrl;
        this.httpClient = client;
    }
    generateUserApiKey(description) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.userAPITokenUrl,
                method: 'POST',
                body: {
                    description: description
                }
            });
            // handle key already exists error
            if (httpResponse.statusCode === HttpStatusCode.serverError && ((_a = httpResponse === null || httpResponse === void 0 ? void 0 : httpResponse.body) === null || _a === void 0 ? void 0 : _a.type) === "ApplicationExistsException") {
                return {
                    error: true,
                    type: (_b = httpResponse === null || httpResponse === void 0 ? void 0 : httpResponse.body) === null || _b === void 0 ? void 0 : _b.type
                };
            }
            return HandleHttpResponse(httpResponse);
        });
    }
    updateUserAPIKeyDescription(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).UpdateUserApiKeyDescription(params); }));
        });
    }
    resetUserPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.userResetPasswordUrl,
                method: 'POST'
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListUserApiKeys(); }));
        });
    }
    update(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).UpdateUserInfo(params); }));
        });
    }
    removeUserFromOrganization(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).DeleteUserFromOrganization(params); }));
        });
    }
    deactivateUserApiKeys(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const constructedURL = this.userAPITokenUrl + `?key_id=${params.key_id}`;
            const httpResponse = yield this.httpClient.request({
                url: constructedURL,
                method: 'DELETE'
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    get() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListOrganization(); }));
        });
    }
    getOrgs(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListUserMemberships(userId); }));
        });
    }
}
