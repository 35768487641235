import React, { useEffect, useRef, useState } from 'react';
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
import { RemoteGetSupportLink } from '@/data/usecases';
import { makeManagementSupportAPIUrl } from '@/presentation/main/factories/http';
import { makeAuthorizeHttpClientDecorator } from '@/presentation/main/factories/decorators';
import { SupportHandler } from '@/presentation/handlers/pages/support-handler';
import DefaultErrorPage from "@/presentation/pages/errors/default-error-page";
import { getUrlParamsName } from '@/presentation/common-utils/common-utils';
const Support = ({ supportLink }) => {
    const [returnToLink, setReturnToLink] = useState(supportLink || process.env.KIVERA_ZENDESK_URL_ENDPOINT);
    const formRef = useRef(null);
    const inputRef = useRef(null);
    const remotesSupport = {
        support: new RemoteGetSupportLink(makeManagementSupportAPIUrl(''), makeAuthorizeHttpClientDecorator()),
    };
    const supportHandler = new SupportHandler(remotesSupport);
    const [isSupportRequestError, setIsSupportRequestError] = useState(false);
    const referrer = getUrlParamsName('referrer');
    let defaultNavBackUrl = "/";
    if (referrer && referrer !== 'support') {
        defaultNavBackUrl = referrer;
    }
    useEffect(() => {
        supportHandler.getLink().then((response) => {
            if (!(response === null || response === void 0 ? void 0 : response.error) && (response === null || response === void 0 ? void 0 : response.data)) {
                history.replaceState(null, null, defaultNavBackUrl);
                const { url, token } = response.data;
                // add the JWT and action url to a hidden form on your login page
                inputRef.current.value = token;
                formRef.current.action = url;
                formRef.current.submit();
            }
            else {
                setIsSupportRequestError(true);
            }
        }).catch((e) => {
            console.error(e);
            setIsSupportRequestError(true);
        });
    }, []);
    useEffect(() => {
        if (supportLink) {
            setReturnToLink(supportLink);
        }
        else {
            setReturnToLink(process.env.KIVERA_ZENDESK_URL_ENDPOINT);
        }
    }, [supportLink]);
    if (isSupportRequestError) {
        return React.createElement(DefaultErrorPage, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(OverlayBouncyLoader, null),
        React.createElement("form", { ref: formRef, method: "post" },
            React.createElement("input", { ref: inputRef, type: "hidden", name: "jwt" }),
            React.createElement("input", { value: returnToLink, type: "hidden", name: "return_to" }))));
};
export default Support;
