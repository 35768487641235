import React from "react";
import { AttributeErrorCode } from "@/presentation/pages";
export const IdentitiesDeleteStrings = {
    headerTextSingular: 'Delete Identity',
    headerTextPlural: ' Delete Identities',
    modalTextSingular: 'identity',
    modalTextPlural: 'identities',
    consentText: 'confirm'
};
export var HeaderStrings;
(function (HeaderStrings) {
    HeaderStrings["create"] = "Create Identity";
    HeaderStrings["edit"] = "Edit Identity";
})(HeaderStrings || (HeaderStrings = {}));
export const IdentityDeleteShowProp = 'name';
export const ValueWithLabel = ({ label, children }) => (React.createElement("div", null,
    React.createElement("div", { style: { fontWeight: '700' } }, label),
    React.createElement("div", { style: { fontSize: '12px', color: 'rgb(95, 107, 122)' } }, children)));
export const UserIdentitiesErrorCode = {
    [AttributeErrorCode.INVALID_KEY]: 'Please enter a valid attribute. Attribute must be a valid JSONPath',
    [AttributeErrorCode.INVALID_VALUE]: 'Please enter a valid Value',
    [AttributeErrorCode.INVALID_VALUE_ADDITIONAL]: '',
    [AttributeErrorCode.INVALID_KEY_AND_VALUE]: '',
    [AttributeErrorCode.DUPLICATE_KEY]: 'Duplicate attribute. Attribute mappings must be unique',
    [AttributeErrorCode.DUPLICATE_VALUE]: '',
};
