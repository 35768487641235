import React from "react";
import { Alert, Box, Button, ColumnLayout, ExpandableSection, Modal, SpaceBetween } from "@cloudscape-design/components-themed/components";
import { getRandomUUID } from "@/lib/misc/utils";
const DuplicateModal = (props) => {
    var _a;
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit();
    };
    return React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: 'Duplicate Rules', closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
            React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                React.createElement(Button, { variant: "primary", onClick: handleSubmit }, "Proceed"))) },
        React.createElement(SpaceBetween, { size: "m" },
            React.createElement(Alert, { type: 'warning' }, "The following rules already exist. Re-importing these rules will result in duplicates. Would you like to proceed?"),
            React.createElement(ColumnLayout, { columns: 1 },
                React.createElement(ExpandableSection, { headerText: "Rules" }, React.createElement("ul", null, (_a = props.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                    return React.createElement("li", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.id);
                }))))));
};
export default DuplicateModal;
