export const RULES_SIMULATOR_LIST_SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: 'Split panel preferences',
    preferencesPositionLabel: 'Split panel position',
    preferencesPositionDescription: 'Choose the default split panel position for the service.',
    preferencesPositionSide: 'Side',
    preferencesPositionBottom: 'Bottom',
    preferencesConfirm: 'Confirm',
    preferencesCancel: 'Cancel',
    closeButtonAriaLabel: 'Close panel',
    openButtonAriaLabel: 'Open panel',
    resizeHandleAriaLabel: 'Resize split panel',
};
export const RULES_SIMULATOR_LIST_EMPTY_PANEL_CONTENT = {
    header: '0 record selected',
    body: 'Select a log record to see its details.',
};
export const RuleEvaluationProfilesColumnDefinitions = [
    {
        id: 'id',
        header: 'ID',
        cell: item => item.id,
        width: 120
    },
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
    },
];
export const RuleEvaluationRulesColumnDefinitions = [
    {
        id: 'id',
        header: 'ID',
        cell: item => item.id,
        width: 120
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description,
    },
    {
        id: 'provider',
        header: 'Provider',
        cell: item => item.provider,
    },
    {
        id: 'service',
        header: 'Service',
        cell: item => item.service,
    },
    {
        id: 'processResult',
        header: 'Process Result',
        cell: item => item.processResult,
    },
];
export const RuleEvaluationPoliciesColumnDefinitions = [
    {
        id: 'id',
        header: 'ID',
        cell: item => item.id,
        width: 120
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description,
    },
    {
        id: 'enforced',
        header: 'Enforced',
        cell: item => item.enforced,
    },
    {
        id: 'processResult',
        header: 'Process Result',
        cell: item => item.processResult,
    },
];
