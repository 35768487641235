import { Button, Form, SpaceBetween, Link } from "@cloudscape-design/components-themed/components";
import React, { useCallback, useState } from "react";
import { useIdentitiesHandlers } from "@/presentation/providers";
import { useNavigate } from "react-router-dom";
import { IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY, PATH_IDENTITIES, PATHS } from "@/presentation/common-utils/constants";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { makeI18nStrings } from '@/presentation/components/I18nStrings';
import IdentitiesDetails from "@/presentation/pages/identities/identities-details";
import IdentitiesProfiles from "@/presentation/pages/identities/identities-profiles";
import { makeNotification } from "@/presentation/pages";
import { IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE } from "@/presentation/common-utils/constants";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import FormTags from '@/presentation/components/tags/form-tags';
import { makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
const IdentitiesForm = (props) => {
    var _a;
    const [stepsInfo, setStepsInfo] = useState(props.stepInfo);
    const { nameValidator, descriptionValidator, cidrRangesValidator, identities, userIdentityKeyValueValidator } = useIdentitiesHandlers();
    const [nameError, setNameError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [cidrError, setCidrError] = useState(false);
    const [cloudTenantsIDError, setCloudTenantsIDError] = useState(false);
    const [cloudTenantsError, setCloudTenantsError] = useState(false);
    const [userIdentitiesError, setUserIdentitiesError] = useState(false);
    const [overrideNameErrorText, setOverrideNameErrorText] = useState("");
    const [tagsError, setTagsError] = useState(false);
    const [cloudTenantID, setCloudTenantID] = useState(props.cloudTenantID);
    const [recheckAttributeErrors, setRecheckAttributeErrors] = useState(false);
    const navigate = useNavigate();
    const identitiesHandler = identities;
    const { pushNotifications, dismissNotification } = useNotifications();
    const onStepInfoChange = useCallback((stateKey, newStepState) => {
        setStepsInfo(Object.assign(Object.assign({}, stepsInfo), { [stateKey]: Object.assign(Object.assign({}, stepsInfo[stateKey]), newStepState) }));
    }, [stepsInfo]);
    const makeSuccessNotification = (message) => {
        const msg = 'Identity ' + ((props.isCreate || props.isClone) ? 'Created' : 'Updated') + ' Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to ' + ((props.isCreate || props.isClone) ? 'create' : 'update') + '. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const onCancel = () => {
        if (props.isEdit) {
            navigate(PATHS.IDENTITIES + '/' + stepsInfo.details.id);
        }
        else {
            navigate(PATHS.IDENTITIES);
        }
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        if ((getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorType) === "UniqueNameViolation") {
            setNameError(true);
            setOverrideNameErrorText("Duplicate identity name. Please enter a unique identity name.");
        }
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const clearErrors = () => {
        setNameError(false);
        setDescError(false);
        setCidrError(false);
        setCloudTenantsIDError(false);
        setCloudTenantsError(false);
        setUserIdentitiesError(false);
    };
    const onSubmitRequest = () => {
        if (props.isEdit) {
            // All good lets update
            identitiesHandler.updateIdentity(props.identityAndProfiles, stepsInfo, cloudTenantID).then(result => {
                var _a, _b, _c;
                if ((_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.update_Identities) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) {
                    pushNotifications(makeSuccessNotification());
                    navigate(PATH_IDENTITIES.VIEW + '/' + stepsInfo.details.id);
                }
                else {
                    pushNotifications(makeErrorNotification());
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
            });
        }
        else {
            // All good lets create
            identitiesHandler.createIdentity(stepsInfo, cloudTenantID).then(result => {
                var _a, _b, _c;
                const createdId = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Identities) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id;
                if (createdId) {
                    const navigateLink = React.createElement(Link, { fontSize: "inherit", color: "inverted", href: `${PATH_IDENTITIES.VIEW}/${createdId}` },
                        "#",
                        createdId);
                    const messsge = (React.createElement("span", null,
                        "Identity ",
                        navigateLink,
                        " Created Successfully"));
                    pushNotifications(makeSuccessNotification(messsge));
                    navigate(PATHS.IDENTITIES);
                }
                else {
                    pushNotifications(makeErrorNotification());
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
            });
        }
    };
    const onSubmit = () => {
        var _a, _b, _c, _d;
        const nValid = nameValidator === null || nameValidator === void 0 ? void 0 : nameValidator.validate(stepsInfo.details.name);
        const dValid = descriptionValidator === null || descriptionValidator === void 0 ? void 0 : descriptionValidator.validate(stepsInfo.details.description);
        const authMethod = stepsInfo.details.authenticationMethod;
        const madeCidrRanges = identitiesHandler.removeSpacesFromRanges(stepsInfo.details.cidrRange);
        const cValid = cidrRangesValidator === null || cidrRangesValidator === void 0 ? void 0 : cidrRangesValidator.validate(madeCidrRanges);
        const cTenantValid = ((_b = (_a = stepsInfo.details) === null || _a === void 0 ? void 0 : _a.cloudTenants) === null || _b === void 0 ? void 0 : _b.length) > 0;
        const userIdentityValid = ((_c = stepsInfo.details) === null || _c === void 0 ? void 0 : _c.userIdentities) ? userIdentityKeyValueValidator.validate((_d = stepsInfo.details) === null || _d === void 0 ? void 0 : _d.userIdentities) : [];
        if (!(nValid === null || nValid === void 0 ? void 0 : nValid.valid) || !(dValid === null || dValid === void 0 ? void 0 : dValid.valid) || tagsError ||
            (authMethod === IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE && !(cValid === null || cValid === void 0 ? void 0 : cValid.valid)) ||
            (authMethod === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT && (!cTenantValid || !cloudTenantID)) ||
            (authMethod === IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY && !(userIdentityValid === null || userIdentityValid === void 0 ? void 0 : userIdentityValid.valid))) {
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('identity', (props.isCreate || props.isClone) ? 'create' : 'update')));
            setRecheckAttributeErrors(!recheckAttributeErrors);
            setNameError(!(nValid === null || nValid === void 0 ? void 0 : nValid.valid));
            setDescError(!(dValid === null || dValid === void 0 ? void 0 : dValid.valid));
            setCloudTenantsIDError(authMethod === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT && !cloudTenantID);
            setCloudTenantsError((authMethod === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT && !cTenantValid));
            setUserIdentitiesError((authMethod === IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY && !(userIdentityValid === null || userIdentityValid === void 0 ? void 0 : userIdentityValid.valid)));
            setCidrError(authMethod === IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE && !(cValid === null || cValid === void 0 ? void 0 : cValid.valid));
        }
        else {
            clearErrors();
            onSubmitRequest();
        }
    };
    const i18nStrings = makeI18nStrings(props, "Identity");
    const onTagsChange = (newtagsInfo) => {
        setTagsError(!(newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.valid));
        onStepInfoChange("tags", { editorTags: (newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.tags) || [] });
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'form-container' },
            React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: onCancel }, i18nStrings.cancelButton),
                    React.createElement(Button, { variant: "primary", onClick: onSubmit }, i18nStrings.submitButton)) },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(IdentitiesDetails, { info: stepsInfo, isEdit: props.isEdit, setActiveStepIndex: () => { }, onChange: newStepState => {
                            onStepInfoChange('details', newStepState);
                        }, cloudTenantID: cloudTenantID, cloudTenantIDOnChange: (id) => {
                            setCloudTenantID(id);
                        }, recheckErrors: recheckAttributeErrors, spacing: 'l', validation: {
                            nameError,
                            descError,
                            cidrError,
                            overrideNameErrorText,
                            cloudTenantsIDError,
                            cloudTenantsError,
                            userIdentitiesError
                        } }),
                    React.createElement(IdentitiesProfiles, { info: stepsInfo, setActiveStepIndex: () => { }, onChange: newStepState => onStepInfoChange('profiles', newStepState), profiles: props.profiles }),
                    React.createElement(FormTags, { tags: ((_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) || [], onChange: (newStepState) => {
                            onTagsChange(newStepState);
                        } })))));
};
export default IdentitiesForm;
