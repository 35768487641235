import React, { useEffect, useState } from 'react';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { Box, Button, CollectionPreferences, Container, DateRangePicker, Pagination, PropertyFilter, SpaceBetween, Table } from "@cloudscape-design/components-themed/components";
import { TableHeader, TableNoMatchState } from "@/presentation/components/commons/common-components";
import { getFilterCounterText } from "@/presentation/components/commons/tableCounterStrings";
import { paginationLabels } from "@/presentation/components/commons/labels";
import { AuditLogsListColumnDefinitions, AuditLogsListFilteringOptions, AuditLogsListFilteringProperties, AuditLogsListTablePageSizeOptions, AuditLogsListTablePreferences, AuditLogsListTableVisibleContentOptions, AuditLogsDefaultDatetimeOptions, AuditLogsInitialTableOptions, AuditLogsDefaultDateRangeFilter } from '@/presentation/pages/settings/audit-logs/settings-audit-logs-config';
import { useAuth0 } from '@/lib/auth0';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { GetDateRangeChangeDetail, GetDateTimeOptions, InitialDateRangeOptions, SetDateRangeChangeDetail, SetDateTimeOptions, } from "@/presentation/storage";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { getDateTimeRangeOptions, makeDateRangeI18nStrings, makeDateRangeRelativeOptions, makePropertyFilterI18nStrings, validateDateRange } from '@/presentation/pages';
import TableContainer from "@/presentation/components/table/table-container";
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { useIsMounted } from '@/presentation/hooks';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const AuditLogsEmptyState = () => {
    return React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement(Box, null,
                React.createElement("b", null, "No logs found."))));
};
const SettingsAuditLogs = (props) => {
    var _a;
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [logsData, setLogsData] = useState([]);
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.AUDIT_LOGS_LIST, AuditLogsListColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.AUDIT_LOGS_LIST, AuditLogsListTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_AUDIT_LOGS_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_AUDIT_LOGS_LIST);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : AuditLogsListColumnDefinitions === null || AuditLogsListColumnDefinitions === void 0 ? void 0 : AuditLogsListColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(logsData, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: AuditLogsListFilteringProperties,
            empty: React.createElement(AuditLogsEmptyState, null),
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'audit logs' })
        },
        sorting: { defaultState: defaultSortingState },
        pagination: { pageSize: preferences.pageSize },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const isMounted = useIsMounted();
    const [selectedItems, setSelectedItems] = useState([]);
    const getDateTimeRangeFilters = (_a = GetDateRangeChangeDetail(AuditLogsInitialTableOptions.dateRangeStorage, AuditLogsDefaultDateRangeFilter)) === null || _a === void 0 ? void 0 : _a.value;
    const [dateTimeOptions, setDateTimeOptions] = useState(GetDateTimeOptions(AuditLogsInitialTableOptions.dateTimeOptionsStorage, AuditLogsDefaultDatetimeOptions));
    const [dateRangeFilter, setDateRangeFilter] = useState(getDateTimeRangeFilters);
    const [filteringOptions, setFilteringOptions] = useState(AuditLogsListFilteringOptions);
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        const listParams = {
            start_timestamp: dateTimeOptions.startDateTime,
            end_timestamp: dateTimeOptions.endDateTime
        };
        settingsHandler === null || settingsHandler === void 0 ? void 0 : settingsHandler.listAuditLogs(listParams).then((entityItems) => {
            const formattedItems = settingsHandler === null || settingsHandler === void 0 ? void 0 : settingsHandler.getFormattedAuditLogs(entityItems);
            const { auditLogsData, formattedFilteredList } = formattedItems;
            if (isMounted.current) {
                setLogsData(auditLogsData);
                const updateFilters = [...AuditLogsListFilteringOptions, ...formattedFilteredList];
                setFilteringOptions(updateFilters);
            }
        }).catch((e) => {
            console.error('unable to list entity got error:', e);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        refreshList();
        return () => {
            console.log('cleaning up');
        };
    }, [user]);
    const dateRangeOnChange = (detail) => {
        const dateTimeOptions = getDateTimeRangeOptions(detail);
        if (detail === null || detail === void 0 ? void 0 : detail.value) {
            SetDateRangeChangeDetail(AuditLogsInitialTableOptions.dateRangeStorage, detail);
        }
        else {
            SetDateRangeChangeDetail(AuditLogsInitialTableOptions.dateRangeStorage, InitialDateRangeOptions);
        }
        const dateTimeValue = (detail === null || detail === void 0 ? void 0 : detail.value) || (AuditLogsDefaultDateRangeFilter === null || AuditLogsDefaultDateRangeFilter === void 0 ? void 0 : AuditLogsDefaultDateRangeFilter.value);
        setDateRangeFilter(dateTimeValue);
        setDateTimeOptions(dateTimeOptions);
        SetDateTimeOptions(AuditLogsInitialTableOptions.dateTimeOptionsStorage, dateTimeOptions);
    };
    return React.createElement(ContentWrapper, null,
        React.createElement(Container, null,
            React.createElement(TableContainer, { key: renderCount },
                React.createElement(Table, Object.assign({}, collectionProps, { key: props.splitKeyValue, resizableColumns: true, loading: loading, loadingText: 'Loading Logs...', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: {}, variant: "embedded", wrapLines: preferences.wrapLines, selectionType: "single", selectedItems: selectedItems, onSelectionChange: event => {
                        setSelectedItems(event.detail.selectedItems);
                        props.onSelectionChange(event);
                    }, onSortingChange: ({ detail }) => {
                        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_AUDIT_LOGS_LIST, detail);
                        actions.setSorting(detail);
                    }, header: React.createElement(TableHeader, Object.assign({ title: 'Audit Logs', singleSelect: true, selectedItems: selectedItems, totalItems: logsData }, props)), filter: React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                        React.createElement("div", { className: 'list-property-filter' },
                            React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Logs' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: filteringOptions, filteringProperties: AuditLogsListFilteringProperties, onChange: ({ detail }) => {
                                    const filteredDetail = fetchUniquePropertyFilters(detail);
                                    SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_AUDIT_LOGS_LIST, filteredDetail);
                                    actions.setPropertyFiltering(filteredDetail);
                                } }))),
                        React.createElement(DateRangePicker, { onChange: ({ detail }) => {
                                dateRangeOnChange(detail);
                            }, value: dateRangeFilter, relativeOptions: makeDateRangeRelativeOptions(), isValidRange: validateDateRange, i18nStrings: makeDateRangeI18nStrings(), placeholder: "Search by date and time range", showClearButton: true }),
                        React.createElement(Button, { variant: "primary", onClick: () => refreshList() }, "Search")), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: AuditLogsListTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: AuditLogsListTableVisibleContentOptions
                        } }) })))));
};
export default SettingsAuditLogs;
