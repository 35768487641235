import { GENERIC_FORBIDDEN_MESSAGE, GENERIC_CONFLICT_MESSAGE, GENERIC_UNKNOWN_ERROR_MESSAGE, } from "@/data/protocols/http";
const resolveCloudTenantErrors = (errors, msg, type, resolveGeneric) => {
    errors.forEach((item) => {
        var _a, _b;
        if (((_a = item === null || item === void 0 ? void 0 : item.extensions) === null || _a === void 0 ? void 0 : _a.code) === "permission-error" && ((_b = item === null || item === void 0 ? void 0 : item.message) === null || _b === void 0 ? void 0 : _b.includes("cloudtenants_tenant_structure_valid"))) {
            msg = 'Unable to proceed with this request, Cloud Tenant Structure is invalid.';
            type = "ValidationFailed";
            resolveGeneric = false;
        }
    });
    return { msg, type, resolveGeneric };
};
const resolveToGenericErrors = (errors, msg, type) => {
    errors.forEach((item) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if ((((_a = item === null || item === void 0 ? void 0 : item.extensions) === null || _a === void 0 ? void 0 : _a.code) === "constraint-violation" &&
            ((_b = item === null || item === void 0 ? void 0 : item.message) === null || _b === void 0 ? void 0 : _b.includes("Uniqueness violation"))) ||
            (((_c = item === null || item === void 0 ? void 0 : item.extensions) === null || _c === void 0 ? void 0 : _c.code) === "data-exception" &&
                ((_d = item === null || item === void 0 ? void 0 : item.message) === null || _d === void 0 ? void 0 : _d.includes("unique")))) {
            msg = GENERIC_CONFLICT_MESSAGE;
            type = "UniqueNameViolation";
        }
        else if (((_e = item === null || item === void 0 ? void 0 : item.extensions) === null || _e === void 0 ? void 0 : _e.code) === "validation-failed" &&
            (((_f = item === null || item === void 0 ? void 0 : item.message) === null || _f === void 0 ? void 0 : _f.includes("not found")) ||
                ((_g = item === null || item === void 0 ? void 0 : item.message) === null || _g === void 0 ? void 0 : _g.includes("no mutation")))) {
            msg = GENERIC_FORBIDDEN_MESSAGE;
            type = "AccessDenied";
        }
        else if (((_h = item === null || item === void 0 ? void 0 : item.extensions) === null || _h === void 0 ? void 0 : _h.code) === "data-exception" && ((_j = item === null || item === void 0 ? void 0 : item.message) === null || _j === void 0 ? void 0 : _j.includes("limit reached"))) {
            msg = item.message;
            type = "AccessDenied";
        }
    });
    return { msg, type };
};
export var PAGE_SPECIFIC_ERROR;
(function (PAGE_SPECIFIC_ERROR) {
    PAGE_SPECIFIC_ERROR["CLOUD_TENANT_SUBMIT"] = "CLOUD_TENANT_SUBMIT";
})(PAGE_SPECIFIC_ERROR || (PAGE_SPECIFIC_ERROR = {}));
export const PagesRequestErrorHandler = (response, pageSpecific) => {
    let errorMessage = GENERIC_UNKNOWN_ERROR_MESSAGE;
    let errorType = "UnknownException";
    // if no response present or no errors property present send back default
    if (!response || !(response === null || response === void 0 ? void 0 : response['errors'])) {
        return {
            errorMessage,
            errorType,
        };
    }
    const { errors } = response;
    let RESOLVE_GENERIC = true;
    if ((errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
        //  check if we could resolve to page specific error first
        //  there are many cases where the backend says one thing but in reality it is something else
        //  eg.) in Cloud Tenant Structures validation, we get `permission-error`
        //  but in reality it is a validation fail coming from backend
        //  the reason we cant specify the message we want from backend is because that's how postgres and hasura work together for now
        switch (pageSpecific) {
            case PAGE_SPECIFIC_ERROR.CLOUD_TENANT_SUBMIT:
                const cloudError = resolveCloudTenantErrors(errors, errorMessage, errorType, RESOLVE_GENERIC);
                errorMessage = cloudError.msg;
                errorType = cloudError.type;
                // will tell if the resolution succeeded or if we should try resolving it to generic messages
                RESOLVE_GENERIC = cloudError.resolveGeneric;
                break;
            default:
                break;
        }
    }
    if (RESOLVE_GENERIC) {
        const genError = resolveToGenericErrors(errors, errorMessage, errorType);
        errorMessage = genError.msg;
        errorType = genError.type;
    }
    return {
        errorMessage,
        errorType,
    };
};
export default PagesRequestErrorHandler;
