import { Alert, Box, Button, Container, Header, Modal, SpaceBetween, } from "@cloudscape-design/components-themed/components";
import React from "react";
const TokensModal = (props) => {
    const handleDownloadTokenSubmit = (event) => {
        event.preventDefault();
        props.onConfirm();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: props.visible, onDismiss: props.onCancel, header: props.headerText, closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
                React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: props.onCancel }, "Close"),
                    React.createElement(Button, { variant: "primary", onClick: handleDownloadTokenSubmit }, "Download Key"))) },
            React.createElement(SpaceBetween, { size: "m" },
                React.createElement(Container, { header: React.createElement(Header, { variant: "h3" }, "API Key Details") },
                    React.createElement(SpaceBetween, { size: "m" },
                        React.createElement(Box, null,
                            React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                            React.createElement(Box, { variant: "span" }, props.id)),
                        React.createElement(Box, null,
                            React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                            React.createElement(Box, { variant: "span" }, props.description)))),
                React.createElement(Box, null, "Your new API key is available to download below. Treat this key like a password, as it can be used to access your account without a username, password, or two-factor authentication."),
                React.createElement(Alert, { type: "warning" }, "This key will not be displayed again, make sure you save it to a safe place.")))));
};
export default TokensModal;
