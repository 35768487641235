var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler } from '@/presentation/handlers';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { constructTagString } from '@/presentation/pages';
export const ProfilesInitialValues = {
    id: 0,
    name: '',
    description: '',
    rules: [],
    tags: []
};
export const FormattedProfileTypes = [
    'id',
    'searchableID',
    'key',
    'name',
    'description',
    'numberOfIdentities',
    'numberOfRules'
];
export const FormattedInmemoryProfileTypes = [
    'id',
    'searchableID',
    'isSelected',
    'name',
    'description',
    'numberOfRules',
    'isDisabled'
];
export class ProfilesHandler extends PagesBaseHandler {
    constructor(remoteHandlers, remotes) {
        super();
        this.profileDetails = ProfilesInitialValues;
        this.setID = (id) => {
            this.profileDetails.id = id;
        };
        this.formattedListEntities = (elements) => {
            return elements.map((element, i) => {
                return {
                    key: element,
                    description: element
                };
            });
        };
        this.getFormattedOptions = (profiles) => {
            var _a;
            return (_a = profiles === null || profiles === void 0 ? void 0 : profiles.Profiles) === null || _a === void 0 ? void 0 : _a.map((profile) => {
                return { value: String(profile.id), label: profile.name };
            });
        };
        this.getTags = (profileTags, id) => {
            return profileTags.map(item => {
                let temp = {};
                if (id) {
                    temp = {
                        key: item.key,
                        value: item.value,
                        profile_id: id
                    };
                }
                else {
                    temp = {
                        key: item.key,
                        value: item.value
                    };
                }
                return temp;
            });
        };
        this.getUpdateProfileRulesParams = (profileDetails, profileInfo) => {
            var _a, _b, _c;
            const initialRules = (_c = (_b = (_a = profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.Profiles_by_pk) === null || _a === void 0 ? void 0 : _a.ProfileRules) === null || _b === void 0 ? void 0 : _b.map(rule => rule.rule_id)) === null || _c === void 0 ? void 0 : _c.filter(id => !!id);
            const toBeUpdatedRules = profileInfo.rules.attachedRules.map(rule => rule.id);
            // the below makes a difference of what is present in initialRules, but not in toBeUpdatedRules
            const toBeDeletedRules = initialRules.filter(id => !toBeUpdatedRules.includes(id));
            const ruleObjects = toBeUpdatedRules.map((ruleID) => {
                var _a;
                return {
                    profile_id: (_a = profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.Profiles_by_pk) === null || _a === void 0 ? void 0 : _a.id,
                    rule_id: ruleID,
                    deleted: false
                };
            });
            toBeDeletedRules.map(ruleID => {
                var _a;
                ruleObjects.push({
                    profile_id: (_a = profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.Profiles_by_pk) === null || _a === void 0 ? void 0 : _a.id,
                    rule_id: ruleID,
                    deleted: true
                });
            });
            return ruleObjects;
        };
        this.getUpdateProfileParams = (profileDetails, profileInfo) => {
            var _a, _b;
            return {
                id: profileInfo.details.id,
                name: profileInfo.details.name,
                description: profileInfo.details.description,
                tags: (_b = (_a = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) === null || _b === void 0 ? void 0 : _b.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!tag.key).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                }),
                rules: this.getUpdateProfileRulesParams(profileDetails, profileInfo) || []
            };
        };
        this.getCreateParams = (orgID, profileInfo) => {
            var _a, _b;
            return {
                name: profileInfo.details.name,
                description: profileInfo.details.description,
                organization_id: orgID,
                tags: (_b = (_a = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) === null || _b === void 0 ? void 0 : _b.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!tag.key).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                }),
                rules: profileInfo.rules.attachedRules.map(rule => {
                    return {
                        rule_id: rule.id
                    };
                })
            };
        };
        this.getPageName = () => {
            return 'Profile';
        };
        this.handleCreate = (profileInfo) => __awaiter(this, void 0, void 0, function* () {
            const token = yield this.remotes.identities.getToken();
            const orgID = getOrgIDFromToken(token);
            return this.remotes.profiles.create(this.getCreateParams(orgID, profileInfo));
        });
        this.handleUpdate = (profileDetails, profileInfo) => __awaiter(this, void 0, void 0, function* () {
            const profileParams = this.getUpdateProfileParams(profileDetails, profileInfo);
            const result = yield this.remotes.profiles.updateV2(profileParams);
            if (result.update_Profiles.returning.length > 0) {
                return result;
            }
            return undefined;
        });
        this.remotes = remotes;
        this.remoteHandlers = remoteHandlers;
    }
    getEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.profiles.get({
                profile_id: id
            });
        });
    }
    deleteEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.profiles.delete({
                profile_id: id
            });
        });
    }
    getNonDeletedItems(items, entityID) {
        return items.filter((item) => item.id !== entityID);
    }
    getItemID() {
        return 'id';
    }
    getFormattedEntity(profile, profileInfo) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        const { id, name, description, tags, ProfileRules, IdentityProfiles } = (profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) || {};
        const profileRules = (_a = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.rules) === null || _a === void 0 ? void 0 : _a.attachedRules.map(rule => {
            return {
                id: rule === null || rule === void 0 ? void 0 : rule.id,
                provider: rule === null || rule === void 0 ? void 0 : rule.provider,
                description: rule === null || rule === void 0 ? void 0 : rule.description,
                service: rule === null || rule === void 0 ? void 0 : rule.service,
                actions: rule === null || rule === void 0 ? void 0 : rule.actions,
                actionsConcat: rule === null || rule === void 0 ? void 0 : rule.actions.join(', '),
            };
        });
        return {
            id,
            name,
            description,
            tags,
            searchableID: String(id),
            key: String(id),
            profileRules,
            numberOfIdentities: String((IdentityProfiles === null || IdentityProfiles === void 0 ? void 0 : IdentityProfiles.length) || 0),
            numberOfRules: String((ProfileRules === null || ProfileRules === void 0 ? void 0 : ProfileRules.length) || 0),
            created_at: (_c = (_b = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _b === void 0 ? void 0 : _b.created_at) !== null && _c !== void 0 ? _c : '',
            created_by: (_f = (_e = (_d = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _d === void 0 ? void 0 : _d.CreatedByUser) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : '',
            updated_at: (_h = (_g = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _g === void 0 ? void 0 : _g.updated_at) !== null && _h !== void 0 ? _h : '',
            updated_by: (_l = (_k = (_j = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _j === void 0 ? void 0 : _j.UpdatedByUser) === null || _k === void 0 ? void 0 : _k.id) !== null && _l !== void 0 ? _l : '',
            createdByFullName: ((_m = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _m === void 0 ? void 0 : _m.CreatedByUser) ? `${((_p = (_o = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _o === void 0 ? void 0 : _o.CreatedByUser) === null || _p === void 0 ? void 0 : _p.given_name) || ''} ${((_r = (_q = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _q === void 0 ? void 0 : _q.CreatedByUser) === null || _r === void 0 ? void 0 : _r.family_name) || ''}` : '',
            updatedByFullName: ((_s = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _s === void 0 ? void 0 : _s.UpdatedByUser) ? `${((_u = (_t = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _t === void 0 ? void 0 : _t.UpdatedByUser) === null || _u === void 0 ? void 0 : _u.given_name) || ''} ${((_w = (_v = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _v === void 0 ? void 0 : _v.UpdatedByUser) === null || _w === void 0 ? void 0 : _w.family_name) || ''}` : '',
        };
    }
    getFormattedEntities(profiles) {
        return profiles.Profiles.map((profile) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            let identitiesCount = 0;
            if ((_b = (_a = profile.IdentityProfiles_aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count) {
                identitiesCount = identitiesCount + ((_d = (_c = profile.IdentityProfiles_aggregate) === null || _c === void 0 ? void 0 : _c.aggregate) === null || _d === void 0 ? void 0 : _d.count);
            }
            return {
                id: profile.id,
                searchableID: String(profile.id),
                key: String(profile.id),
                name: profile.name,
                description: profile.description,
                numberOfIdentities: String(identitiesCount),
                numberOfRules: ((_f = (_e = profile.ProfileRules_aggregate) === null || _e === void 0 ? void 0 : _e.aggregate) === null || _f === void 0 ? void 0 : _f.count) ? String((_h = (_g = profile.ProfileRules_aggregate) === null || _g === void 0 ? void 0 : _g.aggregate) === null || _h === void 0 ? void 0 : _h.count) : String(0),
                created_at: (_j = profile === null || profile === void 0 ? void 0 : profile.created_at) !== null && _j !== void 0 ? _j : '',
                created_by: (_l = (_k = profile === null || profile === void 0 ? void 0 : profile.CreatedByUser) === null || _k === void 0 ? void 0 : _k.id) !== null && _l !== void 0 ? _l : '',
                updated_at: (_m = profile === null || profile === void 0 ? void 0 : profile.updated_at) !== null && _m !== void 0 ? _m : '',
                updated_by: (_p = (_o = profile === null || profile === void 0 ? void 0 : profile.UpdatedByUser) === null || _o === void 0 ? void 0 : _o.id) !== null && _p !== void 0 ? _p : '',
                createdByFullName: (profile === null || profile === void 0 ? void 0 : profile.CreatedByUser) ? `${((_q = profile === null || profile === void 0 ? void 0 : profile.CreatedByUser) === null || _q === void 0 ? void 0 : _q.given_name) || ''} ${((_r = profile === null || profile === void 0 ? void 0 : profile.CreatedByUser) === null || _r === void 0 ? void 0 : _r.family_name) || ''}` : '',
                updatedByFullName: (profile === null || profile === void 0 ? void 0 : profile.UpdatedByUser) ? `${((_s = profile === null || profile === void 0 ? void 0 : profile.UpdatedByUser) === null || _s === void 0 ? void 0 : _s.given_name) || ''} ${((_t = profile === null || profile === void 0 ? void 0 : profile.UpdatedByUser) === null || _t === void 0 ? void 0 : _t.family_name) || ''}` : '',
                tagString: constructTagString((profile === null || profile === void 0 ? void 0 : profile.tags) || [])
            };
        });
    }
    getSelectableInMemoryFormattedEntities(profiles, selectedOptions) {
        const formatted = profiles.Profiles.map((profile) => {
            var _a, _b, _c, _d;
            return {
                id: profile.id,
                searchableID: String(profile.id),
                name: profile.name,
                description: profile.description,
                isDisabled: false,
                isSelected: selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.includes(profile.id),
                numberOfRules: ((_b = (_a = profile.ProfileRules_aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count) ? String((_d = (_c = profile.ProfileRules_aggregate) === null || _c === void 0 ? void 0 : _c.aggregate) === null || _d === void 0 ? void 0 : _d.count) : String(0),
                tagString: constructTagString((profile === null || profile === void 0 ? void 0 : profile.tags) || [])
            };
        });
        formatted.sort((optionA, optionB) => {
            if (optionA.isSelected && optionB.isSelected) {
                return 0;
            }
            else if (optionA.isSelected && !optionB.isSelected) {
                return -1;
            }
            else {
                return 1;
            }
        });
        return formatted;
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.profiles.list();
        });
    }
    listEntityForInmemorySelectable() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.listEntity();
        });
    }
    extractFilters(any) {
        return [];
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield input;
        });
    }
    deleteBulk(ids) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.remotes.profiles.deleteBulk({
                ids: ids
            });
            return {
                success: ((_a = result === null || result === void 0 ? void 0 : result.delete_Profiles) === null || _a === void 0 ? void 0 : _a.affected_rows) > 0,
                response: result
            };
        });
    }
}
