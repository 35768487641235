import React from "react";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { breadcrumbsMyProfile } from "@/presentation/components/commons/breadcrumbs";
import { MyProfileHandlersProvider } from "@/presentation/providers/my-profile-handlers-provider";
import ErrorBoundary from '@/presentation/pages/errors/error-boundary';
import { Flashbar } from '@cloudscape-design/components-themed/components';
import MyProfile from "@/presentation/pages/my-profile/my-profile";
import { useNotifications } from '@/presentation/providers/notifications-provider';
const MakeMyProfilePage = () => {
    const { notifications } = useNotifications();
    return (React.createElement(ErrorBoundary, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsMyProfile, contentType: "form", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(React.Fragment, null,
                React.createElement(MyProfileHandlersProvider, null,
                    React.createElement(MyProfile, null))) })));
};
export default MakeMyProfilePage;
