var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HandleHttpResponse } from '@/data/protocols/http';
export class RemoteNotificationsDestinations {
    constructor(url, httpClient) {
        this.isForeignKeyViolationError = (errorMsg) => {
            return errorMsg.includes('Foreign key violation. update or delete on table');
        };
        this.url = url;
        this.httpClient = httpClient;
    }
    create(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.url,
                method: 'POST',
                body: params
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    delete(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: `${this.url}?id=${params.id}`,
                method: 'DELETE',
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.url,
                method: 'GET',
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    update(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: `${this.url}?id=${params.id}`,
                method: 'PUT',
                body: params
            });
            return HandleHttpResponse(httpResponse);
        });
    }
}
