import React from "react";
import NameAndDescriptionForm from "@/presentation/components/form/name-description-form";
import { useProfilesHandlers } from "@/presentation/providers";
const ProfilesDetails = (props) => {
    const { nameValidator, descriptionValidator } = useProfilesHandlers();
    const args = Object.assign({ title: "Profile Details", resourceText: "profile", nameValidator,
        descriptionValidator }, props);
    return React.createElement(NameAndDescriptionForm, Object.assign({}, args));
};
export default ProfilesDetails;
