import React from 'react';
import ProxiesList from '@/presentation/pages/proxies/proxies-list';
import { makeHierarchicalBreadcrumbsFromURLPath } from '@/presentation/components/commons/breadcrumbs';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { Flashbar } from '@cloudscape-design/components-themed/components';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import { useLocation } from "react-router-dom";
import { ProxiesHandlersProvider } from "@/presentation/providers";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
const MakeProxiesList = () => {
    const { notifications } = useNotifications();
    const location = useLocation();
    const breadCrumbs = makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, content: React.createElement(ProxiesHandlersProvider, null,
                React.createElement(ProxiesList, null)), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }) })));
};
export default MakeProxiesList;
