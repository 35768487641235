var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler, } from '@/presentation/handlers';
export class PoliciesHandler extends PagesBaseHandler {
    constructor(remotes) {
        super();
        this.validatePolicy = (policy) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const params = {
                policy: policy
            };
            return (_b = (_a = this.remotes) === null || _a === void 0 ? void 0 : _a.openPolicy) === null || _b === void 0 ? void 0 : _b.validate(params);
        });
        this.remotes = remotes;
    }
    listGlobalPolicyFunctions() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            return (_b = (_a = this.remotes) === null || _a === void 0 ? void 0 : _a.globPolicy) === null || _b === void 0 ? void 0 : _b.list();
        });
    }
    listOrganizationPolicyFunctions() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            return (_b = (_a = this.remotes) === null || _a === void 0 ? void 0 : _a.orgPolicy) === null || _b === void 0 ? void 0 : _b.list();
        });
    }
    formatPolicyFunctions(funcs) {
        let policy = '\r\n';
        funcs === null || funcs === void 0 ? void 0 : funcs.map((item) => {
            var _a;
            let str = (_a = item === null || item === void 0 ? void 0 : item.function) === null || _a === void 0 ? void 0 : _a.replace(/\\n/g, '\n');
            str = str.replace(/\\r/g, '\r');
            str = str.replace(/\\"/g, '"');
            policy += str + '\r\n\n';
        });
        return policy;
    }
    formatOrganizationPolicyFunction(funcs) {
        let orgPolicy = '\r\n';
        const data = funcs.OrganizationPolicyFunctions;
        if (data && data.length > 0) {
            data.forEach((item) => {
                let str = item.function.replace(/\\n/g, '\n');
                str = str.replace(/\\r/g, '\r');
                str = str.replace(/\\"/g, '"');
                orgPolicy += str + '\r\n\n';
            });
        }
        return orgPolicy;
    }
}
