import React from 'react';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { breadcrumbsProxiesDeploymentsCreate } from "@/presentation/components/commons/breadcrumbs";
import CreateDeployment from "@/presentation/pages/proxies/proxies-deployments/create-deployments";
import { ProxiesHandlersProvider } from "@/presentation/providers";
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { useParams } from "react-router-dom";
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { useNotifications } from "@/presentation/providers/notifications-provider";
const MakeProxiesDeployments = (props) => {
    const params = useParams();
    const { notifications } = useNotifications();
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsProxiesDeploymentsCreate(params === null || params === void 0 ? void 0 : params.proxyID), activeHref: 'proxies', contentType: "form", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(React.Fragment, null,
                React.createElement(ProxiesHandlersProvider, null,
                    React.createElement(CreateDeployment, Object.assign({}, props)))) })));
};
export default MakeProxiesDeployments;
