import React, { useEffect, useRef, useState } from "react";
import BackDrop from "./backdrop";
import { OVERLAY_DEFAULT_TIMEOUT, STATES } from "@/presentation/components/overlay/common";
const Overlay = (props) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState(STATES.OPEN);
    const [prevState, setPrevState] = useState(props.isOpen);
    const [initiator, setInitiator] = useState(null);
    const trackerId = useRef(0);
    const tracker = useRef({});
    const [overlayId, setOverlayId] = useState(`overlay-${trackerId}`);
    const mounted = useRef(false);
    // expand from props and set default values
    const { animate = true, top = 0, contentClass = "", clickDismiss = true, escapeDismiss = true, focusOutline = false, showCloseIcon = true, } = props.configs;
    useEffect(() => {
        trackerId.current = trackerId.current + 1;
    });
    useEffect(() => {
        if (props.isOpen === prevState) {
            return null;
        }
        if (props.isOpen) {
            updateDom(true);
            setOverlayState(!animate ? STATES.OPEN : STATES.OPENING);
            setPrevState(props.isOpen);
            setInitiator(document.activeElement);
        }
        else {
            setOverlayState(!animate ? STATES.CLOSED : STATES.CLOSING);
            setPrevState(props.isOpen);
        }
    }, [props.isOpen]);
    useEffect(() => {
        updateOverlayTracker();
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        }
        else {
            // do componentDidUpdate logic
            if (!animate) {
                if (overlayState === STATES.OPEN) {
                    shiftFocusToOverlay();
                }
                else {
                    shiftFocusToEle(initiator);
                }
                return;
            }
            if (overlayState === STATES.OPENING) {
                shiftFocusToOverlay();
                setTimeout(() => {
                    setOverlayState(STATES.OPEN);
                }, OVERLAY_DEFAULT_TIMEOUT);
            }
            else if (overlayState === STATES.CLOSING) {
                shiftFocusToEle(initiator);
                setTimeout(() => {
                    setOverlayState(STATES.CLOSED);
                }, OVERLAY_DEFAULT_TIMEOUT);
            }
        }
    });
    /**
     * Updates classlist of body with scroll-lock class depending on flag val
     * if true, adds classlist
     * else removes the class
     * @param {Boolean} flag
     */
    const updateDom = (flag) => {
        const body = document.getElementsByTagName("body")[0];
        const scrollClass = "scroll-lock";
        if (flag) {
            if (!body.classList.contains(scrollClass)) {
                body.classList.add(scrollClass);
            }
        }
        else {
            // check if tracker has any other overlay open
            if (!Object.values(tracker.current).filter(Boolean).length) {
                body.classList.remove(scrollClass);
            }
        }
    };
    const updateOverlayTracker = () => {
        tracker.current[overlayId] = props.isOpen;
    };
    /**
     * Focuses the element passed as param
     * @param {Element} el
     */
    const updateFocus = (el) => el && el.focus();
    /**
     * Shifts focus to content div inside backdrop
     */
    const shiftFocusToOverlay = () => {
        const node = overlayRef.current;
        updateFocus(node);
    };
    /**
     * shifts focus to the initiating element i.e last active element
     * before overlay opened.
     * And remove the scroll-lock class from body classList
     * @param {JSX.Element} initiator
     */
    const shiftFocusToEle = (initiator) => {
        updateFocus(initiator);
        updateDom(false);
    };
    /**
     * Handles escape key press
     * if the overlay is open, then triggers close function
     * @param {KeyboardEvent} event
     */
    const keyPress = (event) => {
        const code = (event === null || event === void 0 ? void 0 : event.keyCode) ? event.keyCode : event.which;
        // if the escape key is pressed
        if (code === 27) {
            const { isOpen, closeOverlay } = props;
            if (isOpen) {
                closeOverlay();
            }
            event.stopPropagation();
        }
    };
    const className = [
        'overlay-wrapper',
        overlayState === STATES.HIDDEN ? "overlay-hidden" : "",
        overlayState === STATES.OPEN ? "overlay-open" : "",
        overlayState === STATES.OPENING ? "overlay-opening" : "",
        overlayState === STATES.CLOSING ? "overlay-closing" : "",
        overlayState === STATES.CLOSED ? "overlay-closed" : "",
    ]
        .filter(Boolean)
        .join(" ");
    const attrs = {
        className,
        onKeyPress: escapeDismiss ? (e) => keyPress(e) : undefined,
        onKeyDown: escapeDismiss ? (e) => keyPress(e) : undefined,
        "aria-hidden": !props.isOpen,
    };
    const contentAttrs = {
        className: [
            "overlay-content",
            focusOutline ? "with-outline" : "",
            contentClass,
        ]
            .filter(Boolean)
            .join(" "),
        tabIndex: 0,
        role: "dialog",
    };
    const handleCloseOverlay = (e) => {
        e.preventDefault();
        props.closeOverlay();
    };
    return (React.createElement("div", Object.assign({}, attrs, { style: {
            top: top
        } }),
        React.createElement(BackDrop, { overlayState: overlayState, clickDismiss: clickDismiss, closeOverlay: props.closeOverlay },
            React.createElement("div", Object.assign({ ref: overlayRef }, contentAttrs), props.children))));
};
export default Overlay;
