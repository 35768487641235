import React, { useState } from 'react';
import { SETTINGS_TABS } from '@/presentation/pages/settings/settings-types';
import { Button, Container, Form, FormField, Input, Select, SpaceBetween } from '@cloudscape-design/components-themed/components';
import { makeI18nStrings } from "@/presentation/components/I18nStrings";
import { PATHS } from "@/presentation/common-utils/constants";
import { useNavigate } from "react-router-dom";
import { getFieldOnChange } from "@/presentation/components";
import { makeDomainACLsActionSelectionOptions, makeNotification, PagesRequestErrorHandler } from '@/presentation/pages';
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useIsMounted } from '@/presentation/hooks';
import ACLEditor from '@/presentation/components/attribute-editor/acl-editor';
import Skeleton from 'react-loading-skeleton';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { useAuth0 } from '@/lib/auth0';
import { DomainActionMappingsErrorCode } from '@/presentation/pages/settings/domain-acls/settings-domain-acls-config';
import { makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
const SettingsDomainACLForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const navigate = useNavigate();
    const i18nStrings = makeI18nStrings(props, "Domain ACL");
    const { rawToken } = useAuth0();
    const orgID = getOrgIDFromToken(rawToken);
    const navigateUrl = `${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.DOMAIN_ACLS}`;
    const { domainACL, domainACLNameValidator, domainActionMappingsValidator } = useSettingsHandlers();
    const domainACLHandler = domainACL;
    const [defaultActionError, setDefaultActionError] = useState(false);
    const [attributeError, setAttributeError] = React.useState(false);
    const [recheckAttributeErrors, setRecheckAttributeErrors] = React.useState(false);
    const [name, setName] = useState((_b = (_a = props.domainACLInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.name);
    const [nameError, setNameError] = useState(false);
    const [selectedDefaultAction, setSelectedDefaultAction] = useState((_d = (_c = props.domainACLInfo) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.default_action);
    const defaultActionOnChange = getFieldOnChange("select", "defaultAction", props.onChange);
    const nameOnChange = getFieldOnChange("text", "name", props.onChange);
    const domainsMappingsOnChange = getFieldOnChange("as-is", "domains", props.onChange);
    const aclsActionSelectionOptions = makeDomainACLsActionSelectionOptions();
    const [loading, setLoading] = useState(false);
    const { pushNotifications, dismissNotification } = useNotifications();
    const isMounted = useIsMounted();
    const validatedOnDefaultActionBlur = (val) => {
        const defaultActionErrorStatus = (val === null || val === void 0 ? void 0 : val.constructor) === Object && Object.keys(val).length > 0;
        if (!defaultActionErrorStatus) {
            setDefaultActionError(true);
        }
    };
    const handleDefaultActionChangeCallback = ({ detail }) => {
        var _a;
        if (((_a = detail.selectedOption) === null || _a === void 0 ? void 0 : _a.label) !== (selectedDefaultAction === null || selectedDefaultAction === void 0 ? void 0 : selectedDefaultAction.label)) {
            defaultActionOnChange(detail);
            setSelectedDefaultAction(detail.selectedOption);
            setDefaultActionError(false);
        }
    };
    const handleNameChangeCallback = ({ detail }) => {
        setName(detail === null || detail === void 0 ? void 0 : detail.value);
        validatedOnNameBlur(detail === null || detail === void 0 ? void 0 : detail.value);
        nameOnChange(detail);
    };
    const validatedOnNameBlur = (val) => {
        const nValid = domainACLNameValidator === null || domainACLNameValidator === void 0 ? void 0 : domainACLNameValidator.validate(val);
        if (nValid === null || nValid === void 0 ? void 0 : nValid.valid) {
            setNameError(false);
        }
        else {
            setNameError(true);
        }
    };
    const handleDomainMappingsOnChange = (mappings) => {
        domainsMappingsOnChange({ mappings: mappings || [] });
    };
    const makeSuccessNotification = (message) => {
        const msg = 'Domain ACL ' + ((props.isCreate) ? 'Created' : 'Updated') + ' Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to ' + ((props.isCreate) ? 'create' : 'update') + '. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const handleCreate = () => {
        var _a, _b, _c, _d, _e;
        if (isMounted.current) {
            setLoading(true);
        }
        const domainMappings = [];
        (_d = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.domainACLInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.domains) === null || _c === void 0 ? void 0 : _c.mappings) === null || _d === void 0 ? void 0 : _d.map(attribute => {
            var _a;
            if ((attribute === null || attribute === void 0 ? void 0 : attribute.key) && (attribute === null || attribute === void 0 ? void 0 : attribute.value)) {
                domainMappings.push({
                    domain: attribute === null || attribute === void 0 ? void 0 : attribute.key,
                    action: (_a = attribute === null || attribute === void 0 ? void 0 : attribute.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()
                });
            }
        });
        domainACLHandler === null || domainACLHandler === void 0 ? void 0 : domainACLHandler.create({
            name: name,
            default_action: (_e = selectedDefaultAction.value) === null || _e === void 0 ? void 0 : _e.toUpperCase(),
            organization_id: orgID,
            objects: domainMappings
        }).then(result => {
            var _a;
            if (!((_a = result === null || result === void 0 ? void 0 : result.insert_DomainAcls_one) === null || _a === void 0 ? void 0 : _a.id)) {
                pushNotifications(makeErrorNotification());
            }
            else {
                pushNotifications(makeSuccessNotification());
                navigate(navigateUrl);
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const handleUpdate = () => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (isMounted.current) {
            setLoading(true);
        }
        const domainMappings = [];
        (_d = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.domainACLInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.domains) === null || _c === void 0 ? void 0 : _c.mappings) === null || _d === void 0 ? void 0 : _d.map(attribute => {
            var _a, _b, _c;
            if ((attribute === null || attribute === void 0 ? void 0 : attribute.key) && (attribute === null || attribute === void 0 ? void 0 : attribute.value)) {
                domainMappings.push({
                    domain_acl_id: (_b = (_a = props.domainACLInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.id,
                    domain: attribute === null || attribute === void 0 ? void 0 : attribute.key,
                    action: (_c = attribute === null || attribute === void 0 ? void 0 : attribute.value) === null || _c === void 0 ? void 0 : _c.toUpperCase()
                });
            }
        });
        domainACLHandler === null || domainACLHandler === void 0 ? void 0 : domainACLHandler.update({
            name: name,
            id: (_f = (_e = props.domainACLInfo) === null || _e === void 0 ? void 0 : _e.details) === null || _f === void 0 ? void 0 : _f.id,
            default_action: (_g = selectedDefaultAction === null || selectedDefaultAction === void 0 ? void 0 : selectedDefaultAction.value) === null || _g === void 0 ? void 0 : _g.toUpperCase(),
            insert_objects: domainMappings
        }).then(result => {
            if (!(result === null || result === void 0 ? void 0 : result.update_DomainAcls_by_pk)) {
                pushNotifications(makeErrorNotification());
            }
            else {
                pushNotifications(makeSuccessNotification());
                navigate(navigateUrl);
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const isFormValid = () => {
        var _a, _b, _c;
        const nameValid = domainACLNameValidator === null || domainACLNameValidator === void 0 ? void 0 : domainACLNameValidator.validate(name);
        setNameError(!(nameValid === null || nameValid === void 0 ? void 0 : nameValid.valid));
        const defaultActionValid = (selectedDefaultAction === null || selectedDefaultAction === void 0 ? void 0 : selectedDefaultAction.constructor) === Object && Object.keys(selectedDefaultAction).length > 0;
        setDefaultActionError(!defaultActionValid);
        const validateDomainMappings = ((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.domainACLInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.domains) === null || _c === void 0 ? void 0 : _c.mappings) || [];
        let isValidDomainMapping = true;
        if (validateDomainMappings.length > 0) {
            const isValidDefaultActionMapping = !validateDomainMappings.some(obj => (obj === null || obj === void 0 ? void 0 : obj.value) === (selectedDefaultAction === null || selectedDefaultAction === void 0 ? void 0 : selectedDefaultAction.value));
            const validationResult = domainActionMappingsValidator.validate(validateDomainMappings, defaultActionValid);
            isValidDomainMapping = validationResult.valid && isValidDefaultActionMapping;
        }
        return (nameValid === null || nameValid === void 0 ? void 0 : nameValid.valid) && defaultActionValid && !attributeError && isValidDomainMapping;
    };
    const onSubmit = () => {
        if (isFormValid()) {
            if (props.isCreate) {
                handleCreate();
            }
            else {
                handleUpdate();
            }
        }
        else {
            setRecheckAttributeErrors(!recheckAttributeErrors);
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('Domain ACL', props.isCreate ? 'create' : 'update')));
        }
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 12 });
    }
    return React.createElement("div", { className: 'form-container' },
        React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", "data-id": "button-handle", onClick: () => navigate(navigateUrl) }, i18nStrings.cancelButton),
                React.createElement(Button, { variant: "primary", onClick: onSubmit, "data-id": "button-handle" }, i18nStrings.submitButton)) },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Container, null,
                    React.createElement(SpaceBetween, { size: "l" },
                        React.createElement(FormField, { label: "Name", errorText: nameError ? "Please enter a name for this domain acl" : "" },
                            React.createElement(Input, { placeholder: "Example Name", value: name, onChange: handleNameChangeCallback, onBlur: () => {
                                    validatedOnNameBlur(name);
                                } })),
                        React.createElement(FormField, { label: "Default Action", description: "Default action for domains that do not match", errorText: defaultActionError ? 'Please select a default action' : '' },
                            React.createElement(Select, { selectedOption: selectedDefaultAction, onChange: handleDefaultActionChangeCallback, options: aclsActionSelectionOptions, filteringType: "auto", selectedAriaLabel: "Selected", placeholder: "Choose Default Action", onBlur: () => {
                                    validatedOnDefaultActionBlur(selectedDefaultAction);
                                } })))),
                React.createElement(ACLEditor, { selectedDefaultAction: selectedDefaultAction, items: ((_g = (_f = (_e = props.domainACLInfo) === null || _e === void 0 ? void 0 : _e.details) === null || _f === void 0 ? void 0 : _f.domains) === null || _g === void 0 ? void 0 : _g.mappings) || [], onChange: handleDomainMappingsOnChange, onError: (err) => setAttributeError(err), scrollIntoViewHash: "domain-action-mappings", addButtonText: "Add Domain", removeButtonText: "Remove", itemLimit: 50, headerLabel: "Domains", headerDescription: "Evaluation is performed from most specific to least specific domain", attributeKeyValueValidator: domainActionMappingsValidator, attributeKeyLabel: "Domain", attributeValueLabel: "Action", attributeErrorCodeMappings: DomainActionMappingsErrorCode, recheckErrors: recheckAttributeErrors, valueSelectOptions: aclsActionSelectionOptions, attributeKeyPlaceholder: '', attributeValuePlaceholder: '' }))));
};
export default SettingsDomainACLForm;
