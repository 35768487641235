import React, { useEffect, useState } from 'react';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { useProxiesHandlers } from '@/presentation/providers';
import { Container, Form, Header, SpaceBetween, Button, FormField } from '@cloudscape-design/components-themed/components';
import { useNavigate, useParams } from 'react-router-dom';
import { CONFIG_VERSION, PATH_PROXIES } from '@/presentation/common-utils/constants';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import { getContainerHeight } from '@/presentation/common-utils/common-utils';
import { makeNotification } from '@/presentation/pages';
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from '@/presentation/providers/error-boundary-provider';
import ContentLayoutWrapper from '@/presentation/components/content-wrapper/content-layout-wrapper';
const CreateDeployment = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [configValue, setConfigValue] = useState('');
    const [errorText, setErrorText] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const { pushNotifications, dismissNotification } = useNotifications();
    const { proxies } = useProxiesHandlers();
    const proxiesHandler = proxies;
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    const isMounted = useIsMounted();
    const { setErrorStatus } = useErrorBoundaryContext();
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('deploymentEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    useEffect(() => {
        const parsedProxyID = parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10);
        if ((params === null || params === void 0 ? void 0 : params.proxyID) && parsedProxyID !== 0) {
            const vars = {
                proxy_id: parsedProxyID
            };
            proxiesHandler
                .getProxyDeploymentConfig(vars)
                .then((proxy) => {
                var _a, _b;
                if (((_b = (_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) === parsedProxyID && isMounted.current) {
                    setConfigValue(JSON.stringify(proxy, null, 2));
                }
                else {
                    setErrorStatus(true);
                }
            })
                .catch(console.error)
                .finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        }
        else {
            setErrorStatus(true);
        }
    }, []);
    const onNavigate = () => {
        navigate(`${PATH_PROXIES.VIEW}/${params === null || params === void 0 ? void 0 : params.proxyID}`);
    };
    const makeSuccessNotification = (message) => {
        const msg = 'Proxy Deployment created successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to proceed with this action. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const handleOnSubmit = () => {
        if (isValid) {
            const deploymentParams = {
                config: JSON.parse(configValue),
                status: 'PENDING',
                config_version: CONFIG_VERSION,
                proxy_id: Number(params === null || params === void 0 ? void 0 : params.proxyID)
            };
            proxiesHandler
                .createProxyDeployment(deploymentParams)
                .then((result) => {
                var _a;
                if (((_a = result === null || result === void 0 ? void 0 : result.insert_ProxyDeployments) === null || _a === void 0 ? void 0 : _a.affected_rows) === 1) {
                    pushNotifications(makeSuccessNotification());
                    onNavigate();
                }
                else {
                    pushNotifications(makeErrorNotification('Error in creating a proxy deployment'));
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
            });
        }
        else {
            pushNotifications(makeErrorNotification('Not a valid config'));
        }
    };
    const handleValidation = (json) => {
        try {
            // if we are able to successfully parse it, it is valid
            // we need to do better checking in backend when this becomes enabled
            JSON.parse(json);
            setIsValid(true);
            setErrorText(null);
        }
        catch (e) {
            setIsValid(false);
            setErrorText('Not a Valid Config');
        }
    };
    const handleCodeChange = (json) => {
        setConfigValue(json);
        handleValidation(json);
    };
    return (React.createElement(ContentWrapper, { testid: "proxy-create-deployment-page" },
        React.createElement(ContentLayoutWrapper, { header: React.createElement(Header, { variant: "h1" }, "Create Deployment") },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                        React.createElement(Button, { formAction: "none", variant: "link", onClick: onNavigate }, "Cancel"),
                        React.createElement(Button, { variant: "primary", onClick: () => handleOnSubmit() }, "Create")) },
                    React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Config") },
                        React.createElement(SpaceBetween, { direction: "vertical", size: "l" },
                            React.createElement("div", { ref: ref, id: "deploymentEditor" },
                                React.createElement(FormField, { errorText: errorText },
                                    React.createElement(CodeEditor, { code: configValue, height: editorHeight, heightType: 'px', readOnly: false, language: 'json', onChange: handleCodeChange, loading: loading }))))))))));
};
export default CreateDeployment;
