import { Box, Button, FormField, Input, Modal, SpaceBetween, } from "@cloudscape-design/components-themed/components";
import React, { useEffect, useState } from "react";
const TokensCreateUpdateModal = (props) => {
    const [description, setDescription] = useState(props.initialDescription);
    const [descriptionError, setDescriptionError] = useState(false);
    useEffect(() => {
        setDescription(props.initialDescription);
        setDescriptionError(false);
    }, [props.visible]);
    const validateDesc = (desc) => {
        const isValid = !!desc;
        setDescriptionError(!isValid);
        return isValid;
    };
    const handleTextChange = (event) => {
        setDescription(event.detail.value);
        validateDesc(event.detail.value);
    };
    const handleTokenSubmit = (event) => {
        event.preventDefault();
        if (validateDesc(description)) {
            props.onCreateOrUpdate(description);
        }
    };
    return (React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: props.headerText, closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
            React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                React.createElement(Button, { variant: "primary", disabled: descriptionError, onClick: handleTokenSubmit }, props.createUpdateButtonText))) },
        React.createElement(SpaceBetween, { size: "m" },
            React.createElement("form", { onSubmit: handleTokenSubmit },
                React.createElement(FormField, { label: "Description", description: "Description of this API key indicating its purpose or scope", errorText: descriptionError ? "Please enter an API key description" : "" },
                    React.createElement(Input, { placeholder: 'Example API key description', onChange: handleTextChange, onBlur: () => validateDesc(description), value: description, ariaRequired: true, ariaLabelledby: "description-label-" + props.operationName }))),
            React.createElement(Box, { variant: "awsui-value-large" }))));
};
export default TokensCreateUpdateModal;
