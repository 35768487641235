import React, { useContext } from "react";
import { createBrowserHistory } from "history";
const defaultHistoryContext = {
    history: createBrowserHistory()
};
export const HistoryContext = React.createContext(defaultHistoryContext);
export const useHistory = () => useContext(HistoryContext);
export const HistoryProvider = ({ children, history }) => {
    return React.createElement(HistoryContext.Provider, { value: {
            history: history
        } }, children);
};
