import { useIsMounted } from '@/presentation/hooks';
import React, { useState, useEffect } from 'react';
const TableContainer = (props) => {
    const [dimension, setDimension] = useState(window.innerWidth);
    const isMounted = useIsMounted();
    useEffect(() => {
        const handleWindowResize = () => {
            if (isMounted.current) {
                setDimension(window.innerWidth);
            }
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return React.createElement("div", { key: dimension }, props.children);
};
export default TableContainer;
