export var COLUMN_WIDTH_STORAGE_KEY;
(function (COLUMN_WIDTH_STORAGE_KEY) {
    COLUMN_WIDTH_STORAGE_KEY["IDENTITIES_LIST"] = "identities_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["IDENTITIES_VIEW_PROFILES_LIST"] = "identities_view_profiles_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["IDENTITIES_VIEW_IDENTITY_TYPE_CIDR_RANGES_LIST"] = "identities_view_identity_type_cidr_ranges_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["IDENTITIES_VIEW_IDENTITY_TYPE_CLOUD_TENANTS_LIST"] = "identities_view_identity_type_cloud_tenants_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["IDENTITIES_VIEW_IDENTITY_TYPE_USER_IDENTITY_ATTRIBUTES_LIST"] = "identities_view_identity_type_user_identity_attributes_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["PROFILES_LIST"] = "profiles_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["DEPLOYMENTS_LIST"] = "deployments_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["MONITORING_LIST"] = "monitoring_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["AUDIT_LOGS_LIST"] = "audit_logs_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["GLOBAL_SERVICES_LIST"] = "global_services_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["RULES_LIST"] = "rules_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["RULES_LIBRARY_LIST"] = "rules_library_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["RULES_SIMULATOR_LIST"] = "rules_simulator_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["NOTIFICATION_DESTINATIONS_LIST"] = "notification_destination_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["NOTIFICATIONS_MONITORS_LIST"] = "notifications_monitors_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["USER_ACCESS_LIST"] = "user_access_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["MYPROFILE_ORG_LIST"] = "my_profile_org_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["MYPROFILE_TOKENS_LIST"] = "my_profile_tokens_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["SETTINGS_CLOUD_TENANTS"] = "settings_cloud_tenants_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["SETTINGS_DOMAIN_ACLS"] = "settings_domain_acls_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["SETTINGS_DOMAIN_ACLS_LIST_VIEW"] = "settings_domain_acls_list_view_column_width";
    // Profiles Rule width
    COLUMN_WIDTH_STORAGE_KEY["PROFILES_VIEW_RULES_LIST"] = "profiles_view_rules_list_width";
    COLUMN_WIDTH_STORAGE_KEY["PROXIES_VIEW_IDENTITIES_LIST"] = "proxies_view_identities_list_width";
    COLUMN_WIDTH_STORAGE_KEY["MONITORING_LIST_SPLIT_PANEL_IDENTITY_LIST"] = "monitoring_list_split_panel_identity_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["MONITORING_LIST_SPLIT_PANEL_PROFILES_LIST"] = "monitoring_list_split_panel_profiles_list_column_width";
    COLUMN_WIDTH_STORAGE_KEY["MONITORING_LIST_SPLIT_PANEL_RULES_LIST"] = "monitoring_list_split_panel_rules_list_column_width";
})(COLUMN_WIDTH_STORAGE_KEY || (COLUMN_WIDTH_STORAGE_KEY = {}));
export var PREFERENCES_STORAGE_KEY;
(function (PREFERENCES_STORAGE_KEY) {
    PREFERENCES_STORAGE_KEY["DEPLOYMENTS_LIST"] = "deployments_list_preferences";
    PREFERENCES_STORAGE_KEY["MONITORING_LIST"] = "monitoring_list_preferences";
    PREFERENCES_STORAGE_KEY["AUDIT_LOGS_LIST"] = "audit_logs_preferences";
    PREFERENCES_STORAGE_KEY["GLOBAL_SERVICES_LIST"] = "global_services_preferences";
    PREFERENCES_STORAGE_KEY["RULES_LIST"] = "rules_list_preferences";
    PREFERENCES_STORAGE_KEY["RULES_LIBRARY_LIST"] = "rules_library_list_preferences";
    PREFERENCES_STORAGE_KEY["RULES_SIMULATOR_LIST"] = "rules_simulator_list_preferences";
    PREFERENCES_STORAGE_KEY["NOTIFICATION_DESTINATIONS_LIST"] = "notification_destination_list_preferences";
    PREFERENCES_STORAGE_KEY["NOTIFICATIONS_MONITORS_LIST"] = "notifications_monitors_list_preferences";
    PREFERENCES_STORAGE_KEY["USER_ACCESS_LIST"] = "user_access_list_preferences";
    PREFERENCES_STORAGE_KEY["MYPROFILE_ORG_LIST"] = "my_profile_org_preference";
    PREFERENCES_STORAGE_KEY["MYPROFILE_TOKENS_LIST"] = "my_profile_tokens_preference";
    PREFERENCES_STORAGE_KEY["SETTINGS_CLOUD_TENANTS"] = "settings_cloud_tenants_list_preference";
    PREFERENCES_STORAGE_KEY["SETTINGS_DOMAIN_ACLS"] = "settings_domain_acls_list_preference";
    PREFERENCES_STORAGE_KEY["SETTINGS_DOMAIN_ACLS_LIST_VIEW"] = "settings_domain_acls_list_view_preference";
    // Identities Preferences
    PREFERENCES_STORAGE_KEY["IDENTITIES_LIST"] = "identities_list_preferences";
    PREFERENCES_STORAGE_KEY["IDENTITIES_VIEW_PROFILES_LIST"] = "identities_view_profiles_list_preferences";
    PREFERENCES_STORAGE_KEY["IDENTITIES_CREATE_EDIT_PROFILES_LIST"] = "identities_create_edit_profiles_list_preferences";
    // Profiles Preferences
    PREFERENCES_STORAGE_KEY["PROFILES_LIST"] = "profiles_list_preferences";
    PREFERENCES_STORAGE_KEY["PROFILES_VIEW_RULES_LIST"] = "profiles_view_rules_list_preferences";
    // Proxies Preferences
    PREFERENCES_STORAGE_KEY["PROXIES_VIEW_IDENTITIES_LIST"] = "proxies_view_identities_list_preferences";
    PREFERENCES_STORAGE_KEY["PROXIES_CREATE_EDIT_IDENTITIES_LIST"] = "proxies_create_edit_identities_list_preferences";
})(PREFERENCES_STORAGE_KEY || (PREFERENCES_STORAGE_KEY = {}));
