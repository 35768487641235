import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { idFilteringProperty, nameFilteringProperty, userDetailsCreatedColumnDefinition, userDetailsCreatedFilteringProperty, userDetailsUpdatedColumnDefinition, userDetailsUpdatedFilteringProperty, } from '@/presentation/pages/common-config';
import { AttributeErrorCode } from "@/presentation/pages";
import { Badge } from '@cloudscape-design/components-themed/components';
import React from 'react';
import { DomainACL } from '@/domain/models';
var ACLAction = DomainACL.ACLAction;
export const makeDomainACLBadge = (action) => {
    let actionColor = 'grey';
    if (action === ACLAction.ALLOW) {
        actionColor = 'green';
    }
    else if (action === ACLAction.BLOCK) {
        actionColor = 'red';
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Badge, { color: actionColor }, action));
};
export const DomainACLsListColumnDefinitions = addColumnSortLabels([
    {
        id: 'id',
        sortingField: 'id',
        header: 'ID',
        cell: item => {
            return item === null || item === void 0 ? void 0 : item.id;
        },
        width: 120
    },
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: (item) => {
            return item === null || item === void 0 ? void 0 : item.name;
        }
    },
    {
        id: 'blocked_domain_count',
        sortingField: 'blocked_domain_count',
        header: 'Blocked Domains',
        cell: (item) => {
            if (item === null || item === void 0 ? void 0 : item.blocked_domain_count) {
                const domainText = (item === null || item === void 0 ? void 0 : item.blocked_domain_count) === 1 ? 'Domain' : "Domains";
                return `${item === null || item === void 0 ? void 0 : item.blocked_domain_count} ${domainText}`;
            }
            return (item === null || item === void 0 ? void 0 : item.blocked_domain_count_text) || '-';
        }
    },
    {
        id: 'allowed_domain_count',
        sortingField: 'allowed_domain_count',
        header: 'Allowed Domains',
        cell: (item) => {
            if (item === null || item === void 0 ? void 0 : item.allowed_domain_count) {
                const domainText = (item === null || item === void 0 ? void 0 : item.allowed_domain_count) === 1 ? 'Domain' : "Domains";
                return `${item === null || item === void 0 ? void 0 : item.allowed_domain_count} ${domainText}`;
            }
            return '-';
        }
    },
    {
        id: 'default_action',
        sortingField: 'default_action',
        header: 'Default Action',
        cell: (item) => {
            if (item === null || item === void 0 ? void 0 : item.default_action) {
                return makeDomainACLBadge(item === null || item === void 0 ? void 0 : item.default_action);
            }
            return '-';
        }
    },
    ...userDetailsCreatedColumnDefinition,
    ...userDetailsUpdatedColumnDefinition,
]);
export const DomainACLsListFilteringProperties = [
    idFilteringProperty,
    nameFilteringProperty,
    {
        key: 'blocked_domain_count',
        operators: ['=', '!=', '<', '<=', '>', '>='],
        propertyLabel: 'Blocked Domains',
        groupValuesLabel: 'Blocked Domains Values'
    },
    {
        key: 'allowed_domain_count',
        operators: ['=', '!=', '<', '<=', '>', '>='],
        propertyLabel: 'Allowed Domains',
        groupValuesLabel: 'Allowed Domains Values'
    },
    {
        key: 'default_action',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Default Action',
        groupValuesLabel: 'Default Action Values'
    },
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty
];
export const DomainACLsListViewFilteringProperties = [
    {
        key: 'domain',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Domain',
        groupValuesLabel: 'Domain Values'
    },
    {
        key: 'action',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Action',
        groupValuesLabel: 'Action Values'
    },
];
export const DomainACLsListTablePreferences = {
    pageSize: 30,
    visibleContent: ['id', 'name', 'blocked_domain_count', 'allowed_domain_count', 'default_action'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const DomainACLsListViewTablePreferences = {
    pageSize: 30,
    visibleContent: ['domain', 'action'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const DomainACLsListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'name', label: 'Name', editable: true },
            { id: 'blocked_domain_count', label: 'Blocked Domains', editable: true },
            { id: 'allowed_domain_count', label: 'Allowed Domains', editable: true },
            { id: 'default_action', label: 'Default Action', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const DomainACLsListViewTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'domain', label: 'Domain', editable: false },
            { id: 'action', label: 'Action', editable: false },
        ]
    }
];
export const DomainACLsListTablePageSizeOptions = [
    { value: 10, label: '10 Domain ACLs' },
    { value: 30, label: '30 Domain ACLs' },
    { value: 50, label: '50 Domain ACLs' }
];
export const DomainACLsListViewTablePageSizeOptions = [
    { value: 10, label: '10 Domain Actions' },
    { value: 30, label: '30 Domain Actions' },
    { value: 50, label: '50 Domain Actions' }
];
export const DomainACLsDeleteStrings = {
    headerTextSingular: 'Delete Domain ACL',
    headerTextPlural: ' Delete Domain ACLs',
    modalTextSingular: 'Domain ACL',
    modalTextPlural: 'Domain ACLs',
    consentText: 'confirm'
};
export const DomainACLsDeleteShowProp = 'name';
export const DomainActionMappingsErrorCode = {
    [AttributeErrorCode.INVALID_KEY]: 'Please enter a valid domain or domain pattern e.g. example.com or .example.com',
    [AttributeErrorCode.INVALID_VALUE]: 'Please select an action',
    [AttributeErrorCode.INVALID_VALUE_ADDITIONAL]: 'Action should be different from default action',
    // Future proofing the error codes. We need this in the future
    [AttributeErrorCode.INVALID_KEY_AND_VALUE]: '',
    [AttributeErrorCode.DUPLICATE_KEY]: '',
    [AttributeErrorCode.DUPLICATE_VALUE]: '',
};
export const DomainACLListFilteringOptions = [
    {
        propertyKey: 'default_action',
        value: ACLAction.ALLOW
    },
    {
        propertyKey: 'default_action',
        value: ACLAction.BLOCK
    },
];
export const DomainACLSplitPanelListFilteringOptions = [
    {
        propertyKey: 'action',
        value: ACLAction.ALLOW
    },
    {
        propertyKey: 'action',
        value: ACLAction.BLOCK
    },
];
