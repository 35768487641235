import { PROXY_MODE_TYPE_LABEL, PROXY_MODE_TYPE_WITHOUT_SPACE } from "@/presentation/pages/proxies/proxies-types";
export class PagesBaseHandler {
    constructor() {
        this.toWildString = (options) => {
            var _a;
            const wild = (_a = options.map(option => option.label)) === null || _a === void 0 ? void 0 : _a[0];
            if (wild) {
                return wild;
            }
            return '';
        };
        this.toWildBoolean = (options) => {
            var _a;
            const wild = (_a = options.map(option => option.label)) === null || _a === void 0 ? void 0 : _a[0];
            if (wild === '*') {
                return wild;
            }
            if (wild) {
                return wild === 'true';
            }
            return false;
        };
        this.toWildNumber = (options) => {
            var _a;
            const wild = (_a = options.map(option => option.value)) === null || _a === void 0 ? void 0 : _a[0];
            if (wild === '*') {
                return wild;
            }
            if (wild) {
                return parseInt(wild, 10);
            }
            return 0;
        };
        this.sortSelectableOptionsBySelectedFirst = (options) => {
            options.sort((optionA, optionB) => {
                if (optionA.checked === 'on' && optionB.checked === 'on') {
                    return 0;
                }
                else if (optionA.checked === 'on' && optionB.checked !== 'on') {
                    return -1;
                }
                else {
                    return 1;
                }
            });
            return options;
        };
        this.getProxyModeWithSpace = (mode) => {
            switch (mode.toUpperCase()) {
                case PROXY_MODE_TYPE_WITHOUT_SPACE.DEFAULT_ALLOW_BLACKLIST:
                    return PROXY_MODE_TYPE_LABEL.DEFAULT_ALLOW_BLACKLIST;
                case PROXY_MODE_TYPE_WITHOUT_SPACE.DEFAULT_BLOCK_PREVENTATIVE:
                    return PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE;
                case PROXY_MODE_TYPE_WITHOUT_SPACE.LEARNING:
                    return PROXY_MODE_TYPE_LABEL.LEARNING;
                default:
                    return PROXY_MODE_TYPE_LABEL.DEFAULT_ALLOW_BLACKLIST;
            }
        };
    }
}
