import React, { useState } from "react";
import { Form, FormField, Input, SpaceBetween, Button, } from "@cloudscape-design/components-themed/components";
import { useMyProfileHandlers } from "@/presentation/providers";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { makeNotification } from "@/presentation/pages";
import Skeleton from "react-loading-skeleton";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import { useIsMounted } from '@/presentation/hooks';
import { makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
import { useAuth0 } from '@/lib/auth0';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
const MyProfileForm = ({ user }) => {
    var _a, _b;
    const { firstNameValidator, lastNameValidator, myProfile } = useMyProfileHandlers();
    const myProfileHandler = myProfile;
    const [firstName, setFirstName] = useState(user.given_name);
    const [lastName, setLastName] = useState(user.family_name);
    const [firstNameError, setFirstNameError] = useState(!(user === null || user === void 0 ? void 0 : user.given_name));
    const [lastNameError, setLastNameError] = useState(!user.family_name);
    const { pushNotifications, dismissNotification } = useNotifications();
    const [loading, setLoading] = useState(false);
    const isMounted = useIsMounted();
    const { rawToken, refreshToken } = useAuth0();
    const makeSuccessNotification = (message) => {
        const msg = "Profile Updated Successfully";
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to update profile. Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const updateUserDetails = () => {
        const fValid = firstNameValidator === null || firstNameValidator === void 0 ? void 0 : firstNameValidator.validate(firstName);
        const lValid = firstNameValidator === null || firstNameValidator === void 0 ? void 0 : firstNameValidator.validate(lastName);
        if (!(fValid === null || fValid === void 0 ? void 0 : fValid.valid) || !(lValid === null || lValid === void 0 ? void 0 : lValid.valid)) {
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('profile', 'update')));
        }
        else {
            const userObject = {
                id: user.id,
                given_name: firstName,
                family_name: lastName,
            };
            if (isMounted.current) {
                setLoading(true);
            }
            myProfileHandler
                .updateEntity(userObject)
                .then((result) => {
                if (result) {
                    pushNotifications(makeSuccessNotification());
                    const orgID = getOrgIDFromToken(rawToken);
                    refreshToken({ org_id: orgID }).then(() => {
                        if (isMounted.current) {
                            setLoading(false);
                        }
                    }).catch(() => {
                        pushNotifications(makeErrorNotification());
                    }).finally(() => {
                        if (isMounted.current) {
                            setLoading(false);
                        }
                    });
                }
                else {
                    pushNotifications(makeErrorNotification());
                    if (isMounted.current) {
                        setLoading(false);
                    }
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        }
    };
    const onFirstNameChange = (event) => {
        var _a;
        const currNameValue = ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.value) || "";
        const validationResult = firstNameValidator === null || firstNameValidator === void 0 ? void 0 : firstNameValidator.validate(currNameValue);
        setFirstNameError(!validationResult.valid);
        setFirstName(currNameValue);
    };
    const onLastNameChange = (event) => {
        var _a;
        const currLastNameValue = ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.value) || "";
        const validationResult = lastNameValidator === null || lastNameValidator === void 0 ? void 0 : lastNameValidator.validate(currLastNameValue);
        setLastNameError(!validationResult.valid);
        setLastName(currLastNameValue);
    };
    const onReset = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        myProfileHandler
            .resetUserPassword()
            .then((response) => {
            var _a;
            if (response === null || response === void 0 ? void 0 : response.error) {
                const customMsg = "Unable to update profile. Please try again later.";
                pushNotifications(makeErrorNotification((response === null || response === void 0 ? void 0 : response.msg) || customMsg));
            }
            else {
                const custMsg = "Password reset email sent to your mail Id";
                pushNotifications(makeSuccessNotification(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) || custMsg));
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        })
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(SpaceBetween, { size: "l" },
        React.createElement("div", { className: 'form-container' },
            React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "normal", onClick: onReset }, "Reset Password"),
                    React.createElement(Button, { variant: "primary", onClick: updateUserDetails }, "Update Profile")) },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(FormField, { label: "First Name", errorText: firstNameError ? "Please enter a valid first name" : "" },
                        React.createElement(Input, { "data-testid": "my-profile-form-first-name", placeholder: "First Name", disabled: false, value: firstName, onChange: (event) => {
                                onFirstNameChange(event);
                            } })),
                    React.createElement(FormField, { label: "Last Name", errorText: lastNameError ? "Please enter a valid last name" : "" },
                        React.createElement(Input, { "data-testid": "my-profile-form-last-name", placeholder: "Last Name", disabled: false, value: lastName, onChange: (event) => {
                                onLastNameChange(event);
                            } })),
                    React.createElement(FormField, { label: "Email Address", description: "" },
                        React.createElement(Input, { "data-testid": "my-profile-form-email", placeholder: "example-profile-email", disabled: true, value: (_b = (_a = user === null || user === void 0 ? void 0 : user.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '' })))))));
};
export default MyProfileForm;
