var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getPathsOfTenantStructure } from "@/presentation/pages/identities/constants";
import { paginateMultiselectOptions } from "@/presentation/pages";
import { uniqueAndSortedArrayOfObjectFilters } from "@/presentation/common-utils/common-utils";
export class CloudTenantsHandler {
    constructor(remote) {
        this.cloudTenantStructureOptionsPageCount = 1000;
        this.getFormattedCloudTenants = (listTenants) => {
            var _a, _b;
            const providerFilterList = [];
            const formattedData = ((_a = listTenants === null || listTenants === void 0 ? void 0 : listTenants.CloudTenants) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_b = listTenants === null || listTenants === void 0 ? void 0 : listTenants.CloudTenants) === null || _b === void 0 ? void 0 : _b.map(tenant => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
                providerFilterList.push((_b = (_a = tenant === null || tenant === void 0 ? void 0 : tenant.Provider) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toUpperCase());
                return {
                    id: (_c = tenant === null || tenant === void 0 ? void 0 : tenant.id) !== null && _c !== void 0 ? _c : 0,
                    name: (_d = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _d !== void 0 ? _d : '',
                    organizationID: (_e = tenant === null || tenant === void 0 ? void 0 : tenant.organization_id) !== null && _e !== void 0 ? _e : 0,
                    providerID: (_g = (_f = tenant === null || tenant === void 0 ? void 0 : tenant.Provider) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : 0,
                    providerName: (_j = (_h = tenant === null || tenant === void 0 ? void 0 : tenant.Provider) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : '',
                    provider: (_l = (_k = tenant === null || tenant === void 0 ? void 0 : tenant.Provider) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : '',
                    tenantStructure: {},
                    created_at: (_m = tenant === null || tenant === void 0 ? void 0 : tenant.created_at) !== null && _m !== void 0 ? _m : '',
                    created_by: (_p = (_o = tenant === null || tenant === void 0 ? void 0 : tenant.CreatedByUser) === null || _o === void 0 ? void 0 : _o.id) !== null && _p !== void 0 ? _p : '',
                    updated_at: (_q = tenant === null || tenant === void 0 ? void 0 : tenant.updated_at) !== null && _q !== void 0 ? _q : '',
                    updated_by: (_s = (_r = tenant === null || tenant === void 0 ? void 0 : tenant.UpdatedByUser) === null || _r === void 0 ? void 0 : _r.id) !== null && _s !== void 0 ? _s : '',
                    createdByFullName: (tenant === null || tenant === void 0 ? void 0 : tenant.CreatedByUser) ? `${((_t = tenant === null || tenant === void 0 ? void 0 : tenant.CreatedByUser) === null || _t === void 0 ? void 0 : _t.given_name) || ''} ${((_u = tenant === null || tenant === void 0 ? void 0 : tenant.CreatedByUser) === null || _u === void 0 ? void 0 : _u.family_name) || ''}` : '',
                    updatedByFullName: (tenant === null || tenant === void 0 ? void 0 : tenant.UpdatedByUser) ? `${((_v = tenant === null || tenant === void 0 ? void 0 : tenant.UpdatedByUser) === null || _v === void 0 ? void 0 : _v.given_name) || ''} ${((_w = tenant === null || tenant === void 0 ? void 0 : tenant.UpdatedByUser) === null || _w === void 0 ? void 0 : _w.family_name) || ''}` : '',
                };
            }) : [];
            const providerFilteredList = uniqueAndSortedArrayOfObjectFilters(providerFilterList, 'provider');
            const formattedFilteredList = [...providerFilteredList];
            return { cloudTenantsData: formattedData, formattedFilteredList };
        };
        this.getEmptyValues = (value) => {
            return {
                value,
                isTenant: false,
                path: '',
                labelSearchable: '',
                tenantName: '',
                label: '',
                isValid: false
            };
        };
        this.getCloudTenantOptions = (tenantStructure, selectedTenants) => {
            const structWithPaths = getPathsOfTenantStructure(tenantStructure);
            const options = selectedTenants === null || selectedTenants === void 0 ? void 0 : selectedTenants.map((item) => {
                var _a, _b, _c, _d, _e;
                if (tenantStructure[item]) {
                    const tenantStructureName = (_b = (_a = tenantStructure[item]) === null || _a === void 0 ? void 0 : _a.Name) !== null && _b !== void 0 ? _b : null;
                    const structPathsWithKey = String((_c = structWithPaths[item]) !== null && _c !== void 0 ? _c : '');
                    const lexicoPath = ((structPathsWithKey === null || structPathsWithKey === void 0 ? void 0 : structPathsWithKey.length) > 0 ? structPathsWithKey + ' > ' : '') + (tenantStructureName);
                    const option = Object.assign({ value: item, label: (tenantStructureName) + ' (' + item + ')', tenantName: tenantStructureName, labelSearchable: String((tenantStructureName) + ' (' + item + ')').toLowerCase(), isTenant: (_e = (_d = tenantStructure[item]) === null || _d === void 0 ? void 0 : _d.IsTenant) !== null && _e !== void 0 ? _e : false, path: structPathsWithKey, isValid: true, lexicoPath: lexicoPath }, (structWithPaths[item] ? { description: 'Path: ' + structWithPaths[item] } : null));
                    return option;
                }
                else {
                    const emptyOption = this.getEmptyValues(item);
                    return emptyOption;
                }
            });
            return options;
        };
        this.makeCloudTenantStructureSelectionOptions = (tenantStructure) => {
            const options = [];
            const structWithPaths = getPathsOfTenantStructure(tenantStructure);
            Object.keys(tenantStructure).forEach(key => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                const structPathsWithKey = String((_a = structWithPaths[key]) !== null && _a !== void 0 ? _a : '').toLowerCase();
                const lexicoPath = ((structPathsWithKey === null || structPathsWithKey === void 0 ? void 0 : structPathsWithKey.length) > 0 ? structPathsWithKey + ' > ' : '') + ((_c = (_b = tenantStructure[key]) === null || _b === void 0 ? void 0 : _b.Name) !== null && _c !== void 0 ? _c : '');
                const option = Object.assign({ value: key, label: ((_e = (_d = tenantStructure[key]) === null || _d === void 0 ? void 0 : _d.Name) !== null && _e !== void 0 ? _e : '') + ' (' + key + ')', tenantName: (_g = (_f = tenantStructure[key]) === null || _f === void 0 ? void 0 : _f.Name) !== null && _g !== void 0 ? _g : '', labelSearchable: String(((_j = (_h = tenantStructure[key]) === null || _h === void 0 ? void 0 : _h.Name) !== null && _j !== void 0 ? _j : '') + ' (' + key + ')').toLowerCase(), isTenant: (_l = (_k = tenantStructure[key]) === null || _k === void 0 ? void 0 : _k.IsTenant) !== null && _l !== void 0 ? _l : false, path: structPathsWithKey, isValid: true, lexicoPath: lexicoPath }, (structWithPaths[key] ? { description: 'Path: ' + structWithPaths[key] } : null));
                options.push(...[option]);
            });
            return options.sort((a, b) => a.lexicoPath.localeCompare(b.lexicoPath));
        };
        this.search = (options, filteringText) => {
            var _a;
            return (_a = options === null || options === void 0 ? void 0 : options.filter(item => { var _a, _b; return ((_a = item === null || item === void 0 ? void 0 : item.labelSearchable) === null || _a === void 0 ? void 0 : _a.includes(filteringText)) || ((_b = item === null || item === void 0 ? void 0 : item.path) === null || _b === void 0 ? void 0 : _b.includes(filteringText)); })) !== null && _a !== void 0 ? _a : [];
        };
        this.remote = remote;
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.list();
        });
    }
    create(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.create(params);
        });
    }
    get(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.get(params);
        });
    }
    update(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.update(params);
        });
    }
    delete(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.delete(params);
        });
    }
    // the pageNumber is left in the argument for future modifications
    fetchMultiSelectData(options, filteringText, pageNumber) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (filteringText) {
                const searchResults = this.search(options, String(filteringText).toLowerCase());
                return {
                    hasNext: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.length) > this.cloudTenantStructureOptionsPageCount,
                    items: (_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.slice(0, this.cloudTenantStructureOptionsPageCount)) !== null && _a !== void 0 ? _a : []
                };
            }
            else {
                // ignore the page number and send back only the limit
                const paginatedResult = paginateMultiselectOptions(options, 1, this.cloudTenantStructureOptionsPageCount);
                return {
                    hasNext: (paginatedResult === null || paginatedResult === void 0 ? void 0 : paginatedResult.nextPage) !== null,
                    items: (_b = paginatedResult === null || paginatedResult === void 0 ? void 0 : paginatedResult.items) !== null && _b !== void 0 ? _b : []
                };
            }
        });
    }
}
