import { useEffect, useState } from "react";
export const useSplitPanel = (selectedItems, splitOpen = false, onToggle) => {
    const [splitPanelSize, setSplitPanelSize] = useState(800);
    const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);
    const onSplitPanelResize = ({ detail: { size } }) => {
        setSplitPanelSize(size);
    };
    const onSplitPanelToggle = ({ detail: { open } }) => {
        onToggle(open);
        if (!open) {
            setHasManuallyClosedOnce(true);
        }
    };
    useEffect(() => {
        if (selectedItems.length && !hasManuallyClosedOnce) {
            onToggle(true);
        }
    }, [selectedItems.length, hasManuallyClosedOnce]);
    return {
        onSplitPanelToggle,
        splitPanelSize,
        onSplitPanelResize,
    };
};
