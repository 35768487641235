import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useRulesHandlers } from "@/presentation/providers";
import { useParams } from "react-router-dom";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { Header } from "@cloudscape-design/components-themed/components";
import { HeaderStrings } from "@/presentation/pages/rules/rules-types";
import RulesForm from "@/presentation/pages/rules/rules-form";
import RulesView from "@/presentation/pages/rules/rules-view";
import { isJSON } from '@/lib/misc/utils';
import { makeI18nStrings } from "@/presentation/components/I18nStrings";
import moment from 'moment';
import { EmptyTag, formatExistingComplianceMappings, formatExistingTags, getEmptyAttribute } from "@/presentation/pages";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import { configData, getFormattedActions, getFormattedOptions } from "./rules-list-config";
import ContentFormLayoutWrapper from "@/presentation/components/content-wrapper/content-form-layout-wrapper";
const RulesFormWrapper = (props) => {
    const { setErrorStatus } = useErrorBoundaryContext();
    const params = useParams();
    const [servicesList, setListServices] = useState();
    const [ruleDetails, setRuleDetails] = useState();
    const [loading, setLoading] = useState(true);
    const isCreate = !props.isEdit && !props.isClone;
    const [rulesInfo, setRulesInfo] = useState(undefined);
    const [isRuleDependencyService, setIsRuleDependencyService] = useState(false);
    const [globalPolicyFunc, setGlobalPolicyFunc] = useState('');
    const [orgPolicyFunc, setOrgPolicyFunc] = useState('');
    const { policies, rules, services, ruleDependencies } = useRulesHandlers();
    const rulesHandler = rules;
    const policiesHandler = policies;
    const servicesHandler = services;
    const ruleDependenciesHandler = ruleDependencies;
    const setRuleDepsDetails = (serviceID, serviceName) => {
        setIsRuleDependencyService(ruleDependenciesHandler.isRuleDependentService(serviceName));
        const ruleID = params.id ? parseInt(params.id, 10) : 0;
        ruleDependenciesHandler.setRuleDependenciesDetails(ruleID, serviceID);
    };
    const isMounted = useIsMounted();
    const getRulesInfo = (rulesData) => {
        if (rulesData) {
            const formattedRule = rulesHandler === null || rulesHandler === void 0 ? void 0 : rulesHandler.getFormattedEntity(rulesData);
            const existingTags = formatExistingTags(formattedRule === null || formattedRule === void 0 ? void 0 : formattedRule.tags, props.isView);
            const existingComplianceMappings = formatExistingComplianceMappings(formattedRule === null || formattedRule === void 0 ? void 0 : formattedRule.complianceMappings, props.isView);
            const { initialConfig, description } = formattedRule;
            let configDataObject = configData;
            if (initialConfig && isJSON(initialConfig)) {
                const { actions, endpoints, methods, paths } = JSON.parse(initialConfig);
                if (actions) {
                    configDataObject.action = getFormattedActions("action", actions);
                }
                if (endpoints) {
                    configDataObject.endpoint = getFormattedOptions("endpoint", endpoints);
                }
                if (methods) {
                    configDataObject.method = getFormattedOptions("method", methods);
                }
                if (paths) {
                    configDataObject.path = getFormattedOptions("path", paths);
                }
            }
            const currentTimestamp = moment().format('YYYYMMDDHHmmss');
            const modifiedDescription = props.isClone ? `${description}-${currentTimestamp}` : description;
            const madeRule = {
                details: Object.assign(Object.assign({}, formattedRule), { description: modifiedDescription, action: [...configDataObject.action], config: [...configDataObject.endpoint, ...configDataObject.path, ...configDataObject.method] }),
                policyConfig: {
                    policy: formattedRule.policy
                },
                tags: {
                    editorTags: existingTags,
                },
                compliance: {
                    mappings: existingComplianceMappings,
                }
            };
            setRulesInfo(madeRule);
        }
        else {
            setRulesInfo({
                details: {
                    provider: {},
                    service: {},
                    description: '',
                    logBody: true,
                    enforceRule: true,
                    cfnScan: false,
                    action: [...configData.action],
                    config: [...configData.endpoint, ...configData.path, ...configData.method],
                    riskRating: null,
                },
                policyConfig: {
                    policy: null
                },
                tags: {
                    editorTags: [EmptyTag],
                },
                compliance: {
                    mappings: [getEmptyAttribute()],
                }
            });
        }
    };
    const getPolicyFunctions = () => {
        policiesHandler.listGlobalPolicyFunctions().then((globFuncs) => {
            const formattedGlobFuncs = policiesHandler.formatPolicyFunctions(globFuncs === null || globFuncs === void 0 ? void 0 : globFuncs.GlobalPolicyFunctions);
            if (isMounted.current) {
                setGlobalPolicyFunc(formattedGlobFuncs);
            }
        })
            .catch(console.error)
            .finally(() => {
            policiesHandler.listOrganizationPolicyFunctions().then((orgFuncs) => {
                const formattedOrgFuncs = policiesHandler.formatPolicyFunctions(orgFuncs === null || orgFuncs === void 0 ? void 0 : orgFuncs.OrganizationPolicyFunctions);
                if (isMounted.current) {
                    setOrgPolicyFunc(formattedOrgFuncs);
                }
            }).catch(console.error)
                .finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        });
    };
    const getServicesList = (serviceId) => {
        servicesHandler
            .listEntity()
            .then((listServices) => {
            listServices = servicesHandler.sortServicesByName(listServices);
            if (isMounted.current) {
                if (listServices === null || listServices === void 0 ? void 0 : listServices.Services) {
                    // rulesAndServices = listServices;
                    setListServices(listServices);
                    const service = listServices.Services.filter((service) => service.id === serviceId);
                    if (service[0]) {
                        setRuleDepsDetails(serviceId, service[0].GlobalService.name);
                    }
                }
                else {
                    setLoading(false);
                    setErrorStatus(true);
                }
            }
        })
            .catch(console.error)
            .finally(() => {
            getPolicyFunctions();
        });
    };
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.ruleID) && parseInt(params === null || params === void 0 ? void 0 : params.ruleID, 10) !== 0) {
            const ruleID = parseInt(params === null || params === void 0 ? void 0 : params.ruleID, 10);
            let rulesAndPolicies;
            rulesHandler
                .getEntity(ruleID)
                .then((data) => {
                var _a, _b, _c, _d;
                if (isMounted.current) {
                    if (data && ((_a = data === null || data === void 0 ? void 0 : data.Rules) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        rulesHandler.setRulesTagsAndPolicies(data);
                        rulesAndPolicies = data;
                        setRuleDetails(data);
                        const serviceID = (_d = (_c = (_b = rulesAndPolicies === null || rulesAndPolicies === void 0 ? void 0 : rulesAndPolicies.Rules) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.service_id) !== null && _d !== void 0 ? _d : 0;
                        getRulesInfo(rulesAndPolicies);
                        getServicesList(serviceID);
                    }
                    else {
                        setErrorStatus(true);
                    }
                }
            })
                .catch((e) => {
                console.error("unable to list entity got error:", e);
            });
        }
        else {
            if (isMounted.current) {
                getRulesInfo(undefined);
                getServicesList(undefined);
            }
        }
    }, [props.isView]);
    if (loading || (props.isEdit && !rulesInfo) || (isCreate && !servicesList)) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return props.isView ? (React.createElement(RulesView, { rulesInfo: rulesInfo, orgPolicyFunc: orgPolicyFunc, globalPolicyFunc: globalPolicyFunc })) : (React.createElement(ContentWrapper, { testid: "profile-create-edit-page" },
        React.createElement(ContentFormLayoutWrapper, { header: React.createElement(Header, { variant: "h1" }, props.isCreate || props.isClone ? HeaderStrings.create : HeaderStrings.edit) },
            React.createElement(RulesForm, { isCreate: props.isCreate, isEdit: props.isEdit, isClone: props.isClone, rulesInfo: rulesInfo, servicesList: servicesList, ruleDetails: ruleDetails, orgPolicyFunc: orgPolicyFunc, globalPolicyFunc: globalPolicyFunc, i18nStrings: makeI18nStrings(props, "Rule") }))));
};
export default RulesFormWrapper;
