import React from 'react';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { makeHierarchicalBreadcrumbsFromURLPath } from "@/presentation/components/commons/breadcrumbs";
import ProxiesView from "@/presentation/pages/proxies/proxies-view";
import { ProxiesHandlersProvider } from "@/presentation/providers";
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useLocation } from "react-router-dom";
const MakeProxiesView = () => {
    const location = useLocation();
    const breadCrumbs = makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    const { notifications } = useNotifications();
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, activeHref: 'proxies', notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(React.Fragment, null,
                React.createElement(ProxiesHandlersProvider, null,
                    React.createElement(ProxiesView, null))), contentType: "default" })));
};
export default MakeProxiesView;
