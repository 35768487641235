import React, { useContext } from "react";
import { RemoteProviders, RemoteRuleSimulator, RemoteServices, } from "@/data/usecases";
import { makeSimulatorAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { ProvidersHandler, RuleSimulatorHandler, ServicesHandler } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
const defaultContext = {
    ruleSimulator: new RuleSimulatorHandler(null),
    providers: new ProvidersHandler(null),
    services: new ServicesHandler(null)
};
export const RuleSimulatorHandlersProviderContext = React.createContext(defaultContext);
export const useRuleSimulatorHandlers = () => useContext(RuleSimulatorHandlersProviderContext);
export const RuleSimulatorHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const ruleSimulatorRemotes = {
        ruleSimulator: new RemoteRuleSimulator(makeSimulatorAPIUrl(), makeAuthorizeHttpClientDecorator())
    };
    const ruleSimulatorHandler = new RuleSimulatorHandler(ruleSimulatorRemotes);
    const providerRemotes = {
        providers: new RemoteProviders(getTokenSilently)
    };
    const providersHandler = new ProvidersHandler(providerRemotes);
    const servicesRemotes = {
        services: new RemoteServices(getTokenSilently)
    };
    const servicesHandler = new ServicesHandler(servicesRemotes);
    return React.createElement(RuleSimulatorHandlersProviderContext.Provider, { value: {
            ruleSimulator: ruleSimulatorHandler,
            providers: providersHandler,
            services: servicesHandler
        } }, children);
};
