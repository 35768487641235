import React, { useState } from 'react';
import { CollectionPreferences, Pagination, Checkbox, PropertyFilter, Table } from '@cloudscape-design/components-themed/components';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { TableNoMatchState, DetailsTableEmptyState } from '@/presentation/components/commons/common-components';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { makePropertyFilterI18nStrings } from '@/presentation/pages';
import TableContainer from './table-container';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const InmemoryDetailsTable = (props) => {
    var _a, _b;
    const [columnDefinitions] = useColumnWidths(props.columnWidthStorageKey, props.columnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(props.preferencesStorageKey, props.defaultPreferences);
    const getSessionPropertyFilter = GetPropertyFilters(props === null || props === void 0 ? void 0 : props.propertyFilterStorageName);
    const getSessionPropertySorting = GetTableSortingColumn(props === null || props === void 0 ? void 0 : props.propertySortingStorageName);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : (_a = props.columnDefinitions) === null || _a === void 0 ? void 0 : _a[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(props.items, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: props.propertyFilterFilteringProperties,
            empty: React.createElement(DetailsTableEmptyState, { text: props.emptyText }),
            noMatch: (React.createElement(TableNoMatchState, { resourceName: props.resourceName, message: `No ${(props === null || props === void 0 ? void 0 : props.resourceName) || 'records'} matching the specified filters are attached to this ${(props === null || props === void 0 ? void 0 : props.resourceTitle) || 'list'}` }))
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const isCheckboxChecked = ((_b = props === null || props === void 0 ? void 0 : props.items) === null || _b === void 0 ? void 0 : _b.length) > 0;
    const updatedColumns = [{
            id: 'checkbox',
            header: React.createElement("div", { className: "table-header-padding" },
                React.createElement(Checkbox, { checked: isCheckboxChecked, disabled: true })),
            cell: () => React.createElement("div", { className: "table-header-padding" },
                React.createElement(Checkbox, { checked: true, disabled: true })),
            width: 60
        },
        ...columnDefinitions];
    return React.createElement(TableContainer, { key: renderCount },
        React.createElement(Table, Object.assign({}, collectionProps, { resizableColumns: true, columnDefinitions: updatedColumns, visibleColumns: preferences.visibleContent, items: items, ariaLabels: props.ariaLabels, variant: "embedded", wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSelectionChange: event => {
                setSelectedItems(event.detail.selectedItems);
            }, onSortingChange: ({ detail }) => {
                if (props.propertyFilterStorageName)
                    SetTableSortingColumn(props.propertySortingStorageName, detail);
                actions.setSorting(detail);
            }, header: props.header, filter: React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: props.filteringPlaceholder }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: props.propertyFilterFilteringOptions, filteringProperties: props.propertyFilterFilteringProperties, onChange: ({ detail }) => {
                    const filteredDetail = fetchUniquePropertyFilters(detail);
                    if (props.propertyFilterStorageName)
                        SetPropertyFilters(props.propertyFilterStorageName, filteredDetail);
                    actions.setPropertyFiltering(filteredDetail);
                } })), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                    setPreferences(detail);
                    setRenderCount(renderCount + 1);
                }, pageSizePreference: {
                    title: 'Page Size',
                    options: props.pageSizeOptions
                }, wrapLinesPreference: {
                    label: 'Wrap Lines',
                    description: 'If checked, lines will be wrapped to display all of the text'
                }, visibleContentPreference: {
                    title: 'Visible Columns',
                    options: props.visibleContentOptions
                } }) })));
};
export default InmemoryDetailsTable;
