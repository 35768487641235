import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { Badge } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from '@/lib/misc/utils';
import { ROLETYPE, UserRolesReadableText } from '@/presentation/pages/settings/settings-types';
import { companyNameFilteringProperty } from '@/presentation/pages/common-config';
export const MyProfileOrgTableColumnDefinitions = addColumnSortLabels([
    {
        id: 'companyName',
        sortingField: 'companyName',
        header: 'Organization',
        cell: (item) => item === null || item === void 0 ? void 0 : item.companyName,
    },
    {
        id: 'roleName',
        header: 'Role',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.roleName) ? (React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, (item === null || item === void 0 ? void 0 : item.roleName) || '')) : '-';
        },
    }
]);
export const MyProfileOrgTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'companyName', label: 'Organization', editable: false },
            { id: 'roleName', label: 'Role', editable: true }
        ]
    }
];
export const MyProfileOrgTablePageSizeOptions = [
    { value: 10, label: '10 Organizations' },
    { value: 30, label: '30 Organizations' },
    { value: 50, label: '50 Organizations' }
];
export const MyProfileOrgTablePreferences = {
    pageSize: 30,
    visibleContent: ['companyName', 'roleName'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const MyProfileOrgFilteringProperties = [
    companyNameFilteringProperty,
    {
        key: 'roleName',
        operators: ['=', '!='],
        propertyLabel: 'Role',
        groupValuesLabel: 'Role values'
    }
];
export const MyProfileOrgFilteringOptions = [
    {
        propertyKey: 'roleName',
        value: UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[ROLETYPE.USER]
    },
    {
        propertyKey: 'roleName',
        value: UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[ROLETYPE.ADMIN]
    },
    {
        propertyKey: 'roleName',
        value: UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[ROLETYPE.READONLY]
    },
];
