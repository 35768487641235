import { getRandomUUID } from '@/lib/misc/utils';
import { TOASTS_ADD } from '@/presentation/reducers';
export const InitialDispatchOptions = {
    dispatch: '',
    successToken: '',
    successMessage: '',
    errorToken: '',
    errorMessage: ''
};
export function makeToastTypeCreate(history, toastObj) {
    // const message = (<EuiLink value={toastObj.createdID} onClick={(e) => {
    //   return history.push(toastObj.redirectionPath)
    // }} >
    //   {capitalizeFirst(toastObj.type)} ID <span className={'kivera-purple'}>{toastObj.createdID}</span> Created Successfully
    // </EuiLink>)
    return {
        id: toastObj.id,
        // title: <EuiI18n token={toastObj.contextToken} default={message} />,
        color: toastObj.color
    };
}
export const dispatchError = (dispatch, contextToken, message) => {
    const toast = {
        id: 'toast-' + getRandomUUID(),
        // title: <EuiI18n token={contextToken} default={message} />,
        color: 'danger'
    };
    dispatch({
        type: TOASTS_ADD,
        payload: toast
    });
};
export const dispatchSuccess = (dispatch, contextToken, message) => {
    const toast = {
        id: 'toast-' + getRandomUUID(),
        // title: <EuiI18n token={contextToken} default={message} />,
        color: 'success'
    };
    dispatch({
        type: TOASTS_ADD,
        payload: toast
    });
};
