import React from 'react';
import { useBlocker } from 'react-router-dom';
import AppLayout from "@/presentation/layouts/app-layout";
import { useAppContext } from '../providers/app-context-provider';
import UnsavedChangesModal from "@/presentation/components/modal/unsaved-changes-modal";
const ConfirmNavigation = ({ blocker }) => {
    const { setIsFormDirty, setDirtyFormResourceName } = useAppContext();
    const handleOnConfirm = () => {
        var _a;
        setIsFormDirty(false);
        setDirtyFormResourceName(null);
        (_a = blocker.proceed) === null || _a === void 0 ? void 0 : _a.call(blocker);
    };
    const handleOnCancel = () => {
        var _a;
        (_a = blocker.reset) === null || _a === void 0 ? void 0 : _a.call(blocker);
    };
    if (blocker.state === "blocked") {
        return (React.createElement(UnsavedChangesModal
        // @ts-ignore
        , { 
            // @ts-ignore
            showDialog: true, confirm: handleOnConfirm, cancel: handleOnCancel }));
    }
    return null;
};
const NavigationRouter = () => {
    const { isFormDirty } = useAppContext();
    window.addEventListener('unhandledrejection', function (event) {
        if (event.reason && event.reason.name === 'Canceled') {
            // monaco editor promise cancelation
            event.preventDefault();
        }
    });
    let shouldBlock = React.useCallback(({ currentLocation, nextLocation }) => isFormDirty && currentLocation.pathname !== nextLocation.pathname, [isFormDirty]);
    let blocker = useBlocker(shouldBlock);
    const beforeUnloadHandler = (event) => {
        // Recommended
        event.preventDefault();
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
    };
    React.useEffect(() => {
        if (blocker.state === "blocked" && !isFormDirty) {
            blocker.reset();
        }
        if (isFormDirty) {
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
    }, [blocker, isFormDirty]);
    return (React.createElement(React.Fragment, null,
        blocker ? React.createElement(ConfirmNavigation, { blocker: blocker }) : null,
        React.createElement(AppLayout, null)));
};
export default NavigationRouter;
