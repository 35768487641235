var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RemoteBaseClass } from "@/data/usecases";
import { GENERIC_CONFLICT_MESSAGE, GENERIC_FORBIDDEN_MESSAGE, GENERIC_UNKNOWN_ERROR_MESSAGE, HttpStatusCode } from "@/data/protocols/http";
import { KiveraGQLClient } from "@/infra/http";
export class RemoteRoles extends RemoteBaseClass {
    constructor(url, client, getTokenSilently) {
        super(getTokenSilently);
        this.url = url;
        this.httpClient = client;
    }
    attach(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.url,
                method: 'POST',
                body: params
            });
            switch (httpResponse.statusCode) {
                case HttpStatusCode.ok:
                    // if it has error
                    if (Array.isArray(httpResponse.body) && httpResponse.body.length > 0) {
                        // if any of the data has error
                        let hasError = false;
                        httpResponse.body.forEach((result) => {
                            if (result === null || result === void 0 ? void 0 : result.error) {
                                hasError = true;
                            }
                        });
                        if (hasError) {
                            return makeModel({ msg: '' }, true);
                        }
                    }
                    return makeModel(httpResponse.body, false);
                case HttpStatusCode.badRequest:
                    return makeModel(httpResponse.body, true);
                case HttpStatusCode.conflict:
                    return makeModel(makeConflictError(), true);
                case HttpStatusCode.forbidden:
                    return makeModel(makeForbiddenError(), true);
                default:
                    return makeModel(makeUnknownError(), true);
            }
        });
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListRoles(); }));
        });
    }
}
function makeErrorBody(code, errorMsg) {
    return {
        error: true,
        code: code,
        msg: errorMsg,
        type: 'AccessDenied'
    };
}
function makeConflictError() {
    return makeErrorBody(HttpStatusCode.forbidden, GENERIC_CONFLICT_MESSAGE);
}
function makeForbiddenError() {
    return makeErrorBody(HttpStatusCode.forbidden, GENERIC_FORBIDDEN_MESSAGE);
}
function makeUnknownError() {
    return makeErrorBody(HttpStatusCode.forbidden, GENERIC_UNKNOWN_ERROR_MESSAGE);
}
function makeModel(body, error) {
    var _a;
    return {
        error: error,
        msg: (_a = body === null || body === void 0 ? void 0 : body.msg) !== null && _a !== void 0 ? _a : ''
    };
}
