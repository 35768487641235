import React from "react";
import Overlay from "@/presentation/components/overlay/overlay";
const OverlayLoader = (props) => {
    const configs = {
        animate: true,
        clickDismiss: false,
        escapeDismiss: false,
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Overlay, { configs: configs, isOpen: props.isOpen, closeOverlay: props.closeOverlay, showCloseIcon: false },
            React.createElement("h1", null, "Overlay Loader...")));
};
export default OverlayLoader;
