import React, { useContext, createContext } from "react";
import { IdentitiesHandler, ProfilesHandler, StringValidator, RulesHandler, NotificationMonitorsHandler, OptionsArrayValidator, } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
import { RemoteIdentities, RemoteProfiles, RemoteRules, RemoteNotificationsDestinations, RemoteNotificationsMonitors, } from "@/data/usecases";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { makeNotificationsDestinationsAPIUrl, makeNotificationsMonitorsAPIUrl, } from "@/presentation/main/factories/http";
const defaultContext = {
    profiles: new ProfilesHandler(null, null),
    rules: new RulesHandler(null, null),
    identities: new IdentitiesHandler(null, null),
    notificationMonitors: new NotificationMonitorsHandler(null, null),
    nameValidator: new StringValidator(),
    descriptionValidator: new StringValidator(),
    optionsValidatior: new StringValidator(),
    destinationsValidator: new OptionsArrayValidator(),
};
export const NotificationsMonitorsHandlersProviderContext = createContext(defaultContext);
export const useNotificationsMonitorsHandlers = () => useContext(NotificationsMonitorsHandlersProviderContext);
export const NotificationsMonitorsHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const profilesRemotes = {
        profiles: new RemoteProfiles(getTokenSilently),
        rules: new RemoteRules(getTokenSilently),
        identities: new RemoteIdentities(getTokenSilently),
    };
    const rulesRemotes = {
        rules: new RemoteRules(getTokenSilently),
    };
    const identitiesRemotes = {
        identities: new RemoteIdentities(getTokenSilently),
        profiles: new RemoteProfiles(getTokenSilently),
    };
    const remotes = {
        notificationMonitors: new RemoteNotificationsMonitors(makeNotificationsMonitorsAPIUrl(), makeAuthorizeHttpClientDecorator()),
        notificationDestinations: new RemoteNotificationsDestinations(makeNotificationsDestinationsAPIUrl(), makeAuthorizeHttpClientDecorator()),
    };
    const notificationMonitorsHandler = new NotificationMonitorsHandler(null, remotes);
    const profilesHandler = new ProfilesHandler(null, profilesRemotes);
    const rulesHandler = new RulesHandler(null, rulesRemotes);
    const identitiesHandler = new IdentitiesHandler(null, identitiesRemotes);
    const nameValidator = new StringValidator().notEmpty().isValidResourceName();
    const descriptionValidator = new StringValidator()
        .notEmpty()
        .isValidResourceDesc();
    const optionsValidatior = new StringValidator().notEmpty();
    const destinationsValidator = new OptionsArrayValidator().notEmpty();
    return (React.createElement(NotificationsMonitorsHandlersProviderContext.Provider, { value: {
            profiles: profilesHandler,
            rules: rulesHandler,
            identities: identitiesHandler,
            notificationMonitors: notificationMonitorsHandler,
            nameValidator,
            descriptionValidator,
            optionsValidatior,
            destinationsValidator,
        } }, children));
};
