export var PROXY_MODE_TYPE_WITHOUT_SPACE;
(function (PROXY_MODE_TYPE_WITHOUT_SPACE) {
    PROXY_MODE_TYPE_WITHOUT_SPACE["DEFAULT_BLOCK_PREVENTATIVE"] = "DEFAULTBLOCK";
    PROXY_MODE_TYPE_WITHOUT_SPACE["DEFAULT_ALLOW_BLACKLIST"] = "DEFAULTALLOW";
    PROXY_MODE_TYPE_WITHOUT_SPACE["LEARNING"] = "LEARNING";
})(PROXY_MODE_TYPE_WITHOUT_SPACE || (PROXY_MODE_TYPE_WITHOUT_SPACE = {}));
export var PROXY_MODE_TYPE_LABEL;
(function (PROXY_MODE_TYPE_LABEL) {
    PROXY_MODE_TYPE_LABEL["DEFAULT_BLOCK_PREVENTATIVE"] = "Default Block";
    PROXY_MODE_TYPE_LABEL["DEFAULT_ALLOW_BLACKLIST"] = "Default Allow";
    PROXY_MODE_TYPE_LABEL["LEARNING"] = "Learning Mode";
})(PROXY_MODE_TYPE_LABEL || (PROXY_MODE_TYPE_LABEL = {}));
export var PROXY_MODE_TYPE;
(function (PROXY_MODE_TYPE) {
    PROXY_MODE_TYPE["DEFAULT_BLOCK_PREVENTATIVE"] = "preventative";
    PROXY_MODE_TYPE["DEFAULT_ALLOW_BLACKLIST"] = "blacklist";
    PROXY_MODE_TYPE["LEARNING"] = "learning";
})(PROXY_MODE_TYPE || (PROXY_MODE_TYPE = {}));
export const ProxyDeleteStrings = {
    headerTextSingular: 'Delete Proxy',
    headerTextPlural: ' Delete Proxies',
    modalTextSingular: 'proxy',
    modalTextPlural: 'proxies',
    consentText: 'confirm'
};
export const ProxyDeleteShowProp = 'name';
export var HeaderStrings;
(function (HeaderStrings) {
    HeaderStrings["create"] = "Create Proxy";
    HeaderStrings["edit"] = "Edit Proxy";
})(HeaderStrings || (HeaderStrings = {}));
export var PROXY_SETTINGS_ERROR_ACTION_VALUES;
(function (PROXY_SETTINGS_ERROR_ACTION_VALUES) {
    PROXY_SETTINGS_ERROR_ACTION_VALUES["DEFAULT_ACTION"] = "DEFAULT_ACTION";
    PROXY_SETTINGS_ERROR_ACTION_VALUES["ALLOW"] = "ALLOW";
    PROXY_SETTINGS_ERROR_ACTION_VALUES["BLOCK"] = "BLOCK";
})(PROXY_SETTINGS_ERROR_ACTION_VALUES || (PROXY_SETTINGS_ERROR_ACTION_VALUES = {}));
export var PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING;
(function (PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING) {
    PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING["DEFAULT_ACTION"] = "Use Default Mode";
    PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING["ALLOW"] = "Allow";
    PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING["BLOCK"] = "Block";
})(PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING || (PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING = {}));
export const proxySettingsErrorActionOptions = [
    {
        label: "Use Default Mode",
        value: PROXY_SETTINGS_ERROR_ACTION_VALUES === null || PROXY_SETTINGS_ERROR_ACTION_VALUES === void 0 ? void 0 : PROXY_SETTINGS_ERROR_ACTION_VALUES.DEFAULT_ACTION,
    },
    {
        label: "Allow",
        value: PROXY_SETTINGS_ERROR_ACTION_VALUES === null || PROXY_SETTINGS_ERROR_ACTION_VALUES === void 0 ? void 0 : PROXY_SETTINGS_ERROR_ACTION_VALUES.ALLOW,
    },
    {
        label: "Block",
        value: PROXY_SETTINGS_ERROR_ACTION_VALUES === null || PROXY_SETTINGS_ERROR_ACTION_VALUES === void 0 ? void 0 : PROXY_SETTINGS_ERROR_ACTION_VALUES.BLOCK,
    }
];
