export const kiveraLight = {
    "base": "vs",
    "inherit": true,
    "rules": [
        { token: "operators", foreground: "3296FA" },
        { token: "keywords", foreground: "3296FA" },
        { token: "builtinVariables", foreground: "3296FA", fontStyle: "bold" },
        { token: "builtinFunctions", foreground: "3296FA" },
        { token: "symbols", foreground: "FA5454" },
        { token: "strings", foreground: "B41EFF" },
        { token: "comment", foreground: "65748F" },
        { token: "key", foreground: "3296FA", fontStyle: "bold" },
        { token: "bracket", foreground: "B41EFF" },
        { token: "digits", foreground: "1A3160" },
    ],
    "colors": {
        "editor.foreground": "#080808",
        "editor.background": "#F9F9F9",
        "editor.selectionBackground": "#275FFF4D",
        "editor.lineHighlightBackground": "#2463B41F",
        "editorCursor.foreground": "#000000",
        "editorWhitespace.foreground": "#4B4B7E80"
    }
};
