import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, ColumnLayout, Container, Table, } from "@cloudscape-design/components-themed/components";
import { COMPLIANCE_MAPPINGS_COLUMN_DEFINITIONS, sortMultiColumns } from "@/presentation/pages";
import { TableHeader } from "@/presentation/components/commons/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
const ShowAttributeEditor = (props) => {
    var _a;
    const navigate = useNavigate();
    const mappings = (_a = props.mappings) !== null && _a !== void 0 ? _a : [];
    const sortedMappings = sortMultiColumns(mappings, ["key", "value"]);
    const buttonText = mappings.length > 0 ? "Edit Compliance Mappings" : "Add Compliance Mappings";
    const { items, collectionProps } = useCollection(sortedMappings, {
        sorting: { defaultState: { sortingColumn: COMPLIANCE_MAPPINGS_COLUMN_DEFINITIONS[0], isDescending: false } },
    });
    return (React.createElement(Container, null,
        React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
            React.createElement(Box, null,
                React.createElement(Table, Object.assign({}, collectionProps, { header: React.createElement(TableHeader, { title: "Compliance Mappings", totalItems: items || [], description: "Compliance frameworks and control identifiers that apply to this rule.", actionButtons: (props === null || props === void 0 ? void 0 : props.noButton) ? null : React.createElement(Button, { onClick: () => navigate(props.basePath + "/" + (props === null || props === void 0 ? void 0 : props.id) + "/edit" + "#compliance-mappings") }, buttonText) }), empty: React.createElement(Box, { textAlign: "center", color: "inherit" },
                        React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, `No compliance mappings are assigned to this ${props.resource}`)), variant: "embedded", columnDefinitions: COMPLIANCE_MAPPINGS_COLUMN_DEFINITIONS, items: items }))))));
};
export default ShowAttributeEditor;
