import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as LogoK } from '@/presentation/assets/img/logo/logo-letter-k-white.svg';
import { ReactComponent as LogoRegular } from '@/presentation/assets/img/logo/logo-regular.svg';
import { makePathPageName, PATHS } from "@/presentation/common-utils/constants";
import { ReactComponent as SidebarDashboardIcon } from '@/presentation/assets/img/icons/sidebar-dashboard.svg';
import { ReactComponent as SidebarProxiesIcon } from '@/presentation/assets/img/icons/sidebar-proxies.svg';
import { ReactComponent as SidebarProfilesIcon } from '@/presentation/assets/img/icons/sidebar-profiles.svg';
import { ReactComponent as SidebarMonitoringIcon } from '@/presentation/assets/img/icons/sidebar-monitoring.svg';
import { ReactComponent as SidebarIdentityIcon } from '@/presentation/assets/img/icons/sidebar-identity.svg';
import { ReactComponent as SidebarRulesIcon } from '@/presentation/assets/img/icons/sidebar-rules-flowchart.svg';
import { ReactComponent as SidebarDocumentationIcon } from '@/presentation/assets/img/icons/sidebar-docs.svg';
// import {ReactComponent as SidebarNotificationsIcon} from '@/presentation/assets/img/icons/sidebar-notification.svg'
import { Dialog, Transition } from "@headlessui/react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { Tooltip } from '@/presentation/components/tooltip';
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import HeaderUserMenu from './header-user-menu';
import { getUrlParamsName } from '../common-utils/common-utils';
import { useIsMounted } from '@/presentation/hooks';
const AppLayout = () => {
    const [activePath, setActivePath] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const isActivePath = (path) => {
        var _a, _b;
        if (path === '/') {
            return location.pathname === '/';
        }
        const parts = (_b = (_a = location.pathname) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 : _b.filter(part => !!part);
        return ('/' + (parts === null || parts === void 0 ? void 0 : parts[0])) === path;
    };
    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted.current) {
            // on initial load set the active path name
            setActivePath(location.pathname);
        }
    }, []);
    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };
    const referrer = getUrlParamsName('referrer');
    const docsReferrer = referrer || window.location.pathname;
    const docsPath = `${PATHS.DOCS}?referrer=${docsReferrer}`;
    const topNavigation = [
        { name: 'Proxies', path: PATHS.PROXIES, icon: SidebarProxiesIcon, isActive: isActivePath(PATHS.PROXIES), isExternal: false },
        { name: 'Identities', path: PATHS.IDENTITIES, icon: SidebarIdentityIcon, isActive: isActivePath(PATHS.IDENTITIES), isExternal: false },
        { name: 'Profiles', path: PATHS.PROFILES, icon: SidebarProfilesIcon, isActive: isActivePath(PATHS.PROFILES), isExternal: false },
        { name: 'Rules', path: PATHS.RULES, icon: SidebarRulesIcon, isActive: isActivePath(PATHS.RULES), isExternal: false },
    ];
    const bottomNavigation = [
        { name: 'Dashboard', path: PATHS.DASHBOARD, icon: SidebarDashboardIcon, isActive: isActivePath(PATHS.DASHBOARD), isExternal: false },
        { name: 'Monitoring', path: PATHS.MONITORING, icon: SidebarMonitoringIcon, isActive: isActivePath(PATHS.MONITORING), isExternal: false },
        // { name: 'Notifications', path: PATHS.NOTIFICATIONS, icon: SidebarNotificationsIcon, isActive: isActivePath(PATHS.NOTIFICATIONS), isExternal: false },
        { name: 'Docs', path: docsPath, icon: SidebarDocumentationIcon, isActive: isActivePath(PATHS.DOCS), isExternal: false },
        // { name: 'Reporting', path: PATHS.REPORTING, icon: SidebarReportingIcon, isActive: isActivePath(PATHS.REPORTING), isExternal: false }
    ];
    const activeClassnames = 'text-[#cf4dff] bg-gray-800 ';
    const inActiveClassnames = 'text-gray-300 ';
    const inActiveMobileClassnames = 'text-gray-400 hover:text-white hover:bg-gray-800';
    const commonClassnames = 'group  flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold';
    const tooltipClassname = '!bg-black ml-[18px] font-medium';
    const externalLinkClassNames = 'text-gray-300 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold';
    const makeLink = (details) => {
        let tooltipAnchorName = 'sidebar-menu-tooltip-' + String(details.name).toLowerCase();
        const detailsIcon = (React.createElement(React.Fragment, null,
            React.createElement(details.icon, { className: "h-7 w-7 shrink-0" }),
            React.createElement("span", { className: "sr-only" }, details.name)));
        const activeDesktopClassName = details.isActive ? activeClassnames : inActiveClassnames;
        let allClassNames = details.isExternal ? `${externalLinkClassNames} ${tooltipAnchorName}` : classNames(activeDesktopClassName, commonClassnames, tooltipAnchorName);
        return (React.createElement(React.Fragment, null, details.isExternal ? (React.createElement(Link, { to: details.path, className: allClassNames, target: "_blank" }, detailsIcon)) : (React.createElement(NavLink, { to: details.path, className: allClassNames, "data-tooltip-id": "nav-menu-tooltip", onClick: () => {
                setActivePath(details.path);
                setIsOpen(false);
                setTooltipContent('');
            }, onMouseEnter: () => {
                setIsOpen(true);
                setTooltipContent(details.name);
            }, onMouseLeave: () => {
                setIsOpen(false);
                setTooltipContent('');
            } }, detailsIcon))));
    };
    const makeMobileLink = (details) => {
        const allClasses = classNames(details.isActive ? activeClassnames : inActiveMobileClassnames, commonClassnames);
        const detailsIcon = (React.createElement(React.Fragment, null,
            React.createElement(details.icon, { className: "h-6 w-6 shrink-0" }),
            React.createElement("span", null, details.name)));
        return (React.createElement(React.Fragment, null, details.isExternal ? (React.createElement(Link, { to: details.path, className: allClasses, target: "_blank", onClick: () => {
                setSidebarOpen(false);
            } }, detailsIcon)) : (React.createElement(NavLink, { to: details.path, className: allClasses, onClick: () => {
                setActivePath(details.path);
                setSidebarOpen(false);
            } }, detailsIcon))));
    };
    const makeUserMenuItems = (position) => {
        return (React.createElement(HeaderUserMenu, { position: position, setActivePath: setActivePath }));
    };
    return (React.createElement("div", { className: 'flex' },
        React.createElement(Transition.Root, { show: sidebarOpen, as: Fragment },
            React.createElement(Dialog, { as: "div", className: "relative !z-[10010] sidebar-topnav", onClose: setSidebarOpen },
                React.createElement(Transition.Child, { as: Fragment, enter: "transition-opacity ease-linear duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition-opacity ease-linear duration-300", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                    React.createElement("div", { className: "fixed inset-0 bg-gray-900/80" })),
                React.createElement("div", { className: "fixed inset-0 flex" },
                    React.createElement(Transition.Child, { as: Fragment, enter: "transition ease-in-out duration-300 transform", enterFrom: "-translate-x-full", enterTo: "translate-x-0", leave: "transition ease-in-out duration-300 transform", leaveFrom: "translate-x-0", leaveTo: "-translate-x-full" },
                        React.createElement(Dialog.Panel, { className: "relative mr-16 flex w-full max-w-xs flex-1" },
                            React.createElement(Transition.Child, { as: Fragment, enter: "ease-in-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in-out duration-300", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                                React.createElement("div", { className: "absolute left-full top-0 flex w-16 justify-center pt-5" },
                                    React.createElement("button", { type: "button", className: "-m-2.5 p-2.5", onClick: () => setSidebarOpen(false) },
                                        React.createElement("span", { className: "sr-only" }, "Close sidebar"),
                                        React.createElement(XMarkIcon, { className: "h-6 w-6 text-white bg-[#111111]", "aria-hidden": "true" })))),
                            React.createElement("div", { className: "flex grow flex-col gap-y-5 overflow-y-auto bg-[#111111] px-6 pb-2 ring-1 ring-white/10" },
                                React.createElement("div", { className: "pt-4" },
                                    React.createElement(LogoRegular, { className: "text-gray-400", style: { height: "40px" } })),
                                React.createElement("nav", { className: "flex flex-1 flex-col" },
                                    React.createElement("ul", { role: "list", className: "-mx-2 space-y-2" }, topNavigation.map((item) => (React.createElement("li", { key: item.name }, makeMobileLink(item))))),
                                    React.createElement("ul", { role: "list", className: "mt-4 pt-4 -mx-2 space-y-2 border-t border-gray-500" }, bottomNavigation.map((item) => (React.createElement("li", { key: item.name }, makeMobileLink(item)))))))))))),
        React.createElement("aside", { className: "sidebar-aside flex flex-col lg:sticky h-screen lg:w-20 lg:top-0 lg:bg-[#111111] !z-[10000]" },
            React.createElement("div", { className: "flex mt-6 p-5 h-9 w-auto shrink-0 items-center text-sm justify-center" }, makeUserMenuItems('sidebar')),
            React.createElement("nav", { className: "mt-8 p-3" },
                React.createElement("ul", { role: "list", className: "pt-4 flex flex-col items-center space-y-2 border-t border-gray-700" }, topNavigation.map((item) => (React.createElement("li", { key: item.name }, makeLink(item))))),
                React.createElement("ul", { role: "list", className: "mt-4 pt-4 flex flex-col items-center space-y-2 border-t border-gray-700" }, bottomNavigation.map((item) => (React.createElement("li", { key: item.name }, makeLink(item))))),
                React.createElement(Tooltip, { id: "nav-menu-tooltip", content: tooltipContent, isOpen: isOpen, className: tooltipClassname, place: 'right', opacity: 1 })),
            React.createElement("div", { className: "mt-auto p-4 flex flex-col  items-center justify-center space-y-2" },
                React.createElement(LogoK, { className: "text-[#333] h-8" }))),
        React.createElement("div", { className: "flex flex-1 flex-col" },
            React.createElement("div", { className: 'sidebar-topnav' },
                React.createElement("div", { className: "sticky top-0 !z-[10010] flex items-center gap-x-6 bg-[#111111] px-4 py-4 shadow-sm sm:px-6" },
                    React.createElement("button", { type: "button", className: "-m-2.5 p-2.5 text-gray-400", onClick: () => setSidebarOpen(true) },
                        React.createElement("span", { className: "sr-only" }, "Open sidebar"),
                        React.createElement(Bars3Icon, { className: "h-6 w-6", "aria-hidden": "true" })),
                    React.createElement("div", { className: "flex-1 text-2xl font-semibold leading-6 text-white" }, makePathPageName(activePath)),
                    makeUserMenuItems('topnav'))),
            React.createElement("main", { className: "flex flex-1 flex-col", id: "mainPage" },
                React.createElement(Outlet, null)))));
};
export default AppLayout;
