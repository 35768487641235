export const makeManagementAPIUrl = (path) => `${process.env.ENDPOINTS_MGMT_API}${path}`;
export const makeManagementDocsAPIUrl = (path) => `${process.env.ENDPOINTS_MGMT_API_DOCS}${path}`;
export const makeManagementSupportAPIUrl = (path) => `${process.env.ENDPOINTS_MGMT_API_SUPPORT}${path}`;
export const makeManagementDashboardAPIUrl = () => `${process.env.ENDPOINTS_MGMT_API_DASHBOARD}`;
export const makeManagementProxyAPIUrl = () => `${process.env.ENDPOINTS_MGMT_PROXY_API}`;
export const makeManagementTrailBlazerAPIUrl = () => `${process.env.ENDPOINTS_MGMT_TRAILBLAZER_API}`;
export const makeAudienceAPIUrl = (path) => `${process.env.AUTH_AUDIENCE}${path}`;
export const makeLogsAPIUrl = () => `${process.env.ENDPOINTS_LOGS_API}`;
export const makeAuditLogsAPIUrl = () => `${process.env.ENDPOINTS_LOGS_API}/audit`;
export const makeDashboardAPIUrl = () => `${process.env.ENDPOINTS_DASHBOARD_API}`;
export const makeSimulatorAPIUrl = () => `${process.env.ENDPOINTS_SIMULATOR}`;
export const makeOpenPolicyAPIUrl = () => `${process.env.ENDPOINT_OPEN_POLICY_VALIDATOR}`;
export const makeUserAPITokens = () => `${process.env.ENDPOINTS_USER_API}`;
export const makeResetUserPasswordUrl = () => `${process.env.ENDPOINT_RESET_PASSWORD}`;
export const makeResendUserVerificationEmail = (path) => `${process.env.ENDPOINTS_MGMT_API}${path}`;
export const makeNotificationsMonitorsAPIUrl = () => `${process.env.ENDPOINTS_NOTIFICATIONS}/monitors`;
export const makeNotificationsDestinationsAPIUrl = () => `${process.env.ENDPOINTS_NOTIFICATIONS}/destinations`;
export const makeNotificationsAlertsAPIUrl = () => `${process.env.ENDPOINTS_NOTIFICATIONS}/alerts`;
export const makeTestOrganizationExit = () => `${process.env.CYPRESS_TEST_ORGANIZATION_EXIT}`;
export const makeVersionTextUrl = () => `${process.env.VERSION_TEXT_FILE}`;
