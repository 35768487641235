import { RULE_TYPE_CIDR, RULE_TYPE_EQUAL, RULE_TYPE_GREATER_THAN_NUMBER, RULE_TYPE_MAX_LENGTH, RULE_TYPE_MIN_LENGTH, RULE_TYPE_NOT_EQUAL, RULE_TYPE_VALID_ALLOWED_DOMAIN, RULE_TYPE_VALID_ATTRIBUTE_KEY, RULE_TYPE_VALID_ATTRIBUTE_KEY_JSONPATH, RULE_TYPE_VALID_RESOURCE_DESC, RULE_TYPE_VALID_RESOURCE_NAME, RULE_TYPE_VALID_URL } from '@/presentation/handlers';
import { isAttributeKeyValid, isResourceDescValid, isResourceNameValid, isValidCIDR, isValidJsonPath, isValidURL } from '@/presentation/common-utils/common-utils';
import { WILDCARD } from "@/presentation/common-utils/constants";
export class StringValidator {
    constructor(rules) {
        this.rules = [];
        this.addRule = (rule) => {
            const filtered = this.rules.filter(r => r.type !== rule.type);
            return [...filtered, rule];
        };
        this.makeError = (errorMessage) => {
            return { valid: false, message: errorMessage };
        };
        this.makeValid = (value) => {
            return { valid: true, value: value };
        };
        this.equals = (value) => {
            this.rules = this.addRule({ type: RULE_TYPE_EQUAL, value: value });
            return this;
        };
        this.notEquals = (value) => {
            this.rules = this.addRule({ type: RULE_TYPE_NOT_EQUAL, value: value });
            return this;
        };
        this.minLength = (min) => {
            this.rules = this.addRule({ type: RULE_TYPE_MIN_LENGTH, min: min });
            return this;
        };
        this.maxLength = (max) => {
            this.rules = this.addRule({ type: RULE_TYPE_MAX_LENGTH, max: max });
            return this;
        };
        this.notEmpty = () => {
            this.rules = this.addRule({ type: RULE_TYPE_MIN_LENGTH, min: 1 });
            return this;
        };
        this.empty = () => {
            this.rules = this.addRule({ type: RULE_TYPE_MAX_LENGTH, max: 0 });
            return this;
        };
        this.isValidResourceName = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_RESOURCE_NAME });
            return this;
        };
        this.isValidResourceDesc = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_RESOURCE_DESC });
            return this;
        };
        this.isValidAllowedDomain = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ALLOWED_DOMAIN });
            return this;
        };
        this.isValidAttributeKey = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ATTRIBUTE_KEY });
            return this;
        };
        this.isValidAttributeKeyJsonPath = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_ATTRIBUTE_KEY_JSONPATH });
            return this;
        };
        this.isValidCIDR = () => {
            this.rules = this.addRule({ type: RULE_TYPE_CIDR });
            return this;
        };
        this.isValidURL = () => {
            this.rules = this.addRule({ type: RULE_TYPE_VALID_URL });
            return this;
        };
        this.greaterThanNumber = (num = 0) => {
            this.rules = this.addRule({ type: RULE_TYPE_GREATER_THAN_NUMBER, value: num });
            return this;
        };
        this.checkRule = (rule, value) => {
            switch (rule.type) {
                case RULE_TYPE_EQUAL:
                    return rule.value !== value
                        ? this.makeError(`Value was expected to be ${rule.value} but was ${value}.`)
                        : this.makeValid(value);
                case RULE_TYPE_NOT_EQUAL:
                    return rule.value === value
                        ? this.makeError(`Value must not be ${rule.value}.`)
                        : this.makeValid(value);
                case RULE_TYPE_MIN_LENGTH:
                    return value.length < rule.min
                        ? this.makeError(`String length must be greater than or equal to ${rule.min} but was ${value.length}.`)
                        : this.makeValid(value);
                case RULE_TYPE_MAX_LENGTH:
                    return value.length > rule.max
                        ? this.makeError(`String length must be less than or equal to ${rule.max} but was ${value.length}.`)
                        : this.makeValid(value);
                case RULE_TYPE_VALID_RESOURCE_NAME:
                    return isResourceNameValid(value, ['-'])
                        ? this.makeValid(value)
                        : this.makeError(`String must be alpha numeric or hyphen.`);
                case RULE_TYPE_VALID_RESOURCE_DESC:
                    return isResourceDescValid(value, ['-', ' '])
                        ? this.makeValid(value)
                        : this.makeError(`String must be alpha numeric or hyphen.`);
                case RULE_TYPE_VALID_ATTRIBUTE_KEY:
                    return isAttributeKeyValid(value)
                        ? this.makeValid(value)
                        : this.makeError(`String must be alpha numeric or hyphen or dot.`);
                case RULE_TYPE_VALID_ATTRIBUTE_KEY_JSONPATH:
                    return isValidJsonPath(value)
                        ? this.makeValid(value)
                        : this.makeError(`String must be alpha numeric or hyphen or dot.`);
                case RULE_TYPE_CIDR:
                    return value === '' ? this.makeValid(value) : (isValidCIDR(value)
                        ? this.makeValid(value)
                        : this.makeError(`String must be valid CIDR.`));
                case RULE_TYPE_VALID_URL:
                    return isValidURL(value)
                        ? this.makeValid(value)
                        : this.makeError(`String must be valid URL.`);
                case RULE_TYPE_GREATER_THAN_NUMBER:
                    const num = parseInt(value, 10);
                    return num > rule.value
                        ? this.makeValid(value)
                        : this.makeError(`Number must be greater than ${rule.value}`);
                case RULE_TYPE_VALID_ALLOWED_DOMAIN:
                    const replaced = value.replaceAll(/\s*|\t|\r|\n/gm, '');
                    if (replaced === WILDCARD) {
                        this.makeValid(value);
                    }
                    return (replaced === null || replaced === void 0 ? void 0 : replaced.length) > 0
                        ? this.makeValid(value)
                        : this.makeError(`String must be valid Domain or Wildcard(*)`);
            }
        };
        this.validate = (value) => {
            if (value === null) {
                return this.makeError('StringValidator expected a string but received null.');
            }
            else if (value === undefined) {
                return this.makeError('StringValidator expected a string but received undefined.');
            }
            else if (typeof value !== 'string') {
                return this.makeError(`StringValidator expected a string but received ${typeof value}.`);
            }
            for (const rule of this.rules) {
                const result = this.checkRule(rule, value);
                if (!result.valid) {
                    return result;
                }
            }
            return {
                valid: true,
                value: value
            };
        };
        if (rules && rules.length > 0) {
            this.rules = rules;
        }
    }
}
