/**
 * Represents an placeholder renderer for monaco editor
 * Roughly based on https://github.com/microsoft/vscode/blob/main/src/vs/workbench/contrib/codeEditor/browser/untitledTextEditorHint/untitledTextEditorHint.ts
 */
export class PlaceholderContentWidget {
    constructor(placeholder, editor, monaco) {
        this.placeholder = placeholder;
        this.editor = editor;
        this.monaco = monaco;
        // register a listener for editor code changes
        editor.onDidChangeModelContent(() => this.onDidChangeModelContent());
        // ensure that on initial load the placeholder is shown
        this.onDidChangeModelContent();
    }
    onDidChangeModelContent() {
        if (this.editor.getValue() === '') {
            this.editor.addContentWidget(this);
        }
        else {
            this.editor.removeContentWidget(this);
        }
    }
    getId() {
        return PlaceholderContentWidget.ID;
    }
    getDomNode() {
        if (!this.domNode) {
            this.domNode = document.createElement('div');
            this.domNode.style.width = 'max-content';
            this.domNode.textContent = this.placeholder;
            this.domNode.style.fontStyle = 'italic';
            this.editor.applyFontInfo(this.domNode);
        }
        return this.domNode;
    }
    getPosition() {
        return {
            position: { lineNumber: 1, column: 1 },
            preference: [this.monaco.editor.ContentWidgetPositionPreference.EXACT],
        };
    }
    dispose() {
        this.editor.removeContentWidget(this);
    }
}
PlaceholderContentWidget.ID = 'editor.widget.placeholderHint';
