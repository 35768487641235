import { Alert, Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween } from '@cloudscape-design/components-themed/components';
import React, { useEffect, useState } from 'react';
const DeleteModal = (props) => {
    var _a, _b;
    const isMultiple = props.items.length > 1;
    const headerText = isMultiple ? props.deleteModalStrings.headerTextPlural : props.deleteModalStrings.headerTextSingular;
    const [deleteInputText, setDeleteInputText] = useState('');
    const [isDeleteLoading, setDeleteLoading] = useState((props === null || props === void 0 ? void 0 : props.isDeleteLoading) || false);
    const inputMatchesConsentText = deleteInputText === props.deleteModalStrings.consentText;
    useEffect(() => {
        setDeleteInputText('');
    }, [props.visible]);
    useEffect(() => {
        setDeleteLoading((props === null || props === void 0 ? void 0 : props.isDeleteLoading) || false);
    }, [props === null || props === void 0 ? void 0 : props.isDeleteLoading]);
    const handleDeleteSubmit = (event) => {
        event.preventDefault();
        if (inputMatchesConsentText) {
            props.onDelete();
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: headerText, closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
                React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                    React.createElement(Button, { disabled: !inputMatchesConsentText, onClick: props.onDelete, variant: (!inputMatchesConsentText || isDeleteLoading) ? 'normal' : 'primary', loading: isDeleteLoading }, "Delete"))) },
            React.createElement(SpaceBetween, { size: "m" },
                isMultiple
                    ? (React.createElement(Box, { variant: "span" },
                        "Delete",
                        ' ',
                        React.createElement(Box, { variant: "span", fontWeight: "bold" },
                            props.items.length,
                            ' ',
                            props.deleteModalStrings.modalTextPlural),
                        ' ',
                        "permanently? This action cannot be undone."))
                    : (React.createElement(Box, { variant: "span" },
                        "Delete ",
                        props.deleteModalStrings.modalTextSingular,
                        ' ',
                        React.createElement(Box, { variant: "span", fontWeight: "bold" }, (_b = (_a = props.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b[props.itemDeleteShowProp]),
                        ' ',
                        "permanently? This action cannot be undone.")),
                React.createElement(Alert, { type: 'warning' },
                    "Proceeding with this action will delete ",
                    isMultiple ? props.deleteModalStrings.modalTextPlural : ' this ' + props.deleteModalStrings.modalTextSingular,
                    " with all content and can impact related resources.",
                    ' '),
                React.createElement(Box, null, "To avoid accidental deletions we ask you to provide additional written consent."),
                React.createElement(ColumnLayout, { columns: 2 },
                    React.createElement("form", { onSubmit: handleDeleteSubmit },
                        React.createElement(FormField, { label: `Type "${props.deleteModalStrings.consentText}" to agree.` },
                            React.createElement(Input, { name: props.deleteModalStrings.modalTextPlural, autoComplete: 'off', placeholder: props.deleteModalStrings.consentText, onChange: event => setDeleteInputText(event.detail.value), value: deleteInputText, ariaRequired: true, ariaLabelledby: "delete-confirmation-label" })))))));
};
export default DeleteModal;
