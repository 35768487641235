var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpStatusCode } from '@/data/protocols/http';
import { AccessDeniedError, UnexpectedError } from '@/domain/errors';
export class RemoteLogsMonitoring {
    constructor(url, client) {
        this.url = url;
        this.httpClient = client;
    }
    getURL(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.url,
                method: 'POST',
                body: params
            });
            switch (httpResponse.statusCode) {
                case HttpStatusCode.ok:
                    return httpResponse.body;
                case HttpStatusCode.forbidden:
                    throw new AccessDeniedError();
                default:
                    throw new UnexpectedError();
            }
        });
    }
    downloadLogs(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.unauthenticatedRequest({
                url: url,
                method: 'GET'
            });
            switch (httpResponse.statusCode) {
                case HttpStatusCode.ok:
                    return httpResponse.body;
                case HttpStatusCode.forbidden:
                    throw new AccessDeniedError();
                default:
                    throw new UnexpectedError();
            }
        });
    }
}
