var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler } from '@/presentation/handlers';
import { DEFAULT_NOTIFICATION_MONITOR_INTERVAL } from '@/presentation/common-utils/constants';
export const InitialNotificationMonitorDetails = {
    id: '',
    name: '',
    severity: [],
    description: '',
    webhookURL: '',
    identityID: [],
    ruleID: [],
    profileName: [],
    profileId: [],
    interval: 0,
    valid: [],
    destinations: []
};
export const FormattedNotificationMonitorTypes = [
    'id',
    'name',
    'severity',
    'description',
    'webhookURL',
    'identityID',
    'ruleID',
    'profileId',
    'interval',
    'valid',
    'destinations'
];
export class NotificationMonitorsHandler extends PagesBaseHandler {
    constructor(remoteHandlers, remotes) {
        super();
        this.monitorDetails = InitialNotificationMonitorDetails;
        this.getPageName = () => {
            return 'Notifications';
        };
        this.getMonitorSeverity = (severity) => {
            var _a;
            const parsedSeverity = (_a = severity.map(sev => sev.label)) === null || _a === void 0 ? void 0 : _a[0];
            return parsedSeverity;
        };
        this.getCreateParams = (details) => {
            return {
                name: details.name,
                description: details.description,
                interval: DEFAULT_NOTIFICATION_MONITOR_INTERVAL,
                destinations: details.destinations.map(destination => destination.value),
                severity: details.severity.toUpperCase(),
                query_parameters: {
                    IdentityId: (details === null || details === void 0 ? void 0 : details.identityId) === "*" ? details.identityId : Number(details.identityId),
                    RuleId: (details === null || details === void 0 ? void 0 : details.ruleId) === "*" ? details.ruleId : Number(details.ruleId),
                    ProfileId: (details === null || details === void 0 ? void 0 : details.profileId) === "*" ? details.profileId : Number(details.profileId),
                    Valid: (details === null || details === void 0 ? void 0 : details.validity) === "*" ? details.validity : Boolean(details.validity)
                }
            };
        };
        this.getIdentityName = (identityID) => {
            if (identityID === "*") {
                return identityID;
            }
            let identityName = "";
            const identity = this.identities.filter(identity => identity.id === identityID);
            if (identity.length > 0) {
                identityName = identity[0].name;
            }
            return identityName;
        };
        this.getRuleName = (ruleID) => {
            if (ruleID === "*") {
                return ruleID;
            }
            let ruleDesc = "";
            const rule = this.rules.filter(rule => rule.id === ruleID);
            if (rule.length > 0) {
                ruleDesc = rule[0].description;
            }
            return ruleDesc;
        };
        this.remotes = remotes;
        this.remoteHandlers = remoteHandlers;
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationMonitors.list();
        });
    }
    listDestinationEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationDestinations.list();
        });
    }
    handleCreate(info) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = this.getCreateParams(info);
            return yield this.remotes.notificationMonitors.create(params);
        });
    }
    deleteSingle(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.remotes.notificationMonitors.delete({ id: ids[0] });
            return {
                success: !(result === null || result === void 0 ? void 0 : result.error),
                error: result === null || result === void 0 ? void 0 : result.error,
                msg: result === null || result === void 0 ? void 0 : result.msg
            };
        });
    }
    deleteEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationMonitors.delete({ id: id });
        });
    }
    getNonDeletedItems(items, entityID) {
        return items.filter((item) => item.id !== entityID);
    }
    extractFilters(monitors) {
        const uniqueSeverities = [];
        monitors.monitors.forEach((monitor) => {
            if (uniqueSeverities.includes(monitor.severity)) {
                return;
            }
            uniqueSeverities.push(monitor.severity);
        });
        uniqueSeverities.forEach((severity, idx) => {
            uniqueSeverities[idx] = { value: severity };
        });
        return uniqueSeverities;
    }
    getEntity(any) {
        return Promise.resolve(undefined);
    }
    getEntityForInmemorySelectable(any) {
        return Promise.resolve(undefined);
    }
    getFormattedEntities(monitors) {
        return monitors.monitors.map(monitor => {
            var _a, _b;
            return {
                id: monitor.id,
                name: monitor.name,
                description: monitor.description,
                interval: monitor.interval,
                destinations: monitor.destinations,
                severity: monitor.severity,
                identityID: monitor.query_parameters.IdentityId,
                identityName: this.getIdentityName(monitor.query_parameters.IdentityId),
                ruleID: monitor.query_parameters.RuleId,
                ruleDesc: this.getRuleName(monitor.query_parameters.RuleId),
                profileName: (_a = monitor.query_parameters) === null || _a === void 0 ? void 0 : _a.Profile,
                profileId: (_b = monitor.query_parameters) === null || _b === void 0 ? void 0 : _b.ProfileId,
                valid: monitor.query_parameters.Valid,
            };
        });
    }
    deleteBulk(ids) {
        return Promise.resolve(undefined);
    }
    deleteNotificationMonitor(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationMonitors.delete(params);
        });
    }
}
