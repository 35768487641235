import React from 'react';
import { useAuth0 } from '@/lib/auth0';
import { IdentitiesHandler } from '@/presentation/handlers';
import { RemoteIdentities } from '@/data/usecases';
import { makeHierarchicalBreadcrumbsFromURLPath } from '@/presentation/components/commons/breadcrumbs';
import IdentitiesList from '@/presentation/pages/identities/identities-list';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useLocation } from "react-router-dom";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
const MakeIdentitiesList = () => {
    const { getTokenSilently } = useAuth0();
    const { notifications } = useNotifications();
    const remotes = {
        identities: new RemoteIdentities(getTokenSilently)
    };
    const identitiesHandler = new IdentitiesHandler(null, remotes);
    const location = useLocation();
    const breadCrumbs = makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, content: React.createElement(IdentitiesList, { identitiesHandler: identitiesHandler }), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }) })));
};
export default MakeIdentitiesList;
