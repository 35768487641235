import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { idFilteringProperty, nameFilteringProperty, providerFilteringProperty, userDetailsCreatedColumnDefinition, userDetailsCreatedFilteringProperty, userDetailsUpdatedColumnDefinition, userDetailsUpdatedFilteringProperty } from '@/presentation/pages/common-config';
import { getLogoAsUrl } from "@/presentation/pages";
export const CloudTenantsListColumnDefinitions = addColumnSortLabels([
    {
        id: 'id',
        sortingField: 'id',
        header: 'ID',
        cell: item => {
            return item === null || item === void 0 ? void 0 : item.id;
        },
        width: 120
    },
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: (item) => {
            return item === null || item === void 0 ? void 0 : item.name;
        }
    },
    {
        id: 'provider',
        sortingField: 'provider',
        header: 'Provider',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.providerName) ? React.createElement("img", { src: getLogoAsUrl(item.providerName), width: "23px", height: "23px" }) : ''
    },
    ...userDetailsCreatedColumnDefinition,
    ...userDetailsUpdatedColumnDefinition,
]);
export const CloudTenantsListFilteringProperties = [
    idFilteringProperty,
    nameFilteringProperty,
    providerFilteringProperty,
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty,
];
export const CloudTenantsListTablePreferences = {
    pageSize: 30,
    visibleContent: ['id', 'name', 'provider'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const CloudTenantsListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'name', label: 'Name', editable: true },
            { id: 'provider', label: 'Provider', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const CloudTenantsListTablePageSizeOptions = [
    { value: 10, label: '10 Cloud Tenants' },
    { value: 30, label: '30 Cloud Tenants' },
    { value: 50, label: '50 Cloud Tenants' }
];
export const CloudTenantsDeleteStrings = {
    headerTextSingular: 'Delete Cloud Tenant Structure',
    headerTextPlural: ' Delete Cloud Tenant Structures',
    modalTextSingular: 'cloud tenant structure',
    modalTextPlural: 'cloud tenant structures',
    consentText: 'confirm'
};
export const CloudTenantsDeleteShowProp = 'name';
