import React, { useEffect, useState } from 'react';
import { useSettingsHandlers } from '@/presentation/providers/settings-handlers-provider';
import { getContainerHeight } from '@/presentation/common-utils/common-utils';
import { Button, Container, Header } from '@cloudscape-design/components-themed/components';
import { PATH_SETTINGS } from '@/presentation/common-utils/constants';
import { useNavigate } from 'react-router-dom';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { useIsMounted } from '@/presentation/hooks';
const SettingsOrganizationPolicyFunctions = ({ isUserAdmin }) => {
    const [loading, setLoading] = useState(true);
    const [funcs, setFuncs] = useState('');
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted.current) {
            setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('settingsOrgPolicyEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    useEffect(() => {
        settingsHandler.listOrganizationPolicyFunctions().then((orgFuncs) => {
            var _a, _b;
            if (isMounted.current) {
                setFuncs(((_b = (_a = orgFuncs === null || orgFuncs === void 0 ? void 0 : orgFuncs.OrganizationPolicyFunctions) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.function) || '');
            }
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    }, []);
    const tabbedContentPaddings = 60;
    return React.createElement(Container, { header: React.createElement("div", { className: 'container-header-wrapper top' },
            React.createElement(Header, { variant: "h2", actions: React.createElement(Button, { "ata-testid": "click-to-edit-org-policy-funcs", onClick: () => {
                        navigate(PATH_SETTINGS.ORG_POLICY_FUNCS_EDIT);
                    } }, "Edit") }, "Organization Policy Functions")) },
        React.createElement("div", { ref: ref, id: "settingsOrgPolicyEditor" },
            React.createElement(CodeEditor, { loading: loading, code: funcs, height: editorHeight - tabbedContentPaddings, heightType: 'px', readOnly: true, language: 'rego' })));
};
export default SettingsOrganizationPolicyFunctions;
