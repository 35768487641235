import React, { useEffect, useState } from "react";
import { Button } from "@cloudscape-design/components-themed/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as ContactCustomerSupportImg } from "@/presentation/assets/img/pages/support-team.svg";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import Support from "@/presentation/components/support/support";
import { getContainerHeight } from "@/presentation/common-utils/common-utils";
const BillingErrorPage = () => {
    const [isSupportReqLoading, setIsSupportReqLoading] = useState(false);
    const [containerHeight, setContainerHeight] = useState({});
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const handleContainerHeight = (val) => {
        setContainerHeight({ height: val - 60 });
    };
    useEffect(() => {
        if (currentRef) {
            getContainerHeight("errorPageWrapper", handleContainerHeight, 400);
        }
    }, [currentRef]);
    return (React.createElement("div", { ref: ref, id: "errorPageWrapper", className: "max-w-full text-center", style: Object.assign({}, containerHeight) },
        React.createElement("div", { className: "flex flex-col justify-center items-center gap-1 my-12" },
            React.createElement(ContactCustomerSupportImg, { className: " mx-auto w-64 h-auto mt-10" }),
            React.createElement("div", { className: "text-center mx-auto" },
                React.createElement("h1", { className: "text-3xl lg:text-4xl font-bold my-3" },
                    React.createElement("span", { className: "error-page-wrong px-1" }, "No billing data currently available")),
                React.createElement("div", null,
                    React.createElement("span", { className: "px-1" }, "Please contact your Kivera Organization Admin or"),
                    React.createElement("a", { href: "#", tabIndex: 0, onClick: () => {
                            setIsSupportReqLoading(true);
                        }, onKeyPress: () => {
                            setIsSupportReqLoading(true);
                        }, rel: "noreferrer", className: "text-[#cf4dff]", style: { cursor: "pointer" } }, "Support")),
                React.createElement("div", { className: "my-5" },
                    React.createElement(Button, { variant: "primary", onClick: () => {
                            window.location.reload();
                        }, iconSvg: React.createElement(FontAwesomeIcon, { icon: faArrowsRotate, size: "2xs" }) }, "Try again"))),
            isSupportReqLoading && React.createElement(Support, null))));
};
export default BillingErrorPage;
