import { v4 as uuidv4 } from 'uuid';
export function humanizeDate(dateStr) {
    const now = new Date(dateStr);
    const date = [now.getFullYear().toString(), (now.getMonth() + 1).toString(), now.getDate().toString()];
    const time = [now.getHours(), now.getMinutes(), now.getSeconds()];
    const timeStr = [time[0].toString(), time[1].toString(), time[2].toString()];
    // Prefix a zero to month and day
    date[1] = ('00' + date[1]).slice(-2);
    date[2] = ('00' + date[2]).slice(-2);
    // If seconds and minutes are less than 10, add a zero
    for (let i = 0; i < time.length; i++) {
        if (time[i] < 10) {
            timeStr[i] = '0' + time[i].toString();
        }
    }
    return date.join('-') + ' ' + timeStr.join(':');
}
export function healthCheckTimeHumanized(dateStr) {
    const timizedStr = new Date(dateStr);
    const day = timizedStr.getDate().toString();
    const month = timizedStr.toLocaleString('default', { month: 'long' });
    const hours = timizedStr.getHours() < 10 ? '0' + timizedStr.getHours().toString() : timizedStr.getHours().toString();
    const mins = timizedStr.getMinutes() < 10 ? '0' + timizedStr.getMinutes().toString() : timizedStr.getMinutes().toString();
    return day + ' ' + month + ' • ' + hours + ':' + mins;
}
export function capitalizeFirst(str) {
    return str && str[0].toUpperCase() + str.slice(1);
}
export function capitalizeFirstForcefully(str) {
    return str && str[0].toUpperCase() + str.slice(1).toLowerCase();
}
export const toPascalCase = (str) => {
    return str && str.toLowerCase().split(' ').map(s => capitalizeFirst(s)).join(' ');
};
// Works for most of cases
export function isObject(obj) {
    return obj instanceof Object && obj.constructor === Object;
}
export function isEmptyObject(obj) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;
}
export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}
export function areArraysEqual(array1, array2) {
    return (array1.length === array2.length) && (array1.every(val => array2.includes(val)));
}
export function isJSON(text) {
    if (!text || typeof text !== 'string') {
        return false;
    }
    try {
        const json = JSON.parse(text);
        return (typeof json === 'object');
    }
    catch (error) {
        return false;
    }
}
export function isFunction(functionToCheck) {
    return (functionToCheck && {}.toString.call(functionToCheck) === '[object Function]');
}
export function downloadBlob(blob, fileName) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement('a');
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = fileName;
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    }));
    // Remove link from body
    document.body.removeChild(link);
}
export const getRandomUUID = () => {
    return uuidv4();
};
// This function is used only for number sorting 
export const sortingComparatorByKey = (a, b, key) => {
    if (isNaN(a === null || a === void 0 ? void 0 : a[key]) || isNaN(b === null || b === void 0 ? void 0 : b[key])) {
        return 0;
    }
    const numA = parseInt(a[key], 10);
    const numB = parseInt(b[key], 10);
    if (numA < numB) {
        return -1;
    }
    else if (numA > numB) {
        return 1;
    }
    return 0;
};
export const getRandomNumber = () => {
    var _a, _b;
    // @ts-ignore
    const crypto = (window === null || window === void 0 ? void 0 : window.crypto) || (window === null || window === void 0 ? void 0 : window.msCrypto);
    var array = new Uint32Array(1);
    return (_b = (_a = crypto === null || crypto === void 0 ? void 0 : crypto.getRandomValues(array)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : 0;
};
