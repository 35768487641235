var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpStatusCode } from '@/data/protocols/http';
import axios from 'axios';
export class AxiosHttpClient {
    request(data) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            let axiosResponse;
            const abortController = new AbortController();
            try {
                axiosResponse = yield axios.request({
                    url: data.url,
                    method: data.method,
                    data: data.body,
                    headers: data.headers,
                    timeout: data === null || data === void 0 ? void 0 : data.timeout,
                    timeoutErrorMessage: 'KIVERA_HTTP_CLIENT_TIMED_OUT',
                    signal: abortController.signal
                });
            }
            catch (error) {
                const err = error;
                if ((err === null || err === void 0 ? void 0 : err.message) === 'KIVERA_HTTP_CLIENT_TIMED_OUT') {
                    abortController.abort();
                    return {
                        statusCode: HttpStatusCode.requestTimeout,
                        body: {}
                    };
                }
                return {
                    statusCode: (_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : HttpStatusCode.serverError,
                    body: (_c = err === null || err === void 0 ? void 0 : err.response) === null || _c === void 0 ? void 0 : _c.data
                };
            }
            return {
                statusCode: axiosResponse.status,
                body: axiosResponse.data
            };
        });
    }
}
