import React, { useContext } from "react";
import { RemoteLogsMonitoring, RemoteProviders, RemoteServices, } from "@/data/usecases";
import { makeLogsAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { MonitoringHandler } from "@/presentation/handlers/pages";
import { ProvidersHandler, ServicesHandler } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
const defaultContext = {
    monitoring: new MonitoringHandler(null),
    providers: new ProvidersHandler(null),
    services: new ServicesHandler(null)
};
export const MonitoringHandlersProviderContext = React.createContext(defaultContext);
export const useMonitoringHandlers = () => useContext(MonitoringHandlersProviderContext);
export const MonitoringHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const monitoringRemotes = {
        monitoring: new RemoteLogsMonitoring(makeLogsAPIUrl(), makeAuthorizeHttpClientDecorator())
    };
    const monitoringHandler = new MonitoringHandler(monitoringRemotes);
    const providerRemotes = {
        providers: new RemoteProviders(getTokenSilently)
    };
    const providersHandler = new ProvidersHandler(providerRemotes);
    const servicesRemotes = {
        services: new RemoteServices(getTokenSilently)
    };
    const servicesHandler = new ServicesHandler(servicesRemotes);
    return React.createElement(MonitoringHandlersProviderContext.Provider, { value: {
            monitoring: monitoringHandler,
            providers: providersHandler,
            services: servicesHandler
        } }, children);
};
