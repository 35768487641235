import React, { useEffect, useState } from 'react';
import { Badge, Box, ColumnLayout, Container, Header, SpaceBetween, Table, Tabs } from '@cloudscape-design/components-themed/components';
import { RuleEvaluationProfilesColumnDefinitions, RuleEvaluationRulesColumnDefinitions } from '@/presentation/pages/monitoring/monitoring-list-split-panel-config';
import { getContainerHeight, getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { RULES_SIMULATOR_LIST_EMPTY_PANEL_CONTENT } from '@/presentation/pages/rules-simulator/rules-simulator-list-split-panel-config';
import { makeRuleStateChange, } from '@/presentation/pages/rules-simulator/rules-simulator-list-config';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
const LogDetails = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const tmpStamp = (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.timestamp) === null || _b === void 0 ? void 0 : _b.toString();
    const timestamp = getLocaleTimestamp(tmpStamp);
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Event Details") },
            React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Provider"),
                        React.createElement(Box, null, (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.provider)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Service"),
                        React.createElement(Box, null, (_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.service)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "API Action"),
                        React.createElement(Box, null, (_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.action))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Source"),
                        React.createElement(Box, null, (_f = props === null || props === void 0 ? void 0 : props.data) === null || _f === void 0 ? void 0 : _f.proxy)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Rule State Change"),
                        React.createElement(Box, null, makeRuleStateChange('split-panel-', (_g = props === null || props === void 0 ? void 0 : props.data) === null || _g === void 0 ? void 0 : _g.ruleStateChange)))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Proxy Mode"),
                        React.createElement(Box, null,
                            React.createElement(Badge, { color: 'grey' }, (_h = props === null || props === void 0 ? void 0 : props.data) === null || _h === void 0 ? void 0 : _h.proxyMode))),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Timestamp"),
                        React.createElement(Box, null, timestamp))))));
};
const RuleEvaluation = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    let profiles = [];
    if ((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.ruleSimulation) === null || _b === void 0 ? void 0 : _b.Data) === null || _c === void 0 ? void 0 : _c.Profiles) {
        const rulesProfiles = ((_f = (_e = (_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.ruleSimulation) === null || _e === void 0 ? void 0 : _e.Data) === null || _f === void 0 ? void 0 : _f.Profiles) || [];
        profiles = rulesProfiles;
    }
    const rules = [
        {
            id: (_j = (_h = (_g = props === null || props === void 0 ? void 0 : props.data) === null || _g === void 0 ? void 0 : _g.ruleSimulation) === null || _h === void 0 ? void 0 : _h.RuleEval) === null || _j === void 0 ? void 0 : _j.RuleId,
            service: (_m = (_l = (_k = props === null || props === void 0 ? void 0 : props.data) === null || _k === void 0 ? void 0 : _k.ruleSimulation) === null || _l === void 0 ? void 0 : _l.RuleEval) === null || _m === void 0 ? void 0 : _m.RuleService,
            description: (_q = (_p = (_o = props === null || props === void 0 ? void 0 : props.data) === null || _o === void 0 ? void 0 : _o.ruleSimulation) === null || _p === void 0 ? void 0 : _p.RuleEval) === null || _q === void 0 ? void 0 : _q.RuleDescription,
            provider: (_t = (_s = (_r = props === null || props === void 0 ? void 0 : props.data) === null || _r === void 0 ? void 0 : _r.ruleSimulation) === null || _s === void 0 ? void 0 : _s.RuleEval) === null || _t === void 0 ? void 0 : _t.RuleProvider,
            processResult: (_w = (_v = (_u = props === null || props === void 0 ? void 0 : props.data) === null || _u === void 0 ? void 0 : _u.ruleSimulation) === null || _v === void 0 ? void 0 : _v.RuleEval) === null || _w === void 0 ? void 0 : _w.RuleProcessResult,
            profilesNames: '',
        }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement(SpaceBetween, { size: 'l' },
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Rule") },
                React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                    React.createElement(Box, null,
                        React.createElement(Table, { empty: React.createElement(Box, { textAlign: "center", color: "inherit" },
                                React.createElement(Box, { padding: { bottom: 's' }, variant: "p", color: "inherit" }, "No rule to display.")), variant: 'embedded', columnDefinitions: RuleEvaluationRulesColumnDefinitions, items: rules, sortingDisabled: true })))),
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" },
                    "Profiles (",
                    profiles.length,
                    ")") },
                React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                    React.createElement(Box, null,
                        React.createElement(Table, { empty: React.createElement(Box, { textAlign: "center", color: "inherit" },
                                React.createElement(Box, { padding: { bottom: 's' }, variant: "p", color: "inherit" }, "No profiles were involved in this event")), variant: 'embedded', columnDefinitions: RuleEvaluationProfilesColumnDefinitions, items: profiles, sortingDisabled: true }))))));
};
const LogEvent = (props) => {
    var _a, _b;
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('rulesSimulatorEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, id: "rulesSimulatorEditor" },
            React.createElement(CodeEditor, { code: JSON.stringify((_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.ruleSimulation) !== null && _b !== void 0 ? _b : {}, null, 2), height: editorHeight, heightType: 'px', readOnly: true, language: 'json' })));
};
const PolicySuggestion = (props) => {
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "This feature is in Beta. Please contact Admin for further info.") }));
};
export const getRulesSimulatorListSplitPanel = items => {
    if (!items.length) {
        return RULES_SIMULATOR_LIST_EMPTY_PANEL_CONTENT;
    }
    const item = items[0];
    const tabs = [
        {
            label: 'Rule Evaluations',
            id: 'rule_evaluations',
            content: React.createElement(RuleEvaluation, { data: item })
        },
        {
            label: 'Log Event',
            id: 'log_event',
            content: React.createElement(LogEvent, { data: item })
        },
        {
            label: 'Policy Suggestion ᵇᵉᵗᵃ',
            id: 'policy_suggestion',
            disabled: true
        }
    ];
    return {
        header: `xkid: ${item.xkid}`,
        body: (React.createElement(React.Fragment, null,
            React.createElement(SpaceBetween, { size: 'xl' },
                React.createElement(LogDetails, { data: item }),
                React.createElement(Tabs, { tabs: tabs, ariaLabel: "Log details" }))))
    };
};
