import React, { useCallback, useEffect, useState } from "react";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { Header } from "@cloudscape-design/components-themed/components";
import SettingsCloudTenantForm from "@/presentation/pages/settings/cloud-tenants/settings-cloud-tenant-form";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { PROVIDER_AWS } from "@/presentation/common-utils/constants";
import { getLogoAsUrl, getProviderDescription } from "@/presentation/pages";
import { useAuth0 } from "@/lib/auth0";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import ContentFormLayoutWrapper from "@/presentation/components/content-wrapper/content-form-layout-wrapper";
const SettingsCloudTenantFormWrapper = (props) => {
    var _a, _b, _c;
    const { setErrorStatus } = useErrorBoundaryContext();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [cloudTenantsInfo, setCloudTenantsInfo] = useState(undefined);
    const [providersList, setProvidersList] = useState(undefined);
    const { providers, tenants } = useSettingsHandlers();
    const providersHandler = providers;
    const cloudTenantsHandler = tenants;
    const { user, rawToken } = useAuth0();
    const orgID = getOrgIDFromToken(rawToken);
    const activeOrg = user.memberships.filter(membership => { var _a; return ((_a = membership === null || membership === void 0 ? void 0 : membership.organization) === null || _a === void 0 ? void 0 : _a.id) === orgID; });
    const orgName = (_c = (_b = (_a = activeOrg[0]) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.company_name) !== null && _c !== void 0 ? _c : '';
    const isMounted = useIsMounted();
    const onCloudTenantsInfoChange = useCallback((stateKey, newStepState) => {
        setCloudTenantsInfo(Object.assign(Object.assign({}, cloudTenantsInfo), { [stateKey]: Object.assign(Object.assign({}, cloudTenantsInfo[stateKey]), newStepState) }));
    }, [cloudTenantsInfo]);
    const filterAWSProviderOnly = (providers) => {
        var _a, _b;
        const awsProvider = (_b = (_a = providers === null || providers === void 0 ? void 0 : providers.Providers) === null || _a === void 0 ? void 0 : _a.filter(pro => (pro === null || pro === void 0 ? void 0 : pro.name) === PROVIDER_AWS)) !== null && _b !== void 0 ? _b : [];
        return {
            Providers: awsProvider
        };
    };
    const createHandler = () => {
        providersHandler.listEntity()
            .then((providers) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            // support only AWS provider now
            const filteredProvider = filterAWSProviderOnly(providers);
            if (isMounted.current) {
                setProvidersList(filteredProvider);
            }
            let provider = undefined;
            if (((_a = filteredProvider === null || filteredProvider === void 0 ? void 0 : filteredProvider.Providers) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                provider = {
                    label: (_d = (_c = (_b = filteredProvider === null || filteredProvider === void 0 ? void 0 : filteredProvider.Providers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                    value: String((_g = (_f = (_e = filteredProvider === null || filteredProvider === void 0 ? void 0 : filteredProvider.Providers) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : 0),
                    iconUrl: getLogoAsUrl((_j = (_h = filteredProvider === null || filteredProvider === void 0 ? void 0 : filteredProvider.Providers) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.name),
                    description: getProviderDescription((_l = (_k = filteredProvider === null || filteredProvider === void 0 ? void 0 : filteredProvider.Providers) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.name)
                };
            }
            const tenantInfo = {
                details: {
                    provider: provider,
                    name: '',
                    orgID: orgID,
                    orgName: orgName,
                    tenantStructure: {}
                }
            };
            if (isMounted.current) {
                setCloudTenantsInfo(tenantInfo);
            }
        }).catch((e) => {
            console.error(e);
        }).catch(console.error)
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        if (props.isCreate) {
            createHandler();
        }
        else if (props.isEdit) {
            if ((params === null || params === void 0 ? void 0 : params.tenantID) && parseInt(params === null || params === void 0 ? void 0 : params.tenantID, 10) !== 0) {
                const tenantID = parseInt(params === null || params === void 0 ? void 0 : params.tenantID, 10);
                cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.get({
                    id: tenantID
                }).then(tenant => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                    if ((_a = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _a === void 0 ? void 0 : _a.id) {
                        const provider = {
                            label: (_d = (_c = (_b = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _b === void 0 ? void 0 : _b.Provider) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                            value: String((_g = (_f = (_e = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _e === void 0 ? void 0 : _e.Provider) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : 0),
                            iconUrl: getLogoAsUrl((_j = (_h = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _h === void 0 ? void 0 : _h.Provider) === null || _j === void 0 ? void 0 : _j.name),
                            description: getProviderDescription((_l = (_k = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _k === void 0 ? void 0 : _k.Provider) === null || _l === void 0 ? void 0 : _l.name)
                        };
                        const tenantInfo = {
                            details: {
                                id: (_o = (_m = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _m === void 0 ? void 0 : _m.id) !== null && _o !== void 0 ? _o : 0,
                                name: (_q = (_p = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _p === void 0 ? void 0 : _p.name) !== null && _q !== void 0 ? _q : '',
                                provider: provider,
                                orgID: orgID,
                                orgName: orgName,
                                tenantStructure: (_s = (_r = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _r === void 0 ? void 0 : _r.tenant_structure) !== null && _s !== void 0 ? _s : {}
                            }
                        };
                        if (isMounted.current) {
                            setCloudTenantsInfo(tenantInfo);
                        }
                    }
                    else {
                        setErrorStatus(true);
                    }
                }).catch((err) => {
                    console.error(err);
                    // something went wrong, push to oops
                    setErrorStatus(true);
                }).finally(() => {
                    if (isMounted.current) {
                        setLoading(false);
                    }
                });
            }
            else {
                //  we know this is edit, but something is not right, push to oops
                setErrorStatus(true);
            }
        }
        else {
            // navigate to oops
            setErrorStatus(true);
        }
    }, []);
    if (loading || (props.isEdit && !cloudTenantsInfo) || ((props === null || props === void 0 ? void 0 : props.isCreate) && !providersList)) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return React.createElement(ContentWrapper, { testid: "settings-cloud-tenant-create-edit-page" },
        React.createElement(ContentFormLayoutWrapper, { header: React.createElement(Header, { variant: "h1" },
                props.isCreate ? 'Create ' : 'Edit ',
                " Cloud Tenant Structure") },
            React.createElement(SettingsCloudTenantForm, Object.assign({ cloudTenantsInfo: cloudTenantsInfo, providersList: providersList, onChange: (newStepState) => {
                    onCloudTenantsInfoChange("details", newStepState);
                } }, props))));
};
export default SettingsCloudTenantFormWrapper;
