import { Button, Form, SpaceBetween, Link } from "@cloudscape-design/components-themed/components";
import RulesDetails from "@/presentation/pages/rules/rules-details";
import React, { useCallback, useState, useEffect } from "react";
import { useRulesHandlers } from "@/presentation/providers";
import { useNavigate } from "react-router-dom";
import { PATHS, PATH_RULES } from "@/presentation/common-utils/constants";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { ComplianceMappingsErrorCode } from '@/presentation/pages/rules/rules-types';
import { makeNotification } from "@/presentation/pages";
import Skeleton from "react-loading-skeleton";
import RulesPolicy from "@/presentation/pages/rules/rules-policy";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import FormTags from '@/presentation/components/tags/form-tags';
import FormAttributeEditorWithSelectors from "@/presentation/components/attribute-editor/form-attribute-editor-with-selectors";
import { getRandomUUID } from "@/lib/misc/utils";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import { useAppContext } from "@/presentation/providers/app-context-provider";
import { makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
const RulesForm = (props) => {
    var _a, _b;
    const { setErrorStatus } = useErrorBoundaryContext();
    const [providersList, setProvidersList] = useState();
    const [providerGlobalServicesCounts, setProviderGlobalServicesCounts] = useState();
    const [loading, setLoading] = useState(true);
    const [rulesInfo, setRulesInfo] = useState(props.rulesInfo);
    const { rules, providers, services, policies, descriptionValidator, complianceMappingsValidator, complianceMappings } = useRulesHandlers();
    const navigate = useNavigate();
    const rulesHandler = rules;
    const servicesHandler = services;
    const providersHandler = providers;
    const policiesHandler = policies;
    const complianceMappingsHandler = complianceMappings;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [policyError, setPolicyError] = useState(false);
    const [policySuccess, setPolicySuccess] = useState(false);
    const [policyValidationMessage, setPolicyValidationMessage] = useState("");
    const [editorLoading, setEditorLoading] = useState(true);
    const [providerError, setProviderError] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [tagsError, setTagsError] = useState(false);
    const [attributeError, setAttributeError] = React.useState(false);
    const [recheckAttributeErrors, setRecheckAttributeErrors] = React.useState(false);
    const [uniqueFrameworks, setUniqueFrameworks] = useState([]);
    const [allControls, setAllControls] = useState([]);
    const isMounted = useIsMounted();
    const { setIsFormDirty, setDirtyFormResourceName } = useAppContext();
    const onRulesInfoChange = useCallback((stateKey, newStepState) => {
        setRulesInfo(Object.assign(Object.assign({}, rulesInfo), { [stateKey]: Object.assign(Object.assign({}, rulesInfo[stateKey]), newStepState) }));
    }, [rulesInfo]);
    useEffect(() => {
        if (isMounted.current) {
            setRulesInfo(props.rulesInfo);
        }
    }, [props.rulesInfo]);
    const makeSuccessNotification = (message) => {
        const msg = "Rules  " + (props.isCreate || props.isClone ? "Created" : "Updated") + " Successfully";
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to " +
            (props.isCreate || props.isClone ? "create" : "update") +
            ". Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const makePolicyErrorNotification = (message) => {
        const msg = "Policy is Invalid. Please check the inputs.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onCancel = () => {
        if (props.isEdit) {
            navigate(PATHS.RULES + '/' + rulesInfo.details.id);
        }
        else {
            navigate(PATHS.RULES);
        }
    };
    const loadComplianceMappings = () => {
        complianceMappingsHandler.listComplianceMappingsForCurrentOrg().then(listMappings => {
            const uniqFrameworksAndControls = complianceMappingsHandler.getUniqueComplianceFrameworksAndControls(listMappings === null || listMappings === void 0 ? void 0 : listMappings.ComplianceMappings);
            let controls = [];
            const frameworks = Object.keys(uniqFrameworksAndControls).map((framework) => {
                var _a, _b, _c;
                const options = (_c = (_b = (_a = uniqFrameworksAndControls[framework]) === null || _a === void 0 ? void 0 : _a.filter(val => !!val)) === null || _b === void 0 ? void 0 : _b.map(control => {
                    return {
                        id: getRandomUUID(),
                        key: framework,
                        value: control,
                        label: control
                    };
                })) !== null && _c !== void 0 ? _c : [];
                controls = [...controls, ...options];
                return {
                    id: getRandomUUID(),
                    value: framework,
                    label: framework
                };
            });
            if (isMounted.current) {
                setUniqueFrameworks(frameworks);
                setAllControls(controls);
            }
        })
            .catch(({ response }) => {
            handleErrorResponse(response);
        })
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        providersHandler.listEntity()
            .then((listProviders) => {
            var _a;
            if ((listProviders === null || listProviders === void 0 ? void 0 : listProviders.Providers) && isMounted.current) {
                setProvidersList(listProviders);
                let counts = {};
                (_a = listProviders === null || listProviders === void 0 ? void 0 : listProviders.Providers) === null || _a === void 0 ? void 0 : _a.map(provider => {
                    var _a, _b, _c;
                    if (provider === null || provider === void 0 ? void 0 : provider.id) {
                        counts[provider === null || provider === void 0 ? void 0 : provider.id] = (_c = (_b = (_a = provider === null || provider === void 0 ? void 0 : provider.GlobalServices_aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count) !== null && _c !== void 0 ? _c : 0;
                    }
                });
                setProviderGlobalServicesCounts(counts);
            }
            else {
                if (isMounted.current) {
                    setLoading(false);
                }
                setErrorStatus(true);
            }
        })
            .catch(({ response }) => {
            handleErrorResponse(response);
        })
            .finally(loadComplianceMappings);
        return () => {
            console.log("cleanup");
        };
    }, []);
    const validatePolicy = () => {
        var _a, _b;
        const trimPolicy = ((_b = (_a = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.policyConfig) === null || _a === void 0 ? void 0 : _a.policy) === null || _b === void 0 ? void 0 : _b.trim()) || null;
        if (trimPolicy && trimPolicy !== "") {
            if (isMounted.current) {
                setEditorLoading(true);
            }
            const toBeValidatedPolicy = props.globalPolicyFunc + '\r\n' + props.orgPolicyFunc + '\r\n' + rulesInfo.policyConfig.policy;
            policiesHandler.validatePolicy(toBeValidatedPolicy).then((validationResult) => {
                var _a;
                if (isMounted.current) {
                    setEditorLoading(false);
                    setPolicyValidationMessage((_a = validationResult === null || validationResult === void 0 ? void 0 : validationResult.data) === null || _a === void 0 ? void 0 : _a.message);
                    if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.error) {
                        setPolicyError(true);
                        setPolicySuccess(false);
                    }
                    else {
                        setPolicyError(false);
                        setPolicySuccess(true);
                    }
                }
            });
        }
        else {
            if (isMounted.current) {
                setPolicyValidationMessage("");
                setPolicyError(true);
                setPolicySuccess(false);
            }
        }
    };
    const hanldeDirtyFormChange = (value = true) => {
        setIsFormDirty(value);
        setDirtyFormResourceName(value ? 'policy' : null);
    };
    const onSubmit = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const trimPolicy = ((_b = (_a = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.policyConfig) === null || _a === void 0 ? void 0 : _a.policy) === null || _b === void 0 ? void 0 : _b.trim()) || null;
        const dValid = descriptionValidator === null || descriptionValidator === void 0 ? void 0 : descriptionValidator.validate(rulesInfo.details.description);
        const providerErrorStatus = ((_d = (_c = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _c === void 0 ? void 0 : _c.provider) === null || _d === void 0 ? void 0 : _d.constructor) === Object && Object.keys((_e = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _e === void 0 ? void 0 : _e.provider).length > 0;
        const serviceErrorStatus = ((_g = (_f = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _f === void 0 ? void 0 : _f.service) === null || _g === void 0 ? void 0 : _g.constructor) === Object && Object.keys((_h = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _h === void 0 ? void 0 : _h.service).length > 0;
        const actionData = ((_k = (_j = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _j === void 0 ? void 0 : _j.action) === null || _k === void 0 ? void 0 : _k.length) > 0;
        const getFormattedConfig = [...new Set((_m = (_l = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _l === void 0 ? void 0 : _l.config) === null || _m === void 0 ? void 0 : _m.map(item => item.propertyKey))];
        if ((dValid === null || dValid === void 0 ? void 0 : dValid.valid) && !tagsError && !attributeError && providerErrorStatus && serviceErrorStatus && (trimPolicy && trimPolicy !== "" && getFormattedConfig.length === 3 && actionData)) {
            setProviderError(false);
            setServiceError(false);
            setDescError(false);
            setEditorLoading(true);
            const toBeValidatedPolicy = props.globalPolicyFunc + '\r\n' + props.orgPolicyFunc + '\r\n' + rulesInfo.policyConfig.policy;
            policiesHandler.validatePolicy(toBeValidatedPolicy).then((validationResult) => {
                var _a;
                if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.error) {
                    if (isMounted.current) {
                        setEditorLoading(false);
                        setPolicyValidationMessage((_a = validationResult === null || validationResult === void 0 ? void 0 : validationResult.data) === null || _a === void 0 ? void 0 : _a.message);
                        setPolicyError(true);
                        setPolicySuccess(false);
                    }
                    pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('rule', (props.isCreate || props.isClone) ? 'create' : 'update')));
                }
                else {
                    if (isMounted.current) {
                        setPolicyError(false);
                        setLoading(true);
                    }
                    if (props.isEdit) {
                        // All good lets update
                        rulesHandler.handleUpdate(rulesInfo).then(result => {
                            var _a, _b, _c, _d, _e, _f;
                            if ((_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.update_Rules) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) {
                                pushNotifications(makeSuccessNotification());
                                hanldeDirtyFormChange(false);
                                navigate(PATH_RULES.VIEW + '/' + ((_f = (_e = (_d = result === null || result === void 0 ? void 0 : result.update_Rules) === null || _d === void 0 ? void 0 : _d.returning) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.id));
                            }
                            else {
                                pushNotifications(makeErrorNotification());
                            }
                        }).catch(({ response }) => {
                            handleErrorResponse(response);
                        }).finally(() => {
                            if (isMounted.current) {
                                setLoading(false);
                            }
                        });
                    }
                    else {
                        // All good lets create
                        rulesHandler.handleCreate(rulesInfo).then(result => {
                            var _a, _b, _c;
                            const createdId = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Rules) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id;
                            if (createdId) {
                                const navigateLink = React.createElement(Link, { fontSize: "inherit", color: "inverted", href: `${PATH_RULES.VIEW}/${createdId}` },
                                    "#",
                                    createdId);
                                const messsge = (React.createElement("span", null,
                                    "Rule ",
                                    navigateLink,
                                    " Created Successfully"));
                                pushNotifications(makeSuccessNotification(messsge));
                                hanldeDirtyFormChange(false);
                                navigate(PATHS.RULES);
                            }
                            else {
                                pushNotifications(makeErrorNotification());
                            }
                        }).catch(({ response }) => {
                            handleErrorResponse(response);
                        }).finally(() => {
                            if (isMounted.current) {
                                setLoading(false);
                            }
                        });
                    }
                }
            }).catch((e) => {
                pushNotifications(makePolicyErrorNotification('Unable to validate Policy. Please check again later.'));
            });
        }
        else {
            if (isMounted.current) {
                setRecheckAttributeErrors(!recheckAttributeErrors);
                setPolicyValidationMessage("");
                setPolicyError(!(trimPolicy && trimPolicy !== ""));
                setPolicySuccess(false);
                setProviderError(!providerErrorStatus);
                setServiceError(!serviceErrorStatus);
                setDescError(!(dValid === null || dValid === void 0 ? void 0 : dValid.valid));
            }
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('rule', (props.isCreate || props.isClone) ? 'create' : 'update')));
        }
    };
    const onTagsChange = (newtagsInfo) => {
        setTagsError(!(newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.valid));
        onRulesInfoChange("tags", { editorTags: (newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.tags) || [] });
    };
    const handleComplianceMappingChange = (mappings) => {
        onRulesInfoChange("compliance", { mappings: mappings || [] });
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'form-container' },
            React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: onCancel }, props.i18nStrings.cancelButton),
                    React.createElement(Button, { variant: "primary", onClick: onSubmit }, props.i18nStrings.submitButton)) },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(RulesDetails, { info: rulesInfo, isCreate: props.isCreate, isEdit: props.isEdit, isClone: props.isClone, onChange: (newStepState) => {
                            onRulesInfoChange("details", newStepState);
                        }, spacing: "l", providersList: providersList, providerGlobalServicesCounts: providerGlobalServicesCounts, servicesList: props.servicesList, servicesHandler: servicesHandler, providersHandler: providersHandler, rulesHandler: rulesHandler, ruleDetails: props.ruleDetails, validation: {
                            providerError,
                            serviceError,
                            descError,
                        } }),
                    React.createElement(RulesPolicy, { info: rulesInfo, policyError: policyError, editorLoading: editorLoading, onChange: (newStepState) => {
                            onRulesInfoChange("policyConfig", newStepState);
                            hanldeDirtyFormChange(true);
                        }, validatePolicy: validatePolicy, policySuccess: policySuccess, policyValidationMessage: policyValidationMessage, globalPolicyFunc: props.globalPolicyFunc, orgPolicyFunc: props.orgPolicyFunc }),
                    React.createElement(FormAttributeEditorWithSelectors, { items: ((_a = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.compliance) === null || _a === void 0 ? void 0 : _a.mappings) || [], onChange: (mappings) => {
                            handleComplianceMappingChange(mappings);
                        }, onError: (err) => setAttributeError(err), scrollIntoViewHash: "compliance-mappings", addButtonText: "Add Compliance Mapping", removeButtonText: "Remove", itemLimit: 20, headerLabel: "Manage Compliance Mappings", attributeKeyValueValidator: complianceMappingsValidator, attributeKeyLabel: "Framework", attributeValueLabel: React.createElement("span", null,
                            "Control Identifier - ",
                            React.createElement("i", null, "optional")), attributeErrorCodeMappings: ComplianceMappingsErrorCode, recheckErrors: recheckAttributeErrors, itemLimitEntitySuffixSingle: "compliance mapping", itemLimitEntitySuffixPlural: "compliance mappings", keySelectOptions: uniqueFrameworks, valueSelectOptions: allControls, keyPlaceHolder: "Enter framework", valuePlaceHolder: "Enter control identifier" }),
                    React.createElement(FormTags, { tags: ((_b = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.tags) === null || _b === void 0 ? void 0 : _b.editorTags) || [], onChange: (newTags) => {
                            onTagsChange(newTags);
                        } })))));
};
export default RulesForm;
