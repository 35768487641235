import { Alert, Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween, } from "@cloudscape-design/components-themed/components";
import React, { useEffect, useState } from "react";
const TokensModal = (props) => {
    var _a, _b, _c, _d;
    const [leaveInputText, setTokenInputText] = useState("");
    const inputMatchesConsentText = leaveInputText === props.consentText;
    useEffect(() => {
        setTokenInputText("");
    }, [props.visible]);
    const handleTokenSubmit = (event) => {
        event.preventDefault();
        if (props.consentText) {
            props.onRevoke();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: props.headerText, closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
                React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                    React.createElement(Button, { variant: "primary", disabled: !inputMatchesConsentText, onClick: props.onRevoke }, "Submit"))) },
            React.createElement(SpaceBetween, { size: "m" },
                React.createElement(Box, { variant: "span" },
                    "Revoke API key",
                    React.createElement(Box, { variant: "span", fontWeight: "bold" },
                        React.createElement("span", { className: "pl-[3px]" }, ((_b = (_a = props === null || props === void 0 ? void 0 : props.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.description) || '')),
                    React.createElement("span", { className: "pl-[3px]" },
                        "with ID ", (_d = (_c = props.items) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 :
                        _d[props.itemRevokeShowProp],
                        "?")),
                React.createElement(Alert, { type: "warning" }, "Proceeding with this action will permanently revoke this API Key. This action cannot be undone."),
                React.createElement(Box, null, "To avoid accidental revocation, we ask you to provide additional written consent"),
                React.createElement(ColumnLayout, { columns: 2 },
                    React.createElement("form", { onSubmit: handleTokenSubmit },
                        React.createElement(FormField, { label: `Type "${props.consentText}" to agree` },
                            React.createElement(Input, { name: props.headerText, autoComplete: 'off', placeholder: props.consentText, onChange: (event) => setTokenInputText(event.detail.value), value: leaveInputText, ariaRequired: true, ariaLabelledby: "leave-confirmation-label" }))))))));
};
export default TokensModal;
