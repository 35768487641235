import React from "react";
import { Box, Button, Modal, SpaceBetween, } from "@cloudscape-design/components-themed/components";
const DownloadCredentials = (props) => {
    return (React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: "Download Credentials", closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
            React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Cancel"),
                React.createElement(Button, { onClick: props.onDownload, variant: "primary" }, "Download"))) },
        React.createElement(SpaceBetween, { size: "m" },
            React.createElement(Box, null, "Click Download to download the proxy credentials in JSON format"))));
};
export default DownloadCredentials;
