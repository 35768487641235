export class QueryHandler {
    constructor() {
        this.getProviderAndServicesAggregate = (buckets) => {
            return {
                "providers": {
                    "terms": {
                        "field": "data.Provider"
                    },
                    "aggs": {
                        "services": {
                            "terms": {
                                "field": "data.Service"
                            }
                        }
                    }
                },
                "count": {
                    "auto_date_histogram": {
                        "field": "timestamp",
                        "buckets": buckets
                    }
                }
            };
        };
    }
}
