import React, { useCallback, useEffect, useState } from "react";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { Header } from "@cloudscape-design/components-themed/components";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import SettingsDomainAclsForm from '@/presentation/pages/settings/domain-acls/settings-domain-acls-form';
import { getEmptyAttribute } from '@/presentation/pages';
import { capitalizeFirst, getRandomUUID } from '@/lib/misc/utils';
import ContentFormLayoutWrapper from "@/presentation/components/content-wrapper/content-form-layout-wrapper";
const SettingsDomainACLFormWrapper = (props) => {
    const { setErrorStatus } = useErrorBoundaryContext();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [domainACLInfo, setDomainACLInfo] = useState(undefined);
    const { domainACL } = useSettingsHandlers();
    const domainACLHandler = domainACL;
    const isMounted = useIsMounted();
    const onDomainACLInfoChange = useCallback((stateKey, newStepState) => {
        setDomainACLInfo(Object.assign(Object.assign({}, domainACLInfo), { [stateKey]: Object.assign(Object.assign({}, domainACLInfo[stateKey]), newStepState) }));
    }, [domainACLInfo]);
    const createHandler = () => {
        const domainACLsFormInfo = {
            details: {
                name: '',
                default_action: undefined,
                domains: {
                    mappings: [getEmptyAttribute()]
                }
            }
        };
        if (isMounted.current) {
            setDomainACLInfo(domainACLsFormInfo);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (props.isCreate) {
            createHandler();
        }
        else if (props.isEdit) {
            if ((params === null || params === void 0 ? void 0 : params.domainACLID) && parseInt(params === null || params === void 0 ? void 0 : params.domainACLID, 10) !== 0) {
                const domainACLID = parseInt(params === null || params === void 0 ? void 0 : params.domainACLID, 10);
                domainACLHandler === null || domainACLHandler === void 0 ? void 0 : domainACLHandler.get({
                    id: domainACLID
                }).then(domainACL => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                    if ((_a = domainACL === null || domainACL === void 0 ? void 0 : domainACL.DomainAcls_by_pk) === null || _a === void 0 ? void 0 : _a.id) {
                        const mappings = (_d = (_c = (_b = domainACL === null || domainACL === void 0 ? void 0 : domainACL.DomainAcls_by_pk) === null || _b === void 0 ? void 0 : _b.DomainAclEntries) === null || _c === void 0 ? void 0 : _c.map((entry) => {
                            var _a;
                            const attribute = {
                                id: getRandomUUID(),
                                key: entry === null || entry === void 0 ? void 0 : entry.domain,
                                value: capitalizeFirst((_a = String(entry === null || entry === void 0 ? void 0 : entry.action)) === null || _a === void 0 ? void 0 : _a.toLowerCase())
                            };
                            return attribute;
                        })) !== null && _d !== void 0 ? _d : [];
                        const defaultActionStr = capitalizeFirst((_f = String((_e = domainACL === null || domainACL === void 0 ? void 0 : domainACL.DomainAcls_by_pk) === null || _e === void 0 ? void 0 : _e.default_action)) === null || _f === void 0 ? void 0 : _f.toLowerCase());
                        const defaultAction = {
                            label: defaultActionStr,
                            value: defaultActionStr,
                        };
                        const aclInfo = {
                            details: {
                                id: (_h = (_g = domainACL === null || domainACL === void 0 ? void 0 : domainACL.DomainAcls_by_pk) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : 0,
                                name: (_k = (_j = domainACL === null || domainACL === void 0 ? void 0 : domainACL.DomainAcls_by_pk) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : '',
                                default_action: defaultAction,
                                domains: {
                                    mappings: mappings
                                }
                            }
                        };
                        if (isMounted.current) {
                            setDomainACLInfo(aclInfo);
                        }
                    }
                    else {
                        setErrorStatus(true);
                    }
                }).catch((err) => {
                    console.error(err);
                    // something went wrong, push to oops
                    setErrorStatus(true);
                }).finally(() => {
                    if (isMounted.current) {
                        setLoading(false);
                    }
                });
            }
            else {
                //  we know this is edit, but something is not right, push to oops
                setErrorStatus(true);
            }
        }
        else {
            // navigate to oops
            setErrorStatus(true);
        }
    }, []);
    if (loading || (props.isEdit && !domainACLInfo)) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return React.createElement(ContentWrapper, { testid: "settings-domain-acls-create-edit-page" },
        React.createElement(ContentFormLayoutWrapper, { header: React.createElement(Header, { variant: "h1" },
                props.isCreate ? 'Create ' : 'Edit ',
                " Domain ACL") },
            React.createElement(SettingsDomainAclsForm, Object.assign({ domainACLInfo: domainACLInfo, onChange: (newStepState) => {
                    onDomainACLInfoChange("details", newStepState);
                } }, props))));
};
export default SettingsDomainACLFormWrapper;
