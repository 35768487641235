export var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["ok"] = 200] = "ok";
    HttpStatusCode[HttpStatusCode["noContent"] = 204] = "noContent";
    HttpStatusCode[HttpStatusCode["multiStatus"] = 207] = "multiStatus";
    HttpStatusCode[HttpStatusCode["badRequest"] = 400] = "badRequest";
    HttpStatusCode[HttpStatusCode["unauthorized"] = 401] = "unauthorized";
    HttpStatusCode[HttpStatusCode["forbidden"] = 403] = "forbidden";
    HttpStatusCode[HttpStatusCode["notFound"] = 404] = "notFound";
    HttpStatusCode[HttpStatusCode["requestTimeout"] = 408] = "requestTimeout";
    HttpStatusCode[HttpStatusCode["conflict"] = 409] = "conflict";
    HttpStatusCode[HttpStatusCode["serverError"] = 500] = "serverError";
})(HttpStatusCode || (HttpStatusCode = {}));
export const GENERIC_FORBIDDEN_MESSAGE = 'You do not have permission to perform this action. Please contact your administrator.';
export const GENERIC_MULTISTATUS_MESSAGE = 'Some actions failed';
export const GENERIC_CONFLICT_MESSAGE = 'Unable to perform this action. Please enter a unique resource.';
export const GENERIC_TIMEOUT_MESSAGE = 'The action that you were trying to perform timed out. Please check the status before retrying.';
export const GENERIC_UNKNOWN_ERROR_MESSAGE = 'Unable to proceed with this request, please try again later';
export const GENERIC_INVALID_REQUEST_MESSAGE = 'You do not have permission to perform this action. Please contact your administrator.';
export const GENERIC_SERVER_ERROR_MESSAGE = 'You do not have permission to perform this action. Please contact your administrator.';
const makeSuccessModel = (body) => {
    return {
        error: false,
        data: body
    };
};
const makeErrorModel = (type, code, errorMsg) => {
    return {
        error: true,
        code: code,
        msg: errorMsg,
        type
    };
};
export const HandleHttpResponse = (httpResponse) => {
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return makeSuccessModel(httpResponse.body);
        case HttpStatusCode.multiStatus:
            return makeSuccessModel(httpResponse.body);
        case HttpStatusCode.forbidden:
            return makeErrorModel("AccessDenied", HttpStatusCode.forbidden, GENERIC_FORBIDDEN_MESSAGE);
        case HttpStatusCode.requestTimeout:
            return makeErrorModel("TimedOut", HttpStatusCode.requestTimeout, GENERIC_TIMEOUT_MESSAGE);
        case HttpStatusCode.badRequest:
            return makeErrorModel("InvalidRequest", HttpStatusCode.badRequest, GENERIC_INVALID_REQUEST_MESSAGE);
        case HttpStatusCode.serverError:
            return makeErrorModel("ServerError", HttpStatusCode.serverError, GENERIC_SERVER_ERROR_MESSAGE);
        default:
            return makeErrorModel("UnknownException", HttpStatusCode.forbidden, GENERIC_UNKNOWN_ERROR_MESSAGE);
    }
};
