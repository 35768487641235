import React, { useContext } from "react";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { MyProfileHandler, StringValidator } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
import { RemoteMyProfiles } from "@/data/usecases";
import { makeUserAPITokens, makeResetUserPasswordUrl, } from "@/presentation/main/factories/http";
const defaultContext = {
    myProfile: new MyProfileHandler(null),
    nameValidator: new StringValidator(),
    firstNameValidator: new StringValidator(),
    lastNameValidator: new StringValidator(),
};
export const MyProfileHandlersProviderContext = React.createContext(defaultContext);
export const useMyProfileHandlers = () => useContext(MyProfileHandlersProviderContext);
export const MyProfileHandlersProvider = ({ children, }) => {
    const { getTokenSilently } = useAuth0();
    const myProfileRemotes = {
        myprofile: new RemoteMyProfiles(makeUserAPITokens(), makeResetUserPasswordUrl(), makeAuthorizeHttpClientDecorator(), getTokenSilently),
    };
    const myProfileHandler = new MyProfileHandler(myProfileRemotes);
    const nameValidator = new StringValidator().notEmpty();
    const firstNameValidator = new StringValidator().notEmpty();
    const lastNameValidator = new StringValidator().notEmpty();
    return (React.createElement(MyProfileHandlersProviderContext.Provider, { value: {
            myProfile: myProfileHandler,
            nameValidator,
            firstNameValidator,
            lastNameValidator,
        } }, children));
};
