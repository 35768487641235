import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@/lib/auth0';
import { Box, Button, Container, Form, FormField, Input, Select, SpaceBetween } from "@cloudscape-design/components-themed/components";
import { getFieldOnChange } from "@/presentation/components";
import { useOnboardingHandlers } from "@/presentation/providers/onboarding-handlers-provider";
import { PATHS } from "@/presentation/common-utils/constants";
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
const OnBoarding = (props) => {
    var _a, _b;
    const { user } = useAuth0();
    const userDetails = user;
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [onboardingInfo, setOnboardingInfo] = useState({
        details: {
            firstName: (_a = userDetails.given_name) !== null && _a !== void 0 ? _a : '',
            lastName: (_b = userDetails.family_name) !== null && _b !== void 0 ? _b : '',
            countryISOCode: props.selectedCountry
        }
    });
    const onBoardingInfoChange = useCallback((stateKey, newStepState) => {
        setOnboardingInfo(Object.assign(Object.assign({}, onboardingInfo), { [stateKey]: Object.assign(Object.assign({}, onboardingInfo[stateKey]), newStepState) }));
    }, [onboardingInfo]);
    const onFirstNameChange = getFieldOnChange('input', 'firstName', onBoardingInfoChange);
    const onLastNameChange = getFieldOnChange('input', 'lastName', onBoardingInfoChange);
    const onCountryChange = getFieldOnChange('select', 'countryISOCode', onBoardingInfoChange);
    const { onboardingHandler, firstNameValidator, lastNameValidator, countryValidator } = useOnboardingHandlers();
    const boardingHandler = onboardingHandler;
    const [firstName, setFirstName] = useState(onboardingInfo.details.firstName);
    const [lastName, setLastName] = useState(onboardingInfo.details.lastName);
    const [countryOption, setCountryOption] = useState(props.selectedCountry);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const validateFirstName = (val) => {
        const validationResult = firstNameValidator === null || firstNameValidator === void 0 ? void 0 : firstNameValidator.validate(val);
        setFirstNameError(!validationResult.valid);
    };
    const validateLastName = (val) => {
        const validationResult = lastNameValidator === null || lastNameValidator === void 0 ? void 0 : lastNameValidator.validate(val);
        setLastNameError(!validationResult.valid);
    };
    const validateCountryName = (val) => {
        const validationResult = (countryOption === null || countryOption === void 0 ? void 0 : countryOption.value) ? countryValidator === null || countryValidator === void 0 ? void 0 : countryValidator.validate([val]) : false;
        setCountryError(!validationResult.valid);
    };
    const handleFirstNameChange = (event) => {
        onFirstNameChange(event);
        setFirstName(event.detail.value);
        validateFirstName(event.detail.value);
    };
    const handleLastNameChange = (event) => {
        onLastNameChange(event);
        setLastName(event.detail.value);
        validateLastName(event.detail.value);
    };
    const handleCountryNameChange = (detail) => {
        onCountryChange(detail);
        setCountryOption(detail.selectedOption);
        validateCountryName(detail.selectedOption);
    };
    const updateUserVerificationStatus = () => {
        const firstNameValid = firstNameValidator === null || firstNameValidator === void 0 ? void 0 : firstNameValidator.validate(firstName);
        const lastNameValid = lastNameValidator === null || lastNameValidator === void 0 ? void 0 : lastNameValidator.validate(lastName);
        const countryNameValid = (countryOption === null || countryOption === void 0 ? void 0 : countryOption.value) ? countryValidator === null || countryValidator === void 0 ? void 0 : countryValidator.validate([countryOption]) : false;
        if (!(firstNameValid === null || firstNameValid === void 0 ? void 0 : firstNameValid.valid) || !(lastNameValid === null || lastNameValid === void 0 ? void 0 : lastNameValid.valid) || !countryNameValid.valid) {
            setFirstNameError(!(firstNameValid === null || firstNameValid === void 0 ? void 0 : firstNameValid.valid));
            setLastNameError(!(lastNameValid === null || lastNameValid === void 0 ? void 0 : lastNameValid.valid));
            setCountryError(!(countryNameValid === null || countryNameValid === void 0 ? void 0 : countryNameValid.valid));
        }
        else {
            setFirstNameError(false);
            setLastNameError(false);
            setCountryError(false);
            setIsOverlayOpen(true);
            const params = {
                user_id: userDetails === null || userDetails === void 0 ? void 0 : userDetails.id,
                given_name: firstName,
                family_name: lastName,
                verified: true,
                country_iso_code: countryOption.value
            };
            boardingHandler.updateUserDetails(params).then(result => {
                var _a, _b, _c;
                console.log(result);
                if (((_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.update_Users) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.country_iso_code) === countryOption.value) {
                    setTimeout(() => {
                        window.location.replace(PATHS.DASHBOARD);
                    }, 2000);
                }
            }).catch(console.error).finally(() => {
                setTimeout(() => {
                    setIsOverlayOpen(false);
                }, 2500);
            });
        }
    };
    return (React.createElement("div", { className: "max-w-full bg-[#0e0119] text-[#ffffff] min-h-screen" },
        React.createElement("div", { className: "pl-20 pr-20 pt-20 mb-28 max-w-screen-md z-[10000] relative" },
            React.createElement("div", { className: "flex flex-col mb-4" },
                React.createElement("div", { className: "flex flex-col gap-1" },
                    React.createElement("span", { className: "font-extrabold text-5xl" }, "Welcome to Kivera"),
                    React.createElement("span", { className: "text-base" }, "Finalise your onboarding to get started"))),
            React.createElement("div", { className: "text-center mx-auto" },
                React.createElement("form", { className: "onboarding-form", onSubmit: (event) => event.preventDefault() },
                    React.createElement(Form, { actions: React.createElement(Button, { variant: "primary", onClick: updateUserVerificationStatus, disabled: firstNameError || lastNameError || countryError },
                            React.createElement("span", { className: "text-lg" }, "Start using Kivera")) },
                        React.createElement(Box, { margin: { bottom: 'l' } },
                            React.createElement(Container, null,
                                React.createElement(SpaceBetween, { size: "l" },
                                    React.createElement(FormField, { label: "Email Address" },
                                        React.createElement(Input, { value: userDetails.id, disabled: true })),
                                    React.createElement(FormField, { label: "First Name", errorText: firstNameError ? 'Please enter a valid first name' : '' },
                                        React.createElement(Input, { placeholder: 'First Name', value: firstName, onChange: (event) => {
                                                handleFirstNameChange(event);
                                            }, onBlur: () => {
                                                validateFirstName(firstName);
                                            } })),
                                    React.createElement(FormField, { label: "Last Name", errorText: lastNameError ? 'Please enter a valid last name' : '' },
                                        React.createElement(Input, { placeholder: 'Last Name', value: lastName, onChange: (event) => {
                                                handleLastNameChange(event);
                                            }, onBlur: () => {
                                                validateLastName(lastName);
                                            } })),
                                    React.createElement(FormField, { label: "Country", errorText: countryError ? 'Please select a country' : '' },
                                        React.createElement(Select, { selectedOption: countryOption, onChange: ({ detail }) => {
                                                handleCountryNameChange(detail);
                                            }, onBlur: () => {
                                                validateCountryName(countryOption);
                                            }, options: props.countryList, filteringType: "auto", selectedAriaLabel: "Selected", placeholder: 'Select Country' }))))))))),
        React.createElement("div", { className: "hero-gradient-wrap" },
            React.createElement("div", { className: "hero-gradient-rectangle" })),
        isOverlayOpen && React.createElement(OverlayBouncyLoader, null)));
};
export default OnBoarding;
