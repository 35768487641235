import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { Badge } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from '@/lib/misc/utils';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { companyNameFilteringProperty } from '@/presentation/pages/common-config';
export const MyProfileTokensTableColumnDefinitions = addColumnSortLabels([
    {
        id: 'apiKeyID',
        sortingField: 'apiKeyID',
        header: 'API Key ID',
        cell: (item) => item === null || item === void 0 ? void 0 : item.apiKeyID,
    },
    {
        id: 'description',
        sortingField: 'description',
        header: 'Description',
        cell: (item) => item === null || item === void 0 ? void 0 : item.description,
    },
    {
        id: 'companyName',
        sortingField: 'companyName',
        header: 'Organization',
        cell: (item) => item === null || item === void 0 ? void 0 : item.companyName,
    },
    {
        id: 'status',
        sortingField: 'status',
        header: 'Status',
        cell: (item) => {
            return React.createElement(Badge, { key: getRandomUUID(), color: (item === null || item === void 0 ? void 0 : item.status) === 'ACTIVE' ? 'green' : 'grey' }, item === null || item === void 0 ? void 0 : item.status);
        },
    },
    {
        id: 'createdDate',
        sortingField: 'createdDate',
        header: 'Created',
        cell: (item) => getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.createdDate),
    },
]);
export const MyProfileTokensTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'apiKeyID', label: 'API Key ID', editable: false },
            { id: 'description', label: 'Description', editable: true },
            { id: 'companyName', label: 'Organization', editable: true },
            { id: 'status', label: 'Status', editable: true },
            { id: 'createdDate', label: 'Created', editable: true }
        ]
    }
];
export const MyProfileTokensTablePageSizeOptions = [
    { value: 10, label: '10 API Keys' },
    { value: 30, label: '30 API Keys' },
    { value: 50, label: '50 API Keys' }
];
export const MyProfileTokensFilteringProperties = [
    {
        key: 'apiKeyID',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'API Key ID',
        groupValuesLabel: 'API Key ID values'
    },
    {
        key: 'description',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Description',
        groupValuesLabel: 'Description values'
    },
    companyNameFilteringProperty,
    {
        key: 'status',
        operators: ['=', '!='],
        propertyLabel: 'Status',
        groupValuesLabel: 'Status values'
    }
];
export const MyProfileTokensFilteringOptions = [
    {
        propertyKey: 'status',
        value: 'ACTIVE'
    },
    {
        propertyKey: 'status',
        value: 'REVOKED'
    }
];
export const MyProfileTokensTablePreferences = {
    pageSize: 30,
    visibleContent: ['apiKeyID', 'description', 'companyName', 'status', 'createdDate'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
