import { Box, ColumnLayout, Container, Header, SpaceBetween, Table } from '@cloudscape-design/components-themed/components';
import React, { useEffect, useState } from 'react';
import { makeEmptyPanelContent, makeSplitPanelI18nStrings, TAGS_COLUMN_DEFINITIONS } from '@/presentation/pages';
import { getContainerHeight } from '@/presentation/common-utils/common-utils';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
const DestinationDetails = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Destination Details") },
        React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                    React.createElement(Box, null, (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.id)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Type"),
                    React.createElement(Box, null, (_b = props === null || props === void 0 ? void 0 : props.data) === null || _b === void 0 ? void 0 : _b.type))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Name"),
                    React.createElement(Box, null, (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.name)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                    React.createElement(Box, null, (_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.description))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Destination URL"),
                    React.createElement(Box, null, (_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.config.webhook_url)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Attached Notifications Count"),
                    React.createElement(Box, null, (_g = (_f = props === null || props === void 0 ? void 0 : props.data) === null || _f === void 0 ? void 0 : _f.monitors) === null || _g === void 0 ? void 0 : _g.length)))));
};
const DestinationWebhookHeaders = (props) => {
    var _a, _b;
    const headers = Object.keys((_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.headers).map(headerKey => {
        var _a, _b;
        return {
            key: headerKey,
            value: (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.headers[headerKey]
        };
    });
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" },
            "Headers (",
            headers.length,
            ")") },
        React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
            React.createElement(Box, null,
                React.createElement(Table, { empty: React.createElement(Box, { textAlign: "center", color: "inherit" },
                        React.createElement(Box, { padding: { bottom: 's' }, variant: "p", color: "inherit" }, "No headers to display.")), variant: 'embedded', columnDefinitions: TAGS_COLUMN_DEFINITIONS, items: headers, sortingDisabled: true }))));
};
const DestinationRawJson = (props) => {
    var _a;
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('settingsNotificattionsEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Raw JSON") },
        React.createElement("div", { ref: ref, id: "settingsNotificattionsEditor" },
            React.createElement(CodeEditor, { code: JSON.stringify((_a = props === null || props === void 0 ? void 0 : props.data) !== null && _a !== void 0 ? _a : {}, null, 2), height: editorHeight, heightType: 'px', readOnly: true, language: 'json' })));
};
export const MakeNotificationDestinationsSplitPanel = (items) => {
    if (!items.length) {
        return makeEmptyPanelContent('Select a destination record to see its details.');
    }
    const item = items[0];
    const header = 'Name: ' + item.name;
    return {
        header: header,
        panelStrings: makeSplitPanelI18nStrings(),
        body: (React.createElement(SpaceBetween, { size: 'xl' },
            React.createElement(DestinationDetails, { data: item }),
            (item === null || item === void 0 ? void 0 : item.type) === 'webhook'
                ? React.createElement(DestinationWebhookHeaders, { data: item })
                : React.createElement(React.Fragment, null),
            React.createElement(DestinationRawJson, { data: item })))
    };
};
