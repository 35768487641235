var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';
import { getAllowedRolesFromToken, getDefaultRoleFromToken, getOrgIDFromToken } from '@/lib/auth0/utils';
import { BroadcastChannel } from 'broadcast-channel';
import { InitialAppState } from '@/presentation/main/types/types';
import AuthErrorPage from '@/presentation/pages/errors/auth-error-page';
import { RemoteAuthUserDetails } from "@/data/usecases/users/remote-auth-user-details";
import { SessionStorageAdapter } from '@/infra/cache';
import { useHistory } from '@/lib/browser-history/history-provider';
const defaultAuth0Context = {
    isAuthenticated: false,
    user: {},
    appAuthState: InitialAppState,
    decodedToken: {},
    rawToken: '',
    loading: false,
    popupOpen: false,
    loginWithPopup: () => { },
    handleRedirectCallback: () => { },
    loginWithRedirect: () => { },
    getTokenSilently: (...p) => { },
    getTokenWithPopup: () => { },
    refreshToken: () => { },
    logout: () => { }
};
const LOGOUT_ALL_TABS = 'LOGOUT_ALL_TABS';
export const Auth0Context = React.createContext(defaultAuth0Context);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, auth0Options }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [rawToken, setRawToken] = useState();
    const [decodedToken, setDecodedToken] = useState();
    const [auth0Client, setAuth0Client] = useState();
    const [appAuthState, setAppAuthState] = useState(InitialAppState);
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const channel = useMemo(() => new BroadcastChannel('logout'), []);
    const { history } = useHistory();
    const browserHistory = history;
    const [isInvalidUserCredentials, setIsInvalidUserCredentials] = useState(false);
    const closeChannel = () => __awaiter(void 0, void 0, void 0, function* () {
        yield channel.close;
    });
    const setFullUserDetails = (auth0Token, user) => {
        var _a;
        const remoteAuthUserDetails = new RemoteAuthUserDetails(auth0Token);
        const userEmail = (_a = user === null || user === void 0 ? void 0 : user.email) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        remoteAuthUserDetails.getUserDetails({
            email: userEmail !== null && userEmail !== void 0 ? userEmail : ''
        }).then((userDetails) => {
            var _a, _b, _c, _d;
            if (userDetails.Users.length > 0) {
                const orgID = getOrgIDFromToken(auth0Token);
                const memberships = userDetails.Users[0].Memberships.length > 0
                    ? userDetails.Users[0].Memberships.map((mem) => {
                        return {
                            id: mem.id,
                            organization: {
                                id: mem.Organization.id,
                                company_name: mem.Organization.company_name,
                                beta_access: mem.Organization.beta_access,
                            }
                        };
                    })
                    : [];
                const currentOrg = memberships.filter(mem => mem.organization.id === orgID);
                const fullUserDetails = {
                    id: userDetails.Users[0].id,
                    given_name: userDetails.Users[0].given_name,
                    family_name: userDetails.Users[0].family_name,
                    email: userEmail !== null && userEmail !== void 0 ? userEmail : user === null || user === void 0 ? void 0 : user.name,
                    email_verified: (_a = user === null || user === void 0 ? void 0 : user.email_verified) !== null && _a !== void 0 ? _a : true,
                    verified: userDetails.Users[0].verified,
                    org_id: orgID,
                    org_beta_access: (_d = (_c = (_b = currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg[0]) === null || _b === void 0 ? void 0 : _b.organization) === null || _c === void 0 ? void 0 : _c.beta_access) !== null && _d !== void 0 ? _d : false,
                    allowed_roles: getAllowedRolesFromToken(auth0Token),
                    default_role: getDefaultRoleFromToken(auth0Token),
                    memberships: memberships,
                    country_iso_code: userDetails.Users[0].country_iso_code,
                    timezone: userDetails.Users[0].timezone,
                    nickname: user.nickname,
                    picture: user.picture,
                    created_at: userDetails.Users[0].created_at,
                    updated_at: user.updated_at
                };
                setUser(fullUserDetails);
            }
            else {
                setIsInvalidUserCredentials(true);
                setLoading(false);
            }
        }).catch((e) => {
            console.error('Unable to get user details', e);
        });
    };
    const initAuth0 = () => __awaiter(void 0, void 0, void 0, function* () {
        const auth0FromHook = yield createAuth0Client(auth0Options);
        setAuth0Client(auth0FromHook);
        if (window.location.search.includes('error=')) {
            const appState = { error: true };
            setAppAuthState(appState);
        }
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
            try {
                const { appState } = yield auth0FromHook.handleRedirectCallback();
                setAppAuthState(appState);
                browserHistory.push((appState === null || appState === void 0 ? void 0 : appState.returnTo) !== '' ? appState.returnTo : window.location.pathname);
            }
            catch (e) {
                // invalid state
                yield auth0FromHook.loginWithRedirect();
            }
        }
        const isAuthenticated = yield auth0FromHook.isAuthenticated();
        if (isAuthenticated) {
            try {
                setIsAuthenticated(isAuthenticated);
                const user = yield auth0FromHook.getUser();
                const auth0Token = yield auth0FromHook.getTokenSilently();
                setRawToken(auth0Token);
                const decoded = jwtDecode(auth0Token);
                setDecodedToken(decoded);
                setFullUserDetails(auth0Token, user);
            }
            catch (e) {
                // unknown errors
                const redirectAppState = { appState: { returnTo: window.location.pathname + window.location.search } };
                yield auth0FromHook.loginWithRedirect(redirectAppState);
            }
        }
        setLoading(false);
    });
    useEffect(() => {
        initAuth0().then(() => { });
    }, []);
    useEffect(() => {
        channel.onmessage = (message) => {
            if (message && message === LOGOUT_ALL_TABS) {
                // Just refresh the page because
                // auth0Client is undefined and cant be accessed anymore
                // this will make sure to go to Auth0 login page
                window.location.reload();
            }
        };
        return () => {
            closeChannel().then().catch(console.error);
        };
    }, []);
    const logout = (...p) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const clearSession = new SessionStorageAdapter();
        return (_a = auth0Client === null || auth0Client === void 0 ? void 0 : auth0Client.logout(...p, { logoutParams: { returnTo: window.location.origin } })) === null || _a === void 0 ? void 0 : _a.finally(() => __awaiter(void 0, void 0, void 0, function* () {
            // clear the sessions only after we logout from Auth0
            clearSession.clear();
            yield channel.postMessage(LOGOUT_ALL_TABS);
        }));
    });
    if ((appAuthState === null || appAuthState === void 0 ? void 0 : appAuthState.error) || isInvalidUserCredentials) {
        return (React.createElement(AuthErrorPage, { handleLogout: () => __awaiter(void 0, void 0, void 0, function* () {
                yield logout();
            }), accessDenied: true }));
    }
    const loginWithPopup = (params = {}) => __awaiter(void 0, void 0, void 0, function* () {
        setPopupOpen(true);
        try {
            yield auth0Client.loginWithPopup(params);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setPopupOpen(false);
        }
        // TODO - set the full user details when implementing this functionality
        // const user = await auth0Client.getUser()
        // setUser(user)
        setIsAuthenticated(true);
    });
    const loginWithRedirect = (params = {}) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield auth0Client.loginWithRedirect(params);
        }
        catch (error) {
            console.error(error);
        }
    });
    const refreshToken = (params) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            let authParams = {};
            // support Org Zero
            if ((params === null || params === void 0 ? void 0 : params.org_id) || (params === null || params === void 0 ? void 0 : params.org_id) === 0) {
                authParams = { authorizationParams: { org_id: params === null || params === void 0 ? void 0 : params.org_id } };
            }
            const options = Object.assign({ cacheMode: 'off' }, authParams);
            const auth0Token = yield auth0Client.getTokenSilently(options);
            setRawToken(auth0Token);
            const decoded = jwtDecode(auth0Token);
            setDecodedToken(decoded);
            const user = yield auth0Client.getUser();
            setFullUserDetails(auth0Token, user);
        }
        catch (error) {
            // invalid state errors
            const redirectAppState = { appState: { returnTo: window.location.pathname + window.location.search } };
            yield auth0Client.loginWithRedirect(redirectAppState);
        }
    });
    const handleRedirectCallback = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield auth0Client.handleRedirectCallback();
            const auth0Token = yield auth0Client.getTokenSilently();
            const user = yield auth0Client.getUser();
            setIsAuthenticated(true);
            setFullUserDetails(auth0Token, user);
            setLoading(false);
        }
        catch (error) {
            // any unknown errors
            yield auth0Client.loginWithRedirect();
        }
    });
    const getTokenSilently = (...p) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const auth0Token = yield auth0Client.getTokenSilently(...p);
            const decoded = jwtDecode(auth0Token);
            const nowTime = Math.floor(Date.now() / 1000);
            if (nowTime > decoded.exp) {
                // Force logout if token expired
                yield logout();
            }
            return auth0Token;
        }
        catch (error) {
            // missing refresh token errors
            const redirectAppState = { appState: { returnTo: window.location.pathname + window.location.search } };
            yield auth0Client.loginWithRedirect(redirectAppState);
        }
    });
    return (React.createElement(Auth0Context.Provider, { value: {
            isAuthenticated,
            user,
            appAuthState,
            decodedToken,
            rawToken,
            loading,
            popupOpen,
            loginWithPopup,
            handleRedirectCallback,
            loginWithRedirect,
            getTokenSilently,
            getTokenWithPopup: (...p) => __awaiter(void 0, void 0, void 0, function* () { return auth0Client.getTokenWithPopup(...p); }),
            refreshToken,
            logout
        } }, children));
};
