import { SpaceBetween } from '@cloudscape-design/components-themed/components';
import React from 'react';
import { makeEmptyPanelContent, makeRiskRatingText } from '@/presentation/pages';
import { RuleComplianceMappings, RuleDetails, RulePolicies, RuleTags } from '@/presentation/pages/rules/rules-view';
export const getRulesLibraryListSplitPanel = (items, rulesHandler) => {
    if (!items.length) {
        return makeEmptyPanelContent('');
    }
    const item = items[0];
    const service = {
        label: item.service,
        value: String(item.globalServiceID)
    };
    const provider = {
        label: item.provider,
        value: String(item.providerID)
    };
    const riskRating = {
        label: makeRiskRatingText(item.riskRating),
        value: String(item.providerID)
    };
    const makeRuleFormInfo = (item) => {
        return {
            details: {
                id: item.id,
                action: item.actions,
                cfnScan: false,
                enforceRule: true,
                logBody: true,
                initialConfig: item.config,
                description: item.description,
                provider: provider,
                service: service,
                riskRating: riskRating
            },
            policyConfig: { policy: item.policy },
            tags: { editorTags: item.tags },
            compliance: { mappings: item.complianceMappings }
        };
    };
    return {
        header: `Rule:${item.id}`,
        body: (React.createElement(SpaceBetween, { size: "l" },
            React.createElement(RuleDetails, { rule: makeRuleFormInfo(item), rulesHandler: rulesHandler, viewOnly: true, splitPanelFormat: true }),
            React.createElement(RulePolicies, { rule: makeRuleFormInfo(item), rulesHandler: rulesHandler, viewOnly: true }),
            React.createElement(RuleComplianceMappings, { rule: makeRuleFormInfo(item), rulesHandler: rulesHandler, viewOnly: true, noTagsButton: true }),
            React.createElement(RuleTags, { rule: makeRuleFormInfo(item), rulesHandler: rulesHandler, viewOnly: true, noTagsButton: true })))
    };
};
