import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useProfilesHandlers } from "@/presentation/providers";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { Box, Button, ColumnLayout, Container, Header, SpaceBetween } from "@cloudscape-design/components-themed/components";
import { PATHS, } from "@/presentation/common-utils/constants";
import Skeleton from "react-loading-skeleton";
import ContentWrapper from "@/presentation/components/content-wrapper";
import DeleteModal from "@/presentation/components/modal/delete-modal";
import { ProfilesDeleteStrings, ProfilesDeleteShowProp } from "@/presentation/pages/profiles/profiles-types";
import { TableHeader } from "@/presentation/components/commons/common-components";
import ProfilesRules from "@/presentation/pages/profiles/profiles-rules";
import ShowTags from '@/presentation/components/tags/show-tags';
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import { makeNotification } from "@/presentation/pages";
import { useIsMounted } from '@/presentation/hooks';
import ContentLayoutWrapper from '@/presentation/components/content-wrapper/content-layout-wrapper';
const ProfileDetails = (props) => {
    var _a, _b, _c, _d;
    return (React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Profile Details") },
        React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                    React.createElement(Box, null, (_a = props === null || props === void 0 ? void 0 : props.profile) === null || _a === void 0 ? void 0 : _a.id))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                    React.createElement(Box, null, (_b = props === null || props === void 0 ? void 0 : props.profile) === null || _b === void 0 ? void 0 : _b.description))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Attached to Identities (#)"),
                    React.createElement(Box, null, (_c = props === null || props === void 0 ? void 0 : props.profile) === null || _c === void 0 ? void 0 : _c.numberOfIdentities))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Attached Rules (#)"),
                    React.createElement(Box, null, (_d = props === null || props === void 0 ? void 0 : props.profile) === null || _d === void 0 ? void 0 : _d.numberOfRules))))));
};
const ProfileRules = (props) => {
    var _a, _b, _c;
    const navigate = useNavigate();
    const attachedRules = ((_b = (_a = props.info) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b.attachedRules) || [];
    const atatchedRuleIds = attachedRules.map(a => a === null || a === void 0 ? void 0 : a.id) || [];
    const counter = attachedRules.length > 0 ? `(${attachedRules.length}/${props.rules.length})` : `(0/${props.rules.length})`;
    const buttonText = attachedRules.length > 0 ? 'Manage Rules' : 'Attach Rule';
    const rulesViewTableProps = {
        header: (React.createElement(TableHeader, { title: "Rules", counter: counter, selectedItems: attachedRules, totalItems: props.rules, description: "Rules attached to this Profile", actionButtons: React.createElement(Button, { variant: "normal", onClick: () => { var _a; return navigate(PATHS.PROFILES + '/' + ((_a = props === null || props === void 0 ? void 0 : props.profile) === null || _a === void 0 ? void 0 : _a.id) + '/edit' + '#rules'); } }, buttonText) })),
    };
    return (React.createElement(Container, null,
        React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
            React.createElement(Box, null,
                React.createElement(ProfilesRules, { setActiveStepIndex: () => { }, onChange: () => { }, rulesView: attachedRules, rulesViewTableProps: rulesViewTableProps, profileID: (_c = props === null || props === void 0 ? void 0 : props.profile) === null || _c === void 0 ? void 0 : _c.id, rules: props.rules, info: props.info, attachedRuleIds: atatchedRuleIds, profileRulesView: true })))));
};
const ProfileTags = (props) => {
    var _a, _b, _c;
    const tags = (_b = (_a = props === null || props === void 0 ? void 0 : props.profile) === null || _a === void 0 ? void 0 : _a.tags) !== null && _b !== void 0 ? _b : [];
    const currentId = (_c = props === null || props === void 0 ? void 0 : props.profile) === null || _c === void 0 ? void 0 : _c.id;
    return React.createElement(ShowTags, { tags: tags, id: currentId, resource: "profile", basePath: PATHS.PROFILES });
};
const ProfilesView = (props) => {
    var _a, _b;
    const params = useParams();
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState(undefined);
    const [formattedProfile, setFormattedProfile] = useState(undefined);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const { profiles } = useProfilesHandlers();
    const profilesHandler = profiles;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const isMounted = useIsMounted();
    const makeSuccessNotification = (message) => {
        const msg = 'Profile deleted Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to delete. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onDeleteConfirm = () => {
        var _a;
        const toBeDeleted = parseInt(params === null || params === void 0 ? void 0 : params.profileID, 10);
        (_a = profilesHandler.deleteBulk([toBeDeleted])) === null || _a === void 0 ? void 0 : _a.then((result) => {
            // if success, unselect the items
            if (result === null || result === void 0 ? void 0 : result.success) {
                pushNotifications(makeSuccessNotification());
                navigate(PATHS.PROFILES);
            }
            else { // else show error notification
                pushNotifications(makeErrorNotification());
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            // drop the modal and show success or error
            if (isMounted.current) {
                setShowDeleteModal(false);
            }
        });
    };
    useEffect(() => {
        const { profile, profileInfo } = props;
        const formattedItems = profilesHandler === null || profilesHandler === void 0 ? void 0 : profilesHandler.getFormattedEntity(profile, profileInfo);
        if (isMounted.current) {
            setFormattedProfile(formattedItems);
            setItems([formattedItems]);
            setProfileDetails(profile);
            setLoading(false);
        }
    }, [props]);
    if ((profileDetails === undefined) || loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(ContentWrapper, { testid: "profile-view-page" },
        React.createElement(ContentLayoutWrapper, { header: React.createElement(Header, { variant: "h1", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { "data-testid": "click-to-delete-this-profile", onClick: onDeleteInit }, "Delete"),
                    React.createElement(Button, { variant: "primary", "ata-testid": "click-to-edit-this-profile", onClick: () => {
                            navigate(PATHS.PROFILES + '/' + (params === null || params === void 0 ? void 0 : params.profileID) + '/edit');
                        } }, "Edit")) }, (_b = (_a = profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.Profiles_by_pk) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '') },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(ProfileDetails, { profile: formattedProfile, profilesHandler: profilesHandler }),
                React.createElement(ProfileRules, { profile: formattedProfile, info: props.profileInfo, rules: props.rules, profilesHandler: profilesHandler, proxyID: params === null || params === void 0 ? void 0 : params.proxyID, identityID: params === null || params === void 0 ? void 0 : params.identityID }),
                React.createElement(ProfileTags, { profile: formattedProfile, profilesHandler: profilesHandler }),
                React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: items, deleteModalStrings: ProfilesDeleteStrings, itemDeleteShowProp: ProfilesDeleteShowProp })))));
};
export default ProfilesView;
