import React, { useContext } from 'react';
import { AttributeKeyValueValidator, CloudTenantsHandler, IdentitiesHandler, ProfilesHandler, StringValidator, } from '@/presentation/handlers';
import { useAuth0 } from '@/lib/auth0';
import { RemoteCloudTenants, RemoteIdentities, RemoteProfiles, } from '@/data/usecases';
const defaultContext = {
    identities: new IdentitiesHandler(null, null),
    profiles: new ProfilesHandler(null, null),
    tenants: new CloudTenantsHandler(null),
    nameValidator: new StringValidator(),
    descriptionValidator: new StringValidator(),
    cidrRangesValidator: new StringValidator(),
    userIdentityKeyValidator: new StringValidator(),
    userIdentityKeyValueValidator: new AttributeKeyValueValidator()
};
export const IdentitiesHandlersProviderContext = React.createContext(defaultContext);
export const useIdentitiesHandlers = () => useContext(IdentitiesHandlersProviderContext);
export const IdentitiesHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const profilesRemotes = {
        profiles: new RemoteProfiles(getTokenSilently)
    };
    const identitiesRemotes = {
        identities: new RemoteIdentities(getTokenSilently),
        profiles: new RemoteProfiles(getTokenSilently)
    };
    const identitiesHandler = new IdentitiesHandler(null, identitiesRemotes);
    const profilesHandler = new ProfilesHandler(null, profilesRemotes);
    const nameValidator = new StringValidator().notEmpty().isValidResourceName();
    const cidrRangesValidator = new StringValidator().notEmpty().isValidCIDR();
    const descriptionValidator = new StringValidator().notEmpty().isValidResourceDesc();
    const userIdentityKeyValidator = new StringValidator().isValidAttributeKeyJsonPath();
    const userIdentityKeyValueValidator = new AttributeKeyValueValidator().ignoreEmptyPairs().haveValidAttributeTextKeyValuePairs();
    const tenantRemote = new RemoteCloudTenants(getTokenSilently);
    const cloudTenantsHandler = new CloudTenantsHandler(tenantRemote);
    return React.createElement(IdentitiesHandlersProviderContext.Provider, { value: {
            profiles: profilesHandler,
            identities: identitiesHandler,
            tenants: cloudTenantsHandler,
            nameValidator: nameValidator,
            descriptionValidator: descriptionValidator,
            cidrRangesValidator: cidrRangesValidator,
            userIdentityKeyValidator: userIdentityKeyValidator,
            userIdentityKeyValueValidator: userIdentityKeyValueValidator
        } }, children);
};
