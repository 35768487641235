export const ProfilesDeleteStrings = {
    headerTextSingular: 'Delete Profile',
    headerTextPlural: ' Delete Profiles',
    modalTextSingular: 'profile',
    modalTextPlural: 'profiles',
    consentText: 'confirm'
};
export const ProfilesDeleteShowProp = 'name';
export var HeaderStrings;
(function (HeaderStrings) {
    HeaderStrings["create"] = "Create Profile";
    HeaderStrings["edit"] = "Edit Profile";
})(HeaderStrings || (HeaderStrings = {}));
