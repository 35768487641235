var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RemoteBaseClass } from '@/data/usecases';
import { KiveraGQLClient } from '@/infra/http';
import axios from 'axios';
export class RemoteRules extends RemoteBaseClass {
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListRulesV4(); }));
        });
    }
    create(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).CreateRulesV4(params); }));
        });
    }
    get(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).GetRulesAndPoliciesV4(params); }));
        });
    }
    getRulesByService(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).GetServiceRules(params); }));
        });
    }
    updateV4(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).UpdateRuleV4(params); }));
        });
    }
    delete(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).DeleteRule(params); }));
        });
    }
    deleteBulk(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).DeleteRules(params); }));
        });
    }
    downloadActionsData(currentProvider) {
        return __awaiter(this, void 0, void 0, function* () {
            const downloadActionsPath = `${process.env.SERVICE_ACTION_SDK_URL}/${currentProvider === null || currentProvider === void 0 ? void 0 : currentProvider.toLocaleLowerCase()}/latest/service-actions.json`;
            return yield axios.get(downloadActionsPath);
        });
    }
    enableServices(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).CreateServiceMapping(params); }));
        });
    }
    listManagedRules() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListManagedRules(); }));
        });
    }
    getRulesByImportedFromIDs(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).GetRulesByImportedFromIDs(params); }));
        });
    }
}
