import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { PATH_PROFILES, PATHS } from '@/presentation/common-utils/constants';
import RegularLink from '@/presentation/components/links/regular-link';
import ButtonLink from '@/presentation/components/links/button-link';
import { sortingComparatorByKey } from '@/lib/misc/utils';
import { descriptionFilteringProperty, idFilteringProperty, nameFilteringProperty, numberOfRulesFilteringProperty, tagsFilteringProperty, userDetailsCreatedColumnDefinition, userDetailsCreatedFilteringProperty, userDetailsUpdatedColumnDefinition, userDetailsUpdatedFilteringProperty } from '@/presentation/pages/common-config';
export const ProfilesListCardsColumnDefinitions = addColumnSortLabels([
    {
        id: 'id',
        sortingField: 'id',
        header: 'ID',
        cell: item => {
            const link = `${PATH_PROFILES.VIEW}/${item.id}`;
            return React.createElement(RegularLink, { to: link, label: item.id });
        },
        width: 120
    },
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.name) || '-';
        },
    },
    {
        id: 'description',
        sortingField: 'description',
        header: 'Description',
        cell: item => (item === null || item === void 0 ? void 0 : item.description) || '-',
    },
    {
        id: 'numberOfIdentities',
        sortingField: 'numberOfIdentities',
        header: 'Attached to Identities (#)',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.numberOfIdentities) || '-',
        sortingComparator: (a, b) => sortingComparatorByKey(a, b, 'numberOfIdentities'),
    },
    {
        id: 'numberOfRules',
        sortingField: 'numberOfRules',
        header: 'Attached Rules (#)',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.numberOfRules) || '-',
        sortingComparator: (a, b) => sortingComparatorByKey(a, b, 'numberOfRules'),
    },
    ...userDetailsCreatedColumnDefinition,
    ...userDetailsUpdatedColumnDefinition,
    {
        id: 'table-actions',
        header: '',
        cell: item => {
            const link = PATHS.PROFILES + '/' + item.id + '/clone';
            return React.createElement(ButtonLink, { to: link, variant: "inline-icon", iconName: "copy", ariaLabel: 'copy-button', tooltipClass: `profiles-list-clone-button-${item.id}`, tooltipMessage: 'Clone' });
        },
    }
]);
export const ProfilesListTablePreferences = {
    pageSize: 30,
    visibleContent: ['id', 'name', 'description', 'numberOfIdentities', 'numberOfRules', 'table-actions'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const ProfilesListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'name', label: 'Name', editable: false },
            { id: 'description', label: 'Description', editable: false },
            { id: 'numberOfIdentities', label: 'Attached to Identities (#)', editable: true },
            { id: 'numberOfRules', label: 'Attached Rules (#)', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const ProfilesListTablePageSizeOptions = [
    { value: 10, label: '10 Profiles' },
    { value: 30, label: '30 Profiles' },
    { value: 50, label: '50 Profiles' }
];
export const ProfilesListPropertyFilterFilteringProperties = [
    idFilteringProperty,
    nameFilteringProperty,
    descriptionFilteringProperty,
    {
        key: 'numberOfIdentities',
        operators: ['=', '!=', '<', '<=', '>', '>='],
        propertyLabel: 'Attached to Identities (#)',
        groupValuesLabel: 'Attached to Identities (#) values'
    },
    numberOfRulesFilteringProperty,
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty,
    tagsFilteringProperty
];
