var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpStatusCode } from '@/data/protocols/http';
export class RemoteGetVersion {
    constructor(url, httpClient) {
        this.url = url;
        this.httpClient = httpClient;
    }
    getVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const httpResponse = yield this.httpClient.request({
                    url: this.url,
                    method: 'GET',
                });
                if ((httpResponse === null || httpResponse === void 0 ? void 0 : httpResponse.statusCode) && httpResponse.statusCode === HttpStatusCode.ok) {
                    return makeModel(httpResponse.body, false);
                }
            }
            catch (e) {
                console.log('Error in fetching version', e);
            }
            return makeModel("", true);
        });
    }
}
function makeModel(body, error) {
    return {
        error: error,
        version: body !== null && body !== void 0 ? body : ""
    };
}
