import React, { useEffect } from 'react';
import { useAuth0 } from "@/lib/auth0";
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
const LoginWithRedirect = () => {
    const { loginWithRedirect } = useAuth0();
    useEffect(() => {
        const redirect = { appState: { returnTo: window.location.pathname + window.location.search } };
        loginWithRedirect(redirect);
    }, []);
    return React.createElement(React.Fragment, null,
        React.createElement(OverlayBouncyLoader, null));
};
export default LoginWithRedirect;
