import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { StatusIndicator, SpaceBetween } from '@cloudscape-design/components-themed/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { getLogoAsUrl } from '@/presentation/pages';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { Tooltip } from 'react-tooltip';
import { providerFilteringProperty, serviceFilteringProperty } from '@/presentation/pages/common-config';
import { RULE_SIMULATOR_DATERANGE_OPTIONS, RULE_SIMULATOR_DATETIME_OPTIONS, RULE_SIMULATOR_TABLE_OPTIONS, RULE_SIMULATOR_TABLE_SEARCH_QUERY, SortDirection } from '@/presentation/storage';
export const RulesSimulatorListFilteringProperties = [
    {
        key: 'ruleStateChange',
        operators: ['=', '!='],
        propertyLabel: 'Rule State Change',
        groupValuesLabel: 'Rule State Change values'
    },
    {
        key: 'source',
        operators: ['=', '!='],
        propertyLabel: 'Source',
        groupValuesLabel: 'Source values'
    },
    providerFilteringProperty,
    serviceFilteringProperty
];
export const RulesSimulatorListFilteringOptions = [
    {
        propertyKey: 'ruleStateChange',
        value: 'true-true'
    },
    {
        propertyKey: 'ruleStateChange',
        value: 'true-false'
    },
    {
        propertyKey: 'ruleStateChange',
        value: 'false-true'
    },
    {
        propertyKey: 'ruleStateChange',
        value: 'false-false'
    }
];
export const TooltipStatusIndicator = (props) => {
    const classNameProps = props === null || props === void 0 ? void 0 : props.tooltipClass;
    return React.createElement(React.Fragment, null,
        React.createElement(StatusIndicator, { type: props === null || props === void 0 ? void 0 : props.tooltipType, className: 'button-link-nav ' + classNameProps }),
        React.createElement(Tooltip, { anchorSelect: '.' + classNameProps, clickable: true, place: 'bottom', style: { zIndex: '2000' } }, props === null || props === void 0 ? void 0 : props.tooltipMessage));
};
export const makeRuleStateChange = (id, state) => {
    switch (state) {
        case 'true-true':
            return React.createElement(React.Fragment, null,
                React.createElement(SpaceBetween, { size: 's', direction: 'horizontal' },
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}old-state-success`, tooltipMessage: "Old State", tooltipType: "success" })),
                    React.createElement(FontAwesomeIcon, { icon: faEquals, size: '2xs' }),
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}new-state-success`, tooltipMessage: "New State", tooltipType: "success" }))));
        case 'true-false':
            return React.createElement(React.Fragment, null,
                React.createElement(SpaceBetween, { size: 's', direction: 'horizontal' },
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}old-state-success`, tooltipMessage: "Old State", tooltipType: "success" })),
                    React.createElement(FontAwesomeIcon, { icon: faGreaterThan, size: '2xs' }),
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}new-state-error`, tooltipMessage: "New State", tooltipType: "error" }))));
        case 'false-true':
            return React.createElement(React.Fragment, null,
                React.createElement(SpaceBetween, { size: 's', direction: 'horizontal' },
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}old-state-error`, tooltipMessage: "Old State", tooltipType: "error" })),
                    React.createElement(FontAwesomeIcon, { icon: faGreaterThan, size: '2xs' }),
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}new-state-success`, tooltipMessage: "New State", tooltipType: "success" }))));
        case 'false-false':
            return React.createElement(React.Fragment, null,
                React.createElement(SpaceBetween, { size: 's', direction: 'horizontal' },
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}old-state-error`, tooltipMessage: "Old State", tooltipType: "error" })),
                    React.createElement(FontAwesomeIcon, { icon: faEquals, size: '2xs' }),
                    React.createElement(React.Fragment, null,
                        React.createElement(TooltipStatusIndicator, { tooltipClass: `${id}new-state-error`, tooltipMessage: "New State", tooltipType: "error" }))));
        default:
            return null;
    }
};
export const RulesSimulatorListCardsColumnDefinitions = addColumnSortLabels([
    {
        id: 'ruleStateChange',
        sortingField: 'ruleStateChange',
        header: 'Rule State Change',
        cell: item => {
            return makeRuleStateChange('', item === null || item === void 0 ? void 0 : item.ruleStateChange);
        },
        width: 120
    },
    {
        id: 'timestamp',
        sortingField: 'timestamp',
        header: 'Timestamp',
        cell: (item) => {
            return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.timestamp);
        }
    },
    {
        id: 'xkid',
        sortingField: 'xkid',
        header: 'Xkid',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.xkid) || '-';
        }
    },
    {
        id: 'proxy',
        sortingField: 'proxy',
        header: 'Source',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.proxy) || '-';
        }
    },
    {
        id: 'identity',
        sortingField: 'identity',
        header: 'Identity',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.identity) || '-';
        }
    },
    {
        id: 'provider',
        sortingField: 'provider',
        header: 'Provider',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.provider) ? React.createElement("img", { src: getLogoAsUrl(item.provider), width: "23px", height: "23px" }) : '-'
    },
    {
        id: 'service',
        sortingField: 'service',
        header: 'Service',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.service) || '-';
        }
    },
    {
        id: 'action',
        sortingField: 'action',
        header: 'API action',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.action) || '-';
        }
    }
]);
export const RulesSimulatorListTablePreferences = {
    pageSize: 30,
    visibleContent: ['ruleStateChange', 'timestamp', 'xkid', 'identity', 'provider', 'service', 'action', 'table-actions'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const RulesSimulatorListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'ruleStateChange', label: 'Rule State Change', editable: false },
            { id: 'timestamp', label: 'Timestamp', editable: true },
            { id: 'xkid', label: 'Xkid', editable: true },
            { id: 'proxy', label: 'Source', editable: true },
            { id: 'identity', label: 'Identity', editable: true },
            { id: 'provider', label: 'Provider', editable: true },
            { id: 'service', label: 'Service', editable: true },
            { id: 'action', label: 'API action', editable: true }
        ]
    }
];
export const RulesSimulatorListTablePageSizeOptions = [
    { value: 10, label: '10 Logs' },
    { value: 30, label: '30 Logs' },
    { value: 50, label: '50 Logs' }
];
export const RulesSimulatorInitialTableOptions = {
    tableOptionsStorage: RULE_SIMULATOR_TABLE_OPTIONS,
    queryStorage: RULE_SIMULATOR_TABLE_SEARCH_QUERY,
    sortDirection: SortDirection.DESC,
    sortField: 'Timestamp',
    dateTimeOptionsStorage: RULE_SIMULATOR_DATETIME_OPTIONS,
    dateRangeStorage: RULE_SIMULATOR_DATERANGE_OPTIONS
};
export const RulesSimulatorDefaultDatetimeOptions = {
    startDateTime: 'now-7d',
    endDateTime: 'now'
};
export const RulesSimulatorDefaultDateRangeFilter = {
    value: {
        amount: 4,
        unit: 'hour',
        type: 'relative',
        key: 'last-4-hours'
    }
};
