export class BaseValidator {
    constructor() {
        this.makeError = (errorMessage) => {
            return { valid: false, message: errorMessage };
        };
        this.makeValid = (value) => {
            return { valid: true, value: value };
        };
    }
}
