import React, { useEffect, useState } from "react";
import { Box, Container, FormField, Header, Input, SpaceBetween, } from "@cloudscape-design/components-themed/components";
import { getFieldOnChange } from "@/presentation/components";
const NameAndDescriptionForm = (props) => {
    const onNameChange = getFieldOnChange("input", "name", props.onChange);
    const onDescriptionChange = getFieldOnChange("input", "description", props.onChange);
    const { info, validation } = props;
    const [name, setName] = useState(info.details.name);
    const [desc, setDesc] = useState(info.details.description);
    const [nameError, setNameError] = useState(validation.nameError);
    const [descError, setDescError] = useState(validation.descError);
    const [overrideNameErrorText, setOverrideNameErrorText] = useState("");
    const infoStrings = {
        nameInfoText: `Short name for the ${props.resourceText}`,
        nameErrorText: `Please enter a valid ${props.resourceText} name`,
        nameConstraintText: "Alphanumeric characters and hyphens only. First character must be a letter. Cannot end with a hyphen or contain two consecutive hyphens.",
        namePlaceHolderText: `example-${props.resourceText}-name`,
        descriptionInfoText: `Brief description of the ${props.resourceText}`,
        descriptionErrorText: `Please enter a valid ${props.resourceText} description`,
        descriptionPlaceHolderText: `Example ${props.resourceText} description`,
    };
    const validatedOnNameBlur = (val) => {
        var _a;
        const validationResult = (_a = props.nameValidator) === null || _a === void 0 ? void 0 : _a.validate(val);
        setNameError(!validationResult.valid);
    };
    const validatedOnNameChange = (event) => {
        onNameChange(event);
        setName(event.detail.value);
        validatedOnNameBlur(event.detail.value);
    };
    const validatedOnDescBlur = (val) => {
        var _a;
        const validationResult = (_a = props.descriptionValidator) === null || _a === void 0 ? void 0 : _a.validate(val);
        setDescError(!validationResult.valid);
    };
    const validatedOnDescChange = (event) => {
        onDescriptionChange(event);
        setDesc(event.detail.value);
        validatedOnDescBlur(event.detail.value);
    };
    useEffect(() => {
        setNameError(validation.nameError);
    }, [validation.nameError]);
    useEffect(() => {
        setDescError(validation.descError);
    }, [validation.descError]);
    useEffect(() => {
        setOverrideNameErrorText(validation.overrideNameErrorText);
    }, [validation.overrideNameErrorText]);
    const nameErrorText = (overrideNameErrorText != "") ? overrideNameErrorText : infoStrings.nameErrorText;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, props.title) },
                    React.createElement(SpaceBetween, { size: "l" },
                        React.createElement(FormField, { label: "Name", description: infoStrings.nameInfoText, constraintText: !props.isEdit ? infoStrings.nameConstraintText : '', errorText: nameError ? nameErrorText : "" },
                            React.createElement(Input, { placeholder: infoStrings.namePlaceHolderText, disabled: props.isEdit, value: name, onChange: (event) => {
                                    validatedOnNameChange(event);
                                }, onBlur: () => {
                                    validatedOnNameBlur(name);
                                } })),
                        React.createElement(FormField, { label: "Description", description: infoStrings.descriptionInfoText, errorText: descError ? infoStrings.descriptionErrorText : "" },
                            React.createElement(Input, { placeholder: infoStrings.descriptionPlaceHolderText, value: desc, onChange: (event) => {
                                    validatedOnDescChange(event);
                                }, onBlur: () => {
                                    validatedOnDescBlur(desc);
                                } }))))))));
};
export default NameAndDescriptionForm;
