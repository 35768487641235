import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import MonitoringList from "@/presentation/pages/monitoring/monitoring-list";
import { MonitoringHandlersProvider } from "@/presentation/providers/monitoring-handlers-provider";
const MakeMonitoringList = () => {
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(MonitoringHandlersProvider, null,
            React.createElement(MonitoringList, null))));
};
export default MakeMonitoringList;
