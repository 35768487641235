import React, { useEffect, useState } from 'react';
import { FormField, Select } from '@cloudscape-design/components-themed/components';
import { AttributeErrorCode } from '@/presentation/pages';
const AttributeKeyValueSelectComponent = (props) => {
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        const errorItem = (_b = (_a = props.errorItems) === null || _a === void 0 ? void 0 : _a.filter(it => { var _a; return (it === null || it === void 0 ? void 0 : it.id) === ((_a = props.item) === null || _a === void 0 ? void 0 : _a.id); })) !== null && _b !== void 0 ? _b : [];
        const errText = (_e = (_c = props.attributeErrorCodeMappings) === null || _c === void 0 ? void 0 : _c[(_d = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _d === void 0 ? void 0 : _d.errorCode]) !== null && _e !== void 0 ? _e : '';
        if (props.keyOrValue === 'key' && ((_f = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _f === void 0 ? void 0 : _f.errorCode) === AttributeErrorCode.INVALID_KEY) {
            setErrorText(errText);
            setIsInvalid((errorItem === null || errorItem === void 0 ? void 0 : errorItem.length) === 1);
        }
        else if (props.keyOrValue === 'value' && ((_g = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _g === void 0 ? void 0 : _g.errorCode) === AttributeErrorCode.DUPLICATE_VALUE) {
            setErrorText(errText);
            setIsInvalid((errorItem === null || errorItem === void 0 ? void 0 : errorItem.length) === 1);
        }
        else {
            // Clear the previous errors
            setErrorText('');
            setIsInvalid(false);
        }
    }, [props.errorItems]);
    useEffect(() => {
        var _a;
        // this item has changed
        if (((_a = props.items.filter(it => { var _a; return (it === null || it === void 0 ? void 0 : it.id) === ((_a = props.item) === null || _a === void 0 ? void 0 : _a.id); })) === null || _a === void 0 ? void 0 : _a.length) === 1 && props.item[props.keyOrValue] !== '') {
            const option = {
                value: props.item[props.keyOrValue],
                label: props.item[props.keyOrValue]
            };
            setSelectedOption(option);
        }
    }, [props.items]);
    useEffect(() => {
        var _a, _b;
        //  accept whatever incoming value is sent for key
        if (props.keyOrValue === 'key') {
            setOptions(props.options);
        }
        //  `value` type
        else {
            // when the item key is invalid value show no options
            if (!props.item.key) {
                setOptions([]);
            }
            // or filter only the respective `key` options
            else {
                // @ts-expect-error
                const vOptions = (_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a.filter(option => { var _a; return (option === null || option === void 0 ? void 0 : option.key) === ((_a = props === null || props === void 0 ? void 0 : props.item) === null || _a === void 0 ? void 0 : _a.key); })) !== null && _b !== void 0 ? _b : [];
                setOptions(vOptions);
            }
        }
    }, [props.options, props.items]);
    return (React.createElement(FormField, { errorText: isInvalid ? errorText : '' },
        React.createElement(Select, { key: props.item.id + '-' + props.keyOrValue, selectedOption: selectedOption, onChange: (event) => {
                props.onChange(event, props.item);
            }, options: options, invalid: isInvalid, filteringType: "auto", onBlur: () => {
                props.onBlur();
            }, onLoadItems: props.onLoadChange, placeholder: (props === null || props === void 0 ? void 0 : props.placeHolder) || '' })));
};
export default AttributeKeyValueSelectComponent;
