import React, { useEffect, useState } from 'react';
import { Badge, Box, ColumnLayout, Container, Header, SpaceBetween, StatusIndicator, Tabs } from '@cloudscape-design/components-themed/components';
import { RuleEvaluationIdentitiesColumnDefinitions, RuleEvaluationProfilesColumnDefinitions, RuleEvaluationRulesColumnDefinitions } from '@/presentation/pages/monitoring/monitoring-list-split-panel-config';
import { getContainerHeight, getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { makeEmptyPanelContent, makeUnavailablePanelContent } from '@/presentation/pages';
import { PROXY_MODE_TYPE_LABEL } from '../proxies/proxies-types';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import ViewTable from "@/presentation/components/table/view-table";
import { COLUMN_WIDTH_STORAGE_KEY } from "@/presentation/pages/constants-and-types";
const LogDetails = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const tmpStamp = (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.timestamp) === null || _b === void 0 ? void 0 : _b.toString();
    const timestamp = getLocaleTimestamp(tmpStamp);
    const isLearningMode = (((_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.proxyMode) === PROXY_MODE_TYPE_LABEL.LEARNING || ((_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.learningMode));
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Event Details") },
            React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Provider"),
                        React.createElement(Box, null, (_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.provider)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Service"),
                        React.createElement(Box, null, ((_f = props === null || props === void 0 ? void 0 : props.data) === null || _f === void 0 ? void 0 : _f.serviceStatus) === "disabled" ? (React.createElement("div", { className: "flex" },
                            React.createElement("div", { className: "flex-none mr-2" }, ((_g = props === null || props === void 0 ? void 0 : props.data) === null || _g === void 0 ? void 0 : _g.service) || ''),
                            React.createElement("div", { className: "flex-none" },
                                React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled")))) : (React.createElement("span", null, (_h = props === null || props === void 0 ? void 0 : props.data) === null || _h === void 0 ? void 0 : _h.service)))),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "API Action"),
                        React.createElement(Box, null, (_j = props === null || props === void 0 ? void 0 : props.data) === null || _j === void 0 ? void 0 : _j.action))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Source"),
                        React.createElement(Box, null, (_k = props === null || props === void 0 ? void 0 : props.data) === null || _k === void 0 ? void 0 : _k.proxy)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Valid"),
                        React.createElement(Box, null, ((_l = props === null || props === void 0 ? void 0 : props.data) === null || _l === void 0 ? void 0 : _l.validityStatus) ? React.createElement(Badge, { color: "green" }, "ALLOWED") : React.createElement(Badge, { color: "red" }, "BLOCKED"))),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Learning Mode"),
                        React.createElement(Box, null, isLearningMode
                            ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                            : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled")))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Proxy Mode"),
                        React.createElement(Box, null, (_m = props === null || props === void 0 ? void 0 : props.data) === null || _m === void 0 ? void 0 : _m.proxyMode)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Timestamp"),
                        React.createElement(Box, null, timestamp))))));
};
const RuleEvaluation = (props) => {
    var _a, _b, _c;
    const profiles = [];
    const rules = [];
    const identities = [];
    const identityNames = [];
    (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.rawSource) === null || _b === void 0 ? void 0 : _b.rules) === null || _c === void 0 ? void 0 : _c.map(rule => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        const r = {
            id: rule === null || rule === void 0 ? void 0 : rule.RuleId,
            service: rule === null || rule === void 0 ? void 0 : rule.RuleService,
            description: rule === null || rule === void 0 ? void 0 : rule.RuleDescription,
            provider: rule === null || rule === void 0 ? void 0 : rule.RuleProvider,
            processResult: rule === null || rule === void 0 ? void 0 : rule.RuleProcessResult,
            profilesNames: '',
        };
        if (rule === null || rule === void 0 ? void 0 : rule.Profiles) {
            let ruleProfiles = [];
            const ruleIdentities = [];
            if (Array.isArray(rule === null || rule === void 0 ? void 0 : rule.Profiles)) {
                const tmpProfiles = rule === null || rule === void 0 ? void 0 : rule.Profiles;
                tmpProfiles === null || tmpProfiles === void 0 ? void 0 : tmpProfiles.forEach(profile => {
                    var _a, _b, _c, _d, _e, _f, _g;
                    const i = {
                        id: (_a = profile === null || profile === void 0 ? void 0 : profile.Identity) === null || _a === void 0 ? void 0 : _a.IdentityId,
                        name: (_b = profile === null || profile === void 0 ? void 0 : profile.Identity) === null || _b === void 0 ? void 0 : _b.IdentityName,
                        identifier: (_c = profile === null || profile === void 0 ? void 0 : profile.Identity) === null || _c === void 0 ? void 0 : _c.IdentityIdentifier
                    };
                    if ((profile === null || profile === void 0 ? void 0 : profile.ProfileId) && ((_d = profile === null || profile === void 0 ? void 0 : profile.Identity) === null || _d === void 0 ? void 0 : _d.IdentityName)) {
                        if (!identityNames[profile === null || profile === void 0 ? void 0 : profile.ProfileId]) {
                            identityNames[profile === null || profile === void 0 ? void 0 : profile.ProfileId] = [];
                        }
                        if (!((_e = identityNames[profile === null || profile === void 0 ? void 0 : profile.ProfileId]) === null || _e === void 0 ? void 0 : _e.includes((_f = profile === null || profile === void 0 ? void 0 : profile.Identity) === null || _f === void 0 ? void 0 : _f.IdentityName))) {
                            identityNames[profile === null || profile === void 0 ? void 0 : profile.ProfileId].push((_g = profile === null || profile === void 0 ? void 0 : profile.Identity) === null || _g === void 0 ? void 0 : _g.IdentityName);
                        }
                    }
                    const p = {
                        id: profile === null || profile === void 0 ? void 0 : profile.ProfileId,
                        name: profile === null || profile === void 0 ? void 0 : profile.ProfileName,
                        identityNames: '',
                        identities: [i]
                    };
                    ruleProfiles.push(p);
                    ruleIdentities.push(i);
                });
            }
            else {
                let i = undefined;
                let p = undefined;
                if ((_b = (_a = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _a === void 0 ? void 0 : _a.Identity) === null || _b === void 0 ? void 0 : _b.IdentityId) {
                    i = {
                        id: (_d = (_c = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _c === void 0 ? void 0 : _c.Identity) === null || _d === void 0 ? void 0 : _d.IdentityId,
                        name: (_f = (_e = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _e === void 0 ? void 0 : _e.Identity) === null || _f === void 0 ? void 0 : _f.IdentityName,
                        identifier: (_h = (_g = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _g === void 0 ? void 0 : _g.Identity) === null || _h === void 0 ? void 0 : _h.IdentityIdentifier
                    };
                    ruleIdentities.push(i);
                }
                if ((_j = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _j === void 0 ? void 0 : _j.ProfileId) {
                    p = {
                        id: (_k = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _k === void 0 ? void 0 : _k.ProfileId,
                        name: (_l = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _l === void 0 ? void 0 : _l.ProfileName,
                        identityNames: (_o = (_m = rule === null || rule === void 0 ? void 0 : rule.Profiles) === null || _m === void 0 ? void 0 : _m.Identity) === null || _o === void 0 ? void 0 : _o.IdentityName,
                        identities: i ? [i] : []
                    };
                    ruleProfiles.push(p);
                }
            }
            ruleProfiles = (_p = ruleProfiles === null || ruleProfiles === void 0 ? void 0 : ruleProfiles.map(profile => {
                var _a;
                const uniqueNames = (_a = identityNames[profile === null || profile === void 0 ? void 0 : profile.id]) !== null && _a !== void 0 ? _a : [];
                uniqueNames === null || uniqueNames === void 0 ? void 0 : uniqueNames.sort();
                const returnProfile = {
                    id: profile === null || profile === void 0 ? void 0 : profile.id,
                    name: profile === null || profile === void 0 ? void 0 : profile.name,
                    identityNames: uniqueNames === null || uniqueNames === void 0 ? void 0 : uniqueNames.join(', '),
                    identities: profile === null || profile === void 0 ? void 0 : profile.identities
                };
                return returnProfile;
            })) !== null && _p !== void 0 ? _p : [];
            const uniqueProfileNames = [];
            ruleProfiles === null || ruleProfiles === void 0 ? void 0 : ruleProfiles.forEach(profile => {
                if ((profile === null || profile === void 0 ? void 0 : profile.name) && !uniqueProfileNames.includes(profile.name)) {
                    uniqueProfileNames.push(profile.name);
                }
            });
            uniqueProfileNames.sort();
            r.profilesNames = uniqueProfileNames.join(', ');
            profiles.push(...ruleProfiles);
            identities.push(...ruleIdentities);
        }
        rules.push(r);
    });
    const uniqueProfileIdentifier = 'id';
    const uniqueProfiles = [...new Map(profiles === null || profiles === void 0 ? void 0 : profiles.map(item => [item[uniqueProfileIdentifier], item])).values()];
    const uniqueIdentityIdentifier = 'id';
    const uniqueIdentities = [...new Map(identities === null || identities === void 0 ? void 0 : identities.map(item => [item[uniqueIdentityIdentifier], item])).values()];
    return React.createElement(React.Fragment, null,
        React.createElement(SpaceBetween, { size: 'l' },
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" },
                    "Identities (",
                    uniqueIdentities.length,
                    ")") },
                React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                    React.createElement(Box, null,
                        React.createElement(ViewTable, { header: null, emptyDescription: 'No identities were involved in this event', items: uniqueIdentities, columnDefinitions: RuleEvaluationIdentitiesColumnDefinitions, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.MONITORING_LIST_SPLIT_PANEL_IDENTITY_LIST, sortingColumnIndex: 1, isSortDescending: false })))),
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" },
                    "Profiles (",
                    uniqueProfiles.length,
                    ")") },
                React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                    React.createElement(Box, null,
                        React.createElement(ViewTable, { header: null, emptyDescription: 'No profiles were involved in this event', items: uniqueProfiles, columnDefinitions: RuleEvaluationProfilesColumnDefinitions, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.MONITORING_LIST_SPLIT_PANEL_PROFILES_LIST, sortingColumnIndex: 1, isSortDescending: false })))),
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" },
                    "Rules (",
                    rules.length,
                    ")") },
                React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                    React.createElement(Box, null,
                        React.createElement(ViewTable, { header: null, emptyDescription: 'No rules were evaluated for this event', items: rules, columnDefinitions: RuleEvaluationRulesColumnDefinitions, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.MONITORING_LIST_SPLIT_PANEL_PROFILES_LIST, sortingColumnIndex: 1, isSortDescending: false }))))));
};
const LogEvent = (props) => {
    var _a, _b;
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('monitoringEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, id: "monitoringEditor" },
            React.createElement(CodeEditor, { code: JSON.stringify((_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.rawSource) !== null && _b !== void 0 ? _b : {}, null, 2), height: editorHeight, heightType: 'px', readOnly: true, language: 'json' })));
};
const PolicySuggestion = (props) => {
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "This feature is in Beta. Please contact Admin for further info.") }));
};
export const getMonitoringListSplitPanel = items => {
    var _a;
    if (!items.length) {
        return makeEmptyPanelContent('Select a log record to see its details.');
    }
    else if (((_a = items[0]) === null || _a === void 0 ? void 0 : _a.id) === "unavailable") {
        return makeUnavailablePanelContent();
    }
    const item = items[0];
    const tabs = [
        {
            label: 'Rule Evaluations',
            id: 'rule_evaluations',
            content: React.createElement(RuleEvaluation, { data: item })
        },
        {
            label: 'Log Event',
            id: 'log_event',
            content: React.createElement(LogEvent, { data: item })
        },
        {
            label: 'Policy Suggestion ᵇᵉᵗᵃ',
            id: 'policy_suggestion',
            disabled: true
            // content: <PolicySuggestion data={item}/>,
        }
    ];
    return {
        header: `xkid: ${item.xkid}`,
        body: (React.createElement(React.Fragment, null,
            React.createElement(SpaceBetween, { size: 'xl' },
                React.createElement(LogDetails, { data: item }),
                React.createElement(Tabs, { tabs: tabs, ariaLabel: "Log details" }))))
    };
};
