import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { SettingsHandlersProvider } from "@/presentation/providers/settings-handlers-provider";
import SettingsFormWrapper from "@/presentation/pages/settings/settings-form-wrapper";
import { breadcrumbsSettingsEdit } from "@/presentation/components/commons/breadcrumbs";
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { useNotifications } from "@/presentation/providers/notifications-provider";
const MakeSettingsEditPage = () => {
    const { notifications } = useNotifications();
    return React.createElement(React.Fragment, null,
        React.createElement(ErrorBoundaryContextProvider, null,
            React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsSettingsEdit, content: React.createElement(React.Fragment, null,
                    React.createElement(SettingsHandlersProvider, null,
                        React.createElement(SettingsFormWrapper, null))), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                    // @ts-ignore
                    stackItems: true }) })));
};
export default MakeSettingsEditPage;
