var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@/lib/auth0';
import { GetDashboardURL, SetDashboardURL } from '@/presentation/storage/misc-storage';
import { DASHBOARD_METABASE_LINK } from '@/presentation/storage';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import Skeleton from "react-loading-skeleton";
import IframeWithResize from "@/presentation/components/iframe/iframe-with-resize";
import { Box } from "@cloudscape-design/components-themed/components";
import { useNavigate } from "react-router-dom";
import { useIsMounted } from '@/presentation/hooks';
const defaultDatetimeOptions = {
    startDateTime: 'now-3M',
    endDateTime: 'now'
};
const Dashboard = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { user, rawToken } = useAuth0();
    const [dashboardLink, setDashboardLink] = useState('');
    const isMounted = useIsMounted();
    const resetAndRedirect = () => {
        if (isMounted.current) {
            setLoading(false);
            navigate("/welcome");
        }
    };
    const fetchDashboardInfo = () => {
        const orgID = String(getOrgIDFromToken(rawToken));
        const dashboardObj = GetDashboardURL(DASHBOARD_METABASE_LINK + orgID) ? JSON.parse(GetDashboardURL(DASHBOARD_METABASE_LINK + orgID)) : undefined;
        if (!dashboardObj) {
            props.dashboardHandler.getMetabaseDashboardLink().then((url) => {
                if (isMounted.current) {
                    if (!(url === null || url === void 0 ? void 0 : url.error) && (url === null || url === void 0 ? void 0 : url.data) && isMounted.current) {
                        SetDashboardURL(DASHBOARD_METABASE_LINK + orgID, {
                            url: url === null || url === void 0 ? void 0 : url.data
                        });
                        setDashboardLink(url === null || url === void 0 ? void 0 : url.data);
                        setLoading(false);
                    }
                    else {
                        resetAndRedirect();
                    }
                }
            }).catch((error) => {
                // something went wrong with Metabase URL fetching
                console.error(error);
                resetAndRedirect();
            });
        }
        else if (isMounted.current) {
            const dashboardLink = dashboardObj === null || dashboardObj === void 0 ? void 0 : dashboardObj.url;
            setDashboardLink(dashboardLink);
            setLoading(false);
        }
    };
    const refreshList = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        try {
            const listParams = {
                start_timestamp: defaultDatetimeOptions.startDateTime,
                end_timestamp: defaultDatetimeOptions.endDateTime,
                size: 0,
            };
            const entityItems = yield ((_a = props.monitoringHandler) === null || _a === void 0 ? void 0 : _a.listEntity(listParams));
            if ((_c = (_b = entityItems === null || entityItems === void 0 ? void 0 : entityItems.hits) === null || _b === void 0 ? void 0 : _b.total) === null || _c === void 0 ? void 0 : _c.value) {
                fetchDashboardInfo();
            }
            else {
                resetAndRedirect();
            }
        }
        catch (error) {
            console.error('Error:', error);
            resetAndRedirect();
        }
    });
    useEffect(() => {
        if (isMounted.current) {
            setLoading(true);
            refreshList();
        }
    }, [user]);
    if (loading || !user) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(Box, { margin: { top: "l" } },
        React.createElement(IframeWithResize, { src: dashboardLink })));
};
export default Dashboard;
