var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler } from '@/presentation/handlers';
import { getRandomUUID } from "@/lib/misc/utils";
export const RuleDependenciesInitialValues = {
    service_id: 0,
    parent_rule_id: 0,
    enabled: false
};
export const FormattedInMemoryRuleDependenciesTypes = [
    'id',
    'searchableID',
    'isSelected',
    'description',
    'isDisabled'
];
const RULE_DEPENDENT_SERVICES = ['S3'];
export class RuleDependenciesHandler extends PagesBaseHandler {
    constructor(remoteHandlers, remotes) {
        super();
        this.ruleDependenciesDetails = RuleDependenciesInitialValues;
        this.refreshRuleDependencyList = false;
        this.isFormattedEntity = true;
        this.initialRules = [];
        this.makeGetServiceRulesVariablesParams = () => {
            return { serviceID: this.ruleDependenciesDetails.service_id };
        };
        this.getPageName = () => {
            return 'RuleDependencies';
        };
        this.setInitialRules = (initialRules) => {
            this.initialRules = initialRules;
            return this;
        };
        this.isRuleDependentService = (serviceName) => {
            return serviceName && RULE_DEPENDENT_SERVICES.includes(serviceName.toUpperCase());
        };
        this.setRuleDependenciesDetails = (parentRuleID, serviceID) => {
            this.ruleDependenciesDetails.parent_rule_id = parentRuleID;
            this.ruleDependenciesDetails.service_id = serviceID;
            return this;
        };
        this.getAttachParams = (ruleID, options) => {
            const diffHelper = options.map((item) => { return item.id; });
            const initial = this.initialRules;
            const difference = initial.filter(x => !diffHelper.includes(x));
            const objects = options.map(option => {
                return {
                    rule_id: ruleID,
                    dependent_rule_id: option[this.getItemID()],
                    deleted: false
                };
            });
            const objects1 = difference.map(option => {
                return {
                    rule_id: ruleID,
                    dependent_rule_id: option,
                    deleted: true
                };
            });
            return {
                objects: objects.concat(objects1)
            };
        };
        this.checkIfResourceLockEnabled = (ruleID, options) => {
            var _a;
            let dependentRuleInfo = [];
            let locked = false;
            dependentRuleInfo = (_a = this.formattedEntities) === null || _a === void 0 ? void 0 : _a.RuleDependencies.filter((item) => {
                const isLocked = item === null || item === void 0 ? void 0 : item.RuleDependenciesResources.filter(resource => (resource === null || resource === void 0 ? void 0 : resource.locked) === true);
                return isLocked.length > 0;
            });
            const dependentRuleIds = dependentRuleInfo === null || dependentRuleInfo === void 0 ? void 0 : dependentRuleInfo.map((items) => {
                return items === null || items === void 0 ? void 0 : items.dependent_rule_id;
            });
            // no resources are locked, let the user do whatever they want
            if ((dependentRuleIds === null || dependentRuleIds === void 0 ? void 0 : dependentRuleIds.length) === 0) {
                return false;
            }
            // user is trying to remove the dependentRule(s) while a lock is in place
            if ((options === null || options === void 0 ? void 0 : options.length) === 0 && (dependentRuleIds === null || dependentRuleIds === void 0 ? void 0 : dependentRuleIds.length) > 0) {
                return true;
            }
            const optionsArr = options.map((option) => { return option === null || option === void 0 ? void 0 : option.id; });
            for (let i = 0; i < (dependentRuleIds === null || dependentRuleIds === void 0 ? void 0 : dependentRuleIds.length); i++) {
                // user removed dependedRule(s) but added some other rules, while a lock is in place
                if (!optionsArr.includes(dependentRuleIds[i])) {
                    locked = true;
                }
            }
            return locked;
        };
        this.remotes = remotes;
        this.remoteHandlers = remoteHandlers;
    }
    getRefreshRuleDependencyList() {
        return this.refreshRuleDependencyList;
    }
    setRefreshRuleDependencyList(value) {
        this.refreshRuleDependencyList = value;
        return this;
    }
    getItemID() {
        return 'id';
    }
    getItemResources() {
        return 'resources';
    }
    getRuleDependenciesDetails() {
        return this.ruleDependenciesDetails;
    }
    getEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.ruleDependencies.get({
                rule_id: id
            });
        });
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.getRulesByService(this.makeGetServiceRulesVariablesParams()).then((rules) => {
                var _a, _b;
                const filtered = rules;
                if (((_a = rules === null || rules === void 0 ? void 0 : rules.Rules) === null || _a === void 0 ? void 0 : _a.length) != 0) {
                    filtered.Rules = (_b = rules === null || rules === void 0 ? void 0 : rules.Rules) === null || _b === void 0 ? void 0 : _b.filter(rule => rule.id !== this.ruleDependenciesDetails.parent_rule_id);
                }
                return filtered;
            });
        });
    }
    listEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.listEntity();
        });
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getEntity(this.ruleDependenciesDetails.parent_rule_id);
        });
    }
    extractFilters(any) {
        return [];
    }
    getFormattedSelectableOptions(serviceRules, selectedOptions) {
        const selectables = serviceRules.Rules.map((rule) => {
            return {
                key: rule.id.toString(),
                meta: rule.Service.GlobalService.Provider.name,
                label: getRandomUUID(),
                subtitle: rule.description,
                title: `${rule.Service.GlobalService.Provider.name + '-RULE-' + rule.id.toString()}`,
                searchableLabel: `${rule.description} ${rule.Service.GlobalService.Provider.name + '-RULE-' + rule.id.toString()}`,
                checked: selectedOptions.includes(rule.id) ? 'on' : undefined
            };
        });
        return this.sortSelectableOptionsBySelectedFirst(selectables);
    }
    setEntities(entity) {
        this.formattedEntities = entity;
        return this;
    }
    getSelectableInMemoryFormattedEntities(serviceRules, selectedRules) {
        const selectables = serviceRules.Rules.map((rule) => {
            return {
                id: rule.id,
                isSelected: selectedRules.includes(rule.id),
                searchableID: String(rule.id),
                description: rule.description,
                isDisabled: false,
                isDefault: false
            };
        });
        selectables.sort((optionA, optionB) => {
            if (optionA.isSelected && optionB.isSelected) {
                return 0;
            }
            else if (optionA.isSelected && !optionB.isSelected) {
                return -1;
            }
            else {
                return 1;
            }
        });
        return selectables;
    }
    handleAttach(ruleID, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.ruleDependencies.attach(this.getAttachParams(ruleID, options));
        });
    }
}
