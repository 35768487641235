import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { RuleSimulatorHandlersProvider } from "@/presentation/providers/rule-simulator-handlers-provider";
import RulesSimulatorListWrapper from "@/presentation/pages/rules-simulator/rules-simulator-list-wrapper";
const MakeRulesSimulatorList = () => {
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(RuleSimulatorHandlersProvider, null,
            React.createElement(RulesSimulatorListWrapper, null))));
};
export default MakeRulesSimulatorList;
