import React, { useEffect, useState } from 'react';
import { Multiselect } from '@cloudscape-design/components-themed/components';
const MultiSelect = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [options, setOptions] = useState([]);
    const [status, setStatus] = useState((_a = props === null || props === void 0 ? void 0 : props.status) !== null && _a !== void 0 ? _a : 'finished');
    const [pageNumber, setPageNumber] = useState(1);
    const [finishedText, setFinishedText] = useState('No more results');
    const handleOnLoadItems = ({ detail: { filteringText, firstPage, samePage } }) => {
        if (!samePage) {
            const updatedPageNumber = pageNumber + 1;
            setPageNumber(updatedPageNumber);
        }
        setStatus('loading');
        props.handleOnLoadItems(filteringText, pageNumber).then(result => {
            setOptions([...result.items]);
            setStatus('finished');
            setFinishedText(!(result === null || result === void 0 ? void 0 : result.hasNext) ? 'No more results' : 'Maximum results reached. Please refine your search.');
        }).catch(e => {
            console.error('E ', e);
            setStatus('error');
        });
    };
    useEffect(() => {
        if (props.async && (props === null || props === void 0 ? void 0 : props.status) !== 'pending') {
            const syntheticEvent = {
                detail: {
                    filteringText: '',
                    firstPage: true,
                    samePage: false
                }
            };
            handleOnLoadItems(syntheticEvent);
        }
    }, [props === null || props === void 0 ? void 0 : props.status, props === null || props === void 0 ? void 0 : props.options]);
    if (!props.async) {
        return React.createElement(Multiselect, { selectedOptions: props.selectedOptions, onChange: props.onChange, options: props.options, filteringType: "auto", placeholder: props.placeholderText, selectedAriaLabel: "Selected", tokenLimit: props.tokenLimit, invalid: props.invalid || false, errorText: (_b = props === null || props === void 0 ? void 0 : props.errorText) !== null && _b !== void 0 ? _b : '' });
    }
    return React.createElement(Multiselect, { filteringType: "manual", filteringPlaceholder: (_c = props === null || props === void 0 ? void 0 : props.filteringPlaceholder) !== null && _c !== void 0 ? _c : '', statusType: status, placeholder: props.placeholderText, loadingText: (_d = props === null || props === void 0 ? void 0 : props.loadingText) !== null && _d !== void 0 ? _d : '', errorText: (_e = props === null || props === void 0 ? void 0 : props.errorText) !== null && _e !== void 0 ? _e : '', recoveryText: (_f = props === null || props === void 0 ? void 0 : props.recoveryText) !== null && _f !== void 0 ? _f : 'Retry', finishedText: finishedText, empty: (_g = props === null || props === void 0 ? void 0 : props.emptyText) !== null && _g !== void 0 ? _g : 'No items found', options: options, selectedOptions: props.selectedOptions, onChange: props.onChange, onLoadItems: handleOnLoadItems, tokenLimit: props.tokenLimit, deselectAriaLabel: e => `Remove ${e.label}`, virtualScroll: true, invalid: props.invalid || false });
};
export default MultiSelect;
