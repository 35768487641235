import React, { useContext } from "react";
import { IdentitiesHandler, ProvidersHandler, ProxiesHandler, RemoteAllowNonCloudTrafficHandler, RemoteDeploymentHandler, RemoteLearningModeHandler, RemoteLogLevelHandler, ServicesHandler, StringValidator, } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
import { RemoteDomainACLs, RemoteIdentities, RemoteProviders, RemoteProxies, RemoteProxyCredentials, RemoteServices } from '@/data/usecases';
import { makeManagementProxyAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { DomainACLHandler } from '@/presentation/handlers/pages/domain-acl-handler';
const defaultContext = {
    providers: new ProvidersHandler(null),
    services: new ServicesHandler(null),
    identities: new IdentitiesHandler(null, null),
    domainACL: new DomainACLHandler(null),
    proxies: new ProxiesHandler(null, null),
    nameValidator: new StringValidator(),
    descriptionValidator: new StringValidator()
};
export const ProxyHandlersProviderContext = React.createContext(defaultContext);
export const useProxiesHandlers = () => useContext(ProxyHandlersProviderContext);
export const ProxiesHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const proxiesRemotes = {
        proxies: new RemoteProxies(getTokenSilently),
        providers: new RemoteProviders(getTokenSilently),
        identities: new RemoteIdentities(getTokenSilently),
        credentials: new RemoteProxyCredentials(makeManagementProxyAPIUrl(), makeAuthorizeHttpClientDecorator())
    };
    const providersRemotes = {
        providers: new RemoteProviders(getTokenSilently),
    };
    const servicesRemotes = {
        services: new RemoteServices(getTokenSilently)
    };
    const servicesHandler = new ServicesHandler(servicesRemotes);
    const identitiesRemotes = {
        identities: new RemoteIdentities(getTokenSilently),
    };
    const proxiesWizardRemoteHandlers = {
        remoteDeploymentHandler: new RemoteDeploymentHandler(),
        remoteLogLevelHandler: new RemoteLogLevelHandler(),
        remoteLearningModeHandler: new RemoteLearningModeHandler(),
        remoteAllowNonCloudTrafficHandler: new RemoteAllowNonCloudTrafficHandler(),
    };
    const providersHandler = new ProvidersHandler(providersRemotes);
    const identitiesHandler = new IdentitiesHandler(null, identitiesRemotes);
    const proxiesHandler = new ProxiesHandler(proxiesWizardRemoteHandlers, proxiesRemotes);
    const nameValidator = new StringValidator().notEmpty().isValidResourceName();
    const descriptionValidator = new StringValidator().notEmpty().isValidResourceDesc();
    const domainACLRemote = new RemoteDomainACLs(getTokenSilently);
    const domainACLHandler = new DomainACLHandler(domainACLRemote);
    return React.createElement(ProxyHandlersProviderContext.Provider, { value: {
            proxies: proxiesHandler,
            services: servicesHandler,
            providers: providersHandler,
            identities: identitiesHandler,
            domainACL: domainACLHandler,
            nameValidator: nameValidator,
            descriptionValidator: descriptionValidator,
        } }, children);
};
