export const RULE_TYPE_EQUAL = 'EQUAL';
export const RULE_TYPE_NOT_EQUAL = 'NOT_EQUAL';
export const RULE_TYPE_MIN_LENGTH = 'MIN_LENGTH';
export const RULE_TYPE_NOT_EMPTY_OPTION_VALUE = 'NOT_EMPTY_OPTION_VALUE';
export const RULE_TYPE_MAX_LENGTH = 'MAX_LENGTH';
export const RULE_TYPE_ALPHANUMERIC = 'ALPHANUMERIC';
export const RULE_TYPE_VALID_RESOURCE_NAME = 'VALID_RESOURCE_NAME';
export const RULE_TYPE_VALID_RESOURCE_DESC = 'VALID_RESOURCE_DESC';
export const RULE_TYPE_VALID_ATTRIBUTE_KEY = 'VALID_ATTRIBUTE_KEY';
export const RULE_TYPE_VALID_ATTRIBUTE_KEY_JSONPATH = 'VALID_ATTRIBUTE_KEY_JSONPATH';
export const RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_VALUE_PAIRS = 'VALID_ATTRIBUTE_TEXT_KEY_VALUE_PAIRS';
export const RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_DOMAIN_NAMES_VALUE_SELECT = 'RULE_TYPE_VALID_ATTRIBUTE_TEXT_KEY_DOMAIN_NAMES_VALUE_SELECT';
export const RULE_TYPE_VALID_ATTRIBUTE_KEYS = 'RULE_TYPE_VALID_ATTRIBUTE_KEYS';
export const RULE_TYPE_VALID_ATTRIBUTE_SELECT_KEY_VALUE_PAIRS = 'VALID_ATTRIBUTE_SELECT_KEY_VALUE_PAIRS';
export const RULE_TYPE_CIDR = 'CIDR';
export const RULE_TYPE_WILDCARD_OVERWRITES = 'WILDCARD_OVERWRITES';
export const RULE_TYPE_VALID_EMAIL = 'VALID_EMAIL';
export const RULE_TYPE_VALID_ALLOWED_DOMAIN = 'VALID_ALLOWED_DOMAIN';
export const RULE_TYPE_VALID_EMAIL_ALLOWED_DOMAIN = 'VALID_EMAIL_ALLOWED_DOMAIN';
export const RULE_TYPE_VALID_URL = 'VALID_URL';
export const RULE_TYPE_GREATER_THAN_NUMBER = 'GREATER_THAN_NUMBER';
export const RULE_TYPE_UNIQUE_ARRAY = 'UNIQUE_ARRAY';
export const RULE_TYPE_ARRAY_COMBO_NOT_VALID = 'ARRAY_COMBO_NOT_VALID';
