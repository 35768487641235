import React, { useContext } from "react";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { OptionsArrayValidator, StringValidator } from "@/presentation/handlers";
import { RemoteUsers } from "@/data/usecases/users/remote-users";
import { OnboardingHandler } from "@/presentation/handlers/pages/onboarding-handler";
const defaultContext = {
    onboardingHandler: new OnboardingHandler(null),
    firstNameValidator: new StringValidator(),
    lastNameValidator: new StringValidator(),
    countryValidator: new OptionsArrayValidator()
};
export const OnboardingHandlersProviderContext = React.createContext(defaultContext);
export const useOnboardingHandlers = () => useContext(OnboardingHandlersProviderContext);
export const OnboardingHandlersProvider = ({ children }) => {
    const authorizeHttpClient = makeAuthorizeHttpClientDecorator();
    const onboardingRemotes = {
        users: new RemoteUsers('', '', '', authorizeHttpClient),
    };
    const onboardingHandler = new OnboardingHandler(onboardingRemotes);
    const firstNameValidator = new StringValidator().notEmpty();
    const lastNameValidator = new StringValidator().notEmpty();
    const countryValidator = new OptionsArrayValidator().notEmptyOptionValue();
    return React.createElement(OnboardingHandlersProviderContext.Provider, { value: {
            onboardingHandler,
            firstNameValidator,
            lastNameValidator,
            countryValidator
        } }, children);
};
