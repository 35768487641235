import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { makeHierarchicalBreadcrumbsFromURLPath, makeBreadcrumbsFromURLPath } from "@/presentation/components/commons/breadcrumbs";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { RulesHandlersProvider } from "@/presentation/providers";
import RulesFormWrapper from "@/presentation/pages/rules/rules-form-wrapper";
import { useLocation } from "react-router-dom";
const MakeRulesWrapper = (props) => {
    const { notifications } = useNotifications();
    const location = useLocation();
    const breadCrumbs = (props === null || props === void 0 ? void 0 : props.isCreate) ? makeBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname) : makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(React.Fragment, null,
                React.createElement(RulesHandlersProvider, null,
                    React.createElement(RulesFormWrapper, Object.assign({}, props)))), contentType: "default" })));
};
export default MakeRulesWrapper;
