export const kiveraDark = {
    base: "vs-dark",
    inherit: true,
    rules: [
        { token: "operators", foreground: "3296FA" },
        { token: "keywords", foreground: "3296FA" },
        { token: "builtinVariables", foreground: "3296FA", fontStyle: "bold" },
        { token: "builtinFunctions", foreground: "3296FA" },
        { token: "symbols", foreground: "FA5454" },
        { token: "strings", foreground: "F3DBFF" },
        { token: "comment", foreground: "65748F" },
        { token: "key", foreground: "3296FA", fontStyle: "bold" },
        { token: "bracket", foreground: "B41EFF" },
        { token: "digits", foreground: "DEEEFE" }
    ],
    colors: {
        "editor.background": "#111122",
        "editor.lineHighlightBackground": "#323246",
        "editorLineNumber.foreground": "#FFFFFF",
        "scrollbar.shadow": "#323246",
        "editorIndentGuide.background": "#65748F"
    }
};
