import jwtDecode from 'jwt-decode';
import { HASURA_ALLOWED_ROLES, HASURA_CLAIMS_URL, HASURA_DEFAULT_ROLE, HASURA_ORG_ID } from '@/lib/auth0/constants';
export function getOrgIDFromToken(token) {
    const decodeAuthToken = jwtDecode(token);
    return decodeAuthToken[HASURA_CLAIMS_URL][HASURA_ORG_ID] ? +decodeAuthToken[HASURA_CLAIMS_URL][HASURA_ORG_ID] : 0;
}
export function getAllowedRolesFromToken(token) {
    const decodeAuthToken = jwtDecode(token);
    return decodeAuthToken[HASURA_CLAIMS_URL][HASURA_ALLOWED_ROLES] ? decodeAuthToken[HASURA_CLAIMS_URL][HASURA_ALLOWED_ROLES] : [];
}
export function getDefaultRoleFromToken(token) {
    const decodeAuthToken = jwtDecode(token);
    return decodeAuthToken[HASURA_CLAIMS_URL][HASURA_DEFAULT_ROLE] ? decodeAuthToken[HASURA_CLAIMS_URL][HASURA_DEFAULT_ROLE] : '';
}
