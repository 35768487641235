var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { KiveraAdminGQLClient, KiveraGQLClient } from '@/infra/http';
import { RemoteBaseClass } from "@/data/usecases";
export class RemoteOrganizationPolicyFunctions extends RemoteBaseClass {
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListOrganizationPolicyFunctions(); }));
        });
    }
    createAndUpdateOrganizationPolicyFunctions(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraAdminGQLClient(token).CreateAndUpdateOrganizationPolicyFunction(params); }));
        });
    }
}
