import React from "react";
import { STATES } from "@/presentation/components/overlay/common";
const Backdrop = (props) => {
    const { overlayState, clickDismiss = true, closeOverlay } = props;
    const inTransition = overlayState === STATES.OPENING || overlayState === STATES.CLOSING;
    /**
     * Closes overlay when the backdrop is clicked and not
     * in transition mode (opening or closing)
     * @param {Event} e
     */
    const handleClick = (e) => {
        const isBackdrop = e.target.dataset && e.target.dataset.comp === "backdrop";
        const canClose = clickDismiss && !inTransition;
        if (canClose && isBackdrop) {
            closeOverlay();
        }
        e.stopPropagation();
    };
    return (React.createElement("div", { className: "backdrop", "data-comp": "backdrop", onClick: handleClick }, props.children));
};
export default Backdrop;
