import React, { useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react';
const IframeWithResize = (props) => {
    const [loading, setLoading] = useState(true);
    const [source, setSource] = useState((props === null || props === void 0 ? void 0 : props.src) || "");
    useEffect(() => {
        if (props.src !== "") {
            setSource(props.src);
        }
    }, [props.src]);
    return (React.createElement(React.Fragment, null,
        loading ? React.createElement("div", { className: 'iframe-loader' },
            React.createElement("div", { className: 'iframe-loader-inner' },
                React.createElement("span", { className: 'iframe-loader-inner-text' }, "Loading..."))) : null,
        source !== "" ?
            React.createElement(IframeResizer, { heightCalculationMethod: "lowestElement", onLoad: () => {
                    setLoading(false);
                }, src: source, style: { width: '1px', minWidth: '100%' } })
            : null));
};
export default IframeWithResize;
