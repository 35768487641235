var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ProvidersHandler {
    constructor(remotes) {
        this.getPropertyFilteringOptions = (providers, propertyKey) => {
            return providers.Providers.map((provider) => {
                return {
                    propertyKey: propertyKey !== null && propertyKey !== void 0 ? propertyKey : 'provider',
                    value: provider.name
                };
            });
        };
        this.remotes = remotes;
    }
    deleteEntity(any) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
    extractFilters(any) {
        return [];
    }
    getEntity(any) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
    getFormattedEntities(any) {
        return [];
    }
    getProviderIDByName(providerName, providers) {
        const filteredProvider = providers.Providers.filter((provider) => {
            return provider.name === providerName;
        });
        if (filteredProvider[0]) {
            return filteredProvider[0].id;
        }
        return 0;
    }
    getFormattedComboValueOptions(providers) {
        const combos = [];
        providers.Providers.forEach((provider) => {
            combos.push({
                label: provider.name,
                value: String(provider.id)
            });
        });
        return combos;
    }
    getFormattedSuperSelectOptions(providers) {
        return providers.Providers.map((provider) => {
            return {
                value: String(provider.id),
                inputDisplay: provider.name
            };
        });
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.providers.list();
        });
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield input;
        });
    }
    deleteBulk(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
}
