import React from 'react';
import { Box, Table } from '@cloudscape-design/components-themed/components';
import TableContainer from '@/presentation/components/table/table-container';
import { useColumnWidths } from "@/presentation/components/commons/use-column-widths";
import { useCollection } from "@cloudscape-design/collection-hooks";
const ViewTable = (props) => {
    const [columnDefinitions] = useColumnWidths(props.columnWidthStorageKey, props.columnDefinitions);
    const { items, collectionProps } = useCollection(props.items, {
        sorting: { defaultState: { sortingColumn: columnDefinitions === null || columnDefinitions === void 0 ? void 0 : columnDefinitions[props.sortingColumnIndex], isDescending: props.isSortDescending } },
    });
    return React.createElement(TableContainer, null,
        React.createElement(Table, Object.assign({}, collectionProps, props, { resizableColumns: true, columnDefinitions: columnDefinitions, items: items, ariaLabels: props.ariaLabels, header: props.header, variant: "embedded", empty: React.createElement(Box, { textAlign: "center", color: "inherit" },
                React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, props.emptyDescription)) })));
};
export default ViewTable;
