var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpStatusCode } from '@/data/protocols/http';
export class RemoteTrailBlazersCredentials {
    constructor(url, client) {
        this.url = url;
        this.httpClient = client;
    }
    get(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const constructedURL = this.url + '/' + 'credentials' + '?' + `trailblazer_id=${params.trailblazer_id}`;
            const httpResponse = yield this.httpClient.request({
                url: constructedURL,
                method: 'GET'
            });
            switch (httpResponse.statusCode) {
                case HttpStatusCode.ok:
                    return makeModel(httpResponse.body, false);
                case HttpStatusCode.badRequest:
                    return makeModel(httpResponse.body, true);
                case HttpStatusCode.forbidden:
                    return makeModel(httpResponse.body, true);
                default:
                    return makeModel(httpResponse.body, true);
            }
        });
    }
}
function makeModel(body, error) {
    var _a, _b;
    return {
        error: error,
        method: (_a = body.method) !== null && _a !== void 0 ? _a : '',
        msg: (_b = body.msg) !== null && _b !== void 0 ? _b : '',
        data: body !== null && body !== void 0 ? body : {}
    };
}
