import React, { useEffect, useState } from "react";
import OrganizationModal from "./my-profile-organization-modal";
import OverlayLoader from "@/presentation/components/overlay/overlay-loader";
import { Box, SpaceBetween, Button, Link } from "@cloudscape-design/components-themed/components";
import { paginationLabels } from "@/presentation/components/commons/labels";
import { useMyProfileHandlers } from "@/presentation/providers";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { MyProfileOrgFilteringOptions, MyProfileOrgFilteringProperties, MyProfileOrgTableColumnDefinitions, MyProfileOrgTablePageSizeOptions, MyProfileOrgTablePreferences, MyProfileOrgTableVisibleContentOptions, } from "@/presentation/pages/my-profile/my-profile-organization-config";
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY, } from "@/presentation/pages/constants-and-types";
import { makeNotification, makePropertyFilterI18nStrings } from "@/presentation/pages";
import InmemoryTable from "@/presentation/components/table/inmemory-table";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from "@/presentation/common-utils/constants";
import { useIsMounted } from '@/presentation/hooks';
const TableEmptyState = () => (React.createElement(Box, { margin: { vertical: "xs" }, textAlign: "center", color: "inherit" },
    React.createElement(SpaceBetween, { size: "xxs" },
        React.createElement("div", null,
            React.createElement("b", null, "You do not belong to any organization"),
            React.createElement(Box, { variant: "p", color: "inherit" }, "Please contact the admin")))));
const MyProfileOrganization = ({ activeOrgID, user }) => {
    const { myProfile } = useMyProfileHandlers();
    const myProfileHandler = myProfile;
    const [showLeaveOrgModal, setShowLeaveOrgModal] = useState(false);
    const onLeaveOrgInit = () => setShowLeaveOrgModal(true);
    const onLeaveOrgDiscard = () => setShowLeaveOrgModal(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isLeaveOrgDisabled, setIsLeaveOrgDisabled] = useState(true);
    const closeOverlay = () => setIsOverlayOpen(false);
    const [loading, setLoading] = useState(true);
    const [formattedOrganizationsList, setFormattedOrganizationsList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const { pushNotifications, dismissNotification } = useNotifications();
    const isMounted = useIsMounted();
    const makeSuccessUpdateNotification = () => {
        const msg = "Successfully left organization";
        return makeNotification("success", msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = (React.createElement(React.Fragment, null,
            React.createElement("span", null, "Failed to leave organization. Please "),
            React.createElement(Link, { fontSize: "inherit", color: "inverted", href: "mailto:support@kivera.io" }, "contact Kivera Support.")));
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        myProfileHandler
            .getAllOrganization({ user_id: user.id })
            .then((organizations) => {
            if (isMounted.current) {
                setFormattedOrganizationsList(myProfileHandler.getFormattedOrganizations(organizations));
                setLoading(false);
            }
        })
            .catch((e) => {
            console.log(e);
        });
    };
    useEffect(() => {
        refreshList();
        return () => {
            console.log("cleaning up");
        };
    }, [user]);
    const onLeaveOrgConfirm = () => {
        const selectedOrgId = selectedItems[0].orgID;
        if (selectedOrgId !== 0) {
            setLoading(true);
            setShowLeaveOrgModal(false); // toggle the modal
            myProfileHandler
                .removeUserFromOrganization(selectedOrgId, user.id)
                .then((deletedOrgResult) => {
                var _a, _b, _c;
                if (((_c = (_b = (_a = deletedOrgResult === null || deletedOrgResult === void 0 ? void 0 : deletedOrgResult.delete_Memberships) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.org_id) === selectedOrgId) {
                    refreshList();
                    pushNotifications(makeSuccessUpdateNotification());
                    if (isMounted.current) {
                        setSelectedItems([]);
                    }
                }
                else {
                    pushNotifications(makeErrorNotification());
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
            })
                .finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        }
    };
    const isItemDisabled = (item) => {
        return activeOrgID === (item === null || item === void 0 ? void 0 : item.orgID);
    };
    const selectionChangeCallback = (selectedItems) => {
        setSelectedItems(selectedItems);
        setIsLeaveOrgDisabled(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InmemoryTable, { loading: loading, loadingText: 'Loading Organizations...', selectionType: "single", variant: 'embedded', columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.MYPROFILE_ORG_LIST, preferencesStorageKey: PREFERENCES_STORAGE_KEY.MYPROFILE_ORG_LIST, defaultPreferences: MyProfileOrgTablePreferences, items: formattedOrganizationsList, resourceName: 'Organizations', tableHeaderVariant: 'h2', actionButtons: React.createElement(React.Fragment, null,
                React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
                    React.createElement(Button, { variant: "normal", disabled: isLeaveOrgDisabled, onClick: onLeaveOrgInit }, "Leave Organization"))), ariaLabels: {}, tableEmptyState: TableEmptyState, headerDescription: 'Organizations that you have access to', selectionChangeCallback: selectionChangeCallback, filteringAriaLabel: 'Filter Identities', filteringPlaceholder: 'Filter Identities', paginationLabels: paginationLabels, visibleContentOptions: MyProfileOrgTableVisibleContentOptions, columnDefinitions: MyProfileOrgTableColumnDefinitions, visibleColumns: MyProfileOrgTablePreferences.visibleContent, pageSizeOptions: MyProfileOrgTablePageSizeOptions, propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Organizations' }), propertyFilterFilteringOptions: MyProfileOrgFilteringOptions, propertyFilterFilteringProperties: MyProfileOrgFilteringProperties, isItemDisabled: isItemDisabled, propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.MY_PROFILE_LIST_ORGANIZATIONS, propertySortingStorageName: TABLE_SORTING_SESSION_STORAGE.MY_PROFILE_LIST_ORGANIZATIONS }),
        React.createElement(OrganizationModal, { visible: showLeaveOrgModal, onDiscard: onLeaveOrgDiscard, onLeaveOrg: onLeaveOrgConfirm, items: selectedItems, headerText: "Leave Organization", consentText: "confirm" }),
        isOverlayOpen && (React.createElement(OverlayLoader, { isOpen: isOverlayOpen, closeOverlay: closeOverlay }))));
};
export default MyProfileOrganization;
