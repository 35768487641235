import React, { useEffect, useState } from 'react';
import { FormField, Input } from '@cloudscape-design/components-themed/components';
import { AttributeErrorCode } from '@/presentation/pages';
const AttributeKeyValueTextComponent = (props) => {
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [value, setValue] = useState('');
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const errorItem = (_b = (_a = props.errorItems) === null || _a === void 0 ? void 0 : _a.filter(it => { var _a; return (it === null || it === void 0 ? void 0 : it.id) === ((_a = props.item) === null || _a === void 0 ? void 0 : _a.id); })) !== null && _b !== void 0 ? _b : [];
        // only allow invalid key, invalid value and duplicate key errors for right now
        if (props.keyOrValue === 'key' && (((_c = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _c === void 0 ? void 0 : _c.errorCodeKey) === AttributeErrorCode.INVALID_KEY || ((_d = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _d === void 0 ? void 0 : _d.errorCodeKey) === AttributeErrorCode.DUPLICATE_KEY)) {
            const errText = (_g = (_e = props.attributeErrorCodeMappings) === null || _e === void 0 ? void 0 : _e[(_f = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _f === void 0 ? void 0 : _f.errorCodeKey]) !== null && _g !== void 0 ? _g : '';
            setErrorText(errText);
            setIsInvalid((errorItem === null || errorItem === void 0 ? void 0 : errorItem.length) === 1);
        }
        else if (props.keyOrValue === 'value' && ((_h = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _h === void 0 ? void 0 : _h.errorCodeValue) === AttributeErrorCode.INVALID_VALUE) {
            const errText = (_l = (_j = props.attributeErrorCodeMappings) === null || _j === void 0 ? void 0 : _j[(_k = errorItem === null || errorItem === void 0 ? void 0 : errorItem[0]) === null || _k === void 0 ? void 0 : _k.errorCodeValue]) !== null && _l !== void 0 ? _l : '';
            setErrorText(errText);
            setIsInvalid((errorItem === null || errorItem === void 0 ? void 0 : errorItem.length) === 1);
        }
        else {
            // Clear the previous errors
            setErrorText('');
            setIsInvalid(false);
        }
    }, [props.errorItems]);
    useEffect(() => {
        var _a;
        // this item has changed
        if (((_a = props.items.filter(it => { var _a; return (it === null || it === void 0 ? void 0 : it.id) === ((_a = props.item) === null || _a === void 0 ? void 0 : _a.id); })) === null || _a === void 0 ? void 0 : _a.length) === 1) {
            setValue(props.item[props.keyOrValue]);
        }
    }, [props]);
    return React.createElement(FormField, { errorText: isInvalid ? errorText : '' },
        React.createElement(Input, { key: props.item.id + '-' + props.keyOrValue, value: value, onChange: (event) => {
                var _a;
                setValue((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.value);
                props.onChange(event, props.item);
            }, onBlur: props.onBlur, invalid: isInvalid, placeholder: props.placeholderText }));
};
export default AttributeKeyValueTextComponent;
