import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { SettingsHandlersProvider } from "@/presentation/providers/settings-handlers-provider";
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useParams } from "react-router-dom";
import { breadcrumbsSettingsDomainACLsStructureCreate, breadcrumbsSettingsDomainACLsStructureEdit } from '@/presentation/components/commons/breadcrumbs';
import SettingsDomainACLFormWrapper from '@/presentation/pages/settings/domain-acls/settings-domain-acls-form-wrapper';
const MakeSettingsDomainACLPage = (props) => {
    var _a;
    const { notifications } = useNotifications();
    const params = useParams();
    const domainACLID = (_a = params === null || params === void 0 ? void 0 : params.domainACLID) !== null && _a !== void 0 ? _a : '';
    return React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: props.isCreate ? breadcrumbsSettingsDomainACLsStructureCreate : breadcrumbsSettingsDomainACLsStructureEdit(domainACLID), content: React.createElement(SettingsHandlersProvider, null,
                React.createElement(SettingsDomainACLFormWrapper, Object.assign({}, props))), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }) }));
};
export default MakeSettingsDomainACLPage;
