import React, { useEffect, useState } from "react";
import { Box, Container, Header, SpaceBetween, ColumnLayout, } from "@cloudscape-design/components-themed/components";
import { DashboardHandler } from "@/presentation/handlers/pages/dashboard/dashboard-handler";
import Billing from "./settings-billing";
import { RemoteMetabaseDashboard } from "@/data/usecases";
import { makeManagementDashboardAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
const SettingsBillingWrapper = ({ orgPlanDetails }) => {
    const [currentOrgPlanDetails, setCurrentOrgPlanDetails] = useState(null);
    const remotes = {
        dashboardMetabase: new RemoteMetabaseDashboard(makeManagementDashboardAPIUrl(), makeAuthorizeHttpClientDecorator()),
    };
    const dashboardHandler = new DashboardHandler(remotes);
    useEffect(() => {
        setCurrentOrgPlanDetails(orgPlanDetails);
    }, [orgPlanDetails]);
    const requestCount = (currentOrgPlanDetails === null || currentOrgPlanDetails === void 0 ? void 0 : currentOrgPlanDetails.max_total_request_count) !== undefined &&
        (currentOrgPlanDetails === null || currentOrgPlanDetails === void 0 ? void 0 : currentOrgPlanDetails.max_total_request_count) !== null
        ? currentOrgPlanDetails === null || currentOrgPlanDetails === void 0 ? void 0 : currentOrgPlanDetails.max_total_request_count
        : "";
    return (React.createElement(SpaceBetween, { size: "xl" },
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Billing Details") },
            React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Plan Name"),
                        React.createElement(Box, null, (currentOrgPlanDetails === null || currentOrgPlanDetails === void 0 ? void 0 : currentOrgPlanDetails.name) || ""))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Maximum Total Request Count"),
                        React.createElement(Box, null, requestCount))))),
        React.createElement(Container, null,
            React.createElement(Billing, { dashboardHandler: dashboardHandler }))));
};
export default SettingsBillingWrapper;
