var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { uniqueAndSortedArrayOfObjectFilters } from "@/presentation/common-utils/common-utils";
import { UserRolesReadableText } from "@/presentation/pages/settings/settings-types";
export class MyProfileHandler {
    constructor(remotes) {
        this.getFormattedEntities = (list) => {
            var _a, _b;
            const organizationNameList = [];
            const apiKeyData = (_b = (_a = list === null || list === void 0 ? void 0 : list.UserApiKeys) === null || _a === void 0 ? void 0 : _a.map(apiKey => {
                var _a, _b, _c;
                organizationNameList.push((_a = apiKey === null || apiKey === void 0 ? void 0 : apiKey.Organization) === null || _a === void 0 ? void 0 : _a.company_name);
                return {
                    id: apiKey === null || apiKey === void 0 ? void 0 : apiKey.id,
                    apiKeyID: apiKey === null || apiKey === void 0 ? void 0 : apiKey.client_id,
                    createdDate: apiKey === null || apiKey === void 0 ? void 0 : apiKey.created,
                    companyName: (_b = apiKey === null || apiKey === void 0 ? void 0 : apiKey.Organization) === null || _b === void 0 ? void 0 : _b.company_name,
                    status: (apiKey === null || apiKey === void 0 ? void 0 : apiKey.status) ? 'ACTIVE' : 'REVOKED',
                    description: (_c = apiKey === null || apiKey === void 0 ? void 0 : apiKey.description) !== null && _c !== void 0 ? _c : ""
                };
            })) !== null && _b !== void 0 ? _b : [];
            const organizationNameFilteredList = uniqueAndSortedArrayOfObjectFilters(organizationNameList, 'companyName');
            return { apiKeyTokens: apiKeyData, organizationNameFilteredList };
        };
        this.getFormattedOrganizations = (listOrg) => {
            var _a;
            return (_a = listOrg === null || listOrg === void 0 ? void 0 : listOrg.Memberships) === null || _a === void 0 ? void 0 : _a.map(memberships => {
                var _a;
                const { Organization, MembershipRoles } = memberships;
                const roles = MembershipRoles === null || MembershipRoles === void 0 ? void 0 : MembershipRoles.map(roleData => { var _a; return (_a = roleData === null || roleData === void 0 ? void 0 : roleData.Role) === null || _a === void 0 ? void 0 : _a.role_name; });
                const formattedOrg = {
                    orgID: Organization === null || Organization === void 0 ? void 0 : Organization.id,
                    companyName: Organization === null || Organization === void 0 ? void 0 : Organization.company_name,
                    roleName: (_a = UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[roles === null || roles === void 0 ? void 0 : roles[0]]) !== null && _a !== void 0 ? _a : ''
                };
                return formattedOrg;
            });
        };
        this.remotes = remotes;
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.list();
        });
    }
    deleteEntity(any) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
    extractFilters(any) {
        return [];
    }
    getEntity(any) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
    getEntityForInmemorySelectable(input) {
        return input;
    }
    resetUserPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.resetUserPassword();
        });
    }
    getAllOrganization(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.getOrgs(userId);
        });
    }
    listUserApiKeys() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.list();
        });
    }
    updateEntity(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.update(params);
        });
    }
    generateUserApiKey(description) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.generateUserApiKey(description);
        });
    }
    editUserApiKey(id, description) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.updateUserAPIKeyDescription({
                id: id,
                description: description
            });
        });
    }
    removeUserFromOrganization(orgId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.removeUserFromOrganization({
                user_id: userId,
                org_id: orgId,
            });
        });
    }
    deactivateUserApiKeys(apiKeyID) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.myprofile.deactivateUserApiKeys({
                key_id: apiKeyID,
            });
        });
    }
}
