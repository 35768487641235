import React, { useContext, createContext } from 'react';
import { IdentitiesHandler, ProfilesHandler, StringValidator, RulesHandler, ProvidersHandler, ServicesHandler } from '@/presentation/handlers';
import { useAuth0 } from '@/lib/auth0';
import { RemoteComplianceMappings, RemoteIdentities, RemoteProfiles, RemoteProviders, RemoteRules, RemoteServices } from '@/data/usecases';
import { ComplianceMappingsHandler } from "@/presentation/handlers/pages/compliance-mappings-handler";
const defaultContext = {
    profiles: new ProfilesHandler(null, null),
    rules: new RulesHandler(null, null),
    identities: new IdentitiesHandler(null, null),
    nameValidator: new StringValidator(),
    descriptionValidator: new StringValidator(),
    providers: new ProvidersHandler(null),
    services: new ServicesHandler(null),
    complianceMappings: new ComplianceMappingsHandler(null)
};
export const ProfilesHandlersProviderContext = createContext(defaultContext);
export const useProfilesHandlers = () => useContext(ProfilesHandlersProviderContext);
export const ProfilesHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const profilesRemotes = {
        profiles: new RemoteProfiles(getTokenSilently),
        rules: new RemoteRules(getTokenSilently),
        identities: new RemoteIdentities(getTokenSilently),
    };
    const rulesRemotes = {
        rules: new RemoteRules(getTokenSilently)
    };
    const identitiesRemotes = {
        identities: new RemoteIdentities(getTokenSilently),
        profiles: new RemoteProfiles(getTokenSilently)
    };
    const complianceMappingRemotes = new RemoteComplianceMappings(getTokenSilently);
    const complianceMappingsHandler = new ComplianceMappingsHandler(complianceMappingRemotes);
    const profilesHandler = new ProfilesHandler(null, profilesRemotes);
    const rulesHandler = new RulesHandler({
        remoteComplianceMappingsHandler: complianceMappingsHandler
    }, rulesRemotes);
    const identitiesHandler = new IdentitiesHandler(null, identitiesRemotes);
    const nameValidator = new StringValidator().notEmpty().isValidResourceName();
    const descriptionValidator = new StringValidator().notEmpty().isValidResourceDesc();
    const providerRemotes = {
        providers: new RemoteProviders(getTokenSilently)
    };
    const providersHandler = new ProvidersHandler(providerRemotes);
    const servicesRemotes = {
        services: new RemoteServices(getTokenSilently)
    };
    const servicesHandler = new ServicesHandler(servicesRemotes);
    return React.createElement(ProfilesHandlersProviderContext.Provider, { value: {
            profiles: profilesHandler,
            rules: rulesHandler,
            identities: identitiesHandler,
            nameValidator: nameValidator,
            descriptionValidator: descriptionValidator,
            complianceMappings: complianceMappingsHandler,
            providers: providersHandler,
            services: servicesHandler
        } }, children);
};
