import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { breadcrumbsReporting } from "@/presentation/components/commons/breadcrumbs";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import Reporting from "@/presentation/pages/reporting/reporting";
import { DashboardHandler } from "@/presentation/handlers/pages/dashboard/dashboard-handler";
import { RemoteMetabaseDashboard } from "@/data/usecases";
import { makeManagementDashboardAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
const ReportingFactory = (props) => {
    const remotes = {
        dashboardMetabase: new RemoteMetabaseDashboard(makeManagementDashboardAPIUrl(), makeAuthorizeHttpClientDecorator()),
    };
    const reportingHandler = new DashboardHandler(remotes);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsReporting, content: React.createElement(Reporting, { reportingHandler: reportingHandler }), contentType: "default", className: "metabase-background" })));
};
export default ReportingFactory;
