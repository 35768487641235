import { LocalStorageAdapter } from '@/infra/cache';
export var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "asc";
    SortDirection["DESC"] = "desc";
})(SortDirection || (SortDirection = {}));
export const InitialDateTimeOptions = {
    startDateTime: 'now-4h',
    endDateTime: 'now'
};
export const InitialDateRangeOptions = {
    value: {
        amount: 4,
        unit: 'hour',
        type: 'relative',
        key: 'last-4-hours'
    }
};
export const GetDateTimeOptions = (dateTimeComponentName, defaultDateTimeOptions) => {
    const ls = new LocalStorageAdapter();
    const dateTimeOptions = ls.get(dateTimeComponentName);
    if (!dateTimeOptions) {
        return defaultDateTimeOptions !== null && defaultDateTimeOptions !== void 0 ? defaultDateTimeOptions : InitialDateTimeOptions;
    }
    return dateTimeOptions;
};
export const SetDateTimeOptions = (dateTimeComponentName, dateTimeOptions) => {
    const ls = new LocalStorageAdapter();
    ls.set(dateTimeComponentName, dateTimeOptions);
};
export const GetDateRangeChangeDetail = (dateRangeComponentName, defaultDateRange) => {
    const ls = new LocalStorageAdapter();
    const dateRangeOptions = ls.get(dateRangeComponentName);
    if (!dateRangeOptions) {
        return defaultDateRange !== null && defaultDateRange !== void 0 ? defaultDateRange : InitialDateRangeOptions;
    }
    return dateRangeOptions;
};
export const SetDateRangeChangeDetail = (dateRangeComponentName, dateRange) => {
    const ls = new LocalStorageAdapter();
    ls.set(dateRangeComponentName, dateRange);
};
