export const rulesFilteringActionsProperties = [
    {
        key: "action",
        operators: ["="],
        propertyLabel: "Action",
        groupValuesLabel: "Action"
    }
];
export const rulesFilteringProperties = [
    {
        key: "endpoint",
        operators: ["="],
        propertyLabel: "Endpoint",
        groupValuesLabel: "Endpoint"
    },
    {
        key: "path",
        operators: ['='],
        propertyLabel: "Path",
        groupValuesLabel: "Path"
    },
    {
        key: "method",
        operators: ["="],
        propertyLabel: "Method",
        groupValuesLabel: "Method"
    },
];
export const MethodFilters = [
    {
        propertyKey: "method",
        value: "*"
    },
    {
        propertyKey: "method",
        value: "GET"
    },
    {
        propertyKey: "method",
        value: "POST"
    },
    {
        propertyKey: "method",
        value: "PATCH"
    },
    {
        propertyKey: "method",
        value: "DELETE"
    },
    {
        propertyKey: "method",
        value: "PUT"
    },
    {
        propertyKey: "method",
        value: "OPTIONS"
    },
    {
        propertyKey: "method",
        value: "HEAD"
    },
];
export const ActionFiltersTokenGroup = [
    { value: "*" },
];
export const ActionFilters = [
    {
        propertyKey: "action",
        value: "*"
    },
];
export const EndpointFilters = [
    {
        propertyKey: "endpoint",
        value: "*"
    },
];
export const PathFilters = [
    {
        propertyKey: "path",
        value: "*"
    },
];
