export var NotificationsMonitorsNS;
(function (NotificationsMonitorsNS) {
    NotificationsMonitorsNS.DefaultQueryParameter = {
        IdentityId: "*",
        RuleId: "*",
        Profile: "*",
        ProfileId: "*",
        Valid: "*"
    };
})(NotificationsMonitorsNS || (NotificationsMonitorsNS = {}));
