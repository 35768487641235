import { Box, CollectionPreferences, ColumnLayout, Container, Header, Pagination, PropertyFilter, SpaceBetween, Table } from '@cloudscape-design/components-themed/components';
import React, { useState } from 'react';
import { makeEmptyPanelContent, makePropertyFilterI18nStrings, makeSplitPanelI18nStrings, } from '@/presentation/pages';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { DomainACL } from '@/domain/models';
var ACLAction = DomainACL.ACLAction;
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { DomainACLsListViewFilteringProperties, DomainACLsListViewTablePageSizeOptions, DomainACLsListViewTablePreferences, DomainACLsListViewTableVisibleContentOptions, DomainACLSplitPanelListFilteringOptions, makeDomainACLBadge } from '@/presentation/pages/settings/domain-acls/settings-domain-acls-config';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters } from '@/presentation/storage/misc-storage';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
const DomainACLDetails = (props) => {
    var _a, _b, _c;
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Domain ACL Details") },
        React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                    React.createElement(Box, null, (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.id))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Name"),
                    React.createElement(Box, null, (_b = props === null || props === void 0 ? void 0 : props.data) === null || _b === void 0 ? void 0 : _b.name))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Default Action"),
                    React.createElement(Box, null, makeDomainACLBadge((_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.default_action))))));
};
const DomainActionMappings = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const allowedDomains = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.allowed_domains) === null || _b === void 0 ? void 0 : _b.map(domain => {
        return {
            'domain': domain,
            'action': ACLAction.ALLOW
        };
    })) !== null && _c !== void 0 ? _c : [];
    const blockedDomains = (_f = (_e = (_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.blocked_domains) === null || _e === void 0 ? void 0 : _e.map(domain => {
        return {
            'domain': domain,
            'action': ACLAction.BLOCK
        };
    })) !== null && _f !== void 0 ? _f : [];
    const mappings = [...allowedDomains, ...blockedDomains];
    const aclColumnDefinitions = [
        {
            id: 'domain',
            sortingField: 'domain',
            header: 'Domain',
            cell: (item) => {
                return item === null || item === void 0 ? void 0 : item.domain;
            }
        },
        {
            id: 'action',
            sortingField: 'action',
            header: 'Action',
            cell: (item) => {
                if (item === null || item === void 0 ? void 0 : item.action) {
                    return makeDomainACLBadge(item === null || item === void 0 ? void 0 : item.action);
                }
                return '-';
            }
        }
    ];
    const [renderCount, setRenderCount] = useState(1);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.SETTINGS_DOMAIN_ACLS_LIST_VIEW, aclColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.SETTINGS_DOMAIN_ACLS_LIST_VIEW, DomainACLsListViewTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST_VIEW);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST_VIEW);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : aclColumnDefinitions === null || aclColumnDefinitions === void 0 ? void 0 : aclColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(mappings, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: DomainACLsListViewFilteringProperties,
            empty: (React.createElement(Box, { textAlign: "center", color: "inherit" },
                React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No domains are configured for this ACL"))),
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'domain action mappings' })
        },
        sorting: { defaultState: defaultSortingState },
        pagination: { pageSize: preferences.pageSize },
        selection: {}
    });
    return (React.createElement(Container, null,
        React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
            React.createElement(Box, null,
                React.createElement(Table, Object.assign({}, collectionProps, { header: React.createElement(TableHeader, { title: "Domains", totalItems: items || [] }), filter: React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                        React.createElement("div", { className: 'list-property-filter' },
                            React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Domains' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: DomainACLSplitPanelListFilteringOptions, filteringProperties: DomainACLsListViewFilteringProperties, onChange: ({ detail }) => {
                                    SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST_VIEW, detail);
                                    actions.setPropertyFiltering(detail);
                                } })))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: DomainACLsListViewTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: DomainACLsListViewTableVisibleContentOptions
                        } }), variant: "embedded", columnDefinitions: columnDefinitions, items: items }))))));
};
export const MakeDomainACLSplitPanel = (items) => {
    if (!items.length) {
        return makeEmptyPanelContent('Select a domain acl record to see its details.');
    }
    const item = items[0];
    const header = item.name;
    return {
        header: header,
        panelStrings: makeSplitPanelI18nStrings(),
        body: (React.createElement(SpaceBetween, { size: 'xl' },
            React.createElement(DomainACLDetails, { data: item }),
            React.createElement(DomainActionMappings, { data: item })))
    };
};
