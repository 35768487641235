export const getFieldOnChange = (fieldType, fieldKey, onChangeFn) => {
    return (event) => {
        var _a, _b, _c, _d, _e;
        let fieldValue;
        switch (fieldType) {
            case 'text':
                fieldValue = event === null || event === void 0 ? void 0 : event.value;
                break;
            case 'code':
                fieldValue = event;
                break;
            case 'select':
                fieldValue = event === null || event === void 0 ? void 0 : event.selectedOption;
                break;
            case 'multi-select':
                fieldValue = event === null || event === void 0 ? void 0 : event.selectedOptions;
                break;
            case 'radio':
                fieldValue = event === null || event === void 0 ? void 0 : event.checked;
                break;
            case 'tiles':
                fieldValue = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.value;
                break;
            case 'tags':
                fieldValue = (_b = event === null || event === void 0 ? void 0 : event.detail) === null || _b === void 0 ? void 0 : _b.tags;
                break;
            case 'table':
                fieldValue = (_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.selectedItems;
                break;
            case 'validation':
                fieldValue = event;
                break;
            case 'select-id':
                fieldValue = (_d = event === null || event === void 0 ? void 0 : event.selectedOption) === null || _d === void 0 ? void 0 : _d.value;
                break;
            case 'propertyFilter':
                fieldValue = event === null || event === void 0 ? void 0 : event.tokens;
                break;
            case 'tokenGroup':
                fieldValue = event;
                break;
            case 'as-is':
                fieldValue = event;
                break;
            default:
                fieldValue = (_e = event === null || event === void 0 ? void 0 : event.detail) === null || _e === void 0 ? void 0 : _e.value;
        }
        onChangeFn({
            [fieldKey]: fieldValue,
        });
    };
};
