var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DomainACL } from '@/domain/models';
var ACLAction = DomainACL.ACLAction;
const defaultFormattedUser = {
    id: '',
    given_name: '',
    family_name: ''
};
export class DomainACLHandler {
    constructor(remote) {
        this.getFormattedDomainACLs = (listACLs) => {
            var _a, _b, _c;
            let tempACLs = {};
            let allowedDomains = {};
            let blockedDomains = {};
            for (let i = 0; i < ((_a = listACLs === null || listACLs === void 0 ? void 0 : listACLs.DomainAcls) === null || _a === void 0 ? void 0 : _a.length); i++) {
                const { id = undefined, name = '', default_action = '', DomainAclEntries = [], created_at = '', CreatedByUser, updated_at = '', UpdatedByUser } = listACLs.DomainAcls[i];
                const { id: created_by_id = '', given_name: created_given_name = '', family_name: created_family_name = '' } = CreatedByUser || defaultFormattedUser;
                const { id: updated_by_id = '', given_name: updated_given_name = '', family_name: updated_family_name = '' } = UpdatedByUser || defaultFormattedUser;
                if (id) {
                    DomainAclEntries === null || DomainAclEntries === void 0 ? void 0 : DomainAclEntries.map(entry => {
                        if ((entry === null || entry === void 0 ? void 0 : entry.action) === ACLAction.ALLOW) {
                            if (!allowedDomains[id]) {
                                allowedDomains[id] = [];
                            }
                            allowedDomains[id].push(entry === null || entry === void 0 ? void 0 : entry.domain);
                        }
                        else if ((entry === null || entry === void 0 ? void 0 : entry.action) === ACLAction.BLOCK) {
                            if (!blockedDomains[id]) {
                                blockedDomains[id] = [];
                            }
                            blockedDomains[id].push(entry === null || entry === void 0 ? void 0 : entry.domain);
                        }
                    });
                    const allowedDomainLength = ((_b = allowedDomains[id]) === null || _b === void 0 ? void 0 : _b.length) || 0;
                    const blockedDomainLength = ((_c = blockedDomains[id]) === null || _c === void 0 ? void 0 : _c.length) || 0;
                    tempACLs[id] = {
                        allowed_domains: allowedDomainLength ? allowedDomains[id] : [],
                        blocked_domains: blockedDomainLength ? blockedDomains[id] : [],
                        allowed_domain_count: allowedDomainLength,
                        blocked_domain_count: blockedDomainLength,
                        default_action: default_action,
                        id,
                        name: name,
                        created_at: created_at,
                        created_by: created_by_id,
                        updated_at: updated_at,
                        updated_by: updated_by_id,
                        createdByFullName: `${created_given_name} ${created_family_name}`,
                        updatedByFullName: `${updated_given_name} ${updated_family_name}`,
                    };
                }
            }
            const formattedACLs = Object.values(tempACLs) || [];
            return formattedACLs;
        };
        this.remote = remote;
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.list();
        });
    }
    create(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.create(params);
        });
    }
    get(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.get(params);
        });
    }
    update(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.update(params);
        });
    }
    delete(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.delete(params);
        });
    }
}
