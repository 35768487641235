import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { SettingsHandlersProvider } from "@/presentation/providers/settings-handlers-provider";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { breadcrumbsSettingsOrgPolicyFunctionsEdit } from "@/presentation/components/commons/breadcrumbs";
import { Flashbar, } from "@cloudscape-design/components-themed/components";
import SettingsOrganizationPolicyFunctionsEdit from "@/presentation/pages/settings/orgnanization-policy-functions/settings-organization-policy-functions-edit";
import { useNotifications } from "@/presentation/providers/notifications-provider";
const MakeSettingsOrgPolicyFuncsPage = () => {
    const { notifications } = useNotifications();
    return React.createElement(React.Fragment, null,
        React.createElement(ErrorBoundaryContextProvider, null,
            React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsSettingsOrgPolicyFunctionsEdit, content: React.createElement(React.Fragment, null,
                    React.createElement(SettingsHandlersProvider, null,
                        React.createElement(SettingsOrganizationPolicyFunctionsEdit, null))), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                    // @ts-ignore
                    stackItems: true }) })));
};
export default MakeSettingsOrgPolicyFuncsPage;
