export const LogLevelDebug = 'DEBUG';
export const LogLevelInfo = 'INFO';
export const LogLevelWarning = 'WARNING';
export const LogLevelError = 'ERROR';
export class Logger {
    log(logMsg, level) {
        switch (level) {
            case LogLevelDebug:
                return console.debug(logMsg);
            case LogLevelInfo:
                return console.info(logMsg);
            case LogLevelWarning:
                return console.warn(logMsg);
            case LogLevelError:
                return console.error(logMsg);
            default:
                return console.log(logMsg);
        }
    }
}
