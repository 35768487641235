import React, { useEffect, useState } from 'react';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import Dashboard from "@/presentation/pages/dashboard/dashboard";
import { DashboardHandler } from "@/presentation/handlers/pages/dashboard/dashboard-handler";
import { RemoteMetabaseDashboard, RemoteLogsMonitoring } from "@/data/usecases";
import { makeManagementDashboardAPIUrl, makeLogsAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { MonitoringHandler } from "@/presentation/handlers/pages";
import { useIsMounted } from '@/presentation/hooks';
import { useAuth0 } from '@/lib/auth0';
import { ReactComponent as OrganizationIcon } from '@/presentation/assets/img/icons/organization_icon.svg';
const DashboardFactory = (props) => {
    const remotes = {
        dashboardMetabase: new RemoteMetabaseDashboard(makeManagementDashboardAPIUrl(), makeAuthorizeHttpClientDecorator()),
    };
    const dashboardHandler = new DashboardHandler(remotes);
    const monitoringRemotes = {
        monitoring: new RemoteLogsMonitoring(makeLogsAPIUrl(), makeAuthorizeHttpClientDecorator())
    };
    const monitoringHandler = new MonitoringHandler(monitoringRemotes);
    const [orgName, setOrgName] = useState('');
    const isMounted = useIsMounted();
    const { user } = useAuth0();
    useEffect(() => {
        var _a, _b;
        if (isMounted.current) {
            const fullUserDetails = user;
            if (fullUserDetails) {
                const currentMemberShips = (fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.memberships) || [];
                const org = currentMemberShips.filter((membership) => (membership === null || membership === void 0 ? void 0 : membership.organization.id) === (fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.org_id));
                if (org.length === 1) {
                    setOrgName(((_b = (_a = org[0]) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.company_name) || '');
                }
            }
        }
    }, [user]);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex justify-end" },
                    React.createElement("div", { className: "org-name-banner org-name-banner-dashboard" },
                        React.createElement("span", { className: "org-name-icon" },
                            React.createElement(OrganizationIcon, null)),
                        React.createElement("span", { className: "organization-name" }, orgName))),
                React.createElement(Dashboard, { dashboardHandler: dashboardHandler, monitoringHandler: monitoringHandler })), contentType: "default", className: "metabase-background" })));
};
export default DashboardFactory;
