export const TOASTS_ADD = 'TOASTS_ADD';
export const TOASTS_REMOVE = 'TOASTS_REMOVE';
export const toastsReducer = (state, action) => {
    switch (action.type) {
        case TOASTS_ADD:
            return [action.payload, ...state];
        case TOASTS_REMOVE:
            return state.filter((toast) => toast.id !== action.payload.id);
        default:
            return state;
    }
};
