import React from 'react';
import { Button } from '@cloudscape-design/components-themed/components';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
const ButtonLink = (props) => {
    var _a, _b;
    return React.createElement(React.Fragment, null,
        React.createElement(NavLink, { className: 'button-link-nav ' + ((_a = props === null || props === void 0 ? void 0 : props.tooltipClass) !== null && _a !== void 0 ? _a : ''), to: props.to },
            React.createElement(Button, Object.assign({}, props))),
        (props === null || props === void 0 ? void 0 : props.tooltipClass)
            ? React.createElement(Tooltip, { anchorSelect: '.' + ((_b = props === null || props === void 0 ? void 0 : props.tooltipClass) !== null && _b !== void 0 ? _b : ''), clickable: true, place: 'bottom', opacity: 1, style: { zIndex: 1 }, delayShow: 100 }, props === null || props === void 0 ? void 0 : props.tooltipMessage)
            : null);
};
export default ButtonLink;
