export class SessionStorageAdapter {
    set(key, value, ttl = 0) {
        const now = new Date();
        const item = {
            value: value,
            expiry: ttl === 0 ? 0 : (now.getTime() + ttl)
        };
        sessionStorage.setItem(key, JSON.stringify(item));
    }
    get(key) {
        const itemStr = sessionStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (item.expiry !== 0 && now.getTime() > item.expiry) {
            sessionStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
    clear() {
        sessionStorage.clear();
    }
}
