import { Alert, Box, Button, ExpandableSection, Modal, SpaceBetween, } from "@cloudscape-design/components-themed/components";
import React, { useEffect, useState } from "react";
const CloudTenantStructureReference = (props) => {
    const [items, setItems] = useState((props === null || props === void 0 ? void 0 : props.items) || []);
    useEffect(() => {
        setItems(props === null || props === void 0 ? void 0 : props.items);
    }, [props.visible, props.items]);
    return (React.createElement(Modal, { visible: props.visible, onDismiss: props.onDiscard, header: "Cloud Tenant Structure In Use", closeAriaLabel: "Close dialog", footer: React.createElement(Box, { float: "right" },
            React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: props.onDiscard }, "Close"))) }, items.length > 0 ? (React.createElement(SpaceBetween, { size: "m" },
        React.createElement(Alert, { type: "warning" },
            React.createElement(Box, { variant: "span" },
                "Cloud tenant structure",
                " ",
                React.createElement(Box, { variant: "span", fontWeight: "bold" }, (props === null || props === void 0 ? void 0 : props.tenantName) || ""),
                " ",
                "cannot be deleted because it is being used by",
                " ",
                React.createElement(Box, { variant: "span", fontWeight: "bold" }, items.length === 1
                    ? "1 identity."
                    : `${items.length} identities.`),
                " ",
                "Please detach the cloud tenant structure from the following identities before deleting it.")),
        React.createElement(ExpandableSection, { headerText: "Identities using this cloud tenant structure", defaultExpanded: true }, items))) : null));
};
export default CloudTenantStructureReference;
