var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ComplianceMappingsHandler {
    constructor(remote) {
        this.getUniqueComplianceFrameworksForOrg = (complianceMappings, orgID) => {
            const filtered = complianceMappings === null || complianceMappings === void 0 ? void 0 : complianceMappings.filter(mapping => String(mapping === null || mapping === void 0 ? void 0 : mapping.organization_id) === orgID);
            return this.getUniqueComplianceFrameworks(filtered);
        };
        this.getUniqueComplianceFrameworks = (complianceMappings) => {
            const uniqueComplianceFrameworks = [];
            complianceMappings === null || complianceMappings === void 0 ? void 0 : complianceMappings.forEach(mapping => {
                if (!!(mapping === null || mapping === void 0 ? void 0 : mapping.framework) && !uniqueComplianceFrameworks.includes(mapping === null || mapping === void 0 ? void 0 : mapping.framework)) {
                    uniqueComplianceFrameworks.push(mapping === null || mapping === void 0 ? void 0 : mapping.framework);
                }
            });
            uniqueComplianceFrameworks.sort();
            return uniqueComplianceFrameworks;
        };
        this.getUniqueComplianceFrameworksAndControls = (complianceMappings) => {
            const uniqueComplianceFrameworks = {};
            complianceMappings === null || complianceMappings === void 0 ? void 0 : complianceMappings.forEach(mapping => {
                var _a;
                if (!!(mapping === null || mapping === void 0 ? void 0 : mapping.framework) && !(uniqueComplianceFrameworks === null || uniqueComplianceFrameworks === void 0 ? void 0 : uniqueComplianceFrameworks[mapping.framework])) {
                    uniqueComplianceFrameworks[mapping.framework] = [];
                }
                if (!!(mapping === null || mapping === void 0 ? void 0 : mapping.framework) && !!(mapping === null || mapping === void 0 ? void 0 : mapping.control) && !((_a = uniqueComplianceFrameworks === null || uniqueComplianceFrameworks === void 0 ? void 0 : uniqueComplianceFrameworks[mapping.framework]) === null || _a === void 0 ? void 0 : _a.includes(mapping.control))) {
                    uniqueComplianceFrameworks[mapping.framework].push(mapping.control);
                }
            });
            return uniqueComplianceFrameworks;
        };
        this.getUniqueComplianceFrameworkPropertyFilteringOptions = (uniqueFrameworks, propertyKey) => {
            var _a;
            return (_a = uniqueFrameworks === null || uniqueFrameworks === void 0 ? void 0 : uniqueFrameworks.map((framework) => {
                return {
                    propertyKey: propertyKey,
                    value: framework
                };
            })) !== null && _a !== void 0 ? _a : [];
        };
        this.remote = remote;
    }
    listAll() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.listAll();
        });
    }
    listMappingsForManagedRules() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.listMappingsForManagedRules();
        });
    }
    listComplianceMappingsForCurrentOrg() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remote.listComplianceMappingsForCurrentOrg();
        });
    }
}
