import React, { useEffect, useState } from "react";
import { MyProfileTokensFilteringOptions, MyProfileTokensFilteringProperties, MyProfileTokensTableColumnDefinitions, MyProfileTokensTablePageSizeOptions, MyProfileTokensTablePreferences, MyProfileTokensTableVisibleContentOptions, } from "@/presentation/pages/my-profile/my-profile-tokens-config";
import { downloadBlob } from "@/lib/misc/utils";
import TokensModal from "@/presentation/pages/my-profile/my-profile-tokens-delete-modal";
import TokensCreateUpdateModal from "@/presentation/pages/my-profile/my-profile-tokens-create-update-modal";
import DownloadAPIKeyModal from "@/presentation/pages/my-profile/my-profile-tokens-download-modal";
import Skeleton from "react-loading-skeleton";
import { SpaceBetween, Button, Pagination, Table, PropertyFilter, CollectionPreferences } from "@cloudscape-design/components-themed/components";
import { TableEmptyState, TableHeader, TableNoMatchState, } from "@/presentation/components/commons/common-components";
import { paginationLabels } from "@/presentation/components/commons/labels";
import { getFilterCounterText } from "@/presentation/components/commons/tableCounterStrings";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useMyProfileHandlers } from "@/presentation/providers";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY, } from "@/presentation/pages/constants-and-types";
import { useLocalStorage } from "@/presentation/components/commons/localStorage";
import { useColumnWidths } from "@/presentation/components/commons/use-column-widths";
import OverlayLoader from "@/presentation/components/overlay/overlay-loader";
import { makeNotification, makePropertyFilterI18nStrings } from "@/presentation/pages";
import TableContainer from "@/presentation/components/table/table-container";
import { useAuth0 } from '@/lib/auth0';
import { useIsMounted } from '@/presentation/hooks';
import { fetchUniquePropertyFilters } from "@/presentation/common-utils/common-utils";
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
const MyProfileTokens = () => {
    var _a, _b;
    const { user } = useAuth0();
    const [keyInfo, setKeyInfo] = useState();
    const [keyFileName, setKeyFileName] = useState("userAPIKeyInfo.json");
    const [downloadAPIKeyModal, setDownloadAPIKeyModal] = useState(false);
    const [downloadAPIKeyDescription, setDownloadAPIKeyDescription] = useState("");
    const { myProfile } = useMyProfileHandlers();
    const myProfileHandler = myProfile;
    const [showRevokeModal, setShowRevokeModal] = useState(false);
    const onRevokeInit = () => setShowRevokeModal(true);
    const onRevokeDiscard = () => setShowRevokeModal(false);
    const [isRevokeEnabled, setIsRevokeEnabled] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const onCreateInit = () => setShowCreateModal(true);
    const onCreateDiscard = () => setShowCreateModal(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const onEditInit = () => setShowEditModal(true);
    const onEditDiscard = () => setShowEditModal(false);
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const closeOverlay = () => setIsOverlayOpen(false);
    const [loading, setLoading] = useState(false);
    const [tokensList, setTokensList] = useState([]);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.MYPROFILE_TOKENS_LIST, MyProfileTokensTableColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.MYPROFILE_TOKENS_LIST, MyProfileTokensTablePreferences);
    const retrievedPropertyFilters = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.MY_PROFILE_LIST_API_KEYS, false);
    const defaultPropertyFiltersQuery = JSON.parse('{"tokens":[{"propertyKey":"status","operator":"!=","value":"REVOKED"}],"operation":"and"}');
    const getSessionPropertyFilter = (retrievedPropertyFilters === undefined) ? defaultPropertyFiltersQuery : retrievedPropertyFilters;
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.MY_PROFILE_LIST_API_KEYS);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : MyProfileTokensTableColumnDefinitions === null || MyProfileTokensTableColumnDefinitions === void 0 ? void 0 : MyProfileTokensTableColumnDefinitions[1],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const [renderCount, setRenderCount] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const { pushNotifications, dismissNotification } = useNotifications();
    const isMounted = useIsMounted();
    const [monitoringFilteringOptions, setMonitoringFilteringOptions] = useState(MyProfileTokensFilteringOptions);
    const makeSuccessNotification = (message) => {
        const msg = "Created Successfully";
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to Proceed. Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        myProfileHandler
            .listUserApiKeys()
            .then((tokens) => {
            const formatted = myProfileHandler === null || myProfileHandler === void 0 ? void 0 : myProfileHandler.getFormattedEntities(tokens);
            const { apiKeyTokens, organizationNameFilteredList } = formatted;
            if (isMounted.current) {
                setTokensList(apiKeyTokens);
                setMonitoringFilteringOptions([...MyProfileTokensFilteringOptions, ...organizationNameFilteredList]);
                setLoading(false);
            }
        })
            .catch((e) => {
            console.log(e);
        });
    };
    useEffect(() => {
        refreshList();
    }, [user]);
    const editAPIKey = (description) => {
        var _a;
        if (isMounted.current) {
            setLoading(true);
        }
        myProfileHandler.editUserApiKey((_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.id, description).then((result) => {
            var _a, _b, _c;
            if (((_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.update_UserApiKeys) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description) === description) {
                if (isMounted.current) {
                    refreshList();
                    onEditDiscard();
                    setIsRevokeEnabled(false);
                    setIsEditEnabled(false);
                }
                pushNotifications(makeSuccessNotification("API key description updated successfully"));
            }
            else {
                const customMsg = "Unable to update description. Please try again later.";
                pushNotifications(makeErrorNotification(customMsg));
            }
        })
            .catch((e) => {
            pushNotifications(makeErrorNotification("Unable to update description. Please try again later."));
        })
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const generateApiKey = (description) => {
        if (isMounted.current) {
            setLoading(true);
        }
        myProfileHandler
            .generateUserApiKey(description)
            .then((result) => {
            var _a, _b;
            if (((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.client_id) !== undefined) {
                if (isMounted.current) {
                    setKeyInfo(result.data);
                    setKeyFileName("userAPIKeyInfo-" + ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.client_id) + ".json");
                    setDownloadAPIKeyDescription(description);
                    setDownloadAPIKeyModal(true);
                }
                pushNotifications(makeSuccessNotification("API key created successfully"));
            }
            else if ((result === null || result === void 0 ? void 0 : result.type) === "ApplicationExistsException") {
                pushNotifications(makeErrorNotification("API Keys limit reached (1)"));
            }
            else {
                const customMsg = "Unable to create. Please try again later.";
                pushNotifications(makeErrorNotification((result === null || result === void 0 ? void 0 : result.msg) || customMsg));
            }
        })
            .catch((e) => {
            pushNotifications(makeErrorNotification("Unable to Create API Keys. Please try again later."));
        })
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
                onCreateDiscard();
            }
        });
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps, } = useCollection(tokensList, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: MyProfileTokensFilteringProperties,
            empty: React.createElement(TableEmptyState, { resourceName: "API keys" }),
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'api keys' }),
        },
        pagination: { pageSize: MyProfileTokensTablePreferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {},
    });
    const onRevokeConfirm = () => {
        var _a, _b;
        if (isMounted.current) {
            setShowRevokeModal(false); // toggle the modal
        }
        if ((_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.apiKeyID) {
            setLoading(true);
            myProfileHandler
                .deactivateUserApiKeys((_b = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _b === void 0 ? void 0 : _b.apiKeyID)
                .then((userApiTokenStatus) => {
                var _a;
                if (((_a = userApiTokenStatus === null || userApiTokenStatus === void 0 ? void 0 : userApiTokenStatus.data) === null || _a === void 0 ? void 0 : _a.message) !== undefined) {
                    refreshList();
                    setIsRevokeEnabled(false);
                    setIsEditEnabled(false);
                    pushNotifications(makeSuccessNotification("API key revoked successfully"));
                }
                else {
                    const customMsg = "Unable to create. Please try again later.";
                    pushNotifications(makeErrorNotification((userApiTokenStatus === null || userApiTokenStatus === void 0 ? void 0 : userApiTokenStatus.msg) || customMsg));
                }
            })
                .catch((e) => {
                pushNotifications(makeErrorNotification("Unable to Revoke the API Keys"));
            })
                .finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        }
        else {
            pushNotifications(makeErrorNotification("Invalid Organaization"));
        }
    };
    const downloadKeyInfo = () => {
        const jsonBlob = new Blob([JSON.stringify(keyInfo, null, 4)]);
        downloadBlob(jsonBlob, keyFileName);
    };
    const closeDownloadAPIKey = () => {
        refreshList();
        setDownloadAPIKeyModal(false);
    };
    const handleSelectionChange = (event) => {
        var _a, _b, _c;
        const selectedRecord = ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.selectedItems) || [];
        setSelectedItems(selectedRecord);
        setIsRevokeEnabled(((_b = selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord[0]) === null || _b === void 0 ? void 0 : _b.status) === "ACTIVE");
        setIsEditEnabled(((_c = selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord[0]) === null || _c === void 0 ? void 0 : _c.status) === "ACTIVE");
    };
    const isItemDisabled = (item) => {
        return !item.status;
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(TableContainer, { key: renderCount },
        React.createElement(Table, Object.assign({}, collectionProps, { resizableColumns: true, selectionType: "single", columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, selectedItems: selectedItems, variant: "embedded", onSelectionChange: handleSelectionChange, header: React.createElement(TableHeader, { title: "API Keys", selectedItems: selectedItems, totalItems: tokensList, variant: 'h2', singleSelect: true, description: "API keys are used to authenticate to the Kivera APIs", actionButtons: React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
                    React.createElement(Button, { variant: "normal", disabled: !isRevokeEnabled, onClick: onRevokeInit }, "Revoke"),
                    React.createElement(Button, { variant: "normal", disabled: !isEditEnabled, onClick: onEditInit }, "Edit"),
                    React.createElement(Button, { variant: "normal", onClick: onCreateInit }, "Create")) }), isItemDisabled: isItemDisabled, pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), onSortingChange: ({ detail }) => {
                if (TABLE_SORTING_SESSION_STORAGE.MY_PROFILE_LIST_API_KEYS)
                    SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.MY_PROFILE_LIST_API_KEYS, detail);
                actions.setSorting(detail);
            }, filter: React.createElement("div", { className: 'list-property-filter' },
                React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter API Keys' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: monitoringFilteringOptions, filteringProperties: MyProfileTokensFilteringProperties, onChange: ({ detail }) => {
                        const filteredDetail = fetchUniquePropertyFilters(detail);
                        if (TABLE_FILTERS_SESSION_STORAGE.MY_PROFILE_LIST_API_KEYS)
                            SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.MY_PROFILE_LIST_API_KEYS, filteredDetail);
                        actions.setPropertyFiltering(filteredDetail);
                    } }))), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                    setPreferences(detail);
                    setRenderCount(renderCount + 1);
                }, pageSizePreference: {
                    title: 'Page Size',
                    options: MyProfileTokensTablePageSizeOptions
                }, wrapLinesPreference: {
                    label: 'Wrap Lines',
                    description: 'If checked, lines will be wrapped to display all of the text'
                }, visibleContentPreference: {
                    title: 'Visible Columns',
                    options: MyProfileTokensTableVisibleContentOptions
                } }) })),
        React.createElement(TokensCreateUpdateModal, { visible: showCreateModal, onDiscard: onCreateDiscard, onCreateOrUpdate: generateApiKey, headerText: "Create API Key", createUpdateButtonText: "Create API Key", initialDescription: "", operationName: "create" }),
        React.createElement(TokensCreateUpdateModal, { visible: showEditModal, onDiscard: onEditDiscard, onCreateOrUpdate: editAPIKey, headerText: "Edit API Key", createUpdateButtonText: "Update API Key", initialDescription: (_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.description, operationName: "update" }),
        React.createElement(TokensModal, { visible: showRevokeModal, onDiscard: onRevokeDiscard, onRevoke: onRevokeConfirm, items: selectedItems, itemRevokeShowProp: "apiKeyID", headerText: "Revoke API Key", consentText: "revoke" }),
        React.createElement(DownloadAPIKeyModal, { visible: downloadAPIKeyModal, onCancel: closeDownloadAPIKey, onConfirm: downloadKeyInfo, headerText: "API Key Created", id: (_b = keyInfo === null || keyInfo === void 0 ? void 0 : keyInfo.client_id) !== null && _b !== void 0 ? _b : "", description: downloadAPIKeyDescription }),
        isOverlayOpen && (React.createElement(OverlayLoader, { isOpen: isOverlayOpen, closeOverlay: closeOverlay }))));
};
export default MyProfileTokens;
