import React, { useEffect, useState } from "react";
import { useColumnWidths } from "@/presentation/components/commons/use-column-widths";
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from "@/presentation/pages/constants-and-types";
import { useLocalStorage } from "@/presentation/components/commons/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState, TableHeader, TableNoMatchState } from "@/presentation/components/commons/common-components";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { Button, CollectionPreferences, Container, Pagination, PropertyFilter, SpaceBetween, Table } from "@cloudscape-design/components-themed/components";
import { makeNotification, makePropertyFilterI18nStrings, PagesRequestErrorHandler } from "@/presentation/pages";
import Skeleton from "react-loading-skeleton";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { PATH_IDENTITIES, PATH_SETTINGS, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from "@/presentation/common-utils/constants";
import { getFilterCounterText } from "@/presentation/components/commons/tableCounterStrings";
import { paginationLabels } from "@/presentation/components/commons/labels";
import DeleteModal from "@/presentation/components/modal/delete-modal";
import { CloudTenantsDeleteShowProp, CloudTenantsDeleteStrings, CloudTenantsListColumnDefinitions, CloudTenantsListFilteringProperties, CloudTenantsListTablePageSizeOptions, CloudTenantsListTablePreferences, CloudTenantsListTableVisibleContentOptions } from "@/presentation/pages/settings/cloud-tenants/settings-cloud-tenants-config";
import TableContainer from "@/presentation/components/table/table-container";
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from "@/presentation/storage/misc-storage";
import { useIsMounted } from '@/presentation/hooks';
import { fetchUniquePropertyFilters } from "@/presentation/common-utils/common-utils";
import CloudTenantStructureReference from "./settings-cloud-tenants-reference-modal";
import ExternalLink from "@/presentation/components/links/external-link";
import { manageCreatedUpdatedList } from "../../common-config";
const SettingsCloudTenants = (props) => {
    var _a;
    const [loading, setLoading] = useState(true);
    const [cloudTenants, setCloudTenants] = useState([]);
    const [selectedItems, setSelectedItems] = useState(props.selectedItems);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.SETTINGS_CLOUD_TENANTS, CloudTenantsListColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.SETTINGS_CLOUD_TENANTS, CloudTenantsListTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_CLOUD_TENANTS_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_CLOUD_TENANTS_LIST);
    const [filteringOptions, setFilteringOptions] = useState([]);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : CloudTenantsListColumnDefinitions === null || CloudTenantsListColumnDefinitions === void 0 ? void 0 : CloudTenantsListColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(cloudTenants, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: CloudTenantsListFilteringProperties,
            empty: React.createElement(TableEmptyState, { resourceName: "Cloud Tenant Structures" }),
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'cloud tenant structures' })
        },
        sorting: { defaultState: defaultSortingState },
        pagination: { pageSize: preferences.pageSize },
        selection: { defaultSelectedItems: selectedItems }
    });
    const [renderCount, setRenderCount] = useState(1);
    const navigate = useNavigate();
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const [referencedIdentities, setReferencedIdentities] = useState([]);
    const [showReferencedModal, setShowReferencedModal] = useState(false);
    // const onReferencedInit = () => setShowReferencedModal(true)
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const isMounted = useIsMounted();
    const { tenants } = useSettingsHandlers();
    const cloudTenantsHandler = tenants;
    const makeSuccessNotification = () => {
        const msg = 'Cloud Tenant Structure Deleted Successfully';
        return makeNotification("success", msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to delete. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        var _a, _b;
        const messageContainingReferenced = (_a = response === null || response === void 0 ? void 0 : response.errors) === null || _a === void 0 ? void 0 : _a.find(error => error.message.includes("is referenced by the following Identities"));
        if (messageContainingReferenced) {
            const errorMsg = messageContainingReferenced.message;
            // 1. Split by colon
            const parts = errorMsg.split(':');
            // 2. Take the 2nd part and split by newline characters
            const identities = (_b = parts[1]) === null || _b === void 0 ? void 0 : _b.trim().split(',');
            // 3. Split each identity string by the space before the opening bracket and clean up the IDs
            const result = identities === null || identities === void 0 ? void 0 : identities.map(identity => {
                let [key, value] = identity.trim().split(' (');
                const identityId = value.replace(')', '');
                return (React.createElement("div", { className: "kivera-external-link mx-2 my-1", key: identityId },
                    React.createElement(ExternalLink, { to: `${PATH_IDENTITIES.VIEW}/${identityId}`, label: key })));
            });
            setShowReferencedModal(true);
            setReferencedIdentities(result || []);
        }
        else {
            const getResponseMessage = PagesRequestErrorHandler(response);
            pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
        }
    };
    const handleDiscardReferencedModal = () => {
        if (isMounted.current) {
            setReferencedIdentities([]);
            setShowReferencedModal(false);
        }
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
            setSelectedItems([]);
        }
        cloudTenantsHandler.list().then((result) => {
            const formattedTenants = cloudTenantsHandler.getFormattedCloudTenants(result);
            const { cloudTenantsData, formattedFilteredList } = formattedTenants;
            if (isMounted.current) {
                const defaultId = props === null || props === void 0 ? void 0 : props.urlTenantId;
                if (defaultId) {
                    const filteredID = cloudTenantsData.filter(item => Number(defaultId) === item.id) || [];
                    if (filteredID.length > 0) {
                        props.onSelectionChange({ detail: { selectedItems: filteredID } });
                        setSelectedItems(filteredID);
                        setIsDeleteDisabled(false);
                    }
                }
                setCloudTenants(cloudTenantsData);
                const userFilteredOptionsList = manageCreatedUpdatedList(cloudTenantsData);
                const updateFilters = [...userFilteredOptionsList, ...formattedFilteredList];
                setFilteringOptions(updateFilters);
            }
        }).catch(console.error).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        refreshList();
    }, []);
    const onDeleteConfirm = () => {
        var _a;
        cloudTenantsHandler.delete({
            id: (_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.id
        }).then((result) => {
            var _a, _b;
            // if success, unselect the items
            if (((_a = result === null || result === void 0 ? void 0 : result.delete_CloudTenants_by_pk) === null || _a === void 0 ? void 0 : _a.id) === ((_b = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _b === void 0 ? void 0 : _b.id)) {
                pushNotifications(makeSuccessNotification());
                refreshList();
                props.onDelete();
                if (isMounted.current) {
                    setIsDeleteDisabled(true);
                }
            }
            else { // else show error notification
                const customMsg = "Unable to delete. Please try again later.";
                pushNotifications(makeErrorNotification(customMsg));
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setShowDeleteModal(false);
            }
        });
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 12 });
    }
    return React.createElement(ContentWrapper, null,
        React.createElement(Container, null,
            React.createElement(TableContainer, { key: renderCount },
                React.createElement(Table, Object.assign({}, collectionProps, { key: props.splitKeyValue, resizableColumns: true, loading: loading, loadingText: 'Loading Cloud Tenant Structures...', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, selectionType: "single", variant: "embedded", wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSelectionChange: (event) => {
                        props.onSelectionChange(event);
                        setSelectedItems(event.detail.selectedItems);
                        setIsDeleteDisabled(false);
                    }, onSortingChange: ({ detail }) => {
                        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_CLOUD_TENANTS_LIST, detail);
                        actions.setSorting(detail);
                    }, header: React.createElement(TableHeader, { title: "Cloud Tenant Structures", selectedItems: selectedItems, totalItems: cloudTenants, singleSelect: true, actionButtons: React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
                            React.createElement(Button, { variant: "normal", disabled: isDeleteDisabled, onClick: onDeleteInit }, "Delete"),
                            React.createElement(Button, { variant: "normal", disabled: isDeleteDisabled, onClick: () => { var _a; return navigate(PATH_SETTINGS.CLOUD_TENANTS + '/' + ((_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.id) + '/edit'); } }, "Edit"),
                            React.createElement(Button, { variant: "primary", onClick: () => navigate(PATH_SETTINGS.CLOUD_TENANTS_CREATE) }, "Create Cloud Tenant Structure")) }), filter: React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                        React.createElement("div", { className: 'list-property-filter' },
                            React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Cloud Tenant Structures' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: filteringOptions, filteringProperties: CloudTenantsListFilteringProperties, onChange: ({ detail }) => {
                                    const filteredDetail = fetchUniquePropertyFilters(detail);
                                    SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_CLOUD_TENANTS_LIST, filteredDetail);
                                    actions.setPropertyFiltering(filteredDetail);
                                } })))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: CloudTenantsListTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: CloudTenantsListTableVisibleContentOptions
                        } }) })),
                React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: CloudTenantsDeleteStrings, itemDeleteShowProp: CloudTenantsDeleteShowProp }),
                React.createElement(CloudTenantStructureReference, { visible: showReferencedModal, onDiscard: handleDiscardReferencedModal, items: referencedIdentities, tenantName: ((_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.name) || '' }))));
};
export default SettingsCloudTenants;
