import React, { useContext, createContext } from "react";
import { ServicesHandler, StringValidator, RulesHandler, RuleDependenciesHandler, PoliciesHandler, ProvidersHandler, AttributeKeyValueValidator, } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
import { RemoteServices, RemoteRules, RemoteRuleDependencies, RemoteProviders, RemoteOpenPolicy, RemoteGlobalPolicyFunctions, RemoteComplianceMappings, } from "@/data/usecases";
import { makeOpenPolicyAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { RemoteOrganizationPolicyFunctions } from "@/data/usecases/organization-policy-functions/remote-organization-policy-functions";
import { ComplianceMappingsHandler } from "@/presentation/handlers/pages/compliance-mappings-handler";
const defaultContext = {
    policies: new PoliciesHandler(null),
    rules: new RulesHandler(null, null),
    services: new ServicesHandler(null),
    providers: new ProvidersHandler(null),
    ruleDependencies: new RuleDependenciesHandler(null, null),
    complianceMappings: new ComplianceMappingsHandler(null),
    nameValidator: new StringValidator(),
    descriptionValidator: new StringValidator(),
    complianceMappingsValidator: new AttributeKeyValueValidator(),
};
export const RulesHandlersProviderContext = createContext(defaultContext);
export const useRulesHandlers = () => useContext(RulesHandlersProviderContext);
export const RulesHandlersProvider = ({ children, }) => {
    const { getTokenSilently } = useAuth0();
    const rulesRemotes = {
        rules: new RemoteRules(getTokenSilently),
    };
    const servicesRemotes = {
        services: new RemoteServices(getTokenSilently),
    };
    const providersRemotes = {
        providers: new RemoteProviders(getTokenSilently),
    };
    const ruleDependenciesRemotes = {
        ruleDependencies: new RemoteRuleDependencies(getTokenSilently),
    };
    const policiesRemotes = {
        openPolicy: new RemoteOpenPolicy(makeOpenPolicyAPIUrl(), makeAuthorizeHttpClientDecorator()),
        globPolicy: new RemoteGlobalPolicyFunctions(getTokenSilently),
        orgPolicy: new RemoteOrganizationPolicyFunctions(getTokenSilently)
    };
    const complianceMappingRemotes = new RemoteComplianceMappings(getTokenSilently);
    const complianceMappingsHandler = new ComplianceMappingsHandler(complianceMappingRemotes);
    const policiesHandler = new PoliciesHandler(policiesRemotes);
    const rulesHandler = new RulesHandler({
        remoteComplianceMappingsHandler: complianceMappingsHandler
    }, rulesRemotes);
    const servicesHandler = new ServicesHandler(servicesRemotes);
    const providersHandler = new ProvidersHandler(providersRemotes);
    const ruleDependenciesHandler = new RuleDependenciesHandler(null, ruleDependenciesRemotes);
    const nameValidator = new StringValidator().notEmpty().isValidResourceName();
    const descriptionValidator = new StringValidator().notEmpty().isValidResourceDesc();
    const complianceMappingsValidator = new AttributeKeyValueValidator().ignoreEmptyPairs().haveValidAttributeSelectKeyValuePairs();
    return (React.createElement(RulesHandlersProviderContext.Provider, { value: {
            policies: policiesHandler,
            rules: rulesHandler,
            services: servicesHandler,
            providers: providersHandler,
            ruleDependencies: ruleDependenciesHandler,
            complianceMappings: complianceMappingsHandler,
            nameValidator: nameValidator,
            descriptionValidator: descriptionValidator,
            complianceMappingsValidator: complianceMappingsValidator,
        } }, children));
};
