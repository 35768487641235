import React, { useEffect, useState } from "react";
import SettingsForm from "@/presentation/pages/settings/settings-form";
import { Header } from "@cloudscape-design/components-themed/components";
import ContentWrapper from "@/presentation/components/content-wrapper";
import Skeleton from "react-loading-skeleton";
import { useAuth0 } from "@/lib/auth0";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { useIsMounted } from '@/presentation/hooks';
import DefaultErrorPage from "../errors/default-error-page";
import ContentFormLayoutWrapper from "@/presentation/components/content-wrapper/content-form-layout-wrapper";
const SettingsFormWrapper = () => {
    const [loading, setLoading] = useState(true);
    const [orgRequestError, setOrgRequestError] = useState(false);
    const [settingsInfo, setSettingsInfo] = useState(undefined);
    const { user, rawToken } = useAuth0();
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const isMounted = useIsMounted();
    useEffect(() => {
        const orgID = getOrgIDFromToken(rawToken);
        settingsHandler.getOrgDetail({ org_id: orgID })
            .then((org) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (((_a = org === null || org === void 0 ? void 0 : org.Organizations) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const activeOrg = user.memberships.filter(membership => { var _a; return ((_a = membership === null || membership === void 0 ? void 0 : membership.organization) === null || _a === void 0 ? void 0 : _a.id) === orgID; });
                let orgName = '';
                let activeOrgID = '';
                if ((activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.length) === 1) {
                    orgName = (_c = (_b = activeOrg[0]) === null || _b === void 0 ? void 0 : _b.organization) === null || _c === void 0 ? void 0 : _c.company_name;
                    activeOrgID = String((_e = (_d = activeOrg[0]) === null || _d === void 0 ? void 0 : _d.organization) === null || _e === void 0 ? void 0 : _e.id);
                }
                const enforceMFA = ((_g = (_f = org === null || org === void 0 ? void 0 : org.Organizations) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.enforce_mfa) || false;
                const formatted = settingsHandler.getFormattedTokenGroupAllowedDomains((_j = (_h = org === null || org === void 0 ? void 0 : org.Organizations) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.allowed_domains);
                const settingsForm = {
                    details: {
                        orgID: activeOrgID,
                        orgName: orgName,
                        domain: '',
                        allowedDomains: formatted,
                        enforceMFA
                    }
                };
                if (isMounted.current) {
                    setSettingsInfo(settingsForm);
                }
            }
            else {
                setOrgRequestError(true);
            }
        })
            .catch((e) => {
            setOrgRequestError(true);
            console.log(e);
        })
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    }, [user]);
    if (loading || !settingsInfo) {
        return React.createElement(Skeleton, { count: 10 });
    }
    return React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, { testid: "settings-edit-page" },
            React.createElement(ContentFormLayoutWrapper, { header: React.createElement(Header, { variant: "h1" }, "Organizational Settings") }, orgRequestError ? React.createElement(DefaultErrorPage, null) : React.createElement(SettingsForm, { settingsInfo: settingsInfo }))));
};
export default SettingsFormWrapper;
