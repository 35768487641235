import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@/lib/auth0';
import { useSettingsHandlers } from '@/presentation/providers/settings-handlers-provider';
import { ServiceInspections } from '@/presentation/handlers';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { TableEmptyState, TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { Badge, ButtonDropdown, CollectionPreferences, Container, Pagination, PropertyFilter, SpaceBetween, Table } from '@cloudscape-design/components-themed/components';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { GlobalServicesListColumnDefinitions, GlobalServicesListFilteringOptions, GlobalServicesListFilteringProperties, GlobalServicesListTablePageSizeOptions, GlobalServicesListTablePreferences, GlobalServicesListTableVisibleContentOptions } from '@/presentation/pages/settings/global-services/settings-global-services-config';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import InspectionModal from '@/presentation/components/modal/inspection-modal';
import { PagesRequestErrorHandler, makeNotification, makePropertyFilterI18nStrings, getInspectionColor } from '@/presentation/pages';
import TableContainer from "@/presentation/components/table/table-container";
import { capitalizeFirst } from "@/lib/misc/utils";
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { useIsMounted } from '@/presentation/hooks';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const SettingsGlobalServices = (props) => {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const { settings, providers } = useSettingsHandlers();
    const settingsHandler = settings;
    const providersHandler = providers;
    const [filteringOptions, setFilteringOptions] = useState(GlobalServicesListFilteringOptions);
    const [formattedGlobalServices, setFormattedGlobalServices] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [actionsDisabled, setActionsDisabled] = useState(true);
    const [inspectionType, setInspectionType] = useState();
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showInspectionModal, setShowInspectionModal] = useState(false);
    const onInspectionInit = () => setShowInspectionModal(true);
    const onInspectionDiscard = () => setShowInspectionModal(false);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.GLOBAL_SERVICES_LIST, GlobalServicesListColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.GLOBAL_SERVICES_LIST, GlobalServicesListTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_GLOBAL_SERVICES_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_GLOBAL_SERVICES_LIST);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : GlobalServicesListColumnDefinitions === null || GlobalServicesListColumnDefinitions === void 0 ? void 0 : GlobalServicesListColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(formattedGlobalServices, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: GlobalServicesListFilteringProperties,
            empty: React.createElement(TableEmptyState, { resourceName: "Global Services" }),
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'global services' })
        },
        sorting: { defaultState: defaultSortingState },
        pagination: { pageSize: preferences.pageSize },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const isMounted = useIsMounted();
    const extractServices = (services) => {
        var _a;
        const enabledServices = [];
        const disabledServices = [];
        const bypassServices = [];
        (_a = services === null || services === void 0 ? void 0 : services.Services) === null || _a === void 0 ? void 0 : _a.map(service => {
            var _a, _b, _c;
            switch (service.inspection) {
                case ServiceInspections.DISABLED:
                    disabledServices.push((_a = service === null || service === void 0 ? void 0 : service.GlobalService) === null || _a === void 0 ? void 0 : _a.id);
                    break;
                case ServiceInspections.ENABLED:
                    enabledServices.push((_b = service === null || service === void 0 ? void 0 : service.GlobalService) === null || _b === void 0 ? void 0 : _b.id);
                    break;
                case ServiceInspections.BYPASS:
                    bypassServices.push((_c = service === null || service === void 0 ? void 0 : service.GlobalService) === null || _c === void 0 ? void 0 : _c.id);
                    break;
                default:
            }
        });
        return {
            enabledServices,
            disabledServices,
            bypassServices
        };
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        providersHandler.listEntity().then((providers) => {
            if (providers.Providers.length > 0) {
                const providersFilteringOptions = providersHandler.getPropertyFilteringOptions(providers);
                settingsHandler.listGlobalServices()
                    .then((globalServices) => {
                    settingsHandler.listSelectedServices().then((services) => {
                        if (isMounted.current) {
                            const { enabledServices, disabledServices, bypassServices } = extractServices(services);
                            setFormattedGlobalServices(settingsHandler.formatGlobalServices(globalServices, enabledServices, disabledServices, bypassServices));
                            const servicesFilteringOptions = settingsHandler.getPropertyFilteringOptions(settingsHandler.sortServicesByName(globalServices));
                            setFilteringOptions([...filteringOptions, ...providersFilteringOptions, ...servicesFilteringOptions]);
                        }
                    });
                })
                    .catch(console.error)
                    .finally(() => {
                    if (isMounted.current) {
                        setLoading(false);
                    }
                });
            }
        }).catch((e) => {
            console.error(' Unable to fetch providers', e);
        });
    };
    useEffect(() => {
        refreshList();
        return () => {
            console.log('cleaning up');
        };
    }, [user]);
    const makeSuccessUpdateNotification = () => {
        const msg = 'Global Services Updated Successfully';
        return makeNotification('success', msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to update. Please try again later.';
        return makeNotification('error', message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const updateGlobalServices = (inspection) => {
        if (isMounted.current) {
            setLoading(true);
        }
        settingsHandler.updateGlobalServices(selectedItems, inspection).then((result) => {
            var _a, _b;
            if (((_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Services) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                pushNotifications(makeSuccessUpdateNotification());
                if (isMounted.current) {
                    setSelectedItems([]);
                    setActionsDisabled(true);
                }
                refreshList();
            }
            else {
                pushNotifications(makeErrorNotification());
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
                setShowInspectionModal(false);
            }
        });
    };
    const updateModal = (inspection) => {
        setInspectionType(inspection);
        if (selectedItems.length === 1) {
            updateGlobalServices(inspection);
        }
        else if (selectedItems.length > 1) {
            onInspectionInit();
        }
    };
    const getInspectionSubmitButton = (inspection) => {
        switch (inspection) {
            case ServiceInspections.BYPASS:
                return 'Bypass';
            case ServiceInspections.ENABLED:
                return 'Enable';
            case ServiceInspections.DISABLED:
                return 'Disable';
            default:
                return '';
        }
    };
    return React.createElement(ContentWrapper, null,
        React.createElement(Container, null,
            React.createElement(TableContainer, { key: renderCount },
                React.createElement(Table, Object.assign({}, collectionProps, { resizableColumns: true, loading: loading, loadingText: 'Loading Services...', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: {}, selectionType: "multi", selectedItems: selectedItems, onSelectionChange: event => {
                        setSelectedItems(event.detail.selectedItems);
                        setActionsDisabled(event.detail.selectedItems.length < 1);
                    }, onSortingChange: ({ detail }) => {
                        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_GLOBAL_SERVICES_LIST, detail);
                        actions.setSorting(detail);
                    }, variant: "embedded", wrapLines: preferences.wrapLines, header: React.createElement(TableHeader, Object.assign({ title: 'Global Services', selectedItems: selectedItems, totalItems: formattedGlobalServices, actionButtons: React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
                            React.createElement(ButtonDropdown, { items: [
                                    { text: 'Enable', id: ServiceInspections.ENABLED, disabled: false },
                                    { text: 'Disable', id: ServiceInspections.DISABLED, disabled: false },
                                ], disabled: actionsDisabled, onItemClick: (event) => {
                                    var _a;
                                    const inspection = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.id;
                                    updateModal(inspection);
                                } }, "Actions")) }, props)), filter: React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                        React.createElement("div", { className: 'list-property-filter' },
                            React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Services' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: filteringOptions, filteringProperties: GlobalServicesListFilteringProperties, onChange: ({ detail }) => {
                                    const filteredDetail = fetchUniquePropertyFilters(detail);
                                    SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_GLOBAL_SERVICES_LIST, filteredDetail);
                                    actions.setPropertyFiltering(filteredDetail);
                                } })))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: GlobalServicesListTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: GlobalServicesListTableVisibleContentOptions
                        } }) })),
                React.createElement(InspectionModal, { visible: showInspectionModal, items: selectedItems, onDiscard: onInspectionDiscard, onSubmit: () => updateGlobalServices(inspectionType), header: 'Update Global Services', alertInfo: React.createElement(React.Fragment, null,
                        "Proceeding with this action will set all selected services to become ",
                        React.createElement(Badge, { color: getInspectionColor(inspectionType) }, capitalizeFirst(inspectionType))), submitButton: getInspectionSubmitButton(inspectionType) }))));
};
export default SettingsGlobalServices;
