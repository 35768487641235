import React from 'react';
import { PATH_RULES, PATHS_SINGULAR } from '@/presentation/common-utils/constants';
import RegularLink from '@/presentation/components/links/regular-link';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { Checkbox } from '@cloudscape-design/components-themed/components';
import ExternalLink from '@/presentation/components/links/external-link';
import { actionFilteringProperty, actionsColumnDefinition, complianceFrameworksColumnDefinition, complianceFrameworksFilteringProperty, descriptionColumnDefinition, descriptionFilteringProperty, idFilteringProperty, initialComplianceFrameworksFilteringOptions, providerColumnDefinition, providerFilteringProperty, riskRatingColumnDefinition, riskRatingFilteringOptions, riskRatingFilteringProperty, serviceColumnDefinition, serviceFilteringProperty, userDetailsCreatedColumnDefinition, userDetailsCreatedFilteringProperty, userDetailsUpdatedColumnDefinition, userDetailsUpdatedFilteringProperty } from '@/presentation/pages/common-config';
import { getRandomUUID } from "@/lib/misc/utils";
import LogoLetterKUrl from "@/presentation/assets/img/logo/logo-letter-k-rule-library.svg";
import { Tooltip } from '@/presentation/components/tooltip';
export const ProfilesRulesColumnDefinitions = (propsRules, incomingPath, withPreviousPath = false) => {
    const isCheckboxChecked = (propsRules === null || propsRules === void 0 ? void 0 : propsRules.length) > 0;
    return (addColumnSortLabels([{
            id: 'checkbox',
            header: React.createElement("div", { className: "table-header-padding" },
                React.createElement(Checkbox, { checked: isCheckboxChecked, disabled: true })),
            cell: () => React.createElement("div", { className: "table-header-padding" },
                React.createElement(Checkbox, { checked: true, disabled: true })),
            width: 60
        },
        {
            id: 'id',
            sortingField: 'id',
            header: 'ID',
            cell: item => {
                let linkElement = React.createElement(React.Fragment, null);
                let linkPath = PATH_RULES.VIEW + '/' + item.id;
                if (withPreviousPath) {
                    linkPath = incomingPath + PATHS_SINGULAR.RULE + '/' + item.id;
                    linkElement = React.createElement(RegularLink, { to: linkPath, label: item.id });
                }
                else {
                    linkElement = React.createElement(ExternalLink, { to: linkPath, label: item.id });
                }
                if (item === null || item === void 0 ? void 0 : item.importedFrom) {
                    return React.createElement("div", { className: "flex", key: getRandomUUID() },
                        React.createElement("div", { className: "grow-0" }, linkElement),
                        React.createElement("div", { className: "grow-0" },
                            React.createElement("img", { src: LogoLetterKUrl, width: "15px", height: "15px", className: 'imported-from-status imported-tooltip-' + (item === null || item === void 0 ? void 0 : item.importedFrom) }),
                            React.createElement(Tooltip, { anchorSelect: '.imported-tooltip-' + (item === null || item === void 0 ? void 0 : item.importedFrom), place: 'bottom', style: { zIndex: '2000' } },
                                "Imported from KRL:", item === null || item === void 0 ? void 0 :
                                item.importedFrom)));
                }
                return linkElement;
            },
            width: 120
        },
        providerColumnDefinition,
        serviceColumnDefinition,
        actionsColumnDefinition,
        descriptionColumnDefinition,
        complianceFrameworksColumnDefinition,
        riskRatingColumnDefinition,
        ...userDetailsCreatedColumnDefinition,
        ...userDetailsUpdatedColumnDefinition,]));
};
export const ProfilesRulesTablePreferences = {
    pageSize: 10,
    visibleContent: [
        'id',
        'provider',
        'service',
        'actions',
        'description',
        'riskRating',
    ],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const ProfilesRulesTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'provider', label: 'Provider', editable: true },
            { id: 'service', label: 'Service', editable: true },
            { id: 'actions', label: 'Actions', editable: true },
            { id: 'description', label: 'Description', editable: true },
            { id: 'complianceFrameworks', label: 'Compliance Frameworks', editable: true },
            { id: 'riskRating', label: 'Risk Rating', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const ProfilesRulesTablePageSizeOptions = [
    { value: 10, label: '10 Rules' },
    { value: 30, label: '30 Rules' },
    { value: 50, label: '50 Rules' }
];
export const ProfilesRulesFilteringProperties = [
    idFilteringProperty,
    providerFilteringProperty,
    serviceFilteringProperty,
    actionFilteringProperty,
    descriptionFilteringProperty,
    complianceFrameworksFilteringProperty,
    riskRatingFilteringProperty,
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty,
];
export const ProfilesRulesFilteringOptions = [
    ...riskRatingFilteringOptions,
    ...initialComplianceFrameworksFilteringOptions
];
