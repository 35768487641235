var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler } from '@/presentation/handlers';
import { booleanToString, uniqueAndSortedArrayOfObjectFilters } from '@/presentation/common-utils/common-utils';
import { capitalizeFirstForcefully } from "@/lib/misc/utils";
import { PROXY_MODE_TYPE_LABEL } from "@/presentation/pages/proxies/proxies-types";
export class MonitoringHandler extends PagesBaseHandler {
    constructor(remotes) {
        super();
        this.remotes = remotes;
    }
    getFormattedEntities(logs) {
        const providerFilterList = [];
        const serviceFilterList = [];
        const proxyFilterList = [];
        const identityFilterList = [];
        const serviceActionFilterObject = {};
        const actionTypeFilterList = [];
        const actionFilterList = [];
        const formatted = logs.hits.hits.map((hit) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
            const sourceIdentityName = [];
            let identityName = (_c = (_b = (_a = hit === null || hit === void 0 ? void 0 : hit._source) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.IdentityName) !== null && _c !== void 0 ? _c : '';
            const identitiesArray = ((_e = (_d = hit === null || hit === void 0 ? void 0 : hit._source) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.Identities) || [];
            if (identitiesArray.length > 0) {
                const sortedArray = identitiesArray.map(item => item.IdentityName).sort((a, b) => a > b ? 1 : -1);
                sourceIdentityName.push(...sortedArray);
                identityName = sortedArray.join(', ');
            }
            const proxyMode = ((_f = hit._source.data) === null || _f === void 0 ? void 0 : _f.ProxyMode) ? this.getProxyModeWithSpace((_g = hit._source.data) === null || _g === void 0 ? void 0 : _g.ProxyMode)
                : (((_h = hit._source.data) === null || _h === void 0 ? void 0 : _h.LearningMode) ? PROXY_MODE_TYPE_LABEL.LEARNING : PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE);
            const isCFNRedirectionExist = ((_k = (_j = hit === null || hit === void 0 ? void 0 : hit._source) === null || _j === void 0 ? void 0 : _j.rules) === null || _k === void 0 ? void 0 : _k.some(rule => { var _a; return (_a = rule === null || rule === void 0 ? void 0 : rule.RuleSteps) === null || _a === void 0 ? void 0 : _a.includes("s3_cfn_redirection"); })) || false;
            proxyFilterList.push((_m = (_l = hit === null || hit === void 0 ? void 0 : hit._source) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.ProxyName);
            actionTypeFilterList.push((_p = (_o = hit === null || hit === void 0 ? void 0 : hit._source) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.ActionType);
            providerFilterList.push((_s = (_r = (_q = hit === null || hit === void 0 ? void 0 : hit._source) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.Provider) === null || _s === void 0 ? void 0 : _s.toUpperCase());
            identityFilterList.push(...sourceIdentityName);
            actionFilterList.push(hit._source.data.Action);
            const serviceName = (_u = (_t = hit === null || hit === void 0 ? void 0 : hit._source) === null || _t === void 0 ? void 0 : _t.data) === null || _u === void 0 ? void 0 : _u.Service;
            if (serviceName) {
                serviceFilterList.push(serviceName.toUpperCase());
                if (serviceActionFilterObject[serviceName]) {
                    serviceActionFilterObject[serviceName].push((_w = (_v = hit === null || hit === void 0 ? void 0 : hit._source) === null || _v === void 0 ? void 0 : _v.data) === null || _w === void 0 ? void 0 : _w.Action);
                }
                else {
                    serviceActionFilterObject[serviceName] = [(_y = (_x = hit === null || hit === void 0 ? void 0 : hit._source) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.Action];
                }
            }
            return {
                id: hit._id,
                validityStatus: hit._source.data.Valid,
                valid: capitalizeFirstForcefully(booleanToString(hit._source.data.Valid)),
                proxyMode,
                timestamp: hit._source.timestamp,
                xkid: hit._source.xkid,
                source: (_0 = (_z = hit._source.data.Headers['X-Kivera-Source']) === null || _z === void 0 ? void 0 : _z[0]) !== null && _0 !== void 0 ? _0 : 'Proxy',
                serviceevent: (_3 = (_2 = (_1 = hit._source.data) === null || _1 === void 0 ? void 0 : _1.TrailBlazerMetadata) === null || _2 === void 0 ? void 0 : _2.ServiceEvent) !== null && _3 !== void 0 ? _3 : false,
                remoteaddr: hit._source.data.RemoteAddr,
                proxy: hit._source.data.ProxyName,
                provider: (_4 = hit._source.data.Provider) === null || _4 === void 0 ? void 0 : _4.toUpperCase(),
                service: serviceName,
                identity: identityName,
                action: hit._source.data.Action,
                host: hit._source.data.Host,
                method: hit._source.data.Method,
                path: hit._source.data.Path,
                accountID: (_7 = (_6 = (_5 = hit._source.data) === null || _5 === void 0 ? void 0 : _5.ProviderMetadata) === null || _6 === void 0 ? void 0 : _6.Account) !== null && _7 !== void 0 ? _7 : 'unavailable',
                rawSource: hit._source,
                isCFNRedirectionExist,
                actionType: hit._source.data.ActionType,
                learningMode: ((_8 = hit._source.data) === null || _8 === void 0 ? void 0 : _8.LearningMode) || false,
                serviceStatus: ((_9 = hit._source.data) === null || _9 === void 0 ? void 0 : _9.ServiceStatus) || ""
            };
        });
        const proxyFilteredList = uniqueAndSortedArrayOfObjectFilters(proxyFilterList, 'proxy');
        const identityFilteredList = uniqueAndSortedArrayOfObjectFilters(identityFilterList, 'identity');
        const actionTypeFilteredList = uniqueAndSortedArrayOfObjectFilters(actionTypeFilterList, 'actionType');
        const providerFilteredList = uniqueAndSortedArrayOfObjectFilters(providerFilterList, 'provider');
        const serviceFilteredList = uniqueAndSortedArrayOfObjectFilters(serviceFilterList, 'service');
        const actionFilteredList = uniqueAndSortedArrayOfObjectFilters(actionFilterList, 'action');
        const formattedFilteredList = [...providerFilteredList, ...serviceFilteredList, ...proxyFilteredList, ...identityFilteredList, ...actionFilteredList, ...actionTypeFilteredList];
        return { monitoringData: formatted, formattedFilteredList, serviceActionFilterObject };
    }
    filterFlyout(id, rawItems) {
        const filtered = rawItems.filter((hit) => hit._id === id);
        if (filtered[0]) {
            return ['xkid: ' + filtered[0]._source.xkid, JSON.stringify(filtered[0]._source, null, 2)];
        }
        return [undefined, ''];
    }
    getRedirectionItem(id, rawItems) {
        const filtered = rawItems.filter((hit) => hit._id === id);
        if (filtered[0]) {
            return filtered[0];
        }
        return undefined;
    }
    getAggregates(logs) {
        return logs.aggregations.get_org.count.buckets;
    }
    filterByDateTime(startDateTime, endDateTime, formattedData) {
        try {
            const oneMinute = 1;
            const milliseconds = 1000 * 60 * oneMinute;
            const fromDateTime = Math.floor(new Date(startDateTime).getTime() / milliseconds);
            const toDateTime = Math.floor(new Date(endDateTime).getTime() / milliseconds) - 1;
            console.log('STARTDT', startDateTime, 'FROM', fromDateTime, 'ENDDT', endDateTime, 'TO', toDateTime);
            return formattedData.filter((datum) => {
                const timestamp = Math.floor(new Date(datum.timestamp).getTime() / milliseconds);
                const isDatumPresent = timestamp >= fromDateTime && timestamp <= toDateTime;
                if (isDatumPresent) {
                    console.info('PRESENT', datum.timestamp, 'TIMESTAMP', timestamp);
                }
                return isDatumPresent;
            });
        }
        catch (e) {
            console.error('ERROR while computing timestamp', e);
            return [];
        }
    }
    listEntity(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.monitoring.getURL(params).then((url) => __awaiter(this, void 0, void 0, function* () {
                return yield this.remotes.monitoring.downloadLogs(url.presigned_url);
            }));
        });
    }
    extractFilters(any) {
        return [];
    }
    deleteBulk(ids) {
        return Promise.resolve(undefined);
    }
    deleteEntity(any) {
        return Promise.resolve(undefined);
    }
    getEntity(any) {
        return Promise.resolve(undefined);
    }
    getEntityForInmemorySelectable(any) {
        return Promise.resolve(undefined);
    }
}
