import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@cloudscape-design/components-themed/components';
import React, { useEffect, useState } from 'react';
import { getContainerHeight } from '@/presentation/common-utils/common-utils';
import { makeEmptyPanelContent, makeSplitPanelI18nStrings } from '@/presentation/pages';
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
const CloudTenantDetails = (props) => {
    var _a, _b, _c;
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Cloud Tenant Structure Details") },
        React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                    React.createElement(Box, null, (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.id))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Name"),
                    React.createElement(Box, null, (_b = props === null || props === void 0 ? void 0 : props.data) === null || _b === void 0 ? void 0 : _b.name))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Provider"),
                    React.createElement(Box, null, (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.providerName)))));
};
const CloudTenantStructure = (props) => {
    var _a;
    const { setErrorStatus } = useErrorBoundaryContext();
    const [loading, setLoading] = useState(true);
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    const { tenants } = useSettingsHandlers();
    const cloudTenantsHandler = tenants;
    const [tenantStructure, setTenantStructure] = useState(JSON.stringify({}, null, 2));
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('settingsCloudTenantEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    const fetchTenantStructure = () => {
        var _a;
        setLoading(true);
        cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.get({
            id: (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.id
        }).then(tenant => {
            var _a, _b, _c;
            if ((_a = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _a === void 0 ? void 0 : _a.tenant_structure) {
                setTenantStructure(JSON.stringify((_c = (_b = tenant === null || tenant === void 0 ? void 0 : tenant.CloudTenants_by_pk) === null || _b === void 0 ? void 0 : _b.tenant_structure) !== null && _c !== void 0 ? _c : {}, null, 2));
            }
        }).catch((err) => {
            console.error(err);
            // something went wrong, push to oops
            setErrorStatus(true);
        }).finally(() => setLoading(false));
    };
    useEffect(() => {
        // we fetch tenant structure separately as an optimization strategy coz the json might contain thousands of entries
        fetchTenantStructure();
    }, [(_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.id]);
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Raw JSON") },
        React.createElement("div", { ref: ref, id: "settingsCloudTenantEditor" },
            React.createElement(CodeEditor, { loading: loading, code: tenantStructure, height: editorHeight, heightType: 'px', readOnly: true, language: 'json' })));
};
export const MakeCloudTenantSplitPanel = (items) => {
    if (!items.length) {
        return makeEmptyPanelContent('Select a cloud tenant record to see its details.');
    }
    const item = items[0];
    const header = item.name;
    return {
        header: header,
        panelStrings: makeSplitPanelI18nStrings(),
        body: (React.createElement(SpaceBetween, { size: 'xl' },
            React.createElement(CloudTenantDetails, { data: item }),
            React.createElement(CloudTenantStructure, { data: item })))
    };
};
