import { AttributeErrorCode } from "@/presentation/pages";
export const FallbackRuleDetails = {
    initialConfig: '',
    logBody: false,
    enforceRule: false,
    cfnScan: false,
    service: {
        label: ''
    }
};
export const RulesDeleteStrings = {
    headerTextSingular: 'Delete Rule',
    headerTextPlural: ' Delete Rules',
    modalTextSingular: 'rule',
    modalTextPlural: 'rules',
    consentText: 'confirm'
};
export const RulesDeleteShowProp = 'name';
export var HeaderStrings;
(function (HeaderStrings) {
    HeaderStrings["create"] = "Create Rule";
    HeaderStrings["edit"] = "Edit Rule";
})(HeaderStrings || (HeaderStrings = {}));
export const ORG_ZERO = 0;
export const ComplianceMappingsErrorCode = {
    [AttributeErrorCode.INVALID_KEY]: 'Please enter a valid Framework. Framework can only contain alphanumeric characters, spaces and any of the following: _.:/=+@-',
    [AttributeErrorCode.INVALID_VALUE]: 'Please enter a valid Control.',
    // Future proofing the error codes. We need this in the future
    [AttributeErrorCode.INVALID_VALUE_ADDITIONAL]: '',
    [AttributeErrorCode.INVALID_KEY_AND_VALUE]: '',
    [AttributeErrorCode.DUPLICATE_KEY]: '',
    [AttributeErrorCode.DUPLICATE_VALUE]: 'Duplicate control. Compliance mappings must be unique.',
};
