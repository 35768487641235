import React from 'react';
import { PATH_RULES, PATH_PROFILES, PATH_IDENTITIES } from '@/presentation/common-utils/constants';
import ExternalLink from '@/presentation/components/links/external-link';
import { Badge } from '@cloudscape-design/components-themed/components';
import { getIdentifierLabel } from "@/presentation/pages/identities/constants";
const identifierLabelObject = getIdentifierLabel();
export const RuleEvaluationIdentitiesColumnDefinitions = [
    {
        id: 'id',
        header: 'ID',
        cell: item => {
            const link = `${PATH_IDENTITIES.VIEW}/${item.id}`;
            return React.createElement(ExternalLink, { to: link, label: item.id });
        },
        width: 120,
        sortingField: 'id',
    },
    {
        id: 'name',
        header: 'Name',
        cell: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.name) !== null && _a !== void 0 ? _a : ''; },
        sortingField: 'name',
    },
    {
        id: 'identifier',
        header: 'Identifier',
        cell: item => {
            var _a;
            const identifier = (_a = item === null || item === void 0 ? void 0 : item.identifier) !== null && _a !== void 0 ? _a : '';
            return identifier ? (React.createElement(Badge, { color: 'grey' }, identifierLabelObject[identifier] || identifier)) : '';
        },
        sortingField: 'identifier',
    }
];
export const RuleEvaluationProfilesColumnDefinitions = [
    {
        id: 'id',
        header: 'ID',
        cell: item => {
            const link = `${PATH_PROFILES.VIEW}/${item.id}`;
            return React.createElement(ExternalLink, { to: link, label: item.id });
        },
        width: 120,
        sortingField: 'id',
    },
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        sortingField: 'name',
    },
    {
        id: 'identityNames',
        header: 'Identities',
        cell: item => (item === null || item === void 0 ? void 0 : item.identityNames) || '',
        sortingField: 'identityNames',
    }
];
export const RuleEvaluationRulesColumnDefinitions = [
    {
        id: 'id',
        header: 'ID',
        cell: item => {
            const link = PATH_RULES.VIEW + '/' + item.id;
            return React.createElement(ExternalLink, { to: link, label: item.id });
        },
        width: 120,
        sortingField: 'id',
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description,
        sortingField: 'description',
    },
    {
        id: 'processResult',
        header: 'Process Result',
        cell: item => item.processResult,
        sortingField: 'processResult',
    },
    {
        id: 'profilesNames',
        header: 'Profiles',
        cell: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.profilesNames) !== null && _a !== void 0 ? _a : ''; },
        sortingField: 'profilesNames',
    }
];
