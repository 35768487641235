var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var ServiceInspections;
(function (ServiceInspections) {
    ServiceInspections["ENABLED"] = "enabled";
    ServiceInspections["DISABLED"] = "disabled";
    ServiceInspections["BYPASS"] = "bypass";
})(ServiceInspections || (ServiceInspections = {}));
export class ServicesHandler {
    constructor(remotes) {
        this.getPropertyFilteringOptions = (services) => {
            const filters = [];
            services.Services.map((service) => {
                if (service.inspection !== ServiceInspections.DISABLED) {
                    filters.push({
                        propertyKey: 'service',
                        value: service.GlobalService.name,
                    });
                }
            });
            return filters;
        };
        this.remotes = remotes;
    }
    deleteEntity(any) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
    extractFilters(any) {
        return [];
    }
    getEntity(any) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(undefined);
        });
    }
    getFormattedEntities(any) {
        return [];
    }
    getFormattedSuperSelectOptions(providerID, services) {
        const superSelects = [];
        services.Services.forEach((service) => {
            if (providerID === service.GlobalService.Provider.id && service.inspection !== ServiceInspections.DISABLED) {
                superSelects.push({
                    value: String(service.id),
                    inputDisplay: service.GlobalService.name
                });
            }
        });
        return superSelects;
    }
    getFormattedComboValueOptionsForAllProviders(services) {
        const combos = [];
        services.Services.map((service) => {
            if (service.inspection !== ServiceInspections.DISABLED) {
                combos.push({
                    label: service.GlobalService.name,
                    value: String(service.id)
                });
            }
        });
        return combos;
    }
    getFormattedComboValueOptions(providerID, services) {
        const combos = [];
        services.Services.map((service) => {
            if (providerID === service.GlobalService.Provider.id && service.inspection !== ServiceInspections.DISABLED) {
                combos.push({
                    label: service.GlobalService.name,
                    value: String(service.id)
                });
            }
        });
        return combos;
    }
    getServiceIDByName(serviceName, services) {
        const filteredService = services.Services.filter((service) => {
            return service.GlobalService.name === serviceName;
        });
        if (filteredService[0]) {
            return filteredService[0].id;
        }
        return 0;
    }
    sortServicesByName(services) {
        services.Services = services.Services.sort((a, b) => (a.GlobalService.name > b.GlobalService.name) ? 1 : -1);
        return services;
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.services.list();
        });
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield input;
        });
    }
    deleteBulk(ids) {
        return Promise.resolve(undefined);
    }
}
