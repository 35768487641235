import React from 'react';
import { useAuth0 } from '@/lib/auth0';
import { ProfilesHandler } from "@/presentation/handlers";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { RemoteProfiles } from "@/data/usecases";
import { makeHierarchicalBreadcrumbsFromURLPath } from "@/presentation/components/commons/breadcrumbs";
import ProfileList from "@/presentation/pages/profiles/profiles-list";
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useLocation } from "react-router-dom";
const MakeProfilesList = () => {
    const { getTokenSilently } = useAuth0();
    const { notifications } = useNotifications();
    const location = useLocation();
    const breadCrumbs = makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    const remotes = {
        profiles: new RemoteProfiles(getTokenSilently),
    };
    const profilesHandler = new ProfilesHandler(null, remotes);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(ProfileList, { profilesHandler: profilesHandler }), contentType: "default" })));
};
export default MakeProfilesList;
