import React, { useEffect, useState } from "react";
import { ServiceInspections, } from '@/presentation/handlers';
import { useRulesHandlers } from "@/presentation/providers";
import { EndpointFilters, MethodFilters, PathFilters, rulesFilteringProperties, ActionFiltersTokenGroup, } from "./rules-wizard-config";
import { Button, Container, ExpandableSection, FormField, Header, Input, PropertyFilter, Select, SpaceBetween, Toggle, TokenGroup } from '@cloudscape-design/components-themed/components';
import { getFieldOnChange } from "@/presentation/components";
import { makePropertyFilterI18nStrings, makeProvidersSelectionOptions, makeRiskRatingSelectionOptions } from "@/presentation/pages";
import Autosuggest from "@cloudscape-design/components-themed/components/autosuggest";
import { useIsMounted } from '@/presentation/hooks';
import { PATHS } from '@/presentation/common-utils/constants';
import { SETTINGS_TABS } from '@/presentation/pages/settings/settings-types';
import ExternalLink from "@/presentation/components/links/external-link";
import { configData } from "./rules-list-config";
const makeServiceSelectionOptions = (providerID, services) => {
    var _a, _b;
    return ((_a = services === null || services === void 0 ? void 0 : services.Services) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? (_b = services === null || services === void 0 ? void 0 : services.Services) === null || _b === void 0 ? void 0 : _b.filter((serviceProvider) => providerID === serviceProvider.GlobalService.Provider.id &&
            serviceProvider.inspection !== "disabled").map((service) => {
            return {
                label: service.GlobalService.name,
                value: String(service.id),
            };
        })
        : [];
};
const defaultAction = [
    {
        "operator": "=",
        "propertyKey": "action",
        "value": "*",
        "label": "*"
    }
];
const getUniqueObjects = (detailTokens) => {
    if ((detailTokens === null || detailTokens === void 0 ? void 0 : detailTokens.length) > 0) {
        const objectMapping = {};
        const arrayOfTokens = detailTokens.filter(item => (item.value).trim() !== "").map(tokenItem => { return Object.assign(Object.assign({}, tokenItem), { value: tokenItem.value.trim() }); });
        const result = arrayOfTokens.reduce((group, arr) => {
            const { propertyKey, value } = arr;
            group[propertyKey] = group[propertyKey] || [];
            const keyValuePair = `${propertyKey}-${value}`;
            if (!objectMapping[keyValuePair]) {
                group[propertyKey].push(arr);
                objectMapping[keyValuePair] = arr;
            }
            return group;
        }, {});
        return result;
    }
    return {};
};
const RuleDetails = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { onChange, servicesList, providersList, info, validation, rulesHandler } = props;
    const [actionValue, setActionValue] = React.useState("");
    const [existingActions, setExistingActions] = useState(((_a = info === null || info === void 0 ? void 0 : info.details) === null || _a === void 0 ? void 0 : _a.action) || []);
    const [selectedProvider, setSelectedProvider] = useState(info.details.provider);
    const [selectedRiskRating, setSelectedRiskRating] = useState(info.details.riskRating);
    const [selectedService, setSelectedService] = useState(info.details.service);
    const [formattedServices, setFormattedServices] = useState();
    const isCFNPropsCheck = ((_d = (_c = (_b = info === null || info === void 0 ? void 0 : info.details) === null || _b === void 0 ? void 0 : _b.service) === null || _c === void 0 ? void 0 : _c.label) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === 's3';
    const [isCFN, setIsCFN] = useState(isCFNPropsCheck || false);
    const [isCFNScan, setCFNScan] = useState(info.details.cfnScan || false);
    const [isLogBody, setLogBody] = useState(info.details.logBody);
    const [isEnforce, setEnforce] = useState(info.details.enforceRule);
    const [desc, setDesc] = useState(info.details.description);
    const [descError, setDescError] = useState(validation.descError);
    const [providerError, setProviderError] = useState(validation.providerError);
    const [serviceError, setServiceError] = useState(validation.serviceError);
    const [existingFilters, setExistingFilters] = useState(((_e = info === null || info === void 0 ? void 0 : info.details) === null || _e === void 0 ? void 0 : _e.config) ? getUniqueObjects(info.details.config) : {});
    const { descriptionValidator } = useRulesHandlers();
    const [actionTokenGroup, setActionTokenGroup] = useState({});
    const [selectedServiceActionGroup, setSelectedServiceActionGroup] = useState([]);
    const [isDropdownDisabled, setDisabledDropdown] = useState(false);
    const [filtersErrorText, setFiltersErrorText] = useState("");
    const [filteringOptions] = useState([
        ...EndpointFilters,
        ...PathFilters,
        ...MethodFilters,
    ]);
    const [showServiceLabelHelp, setShowServiceLabelHelp] = useState(false);
    const isMounted = useIsMounted();
    const [filteringQuery, setFilteringQuery] = useState({
        tokens: ((_f = info === null || info === void 0 ? void 0 : info.details) === null || _f === void 0 ? void 0 : _f.config) || [],
        operation: "and",
    });
    const providersOnChange = getFieldOnChange("select", "provider", onChange);
    const serviceOnChange = getFieldOnChange("select", "service", onChange);
    const riskRatingOnChange = getFieldOnChange("select", "riskRating", onChange);
    const onDescriptionChange = getFieldOnChange("text", "description", onChange);
    const configOnChange = getFieldOnChange("propertyFilter", "config", onChange);
    const actionConfigOnChange = getFieldOnChange("tokenGroup", "action", onChange);
    const cfnOnChange = getFieldOnChange("radio", "cfnScan", onChange);
    const logBodyOnChange = getFieldOnChange("radio", "logBody", onChange);
    const enforceRuleOnChange = getFieldOnChange("radio", "enforceRule", onChange);
    const providersListOptions = makeProvidersSelectionOptions(props.providersList);
    const riskRatingListOptions = makeRiskRatingSelectionOptions();
    const formatAndSetServices = (providerID, services) => {
        var _a, _b, _c;
        const formattedServicesList = makeServiceSelectionOptions(providerID, services);
        const atLeastOneServiceIsDisabled = ((_b = (_a = services === null || services === void 0 ? void 0 : services.Services) === null || _a === void 0 ? void 0 : _a.filter(service => {
            var _a, _b;
            return (service === null || service === void 0 ? void 0 : service.inspection) === ServiceInspections.DISABLED && ((_b = (_a = service === null || service === void 0 ? void 0 : service.GlobalService) === null || _a === void 0 ? void 0 : _a.Provider) === null || _b === void 0 ? void 0 : _b.id) === providerID;
        })) === null || _b === void 0 ? void 0 : _b.length) > 0;
        const numberOfServicesLessThanGlobalServices = (formattedServicesList === null || formattedServicesList === void 0 ? void 0 : formattedServicesList.length) < ((_c = props.providerGlobalServicesCounts) === null || _c === void 0 ? void 0 : _c[providerID]);
        setShowServiceLabelHelp(atLeastOneServiceIsDisabled || numberOfServicesLessThanGlobalServices);
        if (isMounted.current) {
            setFormattedServices(formattedServicesList);
        }
    };
    const validatedOnProviderBlur = (val) => {
        const providerErrorStatus = (val === null || val === void 0 ? void 0 : val.constructor) === Object && Object.keys(val).length > 0;
        if (!providerErrorStatus) {
            setProviderError(true);
        }
    };
    const handleProviderChangeCallback = ({ detail }) => {
        var _a, _b;
        if (((_a = detail.selectedOption) === null || _a === void 0 ? void 0 : _a.label) !== (selectedProvider === null || selectedProvider === void 0 ? void 0 : selectedProvider.label)) {
            providersOnChange(detail);
            if (isMounted.current) {
                setSelectedProvider(detail.selectedOption);
                setProviderError(false);
                formatAndSetServices(parseInt((_b = detail === null || detail === void 0 ? void 0 : detail.selectedOption) === null || _b === void 0 ? void 0 : _b.value, 10), servicesList);
                setSelectedService(undefined);
            }
            rulesHandler.downloadActionsData(detail.selectedOption.label)
                .then(res => {
                if (isMounted.current) {
                    setActionTokenGroup(res.data);
                }
            })
                .catch(err => {
                console.log(err);
                if (isMounted.current) {
                    setActionTokenGroup({});
                }
            });
        }
    };
    const clearRiskRating = () => {
        riskRatingOnChange(undefined);
        setSelectedRiskRating(undefined);
    };
    const handleRiskRatingChangeCallback = ({ detail }) => {
        var _a;
        if (((_a = detail.selectedOption) === null || _a === void 0 ? void 0 : _a.label) !== (selectedRiskRating === null || selectedRiskRating === void 0 ? void 0 : selectedRiskRating.label)) {
            riskRatingOnChange(detail);
            setSelectedRiskRating(detail.selectedOption);
        }
    };
    const validatedOnServiceBlur = (val) => {
        const serviceErrorStatus = (val === null || val === void 0 ? void 0 : val.constructor) === Object && Object.keys(val).length > 0;
        if (!serviceErrorStatus) {
            setServiceError(true);
            setSelectedService(undefined);
        }
    };
    const formatSelectedActionService = (serviceLabel) => {
        var _a;
        const filteredActionGroup = ((_a = actionTokenGroup === null || actionTokenGroup === void 0 ? void 0 : actionTokenGroup[serviceLabel]) === null || _a === void 0 ? void 0 : _a.map(item => {
            return { value: item };
        })) || [];
        setSelectedServiceActionGroup(filteredActionGroup);
    };
    const handleServiceChangeCallback = ({ detail }) => {
        var _a, _b, _c;
        setExistingActions(defaultAction);
        serviceOnChange(detail);
        setSelectedService(detail.selectedOption);
        const isCFNCheck = ((_b = (_a = detail === null || detail === void 0 ? void 0 : detail.selectedOption) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 's3';
        setIsCFN(isCFNCheck);
        setServiceError(false);
        formatSelectedActionService((_c = detail === null || detail === void 0 ? void 0 : detail.selectedOption) === null || _c === void 0 ? void 0 : _c.label);
    };
    const validatedOnDescBlur = (val) => {
        const validationResult = descriptionValidator === null || descriptionValidator === void 0 ? void 0 : descriptionValidator.validate(val);
        setDescError(!validationResult.valid);
    };
    const handleDescChangeCallback = ({ detail }) => {
        onDescriptionChange(detail);
        setDesc(detail.value);
        validatedOnDescBlur(detail.value);
    };
    const handleFiltersData = (selectedTokensData) => {
        var _a, _b, _c;
        const selectedTokens = getUniqueObjects(selectedTokensData);
        let errText = "";
        let consolidatedObject = {};
        const filtersCheck = (filterKey) => {
            var _a;
            const currRecord = selectedTokens === null || selectedTokens === void 0 ? void 0 : selectedTokens[filterKey];
            if (((_a = existingFilters === null || existingFilters === void 0 ? void 0 : existingFilters[filterKey]) === null || _a === void 0 ? void 0 : _a.length) === 1 && (existingFilters === null || existingFilters === void 0 ? void 0 : existingFilters[filterKey][0].value) === "*" && (currRecord === null || currRecord === void 0 ? void 0 : currRecord.length) > 1) {
                currRecord.shift();
            }
            const uniqueRecord = [...new Set(currRecord)];
            consolidatedObject = Object.assign({ [filterKey]: uniqueRecord }, consolidatedObject);
        };
        if (((_a = selectedTokens === null || selectedTokens === void 0 ? void 0 : selectedTokens.endpoint) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            filtersCheck("endpoint");
        }
        else {
            errText += "Endpoint filter cannot be empty. ";
        }
        if (((_b = selectedTokens === null || selectedTokens === void 0 ? void 0 : selectedTokens.path) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            filtersCheck("path");
        }
        else {
            errText += "Path filter cannot be empty. ";
        }
        if (((_c = selectedTokens === null || selectedTokens === void 0 ? void 0 : selectedTokens.method) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            filtersCheck("method");
        }
        else {
            errText += "Method filter cannot be empty. ";
        }
        // Combine all objects into a single array
        const consolidated = Object.values(consolidatedObject).flat();
        // Define the order of propertyKey
        const order = ["endpoint", "path", "method"];
        // Sort the array based on propertyKey order
        const consolidatedResult = consolidated.sort((a, b) => order.indexOf(a.propertyKey) - order.indexOf(b.propertyKey));
        setFiltersErrorText(errText);
        setExistingFilters(consolidatedObject);
        return consolidatedResult;
    };
    const handlePropertyFiltersOnChange = (detail) => {
        var _a, _b;
        const validPropertyKeys = ['endpoint', 'path', 'method'];
        const propertyKeyInValid = (_a = detail === null || detail === void 0 ? void 0 : detail.tokens) === null || _a === void 0 ? void 0 : _a.some(token => !validPropertyKeys.includes(token === null || token === void 0 ? void 0 : token.propertyKey));
        const defaultConfig = [...configData.endpoint, ...configData.path, ...configData.method];
        const detailTokens = ((_b = detail === null || detail === void 0 ? void 0 : detail.tokens) === null || _b === void 0 ? void 0 : _b.length) > 0 ? detail === null || detail === void 0 ? void 0 : detail.tokens : defaultConfig;
        if (!propertyKeyInValid) {
            const selectedTokens = handleFiltersData(detailTokens);
            const consolidatedDetail = Object.assign(Object.assign({}, detail), { tokens: selectedTokens });
            configOnChange(consolidatedDetail);
            setFilteringQuery(consolidatedDetail);
        }
    };
    useEffect(() => {
        var _a, _b;
        if (providersList && servicesList && isMounted.current && (props.isEdit || props.isClone)) {
            formatAndSetServices(parseInt(info.details.provider.value, 10), servicesList);
            rulesHandler.downloadActionsData((_b = (_a = info === null || info === void 0 ? void 0 : info.details) === null || _a === void 0 ? void 0 : _a.provider) === null || _b === void 0 ? void 0 : _b.label)
                .then(res => {
                var _a, _b, _c, _d;
                if (isMounted.current) {
                    setActionTokenGroup(res.data);
                    const serviceLabel = (_b = (_a = info === null || info === void 0 ? void 0 : info.details) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.label;
                    const filteredActionGroup = ((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c[serviceLabel]) === null || _d === void 0 ? void 0 : _d.map(item => {
                        return { value: item };
                    })) || [];
                    setSelectedServiceActionGroup(filteredActionGroup);
                }
            })
                .catch(err => {
                console.log(err);
                if (isMounted.current) {
                    setActionTokenGroup({});
                }
            });
            if (props.isEdit && isMounted.current) {
                setDisabledDropdown(true);
            }
        }
    }, [providersList, servicesList]);
    useEffect(() => {
        setProviderError(validation.providerError);
    }, [validation.providerError]);
    useEffect(() => {
        setServiceError(validation.serviceError);
    }, [validation.serviceError]);
    useEffect(() => {
        setDescError(validation.descError);
    }, [validation.descError]);
    const handlePropertyActionFiltersOnChangeToken = (newOptions) => {
        const selectedTokens = (newOptions === null || newOptions === void 0 ? void 0 : newOptions.filter(item => (item.value).trim() !== "")) || [];
        setExistingActions(selectedTokens);
        actionConfigOnChange(selectedTokens);
    };
    const addActionsToList = (actionList) => {
        const trimmedActionList = actionList.trim();
        if (trimmedActionList !== "") {
            const tokenGroup = {
                label: trimmedActionList,
                operator: "=",
                propertyKey: "action",
                value: trimmedActionList,
            };
            if (existingActions.length === 1 && existingActions[0].value === "*") {
                handlePropertyActionFiltersOnChangeToken([tokenGroup]);
            }
            else {
                const updatedActions = [...existingActions, tokenGroup];
                const uniqueIdentifier = 'value';
                const uniqueUpdatedActions = [...new Map(updatedActions === null || updatedActions === void 0 ? void 0 : updatedActions.map(item => [item[uniqueIdentifier], item])).values()];
                handlePropertyActionFiltersOnChangeToken(uniqueUpdatedActions);
            }
        }
    };
    const removeActionsFromList = (event) => {
        const updatedActions = [
            ...existingActions.slice(0, event.detail.itemIndex),
            ...existingActions.slice(event.detail.itemIndex + 1)
        ];
        handlePropertyActionFiltersOnChangeToken(updatedActions);
    };
    const documentPath = `${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.GLOBAL_SERVICES}`;
    const descriptionLabel = showServiceLabelHelp ? (React.createElement(React.Fragment, null,
        React.createElement("span", null,
            "One or more services for ", selectedProvider === null || selectedProvider === void 0 ? void 0 :
            selectedProvider.label,
            " are currently disabled and are not shown here. These services can be enabled in"),
        React.createElement("span", { className: "px-1 kivera-external-link-xs" },
            React.createElement(ExternalLink, { to: documentPath, label: "Global Services" })))) : "";
    return (React.createElement(React.Fragment, null,
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Rule Configuration") },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(FormField, { label: "Provider", errorText: providerError ? 'Please select the cloud provider for this rule' : '' },
                        React.createElement(Select, { selectedOption: selectedProvider, onChange: handleProviderChangeCallback, options: providersListOptions, filteringType: "auto", selectedAriaLabel: "Selected", "data-testid": "rule-details-providers-form-select-default-identity", disabled: isDropdownDisabled, onBlur: () => {
                                validatedOnProviderBlur(selectedProvider);
                            } })),
                    React.createElement(FormField, { label: "Service", description: descriptionLabel, errorText: serviceError ? 'Please select the service for this rule' : '' },
                        React.createElement(Select, { selectedOption: selectedService, onChange: handleServiceChangeCallback, options: formattedServices, filteringType: "auto", selectedAriaLabel: "Selected", "data-testid": "rule-details-services-form-select-default-identity", disabled: isDropdownDisabled, onBlur: () => {
                                validatedOnServiceBlur(selectedService);
                            } })),
                    React.createElement(FormField, { label: "Filter request by action", description: "Use action filters to restrict this rule to specific actions e.g. PutBucket. Wildcards are permitted and multiple filters can be added.", errorText: existingActions.length < 1 ? "Please check actions input" : '' },
                        React.createElement(Autosuggest, { onChange: ({ detail }) => {
                                setActionValue(detail.value);
                            }, value: actionValue, enteredTextLabel: (val) => val, options: [...ActionFiltersTokenGroup, ...selectedServiceActionGroup], ariaLabel: "Autosuggest example with actions", placeholder: "Enter value", empty: "No matches found", onKeyUp: ({ detail }) => {
                                if (detail.keyCode === 13) {
                                    addActionsToList(actionValue);
                                    setActionValue('');
                                }
                            }, onSelect: ({ detail }) => {
                                addActionsToList(detail.value);
                                setActionValue('');
                            } }),
                        React.createElement(TokenGroup, { items: existingActions, onDismiss: removeActionsFromList })),
                    React.createElement(FormField, { label: "Description", description: "Brief description of the rule", errorText: descError ? "Please enter a rule description" : "" },
                        React.createElement(Input, { placeholder: "Example rule description", value: desc, onChange: handleDescChangeCallback, onBlur: () => {
                                validatedOnDescBlur(desc);
                            } })),
                    React.createElement(FormField, { label: "Risk Rating" },
                        React.createElement("div", { className: "flex flex-wrap justify-between" },
                            React.createElement("div", { className: "w-5/6 grow mr-5 py-1" },
                                React.createElement(Select, { selectedOption: selectedRiskRating, onChange: handleRiskRatingChangeCallback, options: riskRatingListOptions, filteringType: "auto", selectedAriaLabel: "Selected" })),
                            React.createElement("div", { className: "py-1" },
                                React.createElement(Button, { variant: "normal", disabled: !selectedRiskRating, onClick: () => clearRiskRating() }, "Clear")))),
                    React.createElement(ExpandableSection, { headerText: "Advanced Configuration" },
                        React.createElement(SpaceBetween, { size: "l" },
                            React.createElement(FormField, { label: "Filters", description: "Add filters to restrict this rule by endpoint, path or method", errorText: filtersErrorText },
                                React.createElement(PropertyFilter, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'e.g. Method = POST', clearFiltersText: 'Reset Filters' }), expandToViewport: true, filteringOptions: filteringOptions, filteringProperties: rulesFilteringProperties, onChange: ({ detail }) => {
                                        handlePropertyFiltersOnChange(detail);
                                    }, query: filteringQuery, hideOperations: true })),
                            React.createElement(FormField, { label: "Log Body", description: "When enabled, log the body of request. Disable this option if the request contains sensitive data." },
                                React.createElement(Toggle, { onChange: ({ detail }) => {
                                        logBodyOnChange(detail);
                                        setLogBody(detail.checked);
                                    }, checked: isLogBody })),
                            React.createElement(FormField, { label: "Enforce Rule", description: "When enabled, the rule will be enforced. Disable this option when you would like to see the rule evaluation result without blocking traffic." },
                                React.createElement(Toggle, { onChange: ({ detail }) => {
                                        enforceRuleOnChange(detail);
                                        setEnforce(detail.checked);
                                    }, checked: isEnforce })),
                            isCFN ? (React.createElement(React.Fragment, null,
                                React.createElement(FormField, { label: "Cloudformation Scan", description: "Enable or disable Cloudformation scan" },
                                    React.createElement(Toggle, { onChange: ({ detail }) => {
                                            cfnOnChange(detail);
                                            setCFNScan(detail.checked);
                                        }, checked: isCFNScan })))) : null)))))));
};
const RulesForm = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(RuleDetails, Object.assign({}, props)))));
};
export default RulesForm;
