import React from 'react';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { makeHierarchicalBreadcrumbsFromURLPath } from '@/presentation/components/commons/breadcrumbs';
import RulesList from '@/presentation/pages/rules/rules-list';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { Flashbar } from '@cloudscape-design/components-themed/components';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import { useLocation } from "react-router-dom";
import { RulesHandlersProvider } from '@/presentation/providers';
const MakeRulesList = (props) => {
    const { notifications } = useNotifications();
    const location = useLocation();
    const breadCrumbs = makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, notifications: React.createElement(Flashbar, { items: notifications, stackItems: true }), content: React.createElement(RulesHandlersProvider, null,
                React.createElement(RulesList, null)), contentType: "default" })));
};
export default MakeRulesList;
