export class NotificationsHandler {
    constructor() {
        this.notifications = [];
        this.setNotifications = (definitions) => {
            this.notifications = definitions;
        };
        this.getNotifications = () => {
            return this.notifications;
        };
    }
}
