import React, { useContext, useMemo, useState } from "react";
import DefaultErrorPage from "@/presentation/pages/errors/default-error-page";
const defaultErrorBoundaryContext = {
    errorStatus: false,
    setErrorStatus: () => { },
};
export const ErrorBoundaryContext = React.createContext(defaultErrorBoundaryContext);
export const useErrorBoundaryContext = () => useContext(ErrorBoundaryContext);
export const ErrorBoundaryContextProvider = (props) => {
    const [errorStatus, setErrorStatus] = useState(false);
    const contextValue = useMemo(() => ({ errorStatus, setErrorStatus }), [errorStatus]);
    return (React.createElement(ErrorBoundaryContext.Provider, { value: contextValue }, errorStatus ? React.createElement(DefaultErrorPage, null) : props.children));
};
