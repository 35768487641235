import React, { useEffect, useState } from "react";
import { useAuth0 } from "@/lib/auth0";
import { GetReportingURL, SetReportingURL, } from "@/presentation/storage/misc-storage";
import { DASHBOARD_METABASE_REPORTING_LINK } from "@/presentation/storage";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import Skeleton from "react-loading-skeleton";
import IframeWithResize from "@/presentation/components/iframe/iframe-with-resize";
import { Box, } from "@cloudscape-design/components-themed/components";
const Reporting = (props) => {
    const [loading, setLoading] = useState(true);
    const { user, rawToken } = useAuth0();
    const [reportingLink, setReportingLink] = useState("");
    const [reportNotAvailable, setReportNotAvailable] = useState(false);
    useEffect(() => {
        setLoading(false);
        const orgID = String(getOrgIDFromToken(rawToken));
        const reportingObj = GetReportingURL(DASHBOARD_METABASE_REPORTING_LINK + orgID)
            ? JSON.parse(GetReportingURL(DASHBOARD_METABASE_REPORTING_LINK + orgID))
            : undefined;
        if (!reportingObj) {
            props.reportingHandler
                .getMetabaseReportingLink()
                .then((url) => {
                if (!(url === null || url === void 0 ? void 0 : url.error) && (url === null || url === void 0 ? void 0 : url.data)) {
                    SetReportingURL(DASHBOARD_METABASE_REPORTING_LINK + orgID, {
                        url: url === null || url === void 0 ? void 0 : url.data,
                    });
                    setReportingLink(url === null || url === void 0 ? void 0 : url.data);
                }
                else {
                    setReportNotAvailable(true);
                }
            })
                .catch((error) => {
                // something went wrong with Metabase URL fetching
                setReportNotAvailable(true);
            })
                .finally(() => {
                setLoading(false);
            });
        }
        else {
            const reportingLinkUrl = reportingObj === null || reportingObj === void 0 ? void 0 : reportingObj.url;
            setReportingLink(reportingLinkUrl);
            setLoading(false);
        }
        return () => { };
    }, [user]);
    if (loading || !user) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(Box, { margin: {
            top: "xl",
        } }, reportNotAvailable ? (React.createElement(React.Fragment, null, "Report Not Available")) : (React.createElement(React.Fragment, null,
        React.createElement(IframeWithResize, { src: reportingLink })))));
};
export default Reporting;
