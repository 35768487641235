import React from 'react';
import { Box, StatusIndicator, Icon } from '@cloudscape-design/components-themed/components';
import RegularLink from '@/presentation/components/links/regular-link';
import { getLogoAsUrl } from '@/presentation/pages';
import { getRandomUUID } from '@/lib/misc/utils';
import { PROXY_MODE_TYPE_LABEL } from '@/presentation/pages/proxies/proxies-types';
import { descriptionFilteringProperty, nameFilteringProperty, tagsFilteringProperty, userDetailsCreatedFilteringProperty, userDetailsUpdatedFilteringProperty, } from '@/presentation/pages/common-config';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import gravatarUrl from 'gravatar-url';
export function makeProxyStatus(status) {
    switch (status) {
        case 'ERROR':
            return React.createElement(StatusIndicator, { type: 'error' }, "Error");
        case 'PROVISIONING':
            return React.createElement(StatusIndicator, { type: 'in-progress' }, "Provisioning");
        case 'DELETING':
            return React.createElement(StatusIndicator, { type: 'pending' }, "Deleting");
        case 'READY':
            return React.createElement(StatusIndicator, { type: 'success' }, "Ready");
        default:
            return React.createElement(StatusIndicator, { type: 'error' }, "Error");
    }
}
export function makeHealthCheck(online) {
    if (!online) {
        return React.createElement(StatusIndicator, { type: 'stopped' }, "Offline");
    }
    return React.createElement(StatusIndicator, { type: 'success' }, "Online");
}
export const GetProxyProvidersList = (providersData) => {
    var _a;
    const providerList = (_a = providersData === null || providersData === void 0 ? void 0 : providersData.filter(status => status.enabled)) === null || _a === void 0 ? void 0 : _a.map((provider) => { var _a, _b; return (React.createElement("span", { key: getRandomUUID(), className: 'pl-1' },
        " ",
        React.createElement(Icon, { size: "medium", url: getLogoAsUrl((_a = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _a === void 0 ? void 0 : _a.name), alt: ((_b = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _b === void 0 ? void 0 : _b.name) || '' }),
        " ")); });
    if (providerList.length > 0) {
        return providerList;
    }
    return "-";
};
export const ProxiesListCardsColumnDefinitions = {
    header: (item) => {
        var _a;
        const link = String(item === null || item === void 0 ? void 0 : item.status).toUpperCase() !== 'READY' ? '#' : '/proxies/' + item.id;
        return React.createElement(RegularLink, { to: link, label: ((_a = item.name) === null || _a === void 0 ? void 0 : _a.length) <= 26 ? item.name : String(item.name).slice(0, 23) + '...', fontSize: "heading-m", "data-testid": "click-to-view-this-proxy" });
    },
    sections: [
        {
            id: 'description',
            header: 'Description',
            content: item => {
                return (React.createElement(Box, { key: getRandomUUID() }, (item === null || item === void 0 ? void 0 : item.description) || "-"));
            }
        },
        {
            id: 'status',
            header: 'Status',
            sortingField: 'status',
            content: (item) => {
                return (React.createElement(Box, null, makeProxyStatus((String(item === null || item === void 0 ? void 0 : item.status).toUpperCase()))));
            }
        },
        {
            id: 'onlineSearchable',
            header: 'Healthcheck',
            content: (item) => {
                return React.createElement(React.Fragment, null, makeHealthCheck(item === null || item === void 0 ? void 0 : item.online));
            }
        },
        {
            id: 'providerNames',
            header: 'Providers',
            content: (item) => {
                return ((item === null || item === void 0 ? void 0 : item.providerNames) && (item === null || item === void 0 ? void 0 : item.providerNames) !== '[null]') ? GetProxyProvidersList((item === null || item === void 0 ? void 0 : item.providers) || []) : '-';
            }
        },
        {
            id: 'deployments',
            header: 'Deployment Id',
            content: (item) => {
                var _a, _b;
                return ((_b = (_a = item === null || item === void 0 ? void 0 : item.deployments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) || '-';
            }
        },
        {
            id: 'default_mode',
            header: 'Default Mode',
            content: (item) => item === null || item === void 0 ? void 0 : item.default_mode
        },
        {
            id: 'created_by',
            header: 'Created By',
            content: (item) => {
                var _a;
                return ((item === null || item === void 0 ? void 0 : item.created_by) ?
                    (React.createElement("div", { className: "flex items-center gap-2" },
                        (item === null || item === void 0 ? void 0 : item.created_by) && React.createElement("img", { className: "rounded-full", src: gravatarUrl((_a = item === null || item === void 0 ? void 0 : item.created_by) !== null && _a !== void 0 ? _a : '', {
                                size: 28,
                                default: 'retro'
                            }), alt: '', title: (item === null || item === void 0 ? void 0 : item.createdByFullName) || '' }),
                        React.createElement("span", null, item === null || item === void 0 ? void 0 : item.createdByFullName))) : '-');
            }
        },
        {
            id: 'created_at',
            header: 'Created',
            content: (item) => {
                return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.created_at);
            },
        },
        {
            id: 'updated_by',
            header: 'Updated By',
            content: (item) => {
                var _a;
                return ((item === null || item === void 0 ? void 0 : item.updated_by) ?
                    (React.createElement("div", { className: "flex items-center gap-2" },
                        (item === null || item === void 0 ? void 0 : item.updated_by) && React.createElement("img", { className: "rounded-full", src: gravatarUrl((_a = item === null || item === void 0 ? void 0 : item.updated_by) !== null && _a !== void 0 ? _a : '', {
                                size: 28,
                                default: 'retro'
                            }), alt: '', title: (item === null || item === void 0 ? void 0 : item.updatedByFullName) || '' }),
                        React.createElement("span", null, item === null || item === void 0 ? void 0 : item.updatedByFullName))) : '-');
            }
        },
        {
            id: 'updated_at',
            header: 'Updated',
            content: (item) => {
                return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.updated_at);
            },
        },
    ]
};
export const ProxiesListCardsVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'name', label: 'Name', editable: false },
            { id: 'description', label: 'Description', editable: false },
            { id: 'status', label: 'Status', editable: true },
            { id: 'onlineSearchable', label: 'Healthcheck', editable: true },
            { id: 'providerNames', label: 'Providers', editable: true },
            { id: 'default_mode', label: 'Default Mode', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const ProxiesListCardsPageSizeOptions = [
    { value: 10, label: '10 Proxies' },
    { value: 20, label: '20 Proxies' },
    { value: 30, label: '30 Proxies' }
];
export const ProxiesListCardsPreferences = {
    pageSize: 20,
    visibleContent: ['id', 'name', 'description', 'status', 'onlineSearchable', 'providerNames', 'default_mode', 'table-actions'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const ProxiesListFilteringProperties = [
    nameFilteringProperty,
    descriptionFilteringProperty,
    {
        key: 'status',
        operators: ['=', '!='],
        propertyLabel: 'Status',
        groupValuesLabel: 'Status values'
    },
    {
        key: 'onlineSearchable',
        operators: ['=', '!='],
        propertyLabel: 'Healthcheck',
        groupValuesLabel: 'Healthcheck values'
    },
    {
        key: 'providerNames',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Providers',
        groupValuesLabel: 'Providers values'
    },
    {
        key: 'default_mode',
        operators: ['=', '!='],
        propertyLabel: 'Default Mode',
        groupValuesLabel: 'Default Mode values'
    },
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty,
    tagsFilteringProperty
];
export const ProxiesListFilteringOptions = [
    {
        propertyKey: 'status',
        value: 'READY'
    },
    {
        propertyKey: 'status',
        value: 'PROVISIONING'
    },
    {
        propertyKey: 'status',
        value: 'ERROR'
    },
    {
        propertyKey: 'status',
        value: 'DELETING'
    },
    {
        propertyKey: 'onlineSearchable',
        value: 'ONLINE'
    },
    {
        propertyKey: 'onlineSearchable',
        value: 'OFFLINE'
    },
    {
        propertyKey: 'default_mode',
        value: PROXY_MODE_TYPE_LABEL.LEARNING
    },
    {
        propertyKey: 'default_mode',
        value: PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE
    },
    {
        propertyKey: 'default_mode',
        value: PROXY_MODE_TYPE_LABEL.DEFAULT_ALLOW_BLACKLIST
    },
    {
        propertyKey: 'providerNames',
        value: '[null]'
    }
];
