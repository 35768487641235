import React, { useEffect, useState } from 'react';
import { AttributeEditor, Box, Container, Header, SpaceBetween } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from '@/lib/misc/utils';
import { isEmpty } from 'lodash';
import AttributeKeyValueSelectComponent from '@/presentation/components/attribute-editor/attribute-key-value-select-component';
const FormAttributeEditorWithSelectors = (props) => {
    var _a;
    const [items, setItems] = React.useState(props.items);
    const [errorItems, setErrorItems] = React.useState([]);
    const [disableAddButton, setDisableAddButton] = React.useState(((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) === props.itemLimit);
    const [keyOptions, setKeyOptions] = useState(props.keySelectOptions);
    const [valueOptions, setValueOptions] = useState(props.valueSelectOptions);
    const ref = React.useRef(null);
    useEffect(() => {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
            if (hashValue === props.scrollIntoViewHash) {
                document.getElementById(props.scrollIntoViewHash).scrollIntoView();
            }
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const getErrorItems = (items) => {
        const result = props.attributeKeyValueValidator.validate(items);
        // @ts-ignore
        return !(result.valid) ? result === null || result === void 0 ? void 0 : result.errorItems : [];
    };
    const areAllItemsEmpty = () => {
        var _a;
        return ((_a = items.filter(item => isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) && isEmpty(String(item === null || item === void 0 ? void 0 : item.value).trim()))) === null || _a === void 0 ? void 0 : _a.length) === (items === null || items === void 0 ? void 0 : items.length);
    };
    const checkErrors = () => {
        if (!areAllItemsEmpty()) {
            const errItems = getErrorItems(items);
            setErrorItems(errItems);
        }
    };
    useEffect(() => {
        checkErrors();
    }, [props.recheckErrors]);
    useEffect(() => {
        setItems(props.items);
    }, [props.items]);
    useEffect(() => {
        let errItems = [];
        props.onChange(items);
        setDisableAddButton(items.length === props.itemLimit);
        // at least 1 item is present, lets validate them
        if (!areAllItemsEmpty()) {
            errItems = getErrorItems(items);
            setErrorItems(errItems);
        }
        // if there is at-least 1 error send as error otherwise no error
        props.onError((errItems === null || errItems === void 0 ? void 0 : errItems.length) > 0);
    }, [items]);
    const itemKeyValueOnChange = (text, item, value) => {
        const filtered = items === null || items === void 0 ? void 0 : items.filter(it => (it === null || it === void 0 ? void 0 : it.id) === (item === null || item === void 0 ? void 0 : item.id));
        if (filtered === null || filtered === void 0 ? void 0 : filtered.length) {
            if (value) {
                filtered[0].value = text;
            }
            else {
                filtered[0].key = text;
            }
            // we do this to maintain the order of key values
            const tmpItems = [];
            items === null || items === void 0 ? void 0 : items.forEach(it => {
                if ((it === null || it === void 0 ? void 0 : it.id) !== (item === null || item === void 0 ? void 0 : item.id)) {
                    tmpItems.push(it);
                }
                else {
                    tmpItems.push(filtered[0]);
                }
            });
            setItems(tmpItems);
        }
    };
    const makeSelectOption = (value, key) => {
        return Object.assign({ id: getRandomUUID(), value: value, label: value }, (!!key && { key: key }));
    };
    const clearKeyOptions = (optionShownInSearchBox) => {
        const itemKeys = items.filter(it => !!it.key).map(it => it.key);
        const kLabels = props.keySelectOptions.filter(op => !!(op === null || op === void 0 ? void 0 : op.label)).map(op => op.label);
        const keyOptionsPresentInItems = keyOptions.filter(option => !!(option === null || option === void 0 ? void 0 : option.label) && itemKeys.includes(option === null || option === void 0 ? void 0 : option.label));
        const keyOptionsNotPresentInOriginal = keyOptionsPresentInItems.filter(kOption => !!(kOption === null || kOption === void 0 ? void 0 : kOption.label) && !kLabels.includes(kOption === null || kOption === void 0 ? void 0 : kOption.label));
        // only keep the options from incoming props.keyValueSelectOptions and the ones that are added to the box
        const settableOptions = [...props.keySelectOptions, ...keyOptionsNotPresentInOriginal];
        if (optionShownInSearchBox) {
            settableOptions.push(optionShownInSearchBox);
        }
        setKeyOptions(settableOptions);
    };
    const clearValueOptions = (optionShownInSearchBox) => {
        // consider all values present in boxes
        const itemValues = items.filter(it => !!(it === null || it === void 0 ? void 0 : it.value)).map(it => it.value);
        // @ts-ignore
        const vLabels = props.valueSelectOptions.filter(op => !!(op === null || op === void 0 ? void 0 : op.label)).map(op => op.label);
        // @ts-ignore
        const valueOptionsPresentInItems = valueOptions.filter(option => !!(option === null || option === void 0 ? void 0 : option.label) && itemValues.includes(option === null || option === void 0 ? void 0 : option.label));
        const valueOptionsNotPresentInOriginal = valueOptionsPresentInItems.filter(vOption => !!(vOption === null || vOption === void 0 ? void 0 : vOption.label) && !vLabels.includes(vOption === null || vOption === void 0 ? void 0 : vOption.label));
        // only keep the options from incoming props.valueSelectOptions and the ones that are added to the box
        const settableOptions = [...props.valueSelectOptions, ...valueOptionsNotPresentInOriginal];
        if (optionShownInSearchBox) {
            settableOptions.push(optionShownInSearchBox);
        }
        setValueOptions(settableOptions);
    };
    const handleAttributeKeyOnLoadChange = (event) => {
        var _a, _b, _c;
        if (!((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.filteringText)) {
            // clear the intermediary values
            clearKeyOptions();
        }
        else {
            const optionAlreadyExists = keyOptions.filter(option => { var _a; return (option === null || option === void 0 ? void 0 : option.label) === ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.filteringText); }).length > 0;
            if (!!((_b = event === null || event === void 0 ? void 0 : event.detail) === null || _b === void 0 ? void 0 : _b.filteringText) && !optionAlreadyExists) {
                const kOption = makeSelectOption((_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.filteringText);
                // clear the intermediary values but keep the last typed in value
                clearKeyOptions(kOption);
            }
        }
    };
    const handleAttributeKeyChange = (event, item) => {
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.selectedOption) === null || _b === void 0 ? void 0 : _b.value) !== item.key) {
            const attribute = {
                id: item.id,
                key: (_d = (_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.selectedOption) === null || _d === void 0 ? void 0 : _d.value,
                value: item.value,
            };
            itemKeyValueOnChange((_f = (_e = event === null || event === void 0 ? void 0 : event.detail) === null || _e === void 0 ? void 0 : _e.selectedOption) === null || _f === void 0 ? void 0 : _f.value, attribute, false);
            // when the key changes make sure to reset the value
            itemKeyValueOnChange('', attribute, true);
        }
    };
    const handleAttributeValueChange = (event, item) => {
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.selectedOption) === null || _b === void 0 ? void 0 : _b.value) !== item.value) {
            const attribute = {
                id: item.id,
                key: item.key,
                value: (_d = (_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.selectedOption) === null || _d === void 0 ? void 0 : _d.value,
            };
            itemKeyValueOnChange((_f = (_e = event === null || event === void 0 ? void 0 : event.detail) === null || _e === void 0 ? void 0 : _e.selectedOption) === null || _f === void 0 ? void 0 : _f.value, attribute, true);
        }
    };
    const handleAttributeValueOnLoadChange = (event, item) => {
        var _a, _b, _c, _d;
        if (!((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.filteringText)) {
            // clear the intermediary values
            clearValueOptions();
        }
        else {
            // @ts-ignore
            const optionAlreadyExists = ((_b = valueOptions === null || valueOptions === void 0 ? void 0 : valueOptions.filter(option => { var _a; return (item === null || item === void 0 ? void 0 : item.key) === (option === null || option === void 0 ? void 0 : option.key) && (option === null || option === void 0 ? void 0 : option.label) === ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.filteringText); })) === null || _b === void 0 ? void 0 : _b.length) > 0;
            if (!!((_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.filteringText) && !optionAlreadyExists) {
                const vOption = makeSelectOption((_d = event === null || event === void 0 ? void 0 : event.detail) === null || _d === void 0 ? void 0 : _d.filteringText, item === null || item === void 0 ? void 0 : item.key);
                // clear the intermediary values but keep the last typed in value
                clearValueOptions(vOption);
            }
        }
    };
    const attributeKeyComponent = (item) => {
        return React.createElement(AttributeKeyValueSelectComponent, { errorItems: errorItems, onChange: handleAttributeKeyChange, keyOrValue: 'key', item: item, items: items, onLoadChange: handleAttributeKeyOnLoadChange, onBlur: clearKeyOptions, options: keyOptions, attributeErrorCodeMappings: props.attributeErrorCodeMappings, placeHolder: (props === null || props === void 0 ? void 0 : props.keyPlaceHolder) || '' });
    };
    const attributeValueComponent = (item) => {
        return React.createElement(AttributeKeyValueSelectComponent, { errorItems: errorItems, onChange: handleAttributeValueChange, keyOrValue: 'value', item: item, items: items, onLoadChange: (event) => handleAttributeValueOnLoadChange(event, item), onBlur: clearValueOptions, options: valueOptions, attributeErrorCodeMappings: props.attributeErrorCodeMappings, placeHolder: (props === null || props === void 0 ? void 0 : props.valuePlaceHolder) || '' });
    };
    return (React.createElement("div", { ref: ref, id: props.scrollIntoViewHash },
        React.createElement(Box, null,
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, props.headerLabel) },
                    React.createElement("div", { className: 'attribute-editor-container' },
                        React.createElement(AttributeEditor, { items: items, definition: [
                                {
                                    label: props.attributeKeyLabel,
                                    control: attributeKeyComponent
                                },
                                {
                                    label: props.attributeValueLabel,
                                    control: attributeValueComponent
                                }
                            ], addButtonText: props.addButtonText, removeButtonText: props.removeButtonText, onAddButtonClick: () => {
                                if (items.length < props.itemLimit) {
                                    const attr = {
                                        id: getRandomUUID(),
                                        key: '',
                                        value: '',
                                    };
                                    setItems([...items, attr]);
                                    checkErrors();
                                }
                            }, onRemoveButtonClick: ({ detail: { itemIndex } }) => {
                                const tmpItems = [...items];
                                tmpItems.splice(itemIndex, 1);
                                setItems(tmpItems);
                                clearKeyOptions();
                                clearValueOptions();
                                checkErrors();
                            }, disableAddButton: disableAddButton, additionalInfo: props.itemLimit === items.length
                                ? null
                                : React.createElement("span", null,
                                    "You can add up to ",
                                    props.itemLimit - items.length,
                                    " more ",
                                    (props.itemLimit - items.length) > 1 ? props.itemLimitEntitySuffixPlural : props.itemLimitEntitySuffixSingle), empty: 'No ' + props.itemLimitEntitySuffixPlural + ' associated with the resource.' })))))));
};
export default FormAttributeEditorWithSelectors;
