import React, { useEffect, useState } from "react";
import MyProfileForm from "@/presentation/pages/my-profile/my-profile-user-form";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import { useAuth0 } from "@/lib/auth0";
import MyProfileOrganization from "@/presentation/pages/my-profile/my-profile-organization";
import MyProfileTokens from "@/presentation/pages/my-profile/my-profile-tokens";
import Skeleton from "react-loading-skeleton";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { Tabs, Header, Container } from "@cloudscape-design/components-themed/components";
import { MYPROFILES_TABS } from "@/presentation/pages/my-profile/my-profile-types";
import { getUrlTabbedId } from "@/presentation/common-utils/common-utils";
import { useNavigate } from "react-router-dom";
import { PATHS } from "@/presentation/common-utils/constants";
import { useIsMounted } from '@/presentation/hooks';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import ContentLayoutWrapper from "@/presentation/components/content-wrapper/content-layout-wrapper";
const MyProfile = () => {
    const { user, rawToken } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [activeOrgID, setActiveOrgID] = useState(0);
    const navigate = useNavigate();
    const allTabIds = [MYPROFILES_TABS.PROFILE, MYPROFILES_TABS.API_KEYS, MYPROFILES_TABS.ORGANIZATIONS];
    const hashValue = getUrlTabbedId(allTabIds);
    const [activeTabID, setActiveTabID] = useState(hashValue || MYPROFILES_TABS.PROFILE);
    const isMounted = useIsMounted();
    const updateOrg = (userMemberships, orgID) => {
        const org = userMemberships.filter((membership) => membership.organization.id === orgID);
        if ((org === null || org === void 0 ? void 0 : org.length) === 1) {
            setActiveOrgID(org[0].organization.id);
        }
    };
    useEffect(() => {
        if (isMounted.current) {
            setLoading(true);
            const orgID = getOrgIDFromToken(rawToken);
            updateOrg(user.memberships, orgID);
            setLoading(false);
        }
    }, [user]);
    const onTabChange = ({ detail: { activeTabId } }) => {
        const navigateUrl = `${PATHS.MYPROFILE}?tabId=${activeTabId}`;
        navigate(navigateUrl);
        setActiveTabID(activeTabId);
    };
    useEffect(() => {
        const tabId = getUrlTabbedId(allTabIds);
        setActiveTabID(tabId || MYPROFILES_TABS.PROFILE);
    }, [location === null || location === void 0 ? void 0 : location.href]);
    const userTabs = [
        {
            label: "Profile",
            id: MYPROFILES_TABS.PROFILE,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(MyProfileForm, { user: user })),
        },
        {
            label: "API Keys",
            id: MYPROFILES_TABS.API_KEYS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(MyProfileTokens, null)),
        },
        {
            label: "Organizations",
            id: MYPROFILES_TABS.ORGANIZATIONS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(MyProfileOrganization, { activeOrgID: activeOrgID, user: user })),
        },
    ];
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(ContentWrapper, { testid: "my-profile-tabs" },
        React.createElement(ContentLayoutWrapper, { header: React.createElement(Header, { variant: "h1" }, "My Profile") },
            React.createElement(Container, null,
                React.createElement(Tabs, { tabs: userTabs, activeTabId: activeTabID, onChange: onTabChange, ariaLabel: "My profile" })))));
};
export default MyProfile;
