import { useAuth0 } from '@/lib/auth0';
import { useEffect, useState } from 'react';
// Deprecated code
export const useListFetcher = (listTableData, loadingInitialState, tableDataInitialState, filtersInitialState, messageInitialState, errorInitialState, setDataAndFilters) => {
    const { rawToken } = useAuth0();
    const [loading, setLoading] = useState(loadingInitialState);
    const [fetchedData, setFetchedData] = useState(undefined);
    const [tableData, setTableData] = useState(tableDataInitialState);
    const [refreshTable, setRefreshTable] = useState(false);
    // const [tableOptions, setTableOptions] = useState()
    const [filters, setFilters] = useState(filtersInitialState);
    const [error, setError] = useState(errorInitialState);
    const [message, setMessage] = useState(messageInitialState);
    useEffect(() => {
        console.log('FETCHING LIST');
        let isMounted = true;
        setLoading(true);
        listTableData(rawToken).then((listOf) => {
            if (isMounted) {
                setLoading(false);
                setFetchedData(listOf);
                setDataAndFilters(listOf, setTableData, setMessage, setFilters);
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            // setError(unknownError)
        });
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isMounted = false;
            console.log('cleaning up in list fetcher hook');
        };
    }, [listTableData, refreshTable]);
    return [loading, fetchedData, refreshTable, tableData, filters, message, error, setLoading, setRefreshTable, setTableData, setFilters, setMessage, setError];
};
