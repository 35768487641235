import React, { useEffect, useState } from 'react';
import { AttributeEditor, Header, Container } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from "@/lib/misc/utils";
import { isEmpty } from "lodash";
import AttributeKeyValueTextComponent from "@/presentation/components/attribute-editor/attribute-key-value-text-component";
import ACLValueSelectComponent from '@/presentation/components/attribute-editor/acl-value-select-component';
const ACLEditor = (props) => {
    var _a;
    const [items, setItems] = React.useState(props.items);
    const [errorItems, setErrorItems] = React.useState([]);
    const [disableAddButton, setDisableAddButton] = React.useState(((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) === props.itemLimit);
    const [touchedFields, setTouchedFields] = useState({});
    const [valueOptions] = useState(props.valueSelectOptions);
    const ref = React.useRef(null);
    const aclEditorRef = React.useRef(null);
    useEffect(() => {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
            if (hashValue === props.scrollIntoViewHash) {
                document.getElementById(props.scrollIntoViewHash).scrollIntoView();
            }
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const getErrorItems = (itemsData, interactiveFields = touchedFields) => {
        const result = props.attributeKeyValueValidator.validate(itemsData, props.selectedDefaultAction, interactiveFields);
        // @ts-ignore
        return !(result === null || result === void 0 ? void 0 : result.valid) ? result === null || result === void 0 ? void 0 : result.errorItems : [];
    };
    const areAllItemsEmpty = () => {
        var _a;
        return ((_a = items.filter(item => isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) && isEmpty(String(item === null || item === void 0 ? void 0 : item.value).trim()))) === null || _a === void 0 ? void 0 : _a.length) === (items === null || items === void 0 ? void 0 : items.length);
    };
    const uniqueArrayById = (array) => {
        const uniqueMap = {};
        array.forEach(item => {
            const keyValue = item.id;
            if (!uniqueMap[keyValue]) {
                uniqueMap[keyValue] = item;
            }
        });
        return Object.values(uniqueMap);
    };
    const checkErrors = (interactiveFields, data = items) => {
        let errItems = [];
        if (!areAllItemsEmpty()) {
            const currErrItems = getErrorItems(data, interactiveFields);
            errItems = uniqueArrayById([...errorItems, ...currErrItems]);
            setErrorItems(errItems);
        }
    };
    useEffect(() => {
        var _a;
        if (props.recheckErrors) {
            let tempInteractiveFields = {};
            (_a = props === null || props === void 0 ? void 0 : props.items) === null || _a === void 0 ? void 0 : _a.forEach(item => {
                const temKeyId = `key-${item.id}`;
                const temValId = `value-${item.id}`;
                tempInteractiveFields = Object.assign(Object.assign({}, tempInteractiveFields), { [temKeyId]: item, [temValId]: item });
            });
            setTouchedFields(tempInteractiveFields);
            checkErrors(tempInteractiveFields, items);
        }
    }, [props.recheckErrors]);
    useEffect(() => {
        checkErrors(touchedFields, items);
    }, [props.selectedDefaultAction]);
    useEffect(() => {
        setItems(props.items);
    }, [props.items]);
    const handleOnItemsChange = (itemsData) => {
        setItems(itemsData);
        props.onChange(itemsData);
        setDisableAddButton(itemsData.length === props.itemLimit);
    };
    const itemKeyValueOnChange = (text, item, value) => {
        const filtered = items === null || items === void 0 ? void 0 : items.filter(it => (it === null || it === void 0 ? void 0 : it.id) === (item === null || item === void 0 ? void 0 : item.id));
        const errorRefName = value ? 'errorInValue' : 'errorInKey';
        const errorRefCode = value ? 'errorCodeValue' : 'errorCodeKey';
        const itemFieldName = value ? 'value' : 'key';
        const currErrItems = [...errorItems];
        if (filtered === null || filtered === void 0 ? void 0 : filtered.length) {
            filtered[0][itemFieldName] = text;
            // we do this to maintain the order of key values
            const tmpItems = [];
            items === null || items === void 0 ? void 0 : items.forEach(it => {
                if ((it === null || it === void 0 ? void 0 : it.id) !== (item === null || item === void 0 ? void 0 : item.id)) {
                    tmpItems.push(it);
                }
                else {
                    tmpItems.push(filtered[0]);
                }
            });
            if (filtered[0].key === "" && filtered[0].value === "") {
                const filteredItems = (currErrItems === null || currErrItems === void 0 ? void 0 : currErrItems.filter(errItem => (errItem === null || errItem === void 0 ? void 0 : errItem.id) !== (item === null || item === void 0 ? void 0 : item.id))) || [];
                setErrorItems(filteredItems);
            }
            else {
                const filteredItems = (currErrItems === null || currErrItems === void 0 ? void 0 : currErrItems.filter(errItem => ((errItem === null || errItem === void 0 ? void 0 : errItem.id) === (item === null || item === void 0 ? void 0 : item.id)))) || [];
                if (filteredItems.length > 0) {
                    const tempItems = filteredItems[0];
                    tempItems[errorRefName] = false;
                    tempItems[errorRefCode] = undefined;
                    const updateErrItems = uniqueArrayById([...currErrItems, tempItems]);
                    setErrorItems(updateErrItems);
                }
            }
            handleOnItemsChange(tmpItems);
        }
    };
    const handleKeyOnBlur = (item, interactedInputFields) => {
        setTimeout(() => {
            var _a, _b;
            const activeElement = document.activeElement;
            const activeId = (_a = activeElement === null || activeElement === void 0 ? void 0 : activeElement.dataset) === null || _a === void 0 ? void 0 : _a.id;
            const activeTagName = (_b = activeElement === null || activeElement === void 0 ? void 0 : activeElement.tagName) === null || _b === void 0 ? void 0 : _b.toLowerCase();
            if (activeTagName !== 'a' && activeTagName !== 'button' && activeId !== 'button-handle') {
                checkErrors(interactedInputFields, [item]);
            }
        }, 0);
    };
    const handleOnBlurValueFields = (item, itemType) => {
        const itemId = `${itemType}-${item.id}`;
        const interactedInputFields = Object.assign(Object.assign({}, touchedFields), { [itemId]: item });
        setTouchedFields(interactedInputFields);
        if (itemType === 'key') {
            handleKeyOnBlur(item, interactedInputFields);
        }
        else {
            checkErrors(interactedInputFields, [item]);
        }
    };
    const handleAttributeValueChange = (event, item) => {
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.selectedOption) === null || _b === void 0 ? void 0 : _b.value) !== item.value) {
            const attribute = {
                id: item.id,
                key: item.key,
                value: (_d = (_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.selectedOption) === null || _d === void 0 ? void 0 : _d.value,
            };
            itemKeyValueOnChange((_f = (_e = event === null || event === void 0 ? void 0 : event.detail) === null || _e === void 0 ? void 0 : _e.selectedOption) === null || _f === void 0 ? void 0 : _f.value, attribute, true);
        }
        handleOnBlurValueFields(item, 'value');
    };
    const getAtributeProps = (item, name, value) => {
        const placeholderText = value ? props.attributeValuePlaceholder : props.attributeKeyPlaceholder;
        return {
            placeholderText,
            keyOrValue: name,
            item,
            items,
            attributeErrorCodeMappings: props.attributeErrorCodeMappings,
            errorItems,
            onChange: (event) => {
                var _a;
                itemKeyValueOnChange((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.value, item, value);
            }
        };
    };
    const handleOnKeyDown = (evt, item) => {
        if (aclEditorRef === null || aclEditorRef === void 0 ? void 0 : aclEditorRef.current) {
            if ((evt === null || evt === void 0 ? void 0 : evt.key) === 'Tab') {
                const itemId = `key-${item.id}`;
                const interactedInputFields = Object.assign(Object.assign({}, touchedFields), { [itemId]: item });
                setTouchedFields(interactedInputFields);
                checkErrors(interactedInputFields, [item]);
            }
        }
    };
    const AttributeKeyComp = (item) => {
        const attrKeyProps = getAtributeProps(item, "key", false);
        return (React.createElement("div", { ref: aclEditorRef, onKeyDown: (evt) => handleOnKeyDown(evt, item) },
            React.createElement(AttributeKeyValueTextComponent, Object.assign({ onBlur: () => {
                    handleOnBlurValueFields(item, 'key');
                } }, attrKeyProps))));
    };
    const AttributeValueComp = (item) => {
        return React.createElement(ACLValueSelectComponent, { errorItems: errorItems, onChange: handleAttributeValueChange, item: item, items: items, onBlur: () => {
                handleOnBlurValueFields(item, 'value');
            }, options: valueOptions, attributeErrorCodeMappings: props.attributeErrorCodeMappings });
    };
    return (React.createElement("div", { ref: ref, id: props.scrollIntoViewHash },
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2", description: (props === null || props === void 0 ? void 0 : props.headerDescription) || '' }, props.headerLabel) },
            React.createElement("div", { className: 'attribute-editor-container' },
                React.createElement(AttributeEditor, { items: items, definition: [
                        {
                            label: props.attributeKeyLabel,
                            control: AttributeKeyComp,
                            constraintText: (item, index) => index === items.length - 1
                                ? "Fully qualified domain name or parent domain with dot prefix to include all subdomains"
                                : null
                        },
                        {
                            label: props.attributeValueLabel,
                            control: AttributeValueComp,
                            constraintText: (item, index) => index === items.length - 1
                                ? "Must be different to the default action"
                                : null
                        }
                    ], addButtonText: props.addButtonText, removeButtonText: props.removeButtonText, onAddButtonClick: () => {
                        if (items.length < props.itemLimit) {
                            const attr = {
                                id: getRandomUUID(),
                                key: '',
                                value: ''
                            };
                            handleOnItemsChange([...items, attr]);
                        }
                    }, onRemoveButtonClick: ({ detail: { itemIndex } }) => {
                        const tmpItems = [...items];
                        tmpItems.splice(itemIndex, 1);
                        handleOnItemsChange(tmpItems);
                        checkErrors(touchedFields, tmpItems);
                    }, disableAddButton: disableAddButton, empty: 'No domains are configured for this ACL' })))));
};
export default ACLEditor;
