import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { SettingsHandlersProvider } from "@/presentation/providers/settings-handlers-provider";
import { breadcrumbsSettingsCloudTenantStructureCreate, breadcrumbsSettingsCloudTenantStructureEdit, } from "@/presentation/components/commons/breadcrumbs";
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import SettingsCloudTenantFormWrapper from "@/presentation/pages/settings/cloud-tenants/settings-cloud-tenant-form-wrapper";
import { useParams } from "react-router-dom";
const MakeSettingsCloudTenantPage = (props) => {
    var _a;
    const { notifications } = useNotifications();
    const params = useParams();
    const tenantID = (_a = params === null || params === void 0 ? void 0 : params.tenantID) !== null && _a !== void 0 ? _a : '';
    return React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: props.isCreate ? breadcrumbsSettingsCloudTenantStructureCreate : breadcrumbsSettingsCloudTenantStructureEdit(tenantID), content: React.createElement(SettingsHandlersProvider, null,
                React.createElement(SettingsCloudTenantFormWrapper, Object.assign({}, props))), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }) }));
};
export default MakeSettingsCloudTenantPage;
