import React, { useContext, useMemo, useState } from "react";
const defaultAppContext = {
    isSwitchOrg: false,
    setIsSwitchOrg: () => { },
    isFormDirty: false,
    setIsFormDirty: () => { },
    setDirtyFormResourceName: () => { },
    dirtyFormResourceName: ''
};
export const AppContext = React.createContext(defaultAppContext);
export const useAppContext = () => useContext(AppContext);
export const AppContextProvider = (props) => {
    const [isSwitchOrg, setIsSwitchOrg] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [dirtyFormResourceName, setDirtyFormResourceName] = useState(null);
    const contextValue = useMemo(() => ({ isSwitchOrg, setIsSwitchOrg, isFormDirty, setIsFormDirty, dirtyFormResourceName, setDirtyFormResourceName }), [isSwitchOrg, isFormDirty, dirtyFormResourceName]);
    return (React.createElement(AppContext.Provider, { value: contextValue }, props.children));
};
