import React from 'react';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { makeBreadcrumbsFromURLPath, } from "@/presentation/components/commons/breadcrumbs";
import { IdentitiesHandlersProvider } from "@/presentation/providers";
import { Flashbar } from "@cloudscape-design/components-themed/components";
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { useNotifications } from "@/presentation/providers/notifications-provider";
import IdentitiesFormWrapper from "@/presentation/pages/identities/identities-form-wrapper";
import { useLocation } from "react-router-dom";
const MakeIdentitiesWizard = (props) => {
    const { notifications } = useNotifications();
    const location = useLocation();
    const breadCrumbs = makeBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, contentType: "form", notifications: React.createElement(Flashbar, { items: notifications, 
                // @ts-ignore
                stackItems: true }), content: React.createElement(React.Fragment, null,
                React.createElement(IdentitiesHandlersProvider, null,
                    React.createElement(IdentitiesFormWrapper, Object.assign({}, props)))) })));
};
export default MakeIdentitiesWizard;
