import React, { useEffect, useState } from "react";
import { useAuth0 } from "@/lib/auth0";
import { GetDashboardURL, SetDashboardURL, } from "@/presentation/storage/misc-storage";
import { DASHBOARD_METABASE_BILLING_LINK } from "@/presentation/storage";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import Skeleton from "react-loading-skeleton";
import IframeWithResize from "@/presentation/components/iframe/iframe-with-resize";
import { useIsMounted } from "@/presentation/hooks";
import { Box } from "@cloudscape-design/components-themed/components";
import BillingErrorPage from "./settings-billing-error";
const Billing = (props) => {
    const [loading, setLoading] = useState(true);
    const { user, rawToken } = useAuth0();
    const [dashboardLink, setDashboardLink] = useState("");
    const isMounted = useIsMounted();
    const [errorStatus, setErrorStatus] = useState(false);
    const handleFailedRequest = () => {
        if (isMounted.current) {
            setLoading(false);
            setErrorStatus(true);
        }
    };
    const fetchDashboardInfo = () => {
        const orgID = String(getOrgIDFromToken(rawToken));
        const dashboardObj = GetDashboardURL(DASHBOARD_METABASE_BILLING_LINK + orgID)
            ? JSON.parse(GetDashboardURL(DASHBOARD_METABASE_BILLING_LINK + orgID))
            : undefined;
        if (!dashboardObj) {
            props.dashboardHandler
                .getMetabaseBillingLink()
                .then((url) => {
                if (isMounted.current) {
                    if (!(url === null || url === void 0 ? void 0 : url.error) && (url === null || url === void 0 ? void 0 : url.data) && isMounted.current) {
                        SetDashboardURL(DASHBOARD_METABASE_BILLING_LINK + orgID, {
                            url: url === null || url === void 0 ? void 0 : url.data,
                        });
                        setDashboardLink(url === null || url === void 0 ? void 0 : url.data);
                        setLoading(false);
                    }
                    else {
                        handleFailedRequest();
                    }
                }
            })
                .catch((error) => {
                handleFailedRequest();
            });
        }
        else if (isMounted.current) {
            const dashboardLink = dashboardObj === null || dashboardObj === void 0 ? void 0 : dashboardObj.url;
            setDashboardLink(dashboardLink);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (isMounted.current) {
            setLoading(true);
            fetchDashboardInfo();
        }
    }, [user]);
    if (loading || !user) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(Box, { margin: { top: "l" } }, errorStatus ? React.createElement(BillingErrorPage, null) : React.createElement(IframeWithResize, { src: dashboardLink })));
};
export default Billing;
