var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { KiveraGQLClient } from '@/infra/http';
import { RemoteBaseClass } from '@/data/usecases';
export class RemoteProxies extends RemoteBaseClass {
    constructor(getTokenSilently) {
        super(getTokenSilently);
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListProxies(); }));
        });
    }
    get(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).GetProxyDetails(params); }));
        });
    }
    create(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).CreateProxyV2(params); }));
        });
    }
    delete(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).DeleteProxyV2(params); }));
        });
    }
    deleteBulk(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).DeleteProxies(params); }));
        });
    }
    update(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).UpdateProxyV2(params); }));
        });
    }
    createProxyDeployment(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).CreateProxyDeployment(params); }));
        });
    }
    getProxyDeploymentConfig(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).GetProxyDeploymentConfigV1_4(params); }));
        });
    }
    updateProxyDeployment(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).UpdateProxyDeployment(params); }));
        });
    }
}
