import { Badge, Box, ColumnLayout, Container, Header, SpaceBetween } from '@cloudscape-design/components-themed/components';
import React, { useEffect, useState } from 'react';
import { getContainerHeight } from '@/presentation/common-utils/common-utils';
import { makeEmptyPanelContent, makeSplitPanelI18nStrings } from '@/presentation/pages';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
const AuditLogDetails = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Log Details") },
        React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                    React.createElement(Box, null, (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.id)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "API"),
                    React.createElement(Box, null, (_b = props === null || props === void 0 ? void 0 : props.data) === null || _b === void 0 ? void 0 : _b.service)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Org"),
                    React.createElement(Box, null, (_d = (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.rawJson) === null || _d === void 0 ? void 0 : _d.org))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "User Email"),
                    React.createElement(Box, null, (_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.useremail)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "User Name"),
                    React.createElement(Box, null, (_f = props === null || props === void 0 ? void 0 : props.data) === null || _f === void 0 ? void 0 : _f.username)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "User Role"),
                    React.createElement(Box, null,
                        React.createElement(Badge, { color: 'blue' }, (_g = props === null || props === void 0 ? void 0 : props.data) === null || _g === void 0 ? void 0 : _g.userrole)))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Action Name"),
                    React.createElement(Box, null, (_h = props === null || props === void 0 ? void 0 : props.data) === null || _h === void 0 ? void 0 : _h.actionname)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Action Type"),
                    React.createElement(Box, null, (_j = props === null || props === void 0 ? void 0 : props.data) === null || _j === void 0 ? void 0 : _j.actiontype)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "IP"),
                    React.createElement(Box, null, (_o = (_m = (_l = (_k = props === null || props === void 0 ? void 0 : props.data) === null || _k === void 0 ? void 0 : _k.rawJson) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.http) === null || _o === void 0 ? void 0 : _o.ip)))));
};
const AuditLogRawJson = (props) => {
    var _a, _b;
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('settingsAuditLogsEditor', setEditorHeight, editorHeight);
        }
    }, [currentRef]);
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Raw JSON") },
        React.createElement("div", { ref: ref, id: "settingsAuditLogsEditor" },
            React.createElement(CodeEditor, { code: JSON.stringify((_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.rawJson) !== null && _b !== void 0 ? _b : {}, null, 2), height: editorHeight, heightType: 'px', readOnly: true, language: 'json' })));
};
export const MakeAuditLogSplitPanel = (items) => {
    if (!items.length) {
        return makeEmptyPanelContent('Select a audit log record to see its details.');
    }
    const item = items[0];
    const header = 'ID: ' + item.id;
    return {
        header: header,
        panelStrings: makeSplitPanelI18nStrings(),
        body: (React.createElement(SpaceBetween, { size: 'xl' },
            React.createElement(AuditLogDetails, { data: item }),
            React.createElement(AuditLogRawJson, { data: item })))
    };
};
