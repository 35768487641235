import React, { useEffect, useState } from 'react';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useAuth0 } from '@/lib/auth0';
import InmemoryCards from '@/presentation/components/cards/inmemory-cards';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { ProxiesListCardsColumnDefinitions, ProxiesListCardsPageSizeOptions, ProxiesListCardsPreferences, ProxiesListCardsVisibleContentOptions, ProxiesListFilteringOptions, ProxiesListFilteringProperties } from '@/presentation/pages/proxies/proxies-list-cards-config';
import { useNavigate } from 'react-router-dom';
import { ProxyDeleteShowProp, ProxyDeleteStrings } from '@/presentation/pages/proxies/proxies-types';
import { useProxiesHandlers } from '@/presentation/providers';
import { Button, SpaceBetween } from '@cloudscape-design/components-themed/components';
import { makePropertyFilterI18nStrings } from '@/presentation/pages';
import DeleteRecord from '@/presentation/components/table/delete-record';
import { TableEmptyState } from '@/presentation/components/commons/common-components';
import { PATH_PROXIES, TABLE_FILTERS_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { useIsMounted } from '@/presentation/hooks';
import { manageCreatedUpdatedList } from '../common-config';
const ProxiesList = () => {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [allProxies, setAllProxies] = useState([]);
    const [filteringOptions, setFilteringOptions] = useState(ProxiesListFilteringOptions);
    const navigate = useNavigate();
    const { proxies, providers, services } = useProxiesHandlers();
    const proxiesHandler = proxies;
    const providersHandler = providers;
    const servicesHandler = services;
    const [selectedItems, setSelectedItems] = useState([]);
    const collectionResourceName = 'Proxies';
    const createOnClick = () => navigate(PATH_PROXIES.CREATE);
    const tableEmptyState = React.createElement(TableEmptyState, { resourceName: collectionResourceName });
    const isMounted = useIsMounted();
    const [userFilteringOptions, setUserFilteringOptions] = useState([]);
    const actionButtons = () => {
        return (React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
            React.createElement(DeleteRecord, { onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: ProxyDeleteStrings, itemDeleteShowProp: ProxyDeleteShowProp, entityHandler: proxiesHandler, deleteFunctionName: "deleteBulk" }),
            React.createElement(Button, { variant: "primary", onClick: createOnClick, "data-testid": 'create-rule-button-from-list-page' }, "Create Proxy")));
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        proxiesHandler === null || proxiesHandler === void 0 ? void 0 : proxiesHandler.listEntity().then((entityItems) => {
            const formattedItems = proxiesHandler === null || proxiesHandler === void 0 ? void 0 : proxiesHandler.getFormattedEntities(entityItems);
            const userFilteredOptionsList = manageCreatedUpdatedList(formattedItems);
            if (isMounted.current) {
                setAllProxies(formattedItems);
                setUserFilteringOptions(userFilteredOptionsList);
            }
            providersHandler === null || providersHandler === void 0 ? void 0 : providersHandler.listEntity().then((providers) => {
                if (providers.Providers.length > 0) {
                    const providersFilteringOptions = providersHandler.getPropertyFilteringOptions(providers, 'providerNames');
                    servicesHandler.listEntity().then((listServices) => {
                        listServices = servicesHandler.sortServicesByName(listServices);
                        const servicesFilteringOptions = servicesHandler.getPropertyFilteringOptions(listServices);
                        if (isMounted.current) {
                            setFilteringOptions([...filteringOptions, ...providersFilteringOptions, ...servicesFilteringOptions]);
                        }
                    }).catch((e) => {
                        console.error(' Unable to fetch services', e);
                    }).finally(() => {
                        if (isMounted.current) {
                            setLoading(false);
                        }
                    });
                }
            }).catch((e) => {
                console.error(' Unable to fetch providers', e);
            });
        }).catch((e) => {
            console.error('unable to list entity got error:', e);
        });
    };
    const selectionChangeCallback = (items) => {
        setSelectedItems(items);
    };
    const onDeleteConfirm = () => {
        setSelectedItems([]);
        refreshList();
    };
    useEffect(() => {
        refreshList();
    }, [user]);
    return (React.createElement(ContentWrapper, { testid: "proxy-list-items" },
        React.createElement(InmemoryCards, { loading: loading, loadingText: 'Loading Proxies', preferencesStorageKey: 'proxies-list-cards', columnDefinitions: ProxiesListCardsColumnDefinitions, defaultPreferences: ProxiesListCardsPreferences, items: allProxies, selectionChangeCallback: selectionChangeCallback, entityHandler: proxiesHandler, collectionResourceName: 'Proxies', cardPreferencePageSize: ProxiesListCardsPreferences.pageSize, resourceName: 'Proxies', actionButtons: actionButtons(), tableEmptyState: tableEmptyState, filteringAriaLabel: 'Filter Proxies', filteringPlaceholder: 'Filter Proxies', paginationLabels: paginationLabels, visibleContentOptions: ProxiesListCardsVisibleContentOptions, pageSizeOptions: ProxiesListCardsPageSizeOptions, propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Proxies' }), propertyFilterFilteringProperties: ProxiesListFilteringProperties, propertyFilterFilteringOptions: [...filteringOptions, ...userFilteringOptions], dataTestId: "create-proxy-button-from-list-page", propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.PROXIES_LIST })));
};
export default ProxiesList;
