import React, { useEffect, useState } from 'react';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useAuth0 } from '@/lib/auth0';
import InmemoryTable from '@/presentation/components/table/inmemory-table';
import { useNavigate } from 'react-router-dom';
import { RulesLibraryListTableFilteringOptions, RulesListColumnDefinitions, RulesListPropertyFilterFilteringProperties, RulesListTableFilteringOptions, RulesListTablePageSizeOptions, RulesListTablePreferences, RulesListTableVisibleContentOptions } from '@/presentation/pages/rules/rules-list-config';
import { PATH_RULES, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { ORG_ZERO, RulesDeleteShowProp, RulesDeleteStrings } from '@/presentation/pages/rules/rules-types';
import { makeNotification, makePropertyFilterI18nStrings, PagesRequestErrorHandler } from '@/presentation/pages';
import { useRulesHandlers } from '@/presentation/providers';
import { TableEmptyState } from '@/presentation/components/commons/common-components';
import { Button, SpaceBetween } from '@cloudscape-design/components-themed/components';
import RulesLibrary from '@/presentation/pages/rules/rules-library/rules-library';
import OverlayBouncyLoader from '@/presentation/components/loader/overlay-bouncy-loader';
import DeleteRecord from '@/presentation/components/table/delete-record';
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { Tooltip } from '@/presentation/components/tooltip';
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import { manageCreatedUpdatedList } from '../common-config';
import { useIsMounted } from '@/presentation/hooks';
const RulesList = (props) => {
    const { user, rawToken } = useAuth0();
    const currentOrgID = String(getOrgIDFromToken(rawToken));
    const fullUserDetails = user;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { rules, services, providers, complianceMappings } = useRulesHandlers();
    const rulesHandler = rules;
    const servicesHandler = services;
    const providersHandler = providers;
    const complianceMappingsHandler = complianceMappings;
    const [allRules, setAllRules] = useState([]);
    const [filteringOptions, setFilteringOptions] = useState(RulesListTableFilteringOptions);
    const [filteringOptionsRulesLibrary, setFilteringOptionsRulesLibrary] = useState(RulesLibraryListTableFilteringOptions);
    const [providerFilteringOptions, setProviderFilteringOptions] = useState([]);
    const [userFilteringOptions, setUserFilteringOptions] = useState([]);
    const { pushNotifications, dismissNotification } = useNotifications();
    const [selectedItems, setSelectedItems] = useState([]);
    const [showRulesLibraryModal, setShowRulesLibraryModal] = useState(false);
    const onRulesLibraryDiscard = () => {
        document.body.style.overflowY = "auto";
        setShowRulesLibraryModal(false);
    };
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const [managedRules, setManagedRules] = useState([]);
    const [managedRulesLoading, setManagedRulesLoading] = useState(true);
    const isMounted = useIsMounted();
    const createButtonText = 'Create Rule';
    const collectionResourceName = 'Rules';
    const createOnClick = () => navigate(PATH_RULES.CREATE);
    const tableEmptyState = React.createElement(TableEmptyState, { resourceName: collectionResourceName });
    const onDeleteConfirm = () => {
        setSelectedItems([]);
        refreshList();
    };
    const showRulesLibraryDelayed = () => {
        setIsOverlayLoading(true);
        document.body.style.overflowY = "hidden";
        setShowRulesLibraryModal(true);
        setIsOverlayLoading(false);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeNotification('error', getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage, dismissNotification));
    };
    const importRules = (rules) => {
        if (isMounted.current) {
            setIsOverlayLoading(true);
        }
        rulesHandler.importManagedRules(rules).then(result => {
            var _a, _b;
            if (((_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Rules) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) === (rules === null || rules === void 0 ? void 0 : rules.length)) {
                pushNotifications(makeNotification('success', 'Rules have been imported successfully', dismissNotification));
                if (isMounted.current) {
                    setShowRulesLibraryModal(false);
                }
                refreshList();
            }
            else {
                pushNotifications(makeNotification('error', 'Unable to import. Please try again later.', dismissNotification));
            }
        })
            .catch(({ response }) => {
            handleErrorResponse(response);
        })
            .finally(() => {
            if (isMounted.current) {
                setIsOverlayLoading(false);
            }
        });
    };
    const checkAndImportRules = (toBeEnabledServiceRules, importableRules) => {
        if (isMounted.current) {
            setIsOverlayLoading(true);
        }
        if (toBeEnabledServiceRules.length > 0) {
            rulesHandler.enableServices(toBeEnabledServiceRules).then(result => {
                var _a, _b;
                if (((_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Services) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    pushNotifications(makeNotification('success', 'Global Services Enabled Successfully', dismissNotification));
                    importRules(importableRules);
                }
                else {
                    pushNotifications(makeNotification('error', 'Unable to enable services. Please try again later.', dismissNotification));
                    if (isMounted.current) {
                        setIsOverlayLoading(true);
                    }
                }
            })
                .catch(({ response }) => {
                handleErrorResponse(response);
                if (isMounted.current) {
                    setIsOverlayLoading(true);
                }
            });
        }
        else {
            importRules(importableRules);
        }
    };
    const actionButtons = () => {
        return (React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
            React.createElement("div", { className: !fullUserDetails.org_beta_access ? 'rules-library-tooltip' : '' },
                React.createElement(Button, { variant: "normal", onClick: showRulesLibraryDelayed, disabled: !fullUserDetails.org_beta_access, "data-testid": 'rules-library-button-from-list-page' }, "Rules Library"),
                React.createElement(Tooltip, { anchorSelect: '.rules-library-tooltip', place: 'bottom', opacity: 1, style: { zIndex: 1 } }, "Please contact Kivera Support to enable beta access")),
            React.createElement(DeleteRecord, { onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: RulesDeleteStrings, itemDeleteShowProp: RulesDeleteShowProp, entityHandler: rulesHandler, deleteFunctionName: "deleteBulk" }),
            React.createElement(Button, { variant: "primary", onClick: createOnClick, "data-testid": 'create-rule-button-from-list-page' }, createButtonText)));
    };
    const selectionChangeCallback = (items) => {
        setSelectedItems(items);
    };
    const fetchManagedRules = () => {
        console.log('Fetching Managed Rules in background');
        rulesHandler === null || rulesHandler === void 0 ? void 0 : rulesHandler.listManagedRules().then(mRules => {
            const formattedRules = rulesHandler.getFormattedManagedRules(mRules);
            if (isMounted.current) {
                setManagedRules(formattedRules);
                setManagedRulesLoading(false);
            }
            console.log('Managed Rules Fetched');
        }).catch(({ response }) => {
            handleErrorResponse(response);
        });
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        rulesHandler === null || rulesHandler === void 0 ? void 0 : rulesHandler.listEntity().then((entityItems) => {
            const formattedItems = rulesHandler === null || rulesHandler === void 0 ? void 0 : rulesHandler.getFormattedEntities(entityItems);
            const userFilteredOptionsList = manageCreatedUpdatedList(formattedItems);
            if (isMounted.current) {
                setAllRules(formattedItems);
                setUserFilteringOptions(userFilteredOptionsList);
            }
            providersHandler.listEntity().then((providers) => {
                if (providers.Providers.length > 0) {
                    const providersFilteringOptions = providersHandler.getPropertyFilteringOptions(providers);
                    servicesHandler.listEntity().then((listServices) => {
                        let uniqueComplianceFrameworksForCurrentOrg = [];
                        let uniqueComplianceFrameworksForManagedRules = [];
                        complianceMappingsHandler.listAll().then(listMappings => {
                            uniqueComplianceFrameworksForCurrentOrg = complianceMappingsHandler.getUniqueComplianceFrameworksForOrg(listMappings === null || listMappings === void 0 ? void 0 : listMappings.ComplianceMappings, currentOrgID);
                            uniqueComplianceFrameworksForManagedRules = complianceMappingsHandler.getUniqueComplianceFrameworksForOrg(listMappings === null || listMappings === void 0 ? void 0 : listMappings.ComplianceMappings, ORG_ZERO.toString()); // Org 0 is for Managed Rules
                        }).catch(({ response }) => {
                            handleErrorResponse(response);
                        }).finally(() => {
                            const complianceFrameworksFilteringOptionsForCurrentOrg = complianceMappingsHandler.getUniqueComplianceFrameworkPropertyFilteringOptions(uniqueComplianceFrameworksForCurrentOrg, 'complianceFrameworks');
                            const complianceFrameworksFilteringOptionsForManagedRules = complianceMappingsHandler.getUniqueComplianceFrameworkPropertyFilteringOptions(uniqueComplianceFrameworksForManagedRules, 'complianceFrameworks');
                            listServices = servicesHandler.sortServicesByName(listServices);
                            const servicesFilteringOptions = servicesHandler.getPropertyFilteringOptions(listServices);
                            if (isMounted.current) {
                                setFilteringOptions([...filteringOptions, ...providersFilteringOptions, ...servicesFilteringOptions, ...complianceFrameworksFilteringOptionsForCurrentOrg]);
                                setFilteringOptionsRulesLibrary([...filteringOptionsRulesLibrary, ...providersFilteringOptions, ...servicesFilteringOptions, ...complianceFrameworksFilteringOptionsForManagedRules]);
                                setProviderFilteringOptions(providersFilteringOptions);
                            }
                        });
                    }).catch(({ response }) => {
                        handleErrorResponse(response);
                    }).finally(() => {
                        if (isMounted.current) {
                            setLoading(false);
                        }
                        // do it once
                        if (managedRulesLoading && (fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.org_beta_access)) {
                            fetchManagedRules();
                        }
                    });
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
            });
        }).catch(({ response }) => {
            handleErrorResponse(response);
        });
    };
    useEffect(() => {
        refreshList();
    }, [user]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "rules-list-page-container" },
            React.createElement(InmemoryTable, { stickyHeader: true, loading: loading, loadingText: 'Loading Rules...', selectionType: 'multi', variant: 'full-page', columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.RULES_LIST, preferencesStorageKey: PREFERENCES_STORAGE_KEY.RULES_LIST, columnDefinitions: RulesListColumnDefinitions(), defaultPreferences: RulesListTablePreferences, items: allRules, ariaLabels: {}, resourceName: 'Rules', tableHeaderVariant: 'awsui-h1-sticky', actionButtons: actionButtons(), tableEmptyState: tableEmptyState, selectionChangeCallback: selectionChangeCallback, filteringAriaLabel: 'Filter Rules', filteringPlaceholder: 'Filter Rules', paginationLabels: paginationLabels, visibleContentOptions: RulesListTableVisibleContentOptions, pageSizeOptions: RulesListTablePageSizeOptions, entityHandler: rulesHandler, propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Rules' }), propertyFilterFilteringProperties: RulesListPropertyFilterFilteringProperties, propertyFilterFilteringOptions: [...filteringOptions, ...userFilteringOptions], propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.RULES_LIST, propertySortingStorageName: TABLE_SORTING_SESSION_STORAGE.RULES_LIST }),
            React.createElement("div", { id: 'rules-library-container' }, showRulesLibraryModal ?
                React.createElement(RulesLibrary, { dataLoading: managedRulesLoading, managedRules: managedRules, onImport: checkAndImportRules, providerFilterFilteringOptions: providerFilteringOptions, propertyFilterFilteringOptions: filteringOptionsRulesLibrary, onDiscard: onRulesLibraryDiscard, disableImport: false })
                :
                    null),
            isOverlayLoading && React.createElement(OverlayBouncyLoader, null))));
};
export default RulesList;
