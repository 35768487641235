import React, { Component } from 'react';
import DefaultErrorPage from "@/presentation/pages/errors/default-error-page";
const InitialErrorState = {
    hasError: false
};
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = InitialErrorState;
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        console.error('ErrorBoundary caught an error', error, info);
    }
    render() {
        var _a;
        // @ts-expect-error
        if (this.state.hasError) {
            return (React.createElement(DefaultErrorPage, null));
        }
        // eslint-disable-next-line react/prop-types
        return (_a = this.props) === null || _a === void 0 ? void 0 : _a.children;
    }
}
export default ErrorBoundary;
