import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useProfilesHandlers } from "@/presentation/providers";
import { useParams } from "react-router-dom";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { Header } from "@cloudscape-design/components-themed/components";
import { HeaderStrings } from "@/presentation/pages/profiles/profiles-types";
import ProfilesForm from "@/presentation/pages/profiles/profiles-form";
import ProfilesView from "@/presentation/pages/profiles/profiles-view";
import moment from 'moment';
import { formatExistingTags } from "@/presentation/pages";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import ContentFormLayoutWrapper from "@/presentation/components/content-wrapper/content-form-layout-wrapper";
const ProfilesFormWrapper = (props) => {
    const { setErrorStatus } = useErrorBoundaryContext();
    const [loading, setLoading] = useState(true);
    const [listRules, setListRules] = useState([]);
    const [profileDetails, setProfileDetails] = useState(undefined);
    const { profiles, rules } = useProfilesHandlers();
    const rulesHandler = rules;
    const profilesHandler = profiles;
    const params = useParams();
    const [profileInfo, setProfileInfo] = useState(undefined);
    const isMounted = useIsMounted();
    const getRulesList = (profile) => {
        const { id, name, description, tags, ProfileRules } = (profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) || {};
        rulesHandler
            .listEntity()
            .then((rules) => {
            var _a, _b;
            const profilesAndRules = (_b = (_a = ProfileRules === null || ProfileRules === void 0 ? void 0 : ProfileRules.filter((rule) => !!(rule === null || rule === void 0 ? void 0 : rule.rule_id))) === null || _a === void 0 ? void 0 : _a.map((rule) => rule === null || rule === void 0 ? void 0 : rule.rule_id)) !== null && _b !== void 0 ? _b : [];
            const formattedRules = rulesHandler.getSelectableInMemoryFormattedEntities(rules, profilesAndRules);
            if (isMounted.current) {
                setListRules(formattedRules);
                const existingTags = formatExistingTags(tags);
                const currentTimestamp = moment().format('YYYYMMDDHHmmss');
                const modifiedName = props.isClone ? `${name}-${currentTimestamp}` : name;
                const profilesFormInfo = {
                    details: { id, name: modifiedName, description },
                    rules: {
                        attachedRules: formattedRules.filter((profile) => profile.isSelected),
                    },
                    tags: {
                        editorTags: [...existingTags],
                    }
                };
                setProfileInfo(profilesFormInfo);
            }
        })
            .catch((e) => {
            console.error("unable to list entity got error:", e);
        })
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.profileID) && parseInt(params === null || params === void 0 ? void 0 : params.profileID, 10) !== 0) {
            const id = parseInt(params === null || params === void 0 ? void 0 : params.profileID, 10);
            let profilesAndRules;
            profilesHandler
                .getEntity(id)
                .then((profile) => {
                var _a;
                if (isMounted.current) {
                    if (profile && ((_a = profile === null || profile === void 0 ? void 0 : profile.Profiles_by_pk) === null || _a === void 0 ? void 0 : _a.id)) {
                        profilesAndRules = profile;
                        setProfileDetails(profile);
                        getRulesList(profilesAndRules);
                    }
                    else {
                        setErrorStatus(true);
                    }
                }
            })
                .catch(console.error);
        }
        else {
            getRulesList(undefined);
        }
    }, []);
    if ((props.isEdit && profileDetails === undefined) || !profileInfo) {
        return React.createElement(Skeleton, { count: 30 });
    }
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return props.isView ? (React.createElement(ProfilesView, { profile: profileDetails, profileInfo: profileInfo, rules: listRules })) : (React.createElement(ContentWrapper, { testid: "profile-create-edit-page" },
        React.createElement(ContentFormLayoutWrapper, { header: React.createElement(Header, { variant: "h1" }, props.isCreate || props.isClone ? HeaderStrings.create : HeaderStrings.edit) },
            React.createElement(ProfilesForm, { isCreate: props.isCreate, isEdit: props.isEdit, isClone: props.isClone, profileInfo: profileInfo, rules: listRules, profileDetails: profileDetails }))));
};
export default ProfilesFormWrapper;
