import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { SettingsHandlersProvider } from "@/presentation/providers/settings-handlers-provider";
import Settings from "@/presentation/pages/settings/settings";
import { useParams } from "react-router-dom";
const MakeSettingsPage = () => {
    const params = useParams();
    const resourceId = params === null || params === void 0 ? void 0 : params.id;
    const resourceName = params === null || params === void 0 ? void 0 : params.tabId;
    return React.createElement(React.Fragment, null,
        React.createElement(ErrorBoundaryContextProvider, null,
            React.createElement(SettingsHandlersProvider, null, resourceId ? React.createElement(Settings, { resourceId: resourceId, resourceName: resourceName }) : React.createElement(Settings, null))));
};
export default MakeSettingsPage;
