import React, { useState } from "react";
import { SettingsWizardEditI18nStrings } from "@/presentation/pages/settings/settings-types";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { Box, Button, Container, Form, FormField, Header, Input, SpaceBetween, TokenGroup, Toggle } from "@cloudscape-design/components-themed/components";
import { useNavigate } from "react-router-dom";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { useAuth0 } from "@/lib/auth0";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import { makeNotification } from "@/presentation/pages";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import { getUrlParamsName, makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
import { PATH_DOCUMENTATION, PATHS } from '@/presentation/common-utils/constants';
import ExternalLink from "@/presentation/components/links/external-link";
const SettingsForm = (props) => {
    var _a, _b;
    const [allowedDomains, setAllowedDomains] = useState(props.settingsInfo.details.allowedDomains);
    const navigate = useNavigate();
    const { rawToken } = useAuth0();
    const { pushNotifications, dismissNotification } = useNotifications();
    const { settings, allowedDomainsValidator } = useSettingsHandlers();
    const settingsHandler = settings;
    const [domain, setDomain] = useState('');
    const [domainsError, setDomainsError] = useState(false);
    const [domainsErrorMessage, setDomainsErrorMessage] = useState('');
    const [isMFAEnabled, setIsMFAEnabled] = useState(((_b = (_a = props === null || props === void 0 ? void 0 : props.settingsInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.enforceMFA) || false);
    const referrer = getUrlParamsName('referrer');
    const docsReferrer = referrer || window.location.pathname;
    const documentPath = `${PATH_DOCUMENTATION.MULTI_FACTOR_AUTHENTICATION}?referrer=${docsReferrer}`;
    const makeSuccessUpdateNotification = () => {
        const msg = "Settings Updated Successfully";
        return makeNotification("success", msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to update. Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onSubmit = () => {
        const orgID = getOrgIDFromToken(rawToken);
        if (!domainsError) {
            settingsHandler.updateOrganizationSettings(orgID, allowedDomains, isMFAEnabled).then((result) => {
                var _a;
                if ((_a = result === null || result === void 0 ? void 0 : result.update_Organizations_by_pk) === null || _a === void 0 ? void 0 : _a.allowed_domains) {
                    pushNotifications(makeSuccessUpdateNotification());
                    navigate(PATHS.SETTINGS);
                }
                else {
                    pushNotifications(makeErrorNotification());
                }
            }).catch(({ response }) => {
                handleErrorResponse(response);
            });
        }
        else {
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('organizational settings', 'update')));
        }
    };
    const handleError = (value) => {
        setDomainsError(value);
        setDomainsErrorMessage(value ? 'Please add at least one valid domain to the list' : '');
    };
    const clearError = () => {
        setDomainsError(false);
        setDomainsErrorMessage('');
    };
    const addAllowedDomainToList = (domain) => {
        var _a;
        if (!((_a = allowedDomainsValidator === null || allowedDomainsValidator === void 0 ? void 0 : allowedDomainsValidator.validate(domain)) === null || _a === void 0 ? void 0 : _a.valid)) {
            return;
        }
        clearError();
        const isWildcardPresent = allowedDomains.filter(allowedDomain => (allowedDomain === null || allowedDomain === void 0 ? void 0 : allowedDomain.label) === "*").length === 1;
        const tokenGroup = {
            label: domain,
        };
        if (isWildcardPresent && allowedDomains.length === 1) {
            setAllowedDomains([tokenGroup]);
        }
        else {
            setAllowedDomains([...allowedDomains, tokenGroup]);
        }
        setDomain('');
    };
    const hanldeMFAChange = (event) => {
        var _a;
        setIsMFAEnabled(((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.checked) || false);
    };
    const mfaDescription = (React.createElement(React.Fragment, null,
        React.createElement("span", null, "Enforce MFA for all users in this organization. Learn more about Kivera MFA"),
        React.createElement("span", { className: "px-1 kivera-external-link-xs" },
            React.createElement(ExternalLink, { to: documentPath, label: "here" }))));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'form-container' },
            React.createElement("form", { onSubmit: (event) => event.preventDefault() },
                React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                        React.createElement(Button, { variant: "link", onClick: () => navigate(-1) }, SettingsWizardEditI18nStrings.cancelButton),
                        React.createElement(Button, { variant: "primary", onClick: onSubmit }, SettingsWizardEditI18nStrings.submitButton)) },
                    React.createElement(SpaceBetween, { size: "l" },
                        React.createElement(Box, { margin: { bottom: 'l' } },
                            React.createElement(SpaceBetween, { size: "l" },
                                React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Settings") },
                                    React.createElement(SpaceBetween, { size: "l" },
                                        React.createElement(FormField, { label: "Organization Name" },
                                            React.createElement(Input, { disabled: true, value: props.settingsInfo.details.orgName })),
                                        React.createElement(FormField, { label: "Organization ID" },
                                            React.createElement(Input, { disabled: true, value: props.settingsInfo.details.orgID })),
                                        React.createElement(FormField, { label: "Allowed Domains", constraintText: "Specify a list of domains to restrict adding users only from those domains e.g. kivera.io (use * to allow all domains)", errorText: domainsError ? domainsErrorMessage : '' },
                                            React.createElement(SpaceBetween, { size: "l", direction: "horizontal" },
                                                React.createElement(Input, { placeholder: 'e.g. kivera.io', value: domain, onChange: (event) => {
                                                        setDomain(event.detail.value);
                                                    } }),
                                                React.createElement(Button, { variant: "normal", onClick: () => addAllowedDomainToList(domain) }, "Add to List")))),
                                    React.createElement(SpaceBetween, { size: "l" },
                                        React.createElement(Box, null,
                                            React.createElement(TokenGroup, { items: allowedDomains, onDismiss: (event) => {
                                                    if (allowedDomains.length === 1) {
                                                        handleError(true);
                                                    }
                                                    else {
                                                        handleError(false);
                                                    }
                                                    setAllowedDomains([
                                                        ...allowedDomains.slice(0, event.detail.itemIndex),
                                                        ...allowedDomains.slice(event.detail.itemIndex + 1)
                                                    ]);
                                                } })),
                                        React.createElement(FormField, { label: "Multi-Factor Authentication", description: mfaDescription },
                                            React.createElement(Toggle, { onChange: hanldeMFAChange, checked: isMFAEnabled })))))))))));
};
export default SettingsForm;
