var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useAuth0 } from '@/lib/auth0';
import App from '@/presentation/main/app';
import { ConfigProvider } from "react-avatar";
import OverlayBouncyLoader from '@/presentation/components/loader/overlay-bouncy-loader';
import LoginWithRedirect from "@/presentation/pages/on-boarding/login-with-redirect";
import { AppContextProvider } from '../providers/app-context-provider';
import AuthErrorPage from "@/presentation/pages/errors/auth-error-page";
const AppWrapper = ({ appProps }) => {
    const { isAuthenticated, loading, logout } = useAuth0();
    const [logoutFailed, setLogoutFailed] = useState(false);
    if (loading) {
        return React.createElement(OverlayBouncyLoader, null);
    }
    if (!isAuthenticated) {
        return React.createElement(LoginWithRedirect, null);
    }
    if (logoutFailed) {
        return React.createElement(AuthErrorPage, { handleLogout: logout, accessDenied: false });
    }
    // only if the user is authenticated and trying to logout by using url
    if (isAuthenticated && window.location.pathname === '/logout') {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            return yield logout().catch((err) => {
                if (err) {
                    console.error(err);
                    // for whatever reason the logout failed
                    // instead of just showing the bouncy loader,
                    // show Auth Error page
                    setLogoutFailed(true);
                }
            });
        }))();
        return React.createElement(OverlayBouncyLoader, null);
    }
    return (React.createElement(ConfigProvider, { colors: ['#6DCCB1', '#F5A35C', '#D2C0A0', '#79AAD9', '#E4A6C7'] },
        React.createElement(AppContextProvider, null,
            React.createElement(App, { appProps: appProps }))));
};
export default AppWrapper;
