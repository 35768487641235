import React, { useEffect, useState } from "react";
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
import { GetDocsToken, SetDocsToken, } from "@/presentation/storage/misc-storage";
import { DOCU_KIVERA_REDIRECTION } from "@/presentation/storage";
import { RemoteGetDocsLink, RemoteGetVersion } from "@/data/usecases";
import { makeManagementDocsAPIUrl, makeVersionTextUrl, } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { DocumentHandler } from "@/presentation/handlers";
import DefaultErrorPage from "@/presentation/pages/errors/default-error-page";
import { getUrlParamsName } from "@/presentation/common-utils/common-utils";
import { useParams } from "react-router-dom";
import { PATHS } from "@/presentation/common-utils/constants";
const Documentation = ({ docsPath }) => {
    const params = useParams();
    const docsParams = params["*"];
    const remotes = {
        docs: new RemoteGetDocsLink(makeManagementDocsAPIUrl(""), makeAuthorizeHttpClientDecorator()),
        version: new RemoteGetVersion(makeVersionTextUrl(), makeAuthorizeHttpClientDecorator()),
    };
    const documentHandler = new DocumentHandler(remotes);
    const [isDocsError, setIsDocsError] = useState(false);
    useEffect(() => {
        const referrer = getUrlParamsName('referrer');
        let defaultNavBackUrl = "/";
        if (referrer) {
            defaultNavBackUrl = referrer;
        }
        const docsToken = GetDocsToken(DOCU_KIVERA_REDIRECTION);
        let docsObj;
        if (docsToken) {
            docsObj = JSON.parse(docsToken);
        }
        if (!docsObj) {
            documentHandler
                .getLink()
                .then((url) => {
                var _a, _b;
                if (!(url === null || url === void 0 ? void 0 : url.error) && (url === null || url === void 0 ? void 0 : url.data)) {
                    const docsDatatoken = (_a = url === null || url === void 0 ? void 0 : url.data) === null || _a === void 0 ? void 0 : _a.split("auth_token=")[1];
                    const docsDataUrl = (_b = url === null || url === void 0 ? void 0 : url.data) === null || _b === void 0 ? void 0 : _b.split("?")[0];
                    SetDocsToken(DOCU_KIVERA_REDIRECTION, {
                        token: docsDatatoken,
                        url: docsDataUrl,
                    });
                    history.replaceState(null, null, defaultNavBackUrl);
                    if (docsParams && docsParams !== "") {
                        const docsDataLink = `${docsDataUrl}${PATHS.DOCS}/${docsParams}?auth_token=${docsDatatoken}`;
                        window.location.href = docsDataLink;
                    }
                    else {
                        window.location.href = url.data;
                    }
                }
                else {
                    setIsDocsError(true);
                }
            }).catch((e) => {
                console.error(e);
                setIsDocsError(true);
            });
        }
        else {
            history.replaceState(null, null, defaultNavBackUrl);
            let docsLink = (docsObj === null || docsObj === void 0 ? void 0 : docsObj.url) + "?auth_token=" + (docsObj === null || docsObj === void 0 ? void 0 : docsObj.token);
            if (docsPath) {
                docsLink = `${docsPath}?auth_token=${docsObj === null || docsObj === void 0 ? void 0 : docsObj.token}`;
            }
            else if (docsParams && docsParams !== "") {
                docsLink = `${docsObj === null || docsObj === void 0 ? void 0 : docsObj.url}${PATHS.DOCS}/${docsParams}?auth_token=${docsObj === null || docsObj === void 0 ? void 0 : docsObj.token}`;
            }
            window.location.href = docsLink;
        }
    }, []);
    if (isDocsError) {
        return React.createElement(DefaultErrorPage, null);
    }
    return React.createElement(OverlayBouncyLoader, null);
};
export default Documentation;
